import React from 'react';

import { FlexBlock, PaddedBlock } from '@utilComponents/layoutPrimitives';

function LabeledIcon({ text, icon, pos }) {
  let iconLeft;
  let iconRight;

  if (pos === 'left') {
    iconLeft = (
      <PaddedBlock tiny right>
        {icon}
      </PaddedBlock>
    );
  } else if (pos === 'right') {
    iconRight = (
      <PaddedBlock tiny left>
        {icon}
      </PaddedBlock>
    );
  }
  return (
    <FlexBlock vcenter>
      {iconLeft}
      {text}
      {iconRight}
    </FlexBlock>
  );
}

export default LabeledIcon;
