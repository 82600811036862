import * as React from 'react';

import { SingleSelect } from '@oldComponents/selectUIs';

function valueRenderer(option) {
  return <div style={{ backgroundColor: option.value }} className={'ColorSelectOption'} />;
}

function OptionRenderer(option) {
  return valueRenderer(option);
}

const colorOptions = [
  { value: '#acc2ff', label: 'Light purple' },
  { value: '#92f7d2', label: 'Mint green' },
  { value: '#86eaff', label: 'Sky blue' },
  { value: '#f7d292', label: 'Orange' },
  { value: '#ffff9a', label: 'Yellow' },
  { value: '#f4ccff', label: 'Light pink' },
  { value: '#ff9b8b', label: 'Salmon' },
  { value: '#c89d7c', label: 'Brown' },
];

export function getDefaultColor() {
  return colorOptions[0].value;
}

export class ColorSelect extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(option) {
    const { onChange } = this.props;
    onChange(option.value);
  }

  render() {
    const { selectedColor, ...restProps } = this.props;

    return (
      <div className="ColorSelect">
        <SingleSelect
          {...restProps}
          title="Vyberte barvu"
          selected={selectedColor}
          options={colorOptions}
          onChange={this.onChange}
          clearable={false}
          optionRenderer={OptionRenderer}
          valueRenderer={valueRenderer}
          placeholder="Barva"
        />
      </div>
    );
  }
}
