// TUTO_NOTE_EASE_LINE_LENGTH_FOR_FLOW
/* eslint max-len: ["error", 120] */

// conventional import: calendarEvent.createNew

import stampit from 'stampit';

import { createDeepCopy } from '@oldUtils/object';
import { stringifyTime, createDurationFromDates } from '@oldUtils/dateFormatting';

import { generateID, assignInit } from './index';
import * as mk from '@whysFn/mk';

import { createNewTimeslot, createTimeslotFromPayload } from './timeslot';

// import { createFiltered } from '../utils/object';
// import { toHourString } from '../utils/dateFormatting';

function removeItemInList(findFunc, list) {
  const idx = list.findIndex(findFunc);
  if (idx !== -1) {
    list.splice(idx, 1);
  }
}

const createCalendarEvent = stampit()
  .methods({
    isPaid() {
      return !!this.reservation && this.reservation.status === 'paid';
    },
    isOpenToReservate() {
      return !this.reservation || (!!this.reservation && this.reservation.status === 'open');
    },
    isReserved() {
      return !!this.reservation && this.reservation.status === 'reserved';
    },
    isTemporary() {
      return this.isTemporary;
    },
    isClientIn(client) {
      // TUTO_REVIEW
      // const isExisting =
      //   this.clientIds.includes(client.id) && !this.removeClientIds.includes(client.id);
      const isExisting =
        this.students.map((_) => _.id).includes(client.id) &&
        !this.removeClientIds.includes(client.id);

      return (
        isExisting ||
          // this.addedStudentIds.includes(client.id) ||
          this.addedClientsById.map((_) => _.id).includes(client.id),
        this.addedClientEmails.includes(client.email)
      );
    },
    // see TUTO_RULE_EVENT_HAS_ONE_TIMESLOT
    getOneTimeslot() {
      return this.timeslots[0];
    },
    // getVisibleStudentIds() {
    //   const existingStudentIds = this.clientIds.filter(mk.notInFilter(this.removeClientIds));
    //   return [...existingStudentIds, ...this.addedStudentIds];
    // },
    getVisibleClients() {
      const existingClients = this.students.filter(({ id }) =>
        mk.notInFilter(this.removeClientIds)(id)
      );
      return [...existingClients, ...this.addedClientsById];
    },
    getAllClientEmails() {
      return this.addedClientEmails;
    },
    hasClientData() {
      return !!this.clientData;
    },
    getStartDate() {
      return this.getOneTimeslot().getStartDate();
    },
    getReservationStatus() {
      return this.reservation && this.reservation.status;
    },
    toCreateJSON() {
      // TUTO_RULE_EVENT_HAS_ONE_TIMESLOT
      const timeslot = this.getOneTimeslot();
      return {
        timeslot: timeslot.toCreateJSON(),
        addClientEmails: this.getAllClientEmails(),
        clientIds: this.getVisibleClients().map((_) => _.id),
        isCreatingFromClientData: !!this.clientData,
        clientEmail: this.clientData && this.clientData.email,
        clientName: this.clientData && this.clientData.name,
        clientPhone: this.clientData && this.clientData.phone,
        trainingId: this.trainingId || null,
      };
    },
    toUpdateJSON() {
      // TUTO_RULE_EVENT_HAS_ONE_TIMESLOT
      const timeslot = this.getOneTimeslot();
      return {
        timeslot: timeslot.toUpdateJSON(),
        studentEmails: this.getAllClientEmails(),
        // clientIds: this.getVisibleStudentIds(),
        clientIds: this.getVisibleClients().map((_) => _.id),
        trainingId: this.trainingId,
      };
    },
  })
  .init(assignInit);

function create(props) {
  return createCalendarEvent({
    id: generateID(),
    trainingId: null,

    timeslots: [],
    // clientIds: [],
    students: [],
    reservation: null,

    addedClientsById: [],
    // addedStudentIds: [],
    removeClientIds: [],
    addedClientEmails: [],
    clientData: null,

    isLocal: false,
    isCreating: false,
    isTemporary: false,
    ...props,
  });
}

function createNew({ trainingId, ...timeslotProps }) {
  return create({
    id: generateID(),
    isLocal: true,
    isCreating: true,
    trainingId,
    timeslots: [
      createNewTimeslot({
        start: timeslotProps.start,
        duration: timeslotProps.duration,
        date: timeslotProps.date,
      }),
    ],
  });
}

export function createNewFromStartAndEnd(start, end, options = { trainingId: null }) {
  return createNew({
    date: start,
    start: stringifyTime(start),
    duration: createDurationFromDates(end, start),
    trainingId: options.trainingId,
  });
}

export function createForTest() {
  const training = createNew({
    start: '08:00',
    duration: '00:30',
    date: new Date(),
  });
  training.isTemporary = true;
  return training;
}

export function copyForEdit(opts) {
  const copyAttrs = createDeepCopy(opts);
  return create(copyAttrs);
}

export function createFromPayload(payload) {
  const mainTimeslot = createTimeslotFromPayload({
    id: `${payload.id}_main_timeslot`,
    start: payload.startTime,
    duration: payload.duration,
    onetime: payload.onetime,
    forReservation: payload.forReservation,
    date: payload.date,
  });

  const { reservation } = payload;

  return create({
    id: payload.id,
    // TUTO_REVIEW: should create object with defaults
    reservation: reservation && {
      id: reservation.id,
      status: reservation.status || 'open',
      price: reservation.price,
      // See TUTO_IMPL_STUDENT_RESERVED_EVENT, its important to have default
      reservedBy: reservation.reservedBy || [],
    },
    // clientIds: payload.clientIds,
    students: payload.students,
    trainingId: payload.trainingId,
    roomId: payload.roomId,
    timeslots: [mainTimeslot],
  });
}

// export function addStudentWithID(event, id) {
//   event.addedStudentIds.push(id);
//   if (event.removeClientIds.includes(id)) {
//     removeItemInList((el) => el === id, event.removeClientIds);
//   }
//   return event;
// }

export function addClientById(event, client) {
  event.addedClientsById.push(client);
  if (event.removeClientIds.includes(client.id)) {
    removeItemInList((el) => el.id === id, event.removeClientIds);
  }
  return event;
}

export function addClientWithEmail(event, email) {
  event.addedClientEmails.push(email);
  return event;
}

export function addClientWithData(event, data) {
  event.clientData = data;
  return event;
}

export function adjustClientData(event, data) {
  event.clientData = data;
  return event;
}

export function removeClientData(event) {
  event.clientData = null;
  return event;
}

export function removeClient(event, client) {
  const findWithID = (el) => el.id === client.id;
  const findWithEmail = (el) => el === client.email;

  if (event.students.find(findWithID)) {
    event.removeClientIds.push(client.id);
    removeItemInList(findWithID, event.students);
    return event;
  }

  if (event.addedClientsById.find(findWithID)) {
    removeItemInList(findWithID, event.addedClientsById);
    return event;
  }

  if (event.addedClientEmails.find(findWithEmail)) {
    removeItemInList(findWithEmail, event.addedClientEmails);
    return event;
  }

  return event;
}

export function updateShallow(training, data) {
  Object.assign(training, data);
  return training;
}
