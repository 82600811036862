// This module contains higher order functions

/*
Common usage:
import * as mk from '../whys-fn/mk';
...
list.filter(mk.notInFilter([1]))
*/

export function notInFilter(list) {
  return (item) => {
    return !list.includes(item);
  };
}

export function filterForArray(filterFunc) {
  return (list) => (item) =>
    // if an item is found negate it to filter it
    // (false values are filtered -- not kept in results)
    !list.find(filterFunc(item));
}
