import { applyCSS } from '@whysReact';
import layout from './icons.module.css';

import { CancelFullIcon, TrashIcon } from '@utilComponents/icons';

export const TutoCancelFullIcon = applyCSS(CancelFullIcon, {
  TutoIcon: layout.FilledIcon,
});
export const TutoTrashFullIcon = applyCSS(TrashIcon, {
  TutoIcon: layout.DangerActionIcon,
});
