import React, { useReducer, useEffect } from 'react';
import { useHistory, useLocation, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import {
  LoginPage,
  LogoutPage,
  ForgotPasswordPage,
  CalendarPage,
  PaymentStatusPage,
  TrainingsPage,
  ClientsListPage,
  FilesPage,
  ReservationsPage,
  TrainingFormsPage,
  ProfilePage,
  ExaminationsPage,
  EmployeesPage,
  NotFoundPage,
} from '@pages';

import {
  request,
  setCookie,
  //
  AttachmentsContext,
  initialAttachmentsState,
  attachmentsReducer,
  //
  BranchContext,
  initialBranchState,
  branchReducer,
  setBranches,
  calculateBranchId,
  //
  ClientsContext,
  initialClientsState,
  clientsReducer,
  //
  PermissionsContext,
  initialPermissionsState,
  permissionsReducer,
  //
  RoomsContext,
  initialRoomsState,
  roomsReducer,
  //
  UserContext,
  initialUserState,
  userReducer,
  setUser,
  setUserToken,
} from '@utils';

import 'antd/dist/antd.css';
import '@css';

import { apolloclient } from './apollo';

const createMuiTheme = () => {
  const muiTheme = getMuiTheme({
    palette: {
      primary1Color: '#6bcd64',
      accent1Color: '#1669af',
    },
  });
  return muiTheme;
};

const App = (props) => {
  const [attachmentsState, attachmentsDispatch] = useReducer(
    attachmentsReducer,
    initialAttachmentsState
  );
  const [branchState, branchDispatch] = useReducer(branchReducer, initialBranchState);
  const [clientsState, clientsDispatch] = useReducer(clientsReducer, initialClientsState);
  const [permissionsState, permissionsDispatch] = useReducer(
    permissionsReducer,
    initialPermissionsState
  );
  const [roomsState, roomsDispatch] = useReducer(roomsReducer, initialRoomsState);
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);

  const history = useHistory();
  const location = useLocation();
  const redirectToAppropriatePage = () => {
    const { hasFetched, loggedIn, user } = userState;

    if (hasFetched) {
      if (!loggedIn) {
        history.replace('/login');
      } else {
        if (user.userType === 'client') {
          history.replace('/training-forms');
        } else {
          history.replace('/calendar');
        }
      }
    }
  };

  // useEffect(async () => {
  //   const { data } = await request('/api/user/');
  //   const { user_data, csrf_token } = data;
  //
  //   userDispatch(setUser(user_data));
  //   userDispatch(setUserToken(csrf_token));
  // }, []);

  useEffect(() => {
    async function fetchData() {
      const { data } = await request('/api/user/');
      const { user_data, csrf_token } = data;

      userDispatch(setUser(user_data));
      userDispatch(setUserToken(csrf_token));
      branchDispatch(setBranches(user_data && user_data.branches));
      branchDispatch(calculateBranchId(user_data));
    }

    fetchData();
  }, []);

  useEffect(() => {
    setCookie('csrftoken', userState.csrfToken);
  }, [userState.csrfToken]);

  const muiTheme = createMuiTheme();

  return (
    <ApolloProvider client={apolloclient}>
      <MuiThemeProvider muiTheme={muiTheme}>
        <AttachmentsContext.Provider
          value={{ state: attachmentsState, dispatch: attachmentsDispatch }}
        >
          <BranchContext.Provider value={{ state: branchState, dispatch: branchDispatch }}>
            <ClientsContext.Provider value={{ state: clientsState, dispatch: clientsDispatch }}>
              <PermissionsContext.Provider
                value={{ state: permissionsState, dispatch: permissionsDispatch }}
              >
                <RoomsContext.Provider value={{ state: roomsState, dispatch: roomsDispatch }}>
                  <UserContext.Provider value={{ state: userState, dispatch: userDispatch }}>
                    <Switch>
                      {/* Just functions / redirects */}
                      <Route path="/" exact render={() => redirectToAppropriatePage()} />
                      <Route path="/logout" exact>
                        <LogoutPage />
                      </Route>
                      {/* Both logged out and logged in */}
                      <Route path="/pobocka/:publicUrl" exact>
                        <CalendarPage isPublic={true} />
                      </Route>
                      <Route path="/payment-success" exact>
                        <PaymentStatusPage
                          user={userState.user}
                          location={location}
                          status="success"
                        />
                      </Route>
                      <Route path="/payment-failure" exact>
                        <PaymentStatusPage
                          user={userState.user}
                          location={location}
                          status="failure"
                        />
                      </Route>
                      {/* Need to be logged out (a.k.a. public) */}
                      <Route path="/login" exact>
                        <LoginPage />
                      </Route>
                      <Route path="/forgot-password" exact>
                        <ForgotPasswordPage />
                      </Route>
                      <Route path="/first-time-access" exact>
                        <ProfilePage />
                      </Route>
                      {/* Need to be logged in */}
                      <Route path="/calendar" exact>
                        <CalendarPage />
                      </Route>
                      <Route path="/calendar/events/:eventId" exact>
                        <CalendarPage />
                      </Route>
                      <Route path="/trainings" exact>
                        <TrainingsPage />
                      </Route>
                      <Route path="/clients" exact>
                        <ClientsListPage />
                      </Route>
                      <Route path="/files" exact>
                        <FilesPage />
                      </Route>
                      <Route path="/reservations" exact>
                        <ReservationsPage />
                      </Route>
                      <Route path="/training-forms" exact>
                        <TrainingFormsPage />
                      </Route>
                      <Route path="/profile" exact>
                        <ProfilePage />
                      </Route>
                      <Route path="/examination/:id" exact>
                        <ExaminationsPage />
                      </Route>
                      <Route path="/employees" exact>
                        <EmployeesPage />
                      </Route>
                      <Route path="*">
                        <NotFoundPage />
                      </Route>
                    </Switch>
                  </UserContext.Provider>
                </RoomsContext.Provider>
              </PermissionsContext.Provider>
            </ClientsContext.Provider>
          </BranchContext.Provider>
        </AttachmentsContext.Provider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
};

export default App;
