import * as React from 'react';

// MaterialUI
import IconButton from 'material-ui/IconButton';
import FlatButton from 'material-ui/FlatButton';
import CalendarSvgIcon from 'material-ui/svg-icons/action/date-range';
import CancelSvgIcon from 'material-ui/svg-icons/navigation/cancel';
import Dialog from 'material-ui/Dialog';

// App UI
import { PrimaryBtn } from '@oldUI/buttons';
import { CalendarDayPicker } from '@oldUI/calendarInputs';
import { CloseableTutoTooltip } from '@oldUI/dialogs';

// App utils
import { strFormatDate } from '@oldUtils/dateFormatting';

// App state

// type SemaphoreBtnProps = {|
//   positive?: boolean,
//   negative?: boolean,
//   neutral?: boolean,
//   onClick?: Function,
//   statusMsg: string,
// |};

function SemaphoreBtn(props) {
  const { positive, negative, neutral, statusMsg } = props;
  let color = 'black';
  let backgroundColor;
  let cursor = 'inherit';

  if (positive) {
    color = 'white';
    backgroundColor = '#6bcd64';
  } else if (negative) {
    color = 'white';
    backgroundColor = '#f7465e';
  }
  if (neutral) {
    backgroundColor = '#ffe07a';
    cursor = undefined;
  }

  return (
    <PrimaryBtn onClick={props.onClick} style={{ cursor, backgroundColor, color, fontWeight: 600 }}>
      {statusMsg}
    </PrimaryBtn>
  );
}

// export type ReservationStatusProps = {|
//   status: ReservationStatusType,
//   reservation: LessonReservation,
//   calendarMode: boolean,
//   cancelMode: boolean,
//
//   // state handlers
//   onCancelInit: *,
//   onCancelExit: *,
//   onCancelDone: *,
//   onConfirmInit: *,
//   onConfirmExit: *,
//   onConfirmDone: (confirmedOn: Date) => *,
// |};

class ReservationStatus extends React.Component {
  mapStatusToProps(status) {
    if (status === 'paid') {
      const { reservation } = this.props;
      const paidOn = reservation.paidOn && strFormatDate(reservation.paidOn);
      return {
        positive: true,
        statusMsg: `Zaplaceno ${paidOn || ''}`,
      };
    } else if (status === 'expired') {
      return { negative: true, statusMsg: 'Nezaplaceno' };
    } else if (status === 'canceled') {
      return { negative: true, statusMsg: 'Zrušeno' };
    }
    return null;
  }

  renderCancelDialog() {
    const actions = [
      <FlatButton label="Zavřít" primary onClick={this.props.onCancelExit} />,
      <FlatButton
        label="Zrušit rezervaci"
        primary
        keyboardFocused
        onClick={this.props.onCancelDone}
      />,
    ];

    return (
      <div>
        <Dialog
          title="Zrušit rezervaci"
          actions={actions}
          modal={false}
          open
          onRequestClose={this.props.onCancelExit}
        >
          Opravdu chcete zrušit rezervaci?
        </Dialog>
      </div>
    );
  }

  renderCalendarPicker() {
    const selectedDate = new Date();
    return (
      <div>
        <p>Vyberte kdy byla rezervace zaplacena:</p>
        <CalendarDayPicker
          selectedDate={selectedDate}
          onSelect={(newDate) => {
            this.props.onConfirmDone(newDate);
          }}
        />
      </div>
    );
  }

  renderCalendarDialog() {
    const { calendarMode } = this.props;

    return (
      <CloseableTutoTooltip
        overlay={this.renderCalendarPicker()}
        placement="topRight"
        visible={calendarMode}
        onClose={this.props.onConfirmExit}
      >
        <div
          className="EventConfirmReservationContainer"
          onClick={this.props.onConfirmInit}
          title="Potvrdit rezervaci"
        >
          <IconButton>
            <CalendarSvgIcon color="#6bcd64" />
          </IconButton>
          <div className="EventConfirmReservationText">Potvrdit</div>
        </div>
      </CloseableTutoTooltip>
    );
  }

  renderChangePending() {
    return (
      <div>
        <IconButton title="Zrušit" onClick={this.props.onCancelInit}>
          <CancelSvgIcon color="#f7465e" />
        </IconButton>
        {this.renderCalendarDialog()}
      </div>
    );
  }

  render() {
    const { status, cancelMode } = this.props;
    if (status === 'reserved') {
      return (
        <div>
          {cancelMode ? this.renderCancelDialog() : null}
          {this.renderChangePending()}
        </div>
      );
    }

    // FLOW_IGNORE_INTENT
    return <SemaphoreBtn {...this.mapStatusToProps(status)} />;
  }
}

export default ReservationStatus;
