const checkNoAuthPageAccess = ({ loggedIn, user }, replace) => {
  if (loggedIn) {
    if (user.type === 'client') {
      replace('/training-forms');
    } else {
      replace('/calendar');
    }
  }
  return !loggedIn;
};

const checkAuthPageAccess = ({ loggedIn }, replace) => {
  if (!loggedIn) replace('/login');
  return loggedIn;
};

const checkEmployeePageAccess = ({ loggedIn, allowed }, replace) => {
  if (!loggedIn) {
    replace('/login');
    return false;
  } else {
    if (!allowed) {
      replace('/calendar');
      return false;
    } else {
      return true;
    }
  }
};

const checkSuperuserAccess = ({ loggedIn, user }, replace) => {
  if (!loggedIn) {
    replace('/login');
  } else if (user.type !== 'superuser') {
    if (user.type === 'client') {
      replace('/training-forms');
    } else {
      replace('/calendar');
    }
  }
  return loggedIn && user.type === 'superuser';
};

const pagesConfig = {
  '/pobocka': {
    checkAccess: () => true,
  },
  '/payment-success': {
    checkAccess: () => true,
  },
  '/payment-failure': {
    checkAccess: () => true,
  },
  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  '/login': {
    checkAccess: checkNoAuthPageAccess,
  },
  '/forgot-password': {
    checkAccess: checkNoAuthPageAccess,
  },
  '/first-time-access': {
    checkAccess: checkNoAuthPageAccess,
  },
  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  '/calendar': {
    checkAccess: checkAuthPageAccess,
  },
  '/calendar/events': {
    checkAccess: checkAuthPageAccess,
  },
  '/training-forms': {
    checkAccess: checkAuthPageAccess,
  },
  '/profile': {
    checkAccess: checkAuthPageAccess,
  },
  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  '/trainings': {
    checkAccess: ({ loggedIn, permissions }, replace) => {
      return checkEmployeePageAccess(
        {
          loggedIn,
          allowed: permissions['allowTrainings'],
        },
        replace
      );
    },
  },
  '/clients': {
    checkAccess: ({ loggedIn, permissions }, replace) => {
      return checkEmployeePageAccess(
        {
          loggedIn,
          allowed: permissions['allowClients'],
        },
        replace
      );
    },
  },
  '/files': {
    checkAccess: ({ loggedIn, permissions }, replace) => {
      return checkEmployeePageAccess(
        {
          loggedIn,
          allowed: permissions['allowFiles'],
        },
        replace
      );
    },
  },
  '/reservations': {
    checkAccess: ({ loggedIn, permissions }, replace) => {
      return checkEmployeePageAccess(
        {
          loggedIn,
          allowed: permissions['allowReservations'],
        },
        replace
      );
    },
  },
  '/examination': {
    checkAccess: ({ loggedIn, permissions }, replace) => {
      return checkEmployeePageAccess(
        {
          loggedIn,
          allowed: permissions['allowFormsAndExports'],
        },
        replace
      );
    },
  },
  '/employees': {
    checkAccess: ({ loggedIn, permissions }, replace) => {
      return checkEmployeePageAccess(
        {
          loggedIn,
          allowed: permissions['allowEmployees'],
        },
        replace
      );
    },
  },
};

export default pagesConfig;
