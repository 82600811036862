import * as React from 'react';

// Forms
import { parseTrainingFormsData } from '@appForms/parseAppFormsData';
import generateTrainingPrintHTML from '@appForms/generateTrainingPrintHTML';
import TrainingForm from '@appForms/TrainingForm';

// UI
import AppPage from '@oldComponents/AppPage';
import { ControlledAccordion, AccordionPanel } from '@oldUI/accordion';

export default class Trainings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trainingForms: [],

      activeAccordionPanel: null,
    };

    this.training_forms = [];
  }

  componentDidMount() {
    this.props.fetchEntryFormData().then((res) => {
      this.entry_form = res.entry_form;
    });

    this.props.fetchTrainingFormsData().then((res) => {
      this.training_forms = res.training_forms;
      const trainingForms = parseTrainingFormsData(res.training_forms);
      if (trainingForms.length > 0) {
        this.setState({ trainingForms });
      }
    });
  }

  returnFilledTrainingForms = () => {
    const { trainingForms } = this.state;

    return trainingForms.filter((t_f) => {
      const value = t_f.acuity['0'].rightValue;
      if (!value || value.toString().length === 0) {
        return false;
      }

      return true;
    });
  };

  setActiveAccordionPanel = (key) => {
    this.setState({ activeAccordionPanel: key });
  };

  printTraining = () => {
    const { training_forms } = this;

    if (training_forms.length === 0) {
      return;
    }

    // It is more efficient to use resData for
    // generating print table data
    const filled_res_training_forms = training_forms.filter((t_f) => t_f.acuity_right_value);
    const TrainingFormChart = document.getElementById('TrainingFormChart');

    if (!TrainingFormChart) {
      return;
    }

    const dataURL = TrainingFormChart.toDataURL();
    const windowContent = generateTrainingPrintHTML(
      filled_res_training_forms,
      this.entry_form,
      dataURL
    );

    const printWin = window.open('', 'PRINT', 'width=' + 800 + ',height=' + 800);
    printWin.document.open();
    printWin.document.write(windowContent);

    printWin.document.addEventListener(
      'load',
      () => {
        setTimeout(() => {
          printWin.focus();
          printWin.print();
          printWin.document.close();
          printWin.close();
        }, 500);
      },
      true
    );
  };

  render() {
    return (
      <AppPage header="">
        {this.state.trainingForms.length > 0 ? (
          <ControlledAccordion
            activeKey={this.state.activeAccordionPanel}
            className="TrainingFormAccordion"
            onActiveChange={this.setActiveAccordionPanel}
            initiallyExpanded={false}
            canCollapseAll
          >
            {this.state.trainingForms.map((trainingForm, index) => {
              const header = (
                <div className={`TrainingFormHeader TrainingFormHeader-${index}`}>
                  <h3 className="AccordionPanelTitle">
                    {`Trénink ${new Date(trainingForm.actualDate).toLocaleDateString()}`}
                  </h3>
                </div>
              );
              return (
                <AccordionPanel key={index} header={header}>
                  <TrainingForm
                    index={index}
                    data={trainingForm}
                    previousData={this.state.trainingForms[index + 1]}
                    onTrainingFormSubmit={() => {}}
                    onRender={() => {}}
                    hideAccordionPanel={() => this.setState({ activeAccordionPanel: null })}
                    printTraining={this.printTraining}
                    clientView
                  />
                </AccordionPanel>
              );
            })}
          </ControlledAccordion>
        ) : (
          <p>Zatím nemáte žádné tréninky</p>
        )}
      </AppPage>
    );
  }
}
