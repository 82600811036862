import { createContext } from 'react';

export const UserContext = createContext();

export const initialUserState = {
  hasFetched: false,
  loggedIn: false,
  user: null,
  csrfToken: null,
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      return { ...state, hasFetched: true, loggedIn: !!action.user, user: action.user };
    case 'UPDATE':
      return { ...state, user: { ...state.user, ...action.user } };
    case 'LOGOUT':
      return { ...state, loggedIn: false, user: null };
    case 'TOKEN':
      return { ...state, csrfToken: action.token };
    default:
      return state;
  }
};

export const setUser = (user) => ({
  type: 'SET',
  user,
});

export const updateUser = (user) => ({
  type: 'UPDATE',
  user,
});

export const logoutUser = () => ({
  type: 'LOGOUT',
});

export const setUserToken = (token) => ({
  type: 'TOKEN',
  token,
});
