import React, { Fragment, Component } from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import { ExportToCsv } from 'export-to-csv';

import IconButton from 'material-ui/IconButton';
import CalendarIcon from 'material-ui/svg-icons/action/date-range';
import EmailSvgIcon from 'material-ui/svg-icons/communication/mail-outline';
import PhoneSvgIcon from 'material-ui/svg-icons/communication/phone';
import LocationIcon from 'material-ui/svg-icons/communication/location-on';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import Popover from 'material-ui/Popover';
import Snackbar from 'material-ui/Snackbar';
// import { Tabs, Tab } from 'material-ui/Tabs';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import parseAppFormsData, {
  parseEntryFormData,
  parseAttachmentsData,
  parseHealthQuestionnaireData,
} from './parseAppFormsData';
import generateTrainingPrintHTML from './generateTrainingPrintHTML';
import generateSummaryPrintHTML from './generateSummaryPrintHTML';
import generateShortSummaryPrintHTML from './generateShortSummaryPrintHTML';
import generateInitialMeasurementPrintHTML from './generateInitialMeasurementPrintHTML';
import {
  generateEntryFormPayload,
  generateHealthQuestionnairePayload,
  generateTrainingFormPayload,
  generateSenaptecFormPayload,
} from './payloads';

import EntryForm from './EntryForm';
import HealthQuestionnaire from './HealthQuestionnaire';
import TrainingFormChart from './TrainingFormChart';
import SenaptecFormChart from './SenaptecFormChart';
import TrainingForm from './TrainingForm';
import SenaptecForm from './SenaptecForm';

import { PageSpace } from '@utilComponents/layoutPrimitives';
import { FlexBlock, PaddedBlock } from '@utilComponents';

import { ControlledAccordion, AccordionPanel } from '@oldUI/accordion';
import { HSpace, PrimaryBtn } from '@oldUI';

import { getTopOffset } from '@oldUtils/window';
import { initUppy } from '@oldUtils/uppy';

import { SelectField, MultipleSelectField } from '@components';

import { request, readCookie } from '@utils';

const StyledTabs = withStyles({
  root: {
    backgroundColor: '#f3f3f3',
  },
  indicator: {
    backgroundColor: '#1669af',
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    fontSize: 14,
    letterSpacing: 1,
  },
})(Tab);

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 10,
    minHeight: '400px',
    minWidth: '600px',
  },
  overlay: {
    zIndex: 10,
  },
};

// type LocalProps = {|
//   onClose: Function,
//   clientId: string,
// |};

// type LocalState = {|
//   dataFetched: boolean,
//   attachments: Array<EntryFormAttachmentData>,
//   profession: string | null,
//   clientData: {
//     email: string,
//     name: string,
//     date_of_birth: ?string,
//     residence: ?string,
//     zip_code: ?string,
//     phone: string,
//   },
//   entryForm: ?{ ...EntryFormData },
//   healthQuestionnaire: ?HealthQuestionnaireData,
//   trainingForms: Array<TrainingFormData>,
//
//   newProfession: string | null,
//   professionPopoverOpen: boolean,
//   professionPopoverAnchor: HTMLElement | null,
//   activeTrainingAccordionPanel: ?number,
//   activeSenaptecAccordionPanel: ?number,
//   initiallyExpanded: boolean,
//   trainingChartModalOpened: boolean,
//   chosenChartTrainings: ?Array<number>,
//   drawOptimalData: boolean,
//   simpleGraph: boolean,
//
//   tab: number,
//   submitSuccess: boolean,
// |};

class AppForms extends Component {
  // available_professions: Array<string | null>;
  // entry_form: Object | null;
  // health_questionnaire: Object | null;
  // training_forms: Array<Object>;

  constructor(props) {
    super(props);
    this.state = {
      dataFetched: false,

      attachments: [],
      profession: '',
      clientData: {
        email: '',
        name: '',
        date_of_birth: '',
        residence: '',
        zip_code: '',
        phone: '',
      },
      entryForm: null,
      healthQuestionnaire: null,
      trainingForms: [],
      senaptecForms: [],

      newProfession: null,
      professionPopoverAnchor: null,
      professionPopoverOpen: false,

      activeTrainingAccordionPanel: null,
      activeSenaptecAccordionPanel: null,
      initiallyExpanded: false,

      trainingChartModalOpened: false,
      chosenChartTrainings: [],
      drawOptimalData: true,
      simpleGraph: true,

      tab: 2,
      submitSuccess: false,
    };

    this.available_professions = [];
    this.entry_form = null;
    this.entry_form_exists = null;
    this.health_questionnaire = null;
    this.health_questionnaire_exists = null;
    this.training_forms = [];
    this.senaptec_forms = [];
  }

  componentDidMount() {
    Modal.setAppElement('body');
    this.fetchClientData();
  }

  componentDidUpdate(prevProps) {
    // Applies to switching to another event in calendar
    if (this.props.clientId !== prevProps.clientId) {
      this.fetchClientData();
    }
  }

  onInitUppy = (options) => {
    const CSRFToken = readCookie('csrftoken', '');

    const { selector, onUploadDone } = options;

    return initUppy({
      selector: `.${selector}`,
      endpointURL: `/api_classroom/upload-file/${this.props.branchId}`,
      onUpload: onUploadDone,
      requestHeaders: {
        'X-CSRFToken': CSRFToken,
      },
    });
  };

  fetchClientData() {
    const { branchId, clientId } = this.props;

    if (clientId) {
      request(`/api_lesson_forms/fetch-data/${branchId}/${clientId}`).then((res) => {
        const {
          available_professions,
          entry_form,
          entry_form_exists,
          health_questionnaire,
          health_questionnaire_exists,
          training_forms,
          senaptec_forms,
        } = res.data;

        this.available_professions = available_professions || [];
        this.entry_form = entry_form;
        this.entry_form_exists = entry_form_exists;
        this.health_questionnaire = health_questionnaire;
        this.health_questionnaire_exists = health_questionnaire_exists;
        this.training_forms = training_forms;
        this.senaptec_forms = senaptec_forms;

        let activeTrainingAccordionPanel = null;
        let activeSenaptecAccordionPanel = null;
        let initiallyExpanded = false;

        if (this.props.event) {
          if (training_forms) {
            const { id } = this.props.event;

            for (let i = 0; i < training_forms.length; i++) {
              if (training_forms[i].lesson.id === id) {
                activeTrainingAccordionPanel = i.toString();
                initiallyExpanded = true;
                break;
              }
            }
          }

          if (senaptec_forms) {
            const { id } = this.props.event;

            for (let i = 0; i < senaptec_forms.length; i++) {
              if (senaptec_forms[i].lesson.id === id) {
                activeSenaptecAccordionPanel = i.toString();
                break;
              }
            }
          }
        }

        this.setState({
          ...parseAppFormsData(res.data),
          activeTrainingAccordionPanel,
          activeSenaptecAccordionPanel,
          initiallyExpanded,
        });
      });
    }
  }

  setActiveTrainingAccordionPanel = (key) => {
    this.setState({ activeTrainingAccordionPanel: key, initiallyExpanded: false });
  };

  setActiveSenaptecAccordionPanel = (key) => {
    this.setState({ activeSenaptecAccordionPanel: key });
  };

  returnFilledTrainingForms = () => {
    const { trainingForms } = this.state;

    return trainingForms.filter((t_f) => {
      const value = t_f.acuity['0'].rightValue;
      if (!value || value.toString().length === 0) {
        return false;
      }

      return true;
    });
  };

  returnFilledSenaptecForms = () => {
    const { senaptecForms } = this.state;

    return senaptecForms.filter((s_f) => {
      const value = s_f.visualClarityRight;
      if ((!value && parseInt(value) !== 0) || value.toString().length === 0) {
        return false;
      }

      return true;
    });
  };

  handleProfessionClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      professionPopoverOpen: true,
      professionPopoverAnchor: event.currentTarget,
    });
  };

  handleProfessionRequestClose = () => {
    this.setState({
      professionPopoverOpen: false,
    });
  };

  submitProfession = () => {
    const { branchId, clientId } = this.props;

    const { newProfession } = this.state;

    if (newProfession) {
      request(`/api_lesson_forms/set-profession/${branchId}/${clientId}`, {
        method: 'POST',
        data: { name: newProfession },
      }).then(() => {
        this.setState({ profession: newProfession, newProfession: '' });
        this.handleProfessionRequestClose();
      });
    }
  };

  onEntryFormSubmit = (data) => {
    const { branchId, clientId } = this.props;
    const { dominance, adaptation, note } = data;
    const payload = generateEntryFormPayload(dominance, adaptation, note);

    request(`/api_lesson_forms/set-entry-form/${branchId}/${clientId}`, {
      method: 'POST',
      data: payload,
    }).then((res) => {
      this.entry_form = res.data.entry_form;
      this.entry_form_exists = true;

      this.setState({
        submitSuccess: true,
        entryForm: parseEntryFormData(res.data.entry_form),
        attachments: parseAttachmentsData(res.data.attachments),
      });
    });
  };

  onHealthQuestionnaireSubmit = (data) => {
    const { branchId, clientId } = this.props;
    const payload = generateHealthQuestionnairePayload(data);

    request(`/api_lesson_forms/set-health-questionnaire/${branchId}/${clientId}`, {
      method: 'POST',
      data: payload,
    }).then((res) => {
      this.health_questionnaire = res.data.health_questionnaire;
      this.health_questionnaire_exists = true;

      this.setState({
        submitSuccess: true,
        healthQuestionnaire: parseHealthQuestionnaireData(this.health_questionnaire),
      });
    });
  };

  onTrainingFormSubmit = (data, index) => {
    const { branchId } = this.props;
    const { lessonId } = data;
    const payload = generateTrainingFormPayload(data);

    const trainingForms = [...this.state.trainingForms];
    trainingForms[index] = data;

    request(`/api_lesson_forms/set-training-form/${branchId}/${lessonId}`, {
      method: 'POST',
      data: payload,
    }).then((res) => {
      const { training_forms } = this;
      const { training_form } = res.data;

      training_forms.forEach((t_f, i) => {
        if (t_f.lesson.id.toString() === training_form.lesson.id) {
          training_forms[i] = training_form;
        }
      });

      this.setState({
        activeTrainingAccordionPanel: null,
        submitSuccess: true,
        trainingForms,
      });
    });
  };

  onSenaptecFormSubmit = (data, index) => {
    const { branchId } = this.props;
    const { lessonId } = data;
    const payload = generateSenaptecFormPayload(data);

    const senaptecForms = [...this.state.senaptecForms];
    senaptecForms[index] = data;

    request(`/api_lesson_forms/set-senaptec-form/${branchId}/${lessonId}`, {
      method: 'POST',
      data: payload,
    }).then((res) => {
      const { senaptec_forms } = this;
      const { senaptec_form } = res.data;

      senaptec_forms.forEach((s_f, i) => {
        if (s_f.lesson.id.toString() === senaptec_form.lesson.id) {
          senaptec_forms[i] = senaptec_form;
        }
      });

      this.setState({
        activeSenaptecAccordionPanel: null,
        submitSuccess: true,
        senaptecForms,
      });
    });
  };

  onFilesUpload = (files) => {
    // After file is uploaded, we need to attach it to a lesson
    const { branchId, clientId } = this.props;

    files.forEach((file) => {
      request(`/api_lesson_forms/upload-entry-form-attachment/${branchId}/${clientId}`, {
        method: 'POST',
        data: { file_id: file.id },
      });
    });
  };

  openTrainingFormChart = () => {
    this.setState({
      trainingChartModalOpened: true,
      chosenChartTrainings: [0],
    });
  };

  closeTrainingFormChart = () => {
    this.setState({
      trainingChartModalOpened: false,
      chosenChartTrainings: [],
    });
  };

  printTrainings = () => {
    this.shouldStopPrint = false;

    const { training_forms } = this;

    if (training_forms.length === 0) {
      return;
    }

    // It is more efficient to use resData for
    // generating print table data
    const filled_res_training_forms = training_forms.filter((t_f) => t_f.acuity_right_value);
    const TrainingFormChart = document.getElementById('TrainingFormChart');

    if (!TrainingFormChart) {
      return;
    }

    const dataURL = TrainingFormChart.toDataURL();
    const windowContent = generateTrainingPrintHTML(
      filled_res_training_forms,
      this.entry_form,
      dataURL
    );

    const printWin = window.open('', 'PRINT', 'width=' + 800 + ',height=' + 800);
    printWin.document.open();
    printWin.document.write(windowContent);

    printWin.document.addEventListener(
      'load',
      () => {
        setTimeout(() => {
          if (!this.shouldStopPrint) {
            printWin.focus();
            printWin.print();
            // Print is finished here
            this.shouldStopPrint = true;
            printWin.document.close();
            printWin.close();
          }
        }, 500);
      },
      true
    );
  };

  printSummary = () => {
    this.shouldStopPrint = false;

    const { training_forms, senaptec_forms } = this;
    const { clientData } = this.state;

    // if (training_forms.length === 0) {
    //   return;
    // }

    training_forms.sort((a, b) => new Date(b.lesson.actual_date) - new Date(a.lesson.actual_date));
    const lastTrainingForm = training_forms[0];
    senaptec_forms.sort((a, b) => new Date(b.lesson.actual_date) - new Date(a.lesson.actual_date));
    const lastSenaptecForm = senaptec_forms[0];

    const TrainingFormChart = document.getElementById('TrainingFormChart');
    const SenaptecFormChart = document.getElementById('SenaptecFormChart');

    // if (!TrainingFormChart) {
    //   return;
    // }

    // if (!SenaptecFormChart) {
    //   return;
    // }

    const trainingChartDataURL = TrainingFormChart.toDataURL();
    const senaptecChartDataURL = SenaptecFormChart ? SenaptecFormChart.toDataURL() : null;
    const windowContent = generateSummaryPrintHTML(
      clientData,
      lastTrainingForm,
      trainingChartDataURL,
      lastSenaptecForm,
      senaptecChartDataURL
    );

    const printWin = window.open('', 'PRINT', 'width=' + 800 + ',height=' + 800);
    printWin.document.open();
    printWin.document.write(windowContent);

    printWin.document.addEventListener(
      'load',
      () => {
        setTimeout(() => {
          if (!this.shouldStopPrint) {
            printWin.focus();
            printWin.print();
            // Print is finished here
            this.shouldStopPrint = true;
            printWin.document.close();
            printWin.close();
          }
        }, 500);
      },
      true
    );
  };

  printShortSummary = () => {
    this.shouldStopPrint = false;

    const { training_forms, senaptec_forms } = this;
    const { clientData } = this.state;

    // if (training_forms.length === 0) {
    //   return;
    // }

    training_forms.sort((a, b) => new Date(a.lesson.actual_date) - new Date(b.lesson.actual_date));
    const lastTrainingForm = training_forms[0];
    senaptec_forms.sort((a, b) => new Date(a.lesson.actual_date) - new Date(b.lesson.actual_date));
    const lastSenaptecForm = senaptec_forms[0];

    const windowContent = generateShortSummaryPrintHTML(clientData, training_forms, senaptec_forms);

    const printWin = window.open('', 'PRINT', 'width=' + 800 + ',height=' + 800);
    printWin.document.open();
    printWin.document.write(windowContent);

    printWin.document.addEventListener(
      'load',
      () => {
        setTimeout(() => {
          if (!this.shouldStopPrint) {
            printWin.focus();
            printWin.print();
            // Print is finished here
            this.shouldStopPrint = true;
            printWin.document.close();
            printWin.close();
          }
        }, 500);
      },
      true
    );
  };

  generateCSV = () => {
    const { entry_form, health_questionnaire, training_forms, senaptec_forms } = this;
    const { clientData } = this.state;

    const trainingRows = [];
    training_forms.forEach((training_form) => {
      const row = {};

      for (const property in training_form) {
        const value = training_form[property];

        if (
          property !== 'initial_measurement' &&
          property !== 'acuity_measurements' &&
          property !== 'acuity_measurement_ids' &&
          property !== 'motility_right_problems' &&
          property !== 'motility_left_problems'
        ) {
          if (property === 'lesson') {
            row.actual_date = value.actual_date;
          } else {
            if (Array.isArray(value)) {
              row[property] = value.join(',');
            } else {
              row[property] = value || (value === 0 ? 0 : '');
            }
          }
        }
      }

      trainingRows.push(row);
    });
    trainingRows.sort((a, b) => new Date(a.actual_date) - new Date(b.actual_date));

    const senaptecRows = [];
    senaptec_forms.forEach((senaptec_form) => {
      const row = {};

      for (const property in senaptec_form) {
        const value = senaptec_form[property];

        if (property !== 'lesson') {
          row[property] = value || (value === 0 ? 0 : '');
        }
      }

      senaptecRows.push(row);
    });
    senaptecRows.sort((a, b) => new Date(a.actual_date) - new Date(b.actual_date));

    const rows = trainingRows.map((trainingRow, index) => {
      const senaptecRow = senaptecRows[index];

      if (index === 0) {
        return { ...trainingRow, ...senaptecRow, ...entry_form, ...health_questionnaire };
      } else {
        return { ...trainingRow, ...senaptecRow };
      }
    });

    rows.forEach((row) => {
      for (const property in row) {
        const value = row[property];
        if (!value && value !== 0) row[property] = '';
      }
    });

    const options = {
      filename: clientData.name,
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      // showTitle: true,
      // title: clientData.name,
      useTextFile: false,
      useBom: true,
      // useKeysAsHeaders: true,
      headers: [
        'Datum',
        'Obruba',
        'Sklo / čočka pravé',
        'Sklo čočka levé',
        'Skryté šilhání',
        'Ostrost - pravé - hodnota',
        'Ostrost - pravé - poznámka',
        'Ostrost - levé - hodnota',
        'Ostrost - levé - poznámka',
        'Ostrost - bino - hodnota',
        'Ostrost - bino - poznámka',
        'Ostrost - korekce',
        'Motilita - pravé',
        'Motilita - levé',
        'Přeostření dálka blízko dálka',
        'Přeostření dálka blízko dálka - korekce',
        'Maximální bod konvergence - rozdvojení',
        'Maximální bod konvergence - spojení',
        'Maximální bod konvergence - poznámka',
        'Maximální bod konvergence - korekce',
        'Vergenční flexibilita - hodnota',
        'Vergenční flexibilita - korekce',
        'Akomodace - levá',
        'Akomodace - pravá',
        'Akomodace - bino',
        'Akomodace - korekce',
        'AC/A poměr',
        'Vnitřní svaly - rozmlžení - dálka',
        'Vnitřní svaly - rozmlžení - blízko',
        'Vnitřní svaly - rozdvojení - dálka',
        'Vnitřní svaly - rozdvojení - blízko',
        'Vnitřní svaly - spojení - dálka',
        'Vnitřní svaly - spojení - blízko',
        'Vnitřní svaly - korekce',
        'Vnější svaly - rozdvojení - dálka',
        'Vnější svaly - rozdvojení - blízko',
        'Vnější svaly - spojení - dálka',
        'Vnější svaly - spojení - blízko',
        'Vnější svaly - korekce',
        'Neurotracker',
        'Poznámka',
        'Ostrost vidění pravé',
        'Ostrost vidění levé',
        'Ostrost vidění bino',
        'Kontrastní citlivost',
        'Hloubka ostrosti pravé',
        'Hloubka ostrosti levé',
        'Hloubka ostrosti bino',
        'Přechod dálka / blízko',
        'Vizualizace / krátkodobá paměť',
        'Různě se pohybující předměty',
        'Reakční čas',
        'Dominance - motorická',
        'Dominance - senzorická',
        'Dominance - ruka / noha',
        'Dominance - sportovní',
        'Adaptace - hodnota',
        'Adaptace - poznámka',
        'Rodinné problémy',
        'Nosí brýle',
        'Jaké brýle',
        'Nosí čočky',
        'Jaké čočky',
        'Má bolesti hlavy',
        'Jaké bolesti hlavy',
        'Používá antibiotika / kortikoidy',
        'Jaké antibiotika / kortikoidy',
        'Má záněty v oblasti očí',
        'Jaké záněty v oblasti očí',
        'Potíže se středním uchem',
        'Potíže s rovnováhou',
        'Závratě',
        'Má vysoký krevní tlak',
        'Vysoký krevní tlak - medikace',
        'Cukrovka - medikace',
        'Jiné',
        'Farmakologická anamnéza',
        'Operace v oblasti očí',
        'Úrazy',
        'Hospitalizace',
        'Sledován specialistou',
        'Alergie',
        'Ostatní',
      ],
    };

    const exporter = new ExportToCsv(options);
    const stuff = exporter.generateCsv(rows);

    // let csvContent = "data:text/csv;charset=utf-8";
    //
    // const encodedURI = encodeURI(csvContent);
    // window.open(encodedURI);
  };

  printTrainingInitialMeasurement(training) {
    this.shouldStopPrint = false;

    const windowContent = generateInitialMeasurementPrintHTML(training, this.state.clientData);

    const printWin = window.open('', 'PRINT', 'width=' + 800 + ',height=' + 800);
    printWin.document.open();
    printWin.document.write(windowContent);

    printWin.document.addEventListener(
      'load',
      () => {
        setTimeout(() => {
          if (!this.shouldStopPrint) {
            printWin.focus();
            printWin.print();
            // Print is finished here
            this.shouldStopPrint = true;
            printWin.document.close();
            printWin.close();
          }
        }, 500);
      },
      true
    );
  }

  onTrainingFormRender(index) {
    if (this.state.initiallyExpanded) {
      const el = document.getElementsByClassName(`TrainingFormHeader-${index}`)[0];
      const y = getTopOffset(el);
      document.getElementsByClassName('CalendarLessons')[0].scrollTo(0, y - 60);
    }
  }

  renderCloseBtn() {
    return (
      <FlexBlock fullWidth toMainEnd>
        <div onClick={this.props.onClose} className="CloseButtonWrap" style={{ cursor: 'pointer' }}>
          <svg viewBox="0 0 768 768" className="LessonCloseIcon" style={{ width: 28 }}>
            <path d="M544.5 499.5l-115.5-115.5 115.5-115.5-45-45-115.5 115.5-115.5-115.5-45 45 115.5 115.5-115.5 115.5 45 45 115.5-115.5 115.5 115.5zM384 64.5c177 0 319.5 142.5 319.5 319.5s-142.5 319.5-319.5 319.5-319.5-142.5-319.5-319.5 142.5-319.5 319.5-319.5z" />
          </svg>
        </div>
      </FlexBlock>
    );
  }

  render() {
    if (!this.props.clientId && this.props.event) {
      return (
        <div>
          {this.renderCloseBtn()}
          <h2>Tento trénink nemá přiřazené klienty.</h2>
          <PageSpace tiny />
          <h4>
            Klienty můžete přidat při vytváření události nebo se přidají automaticky po potvrzení
            rezervace.
          </h4>
          <PageSpace tiny />
          <PrimaryBtn onClick={this.props.onClose}>Zavřít</PrimaryBtn>
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{
              display: 'none',
              position: 'absolute',
            }}
          >
            <TrainingFormChart
              data={[this.returnFilledTrainingForms()[0]]}
              drawOptimalData
              simpleGraph
              options={{ animation: { duration: 0 } }}
            />
          </div>

          <div
            style={{
              display: 'none',
              position: 'absolute',
            }}
          >
            <SenaptecFormChart
              data={this.returnFilledSenaptecForms()[0]}
              options={{ animation: { duration: 0 } }}
            />
          </div>

          {this.props.event && this.props.onClose && this.renderCloseBtn()}
          <PageSpace />
          {this.renderHeader()}
          <PageSpace />
          {this.state.dataFetched && this.renderTabs()}
          <Snackbar
            open={this.state.submitSuccess}
            message="Uložené"
            autoHideDuration={300000}
            onRequestClose={() => this.setState({ submitSuccess: false })}
            bodyStyle={{ backgroundColor: 'rgb(107, 205, 100)' }}
          />

          <Modal
            isOpen={this.state.trainingChartModalOpened}
            onRequestClose={this.closeTrainingFormChart}
            style={modalStyles}
            contentLabel="Grafy tréninků"
          >
            {this.renderModalContent()}
          </Modal>
        </div>
      );
    }
  }

  renderTabs() {
    return (
      <Fragment>
        <StyledTabs
          // tabItemContainerStyle={{ backgroundColor: '#f3f3f3' }}
          // value={this.state.tab}
          // onChange={tab => this.setState({ tab })}
          variant="fullWidth"
          value={this.state.tab}
          onChange={(event, newValue) => {
            this.setState({ tab: newValue });
          }}
        >
          {/* <StyledTab label="Detail klienta" /> */}
          <StyledTab label="Vstupní formulář" />
          <StyledTab label="Zdravotní dotazník" />
          <StyledTab label="Vyšetření" />
          <StyledTab label="Senaptec" />
        </StyledTabs>
        {/* <div role="tabpanel">
          {this.state.tab === 0 && (
            <Fragment>
              <PageSpace />
              {this.state.client && (
                <div>
                  detail klienta
                </div>
              )}
            </Fragment>
          )}
        </div> */}
        <div role="tabpanel">
          {this.state.tab === 0 && (
            <Fragment>
              <PageSpace />
              {this.state.entryForm && (
                <EntryForm
                  branchId={this.props.branchId}
                  onInitUppy={this.onInitUppy}
                  attachments={this.state.attachments}
                  data={this.state.entryForm}
                  onEntryFormSubmit={this.onEntryFormSubmit}
                  onFilesUpload={this.onFilesUpload}
                />
              )}
            </Fragment>
          )}
        </div>
        <div role="tabpanel">
          {this.state.tab === 1 && (
            <Fragment>
              <PageSpace />
              {this.state.healthQuestionnaire && (
                <HealthQuestionnaire
                  data={this.state.healthQuestionnaire}
                  onSubmit={this.onHealthQuestionnaireSubmit}
                />
              )}
            </Fragment>
          )}
        </div>
        <div role="tabpanel">
          {this.state.tab === 2 && (
            <Fragment>
              <PageSpace />
              {this.state.trainingForms.length > 0 && (
                <FlexBlock hcenter>
                  <RaisedButton
                    onClick={this.openTrainingFormChart}
                    label="Otevřít grafy tréninků"
                    primary
                  />
                  <HSpace tiny />
                  <RaisedButton
                    onClick={this.printTrainings}
                    label="Vytisknout vyšetření"
                    primary
                  />
                  <HSpace tiny />
                  <RaisedButton
                    onClick={this.printSummary}
                    label="Vytisknout celkové shrnutí"
                    primary
                  />
                  <HSpace tiny />
                  <RaisedButton
                    onClick={this.printShortSummary}
                    label="Vytisknout krátké shrnutí"
                    primary
                  />
                  <HSpace tiny />
                  <RaisedButton onClick={this.generateCSV} label="CSV" primary />
                </FlexBlock>
              )}
              {this.state.trainingForms.length > 0 ? (
                <ControlledAccordion
                  activeKey={this.state.activeTrainingAccordionPanel}
                  className="TrainingFormAccordion"
                  onActiveChange={this.setActiveTrainingAccordionPanel}
                  initiallyExpanded={this.state.initiallyExpanded}
                  canCollapseAll
                >
                  {this.state.trainingForms.map((trainingForm, index) => {
                    const header = (
                      <div className={`TrainingFormHeader TrainingFormHeader-${index}`}>
                        <h3 className="AccordionPanelTitle">
                          {`Trénink ${new Date(trainingForm.actualDate).toLocaleDateString()}`}
                        </h3>
                        {(() => {
                          const value = trainingForm.acuity['0'].rightValue;
                          if (value && value.toString().length > 0) {
                            return (
                              <button
                                className="PrintInitialMeasurementButton"
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  this.printTrainingInitialMeasurement(trainingForm);
                                }}
                              >
                                Vytisknout úvodní měření
                              </button>
                            );
                          } else {
                            return null;
                          }
                        })()}
                      </div>
                    );
                    return (
                      <AccordionPanel key={index} header={header}>
                        <TrainingForm
                          index={index}
                          data={trainingForm}
                          previousData={this.state.trainingForms[index + 1]}
                          onRender={() => this.onTrainingFormRender(index)}
                          onTrainingFormSubmit={this.onTrainingFormSubmit}
                          hideAccordionPanel={() =>
                            this.setState({ activeTrainingAccordionPanel: null })
                          }
                          clientView={false}
                          printTraining={() => {}}
                        />
                      </AccordionPanel>
                    );
                  })}
                </ControlledAccordion>
              ) : (
                <FlexBlock hcenter>
                  <h4>Zatím nejsou rezervované žádné tréninky</h4>
                </FlexBlock>
              )}
            </Fragment>
          )}
        </div>
        <div role="tabpanel">
          {this.state.tab === 3 && (
            <Fragment>
              <PageSpace />
              {this.state.senaptecForms.length > 0 ? (
                <ControlledAccordion
                  activeKey={this.state.activeSenaptecAccordionPanel}
                  className="SenaptecFormAccordion"
                  onActiveChange={this.setActiveSenaptecAccordionPanel}
                  initiallyExpanded={false}
                  canCollapseAll
                >
                  {this.state.senaptecForms.map((senaptecForm, index) => {
                    const header = (
                      <div className={`SenaptecFormHeader SenaptecFormHeader-${index}`}>
                        <h3 className="AccordionPanelTitle">
                          {`Senaptec ${new Date(senaptecForm.actualDate).toLocaleDateString()}`}
                        </h3>
                      </div>
                    );
                    return (
                      <AccordionPanel key={index} header={header}>
                        <SenaptecForm
                          index={index}
                          data={senaptecForm}
                          onSenaptecFormSubmit={this.onSenaptecFormSubmit}
                        />
                      </AccordionPanel>
                    );
                  })}
                </ControlledAccordion>
              ) : (
                <FlexBlock hcenter>
                  <h4>Zatím nejsou rezervované žádné tréninky</h4>
                </FlexBlock>
              )}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }

  renderHeader() {
    const { name, email, date_of_birth, residence, zip_code, phone } = this.state.clientData;
    const { profession, newProfession, trainingForms } = this.state;

    return (
      <div>
        <FlexBlock vcenter baseline className="AppFormsHeaderMain">
          <h3>
            {name} {date_of_birth && `(${date_of_birth})`}
          </h3>
          <h5 onClick={this.handleProfessionClick}>{profession ? profession : 'Profese'}</h5>
          <Popover
            open={this.state.professionPopoverOpen}
            anchorEl={this.state.professionPopoverAnchor}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onRequestClose={this.handleProfessionRequestClose}
          >
            <div className="AppFormsProfessionChoice">
              <div>
                <SelectField
                  label="Vybrat profesi"
                  value={profession === newProfession ? profession : newProfession}
                  onChange={(event) => this.setState({ newProfession: event.target.value })}
                  values={this.available_professions}
                  shouldUseValue={true}
                />
              </div>
              <div>
                <TextField
                  floatingLabelText="Přidat profesi"
                  value={
                    this.available_professions.indexOf(newProfession) < 0 && newProfession
                      ? newProfession
                      : ''
                  }
                  onChange={(e) => this.setState({ newProfession: e.target.value })}
                />
              </div>
              <PageSpace tiny />
              <RaisedButton label="Uložit" onClick={this.submitProfession} primary />
            </div>
          </Popover>
        </FlexBlock>
        <FlexBlock vcenter className="AppFormsHeaderDetails">
          {trainingForms.length > 0 && (
            <FlexBlock vcenter>
              <IconButton title="Datum prvního tréninku">
                <CalendarIcon />
              </IconButton>
              {new Date(trainingForms[trainingForms.length - 1].actualDate).toLocaleDateString()}
            </FlexBlock>
          )}
          <IconButton title="Email">
            <EmailSvgIcon />
          </IconButton>
          <a href={`mailto:${email}`}>{email}</a>
          <IconButton title="Phone">
            <PhoneSvgIcon />
          </IconButton>
          {phone}
          {residence && zip_code && (
            <FlexBlock vcenter>
              <IconButton title="Bydliště">
                <LocationIcon />
              </IconButton>
              {residence}, {zip_code}
            </FlexBlock>
          )}
        </FlexBlock>
      </div>
    );
  }

  renderModalContent() {
    const { chosenChartTrainings, drawOptimalData, simpleGraph } = this.state;
    const filledTrainingForms = this.returnFilledTrainingForms();

    if (filledTrainingForms.length === 0) {
      return (
        <FlexBlock column hcenter>
          <h4>K dispozici nejsou žádná data</h4>
          <span>(žádný trénink není vyplněný)</span>
          <PageSpace tiny />
          <RaisedButton label="Zavřít" onClick={this.closeTrainingFormChart} primary />
        </FlexBlock>
      );
    }

    return (
      <div>
        <FlexBlock vcenter fullWidth spaceBetween>
          <div style={{ width: '400px' }}>
            <MultipleSelectField
              label="Vybrat trénink"
              value={chosenChartTrainings}
              onChange={(event) => {
                const { value } = event.target;
                this.setState({ chosenChartTrainings: value });
              }}
              values={filledTrainingForms.map((form) =>
                moment(form.actualDate).format('DD. MM. YYYY')
              )}
              renderMenuItemChildren={(value, index) => (
                <Fragment>
                  {chosenChartTrainings && chosenChartTrainings.indexOf(index) >= 0 && (
                    <span className="TrainingFormChartSelectItemCheckmark">&#10003;</span>
                  )}
                  {value}
                </Fragment>
              )}
            />
          </div>
          <RaisedButton label="Zavřít" onClick={this.closeTrainingFormChart} primary />
        </FlexBlock>
        <Toggle
          label="Ukázat&nbsp;optimální&nbsp;hodnoty:"
          toggled={drawOptimalData}
          onToggle={() => this.setState({ drawOptimalData: !drawOptimalData })}
          style={{ display: 'inline-block', marginTop: 10, width: 'auto' }}
        />
        <div>
          <Toggle
            label="Zjednodušený&nbsp;graf:"
            toggled={simpleGraph}
            onToggle={() => this.setState({ simpleGraph: !simpleGraph })}
            style={{ display: 'inline-block', width: 'auto' }}
          />
        </div>
        <PageSpace tiny />
        {chosenChartTrainings && chosenChartTrainings.length > 0 && (
          <TrainingFormChart
            data={filledTrainingForms.map((t, i) =>
              chosenChartTrainings.indexOf(i) >= 0 ? t : null
            )}
            drawOptimalData={drawOptimalData}
            simpleGraph={simpleGraph}
            options={{}}
          />
        )}
      </div>
    );
  }
}

export default AppForms;
