import React, { useState, useEffect, useContext } from 'react';
import { message } from 'antd';

import { PageContainer, FileManager } from '@components';

import { initUppy } from '@oldUtils/uppy';

import {
  request,
  readCookie,
  AttachmentsContext,
  setAttachments,
  addAttachments,
  deleteAttachment,
  filterAttachments,
  stopAttachmentsFilter,
  BranchContext,
} from '@utils';

const FilesPage = ({ branchId }) => {
  if (!branchId) return null;

  const {
    state: { hasFetched, attachments, targetPage, isFiltering, filteredAttachments, filteredPage },
    dispatch,
  } = useContext(AttachmentsContext);

  const [currentBranchId, setCurrentBranchId] = useState(branchId);
  const [filterText, setFilterText] = useState('');
  const [filterType, setFilterType] = useState('all');

  const fetchFiles = (resetData = false, usedBranchId = currentBranchId) => {
    if ((resetData || targetPage) && branchId) {
      request(`/api_classroom/files/${usedBranchId}?page=${resetData ? 1 : targetPage}`).then(
        ({ data }) => {
          const { files, nextPageExists } = data;

          let newAttachments,
            newTargetPage = 2;
          if (resetData) {
            newAttachments = files;
            if (!nextPageExists) newTargetPage = null;
          } else {
            newAttachments = [...attachments, ...files];
            newTargetPage = nextPageExists ? targetPage + 1 : null;
          }

          setCurrentBranchId(branchId);
          dispatch(
            setAttachments({
              attachments: newAttachments,
              targetPage: newTargetPage,
            })
          );
        }
      );
    }
  };

  useEffect(() => {
    if (!hasFetched || currentBranchId !== branchId) {
      fetchFiles(true, branchId);
    }
  }, [hasFetched, branchId]);

  useEffect(() => {
    if (isFiltering) {
      dispatch(stopAttachmentsFilter());
      setFilterText('');
      setFilterType('all');
    }
  }, [branchId]);

  const onSearch = (maintainData = false) => {
    request(
      `/api_classroom/search-files/${branchId}/?page=${
        !maintainData ? 1 : filteredPage
      }&q=${filterText}&type=${filterType}`
    ).then(({ data }) => {
      const { files, nextPageExists } = data;

      let newAttachments,
        newTargetPage = 2;
      if (!maintainData) {
        newAttachments = files;
        if (!nextPageExists) newTargetPage = null;
      } else {
        newAttachments = [...filteredAttachments, ...files];
        newTargetPage = nextPageExists ? filteredPage + 1 : null;
      }

      dispatch(
        filterAttachments({
          attachments: newAttachments,
          targetPage: newTargetPage,
        })
      );
    });
  };

  useEffect(() => {
    if (filterText || filterType !== 'all') {
      onSearch();
    } else {
      dispatch(stopAttachmentsFilter());
    }
  }, [filterText, filterType]);

  const onLoadMore = () => {
    if (isFiltering) {
      onSearch(true);
    } else {
      fetchFiles();
    }
  };

  const onUpload = (newAttachments) => {
    dispatch(addAttachments(newAttachments));

    message.success('Soubory byly úspěšně nahrány');
  };

  const onInitUppy = (options) => {
    const CSRFToken = readCookie('csrftoken', '');

    const { selector } = options;

    const onUploadWrap = (payload) => {
      onUpload(payload);
    };

    return initUppy({
      selector: `.${selector}`,
      endpointURL: `/api_classroom/upload-file/${branchId}`,
      onUpload: onUploadWrap,
      requestHeaders: {
        'X-CSRFToken': CSRFToken,
      },
    });
  };

  const onDelete = ({ id }) => {
    return request(`/api_classroom/files/delete/${branchId}/${id}/`, {
      method: 'DELETE',
    }).then(() => {
      dispatch(deleteAttachment(id));

      message.success('Soubor byl úspěšně smazán');
    });
  };

  const onTextFilter = (newFilterText) => {
    setFilterText(newFilterText);
  };

  const onTypeFilter = (newFilterType) => {
    setFilterType(newFilterType);
  };

  return (
    <FileManager
      branchId={branchId}
      hasMoreFiles={isFiltering ? !!filteredPage : !!targetPage}
      files={isFiltering ? filteredAttachments : attachments}
      onInitUppy={onInitUppy}
      onDelete={onDelete}
      filterText={filterText}
      filterType={filterType}
      hasTypeFilter={filterType !== 'all'}
      hasAnyFilter={!!filterText || filterType !== 'all'}
      onTextFilter={onTextFilter}
      onTypeFilter={onTypeFilter}
      onLoadMore={onLoadMore}
    />
  );
};

export default () => {
  const {
    state: { branchId },
  } = useContext(BranchContext);

  return (
    <PageContainer>
      <FilesPage branchId={branchId} />
    </PageContainer>
  );
};
