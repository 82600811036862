import { useContext, useState, useEffect } from 'react';
import { message } from 'antd';

import { request, UserContext, updateUser, BranchContext, setBranches } from '@utils';

const BranchManagerProfileContainer = (props) => {
  const {
    state: { user },
    dispatch,
  } = useContext(UserContext);
  const {
    state: { branches, branchId },
    dispatch: branchDispatch,
  } = useContext(BranchContext);
  const { branch } = props;

  const [branchInfo, setBranchInfo] = useState(null);

  useEffect(() => {
    request(`/api_customer/get-branch-info/${branch.id}`).then(({ data }) => {
      setBranchInfo(data);
    });
  }, []);

  const onUserChangesSubmit = (payload) => {
    return request(`/api_customer/edit-employee-profile`, {
      method: 'PATCH',
      data: payload,
    })
      .then(({ data }) => {
        if (payload.name) {
          dispatch(updateUser({ name: payload.name }));
        }

        message.success('Změna informací proběhla úspěšně');
      })
      .catch(() => {
        message.error('Došlo k chybě, je potřeba vyplnit povinná pole');
      });
  };

  const onBranchInfoChangesSubmit = (payload) => {
    return request(`/api_customer/edit-branch-info/${branch.id}`, {
      method: 'PATCH',
      data: payload,
    })
      .then(({ data }) => {
        setBranchInfo({
          ...branchInfo,
          ...data,
        });

        if (data.name) {
          // We have to change branches context
          // (name is the single relevant property)
          const newBranches = [...branches];
          const target = newBranches.map((_) => _.id).indexOf(branchId);
          if (target > -1) {
            newBranches[target].name = data.name;
            branchDispatch(setBranches(newBranches));
          }
        }

        message.success('Změna informací proběhla úspěšně');
      })
      .catch(() => {
        message.error('Došlo k chybě, je potřeba vyplnit povinná pole');
      });
  };

  if (!branchInfo) {
    return null;
  }

  const passProps = {
    ...props,
    user: user,
    branch: branch,
    branchInfo: branchInfo,
    onUserChangesSubmit: onUserChangesSubmit,
    onBranchInfoChangesSubmit: onBranchInfoChangesSubmit,
  };

  return props.children(passProps);
};

export default BranchManagerProfileContainer;
