import React, { Fragment, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import querystring from 'query-string';

import {
  PageContainer,
  BranchManagerProfileContainer,
  BranchManagerProfile,
  BranchEmployeeProfileContainer,
  BranchEmployeeProfile,
  ClientProfileContainer,
  ClientProfile,
} from '@components';

import AppPage from '@oldComponents/AppPage';

import { request, UserContext, setUser, BranchContext, PermissionsContext } from '@utils';

const ProfilePage = () => {
  const {
    state: { user },
    dispatch,
  } = useContext(UserContext);
  const {
    state: { branchId },
  } = useContext(BranchContext);
  const {
    state: { allowBranchAdministration },
  } = useContext(PermissionsContext);

  if (!user) {
    return null;
  }

  const location = useLocation();
  useEffect(() => {
    // Log user in
    if (location.pathname === '/first-time-access') {
      const { accesstoken } = querystring.parse(location.search);

      if (accesstoken && accesstoken.length > 0) {
        request(`/api_customer/initial-client-state?accesstoken=${accesstoken}`).then(
          ({ data }) => {
            if (!data.initial_state) {
              return;
            }

            const initState = JSON.parse(data.initial_state);

            if (!initState || !initState.loggedUser) {
              return;
            }

            dispatch(setUser(initState.loggedUser));
          }
        );
      }
    }
  });

  const onPasswordSubmit = (values) => {
    return request('/api_customer/update-password', {
      method: 'POST',
      data: values,
    }).then(() => message.success('Změna hesla proběhla úspěšně'));
  };

  // const isSuperuser = user.type === 'superuser';
  const isEmployee = user.type === 'employee';

  let branch = user.branches.filter((_) => _.id === branchId);
  branch = branch && branch[0];

  if (!branch) return null;

  return (
    <Fragment>
      {allowBranchAdministration ? (
        <AppPage hcenter header="">
          <BranchManagerProfileContainer branch={branch}>
            {(conprops) => (
              <BranchManagerProfile {...conprops} onPasswordSubmit={onPasswordSubmit} />
            )}
          </BranchManagerProfileContainer>
        </AppPage>
      ) : isEmployee ? (
        <AppPage vcenter hcenter header="">
          <BranchEmployeeProfileContainer branch={branch}>
            {(conprops) => (
              <BranchEmployeeProfile {...conprops} onPasswordSubmit={onPasswordSubmit} />
            )}
          </BranchEmployeeProfileContainer>
        </AppPage>
      ) : (
        <AppPage vcenter hcenter header="">
          <ClientProfileContainer branch={branch}>
            {(conprops) => <ClientProfile {...conprops} onPasswordSubmit={onPasswordSubmit} />}
          </ClientProfileContainer>
        </AppPage>
      )}
    </Fragment>
  );
};

export default () => (
  <PageContainer>
    <ProfilePage />
  </PageContainer>
);
