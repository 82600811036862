import React, { Component } from 'react';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import GridList from 'material-ui/GridList/GridList';
import GridTile from 'material-ui/GridList/GridTile';

import { hasNoTrueValue } from '@oldUtils/object';
import { FlexBlock, PageSpace } from '@oldUI';
// import { withUploadableFile } from '../containers/FilesContainer';
import FileTile from './FileTile';
// import { injectStore } from '@whysReact';
// import * as attachmentsActions from '../actions/attachments';

import { SelectField } from '@components';

import { request } from '@utils';

const uppyTriggerClass = 'upload-file-btn';

// type EntryFormProps = {|
//   attachments: Array<EntryFormAttachmentData>,
//   data: EntryFormData,
//
//   onEntryFormSubmit: Function,
//   onFileUpload: Function,
//
//   // Uppy
//   dispatch: Function,
//   onDelete: Function,
//   onInitUppy: Function,
//   onUpload: Function,
//   userToken: string,
// |};

// type _EntryFormState = {|
//   attachments: Array<EntryFormAttachmentData>,
//   dominance: { ...DominanceData },
//   adaptation: { ...AdaptationData },
//   note: string,
//   errors: { ...EntryFormErrors },
//   previewFile: ?string,
// |};

// type EntryFormState = { ..._EntryFormState };

class EntryForm extends Component {
  // closeUppy: AnyBoundMethod;
  constructor(props) {
    super(props);
    const data = this.props.data;

    this.state = {
      attachments: this.props.attachments,
      dominance: {
        motor: '',
        sensory: '',
        sport: '',
        hand: '',
      },
      adaptation: {
        value: '',
        note: '',
      },
      note: '',
      errors: {
        dominance: {
          motor: false,
          sensory: false,
          sport: false,
          hand: false,
        },
        adaptation: {
          value: false,
          note: false,
        },
      },
      previewFile: null,
      ...data,
    };
  }

  componentDidMount() {
    this.reinjectUppy();
  }

  componentWillUnmount() {
    this.closeUppy();
  }

  reinjectUppy() {
    const { onInitUppy } = this.props;
    if (this.closeUppy) {
      this.closeUppy();
    }
    this.closeUppy = onInitUppy({
      selector: uppyTriggerClass,
      onUploadDone: this.onUploadDone,
    });
  }

  onUploadDone = (files) => {
    this.props.onFilesUpload(files);

    this.setState({ attachments: [...this.state.attachments, ...files] });
  };

  onDeleteFile = ({ id }) => {
    return request(`/api_classroom/files/delete/${this.props.branchId}/${id}/`, {
      method: 'DELETE',
    }).then(() => {
      let attachments = [...this.state.attachments];
      attachments = attachments.filter((_) => _.id !== id);
      this.setState({ attachments });
    });
  };

  validate = () => {
    const errors = { ...this.state.errors };
    Object.keys(this.state.dominance).forEach((key) => {
      errors.dominance[key] = isNaN(this.state.dominance[key]);
    });
    errors.adaptation.value = isNaN(this.state.adaptation.value);
    if (this.state.adaptation.value === 2) {
      errors.adaptation.note = this.state.adaptation.note.length === 0;
    } else {
      errors.adaptation.note = false;
    }
    this.setState({ errors });
    return errors;
  };

  onChange = (name, field, value) => {
    this.setState({
      [name]: { ...this.state[name], [field]: value },
      errors: { ...this.state.errors, [name]: { ...this.state.errors[name], [field]: false } },
    });
  };

  submit = (e) => {
    e.preventDefault();
    const errors = this.validate();

    if (hasNoTrueValue(errors)) {
      this.props.onEntryFormSubmit(this.state);
    }
  };

  render() {
    return (
      <form onSubmit={this.submit}>
        <GridList cols={12} cellHeight="auto">
          {this.renderDominance()}
          <PageSpace double />
          {this.renderAdaptation()}
          <GridTile cols={12}>
            <PageSpace double />
          </GridTile>
          {this.renderNote()}
          <GridTile cols={12}>{this.renderSubmitButton()}</GridTile>
          <PageSpace />
          {this.renderAttachments()}
          <PageSpace />
        </GridList>
      </form>
    );
  }

  renderDominance() {
    return (
      <GridList cols={12} cellHeight="auto">
        <GridTile cols={12}>
          <h4>Dominance</h4>
        </GridTile>
        <GridTile cols={6}>
          <div style={{ margin: '14px 0 18px 0', width: '60%' }}>
            <SelectField
              label="Motorická *"
              value={this.state.dominance.motor}
              onChange={(event) => {
                const { value } = event.target;
                this.onChange('dominance', 'motor', value);
              }}
              name="motoric"
              error={this.state.errors.dominance.motor}
              values={['Pravé', 'Levé']}
              incrementIndex
            />
          </div>
        </GridTile>
        <GridTile cols={6}>
          <div style={{ margin: '14px 0 18px 0', width: '60%' }}>
            <SelectField
              label="Senzorická *"
              value={this.state.dominance.sensory}
              onChange={(event) => {
                const { value } = event.target;
                this.onChange('dominance', 'sensory', value);
              }}
              name="sensoric"
              error={this.state.errors.dominance.sensory}
              values={['Pravé', 'Levé', 'Smíšené']}
              incrementIndex
            />
          </div>
        </GridTile>
        <GridTile cols={6}>
          <div style={{ margin: '14px 0 18px 0', width: '60%' }}>
            <SelectField
              label="Ruka / noha *"
              value={this.state.dominance.hand}
              onChange={(event) => {
                const { value } = event.target;
                this.onChange('dominance', 'hand', value);
              }}
              name="handLeg"
              error={this.state.errors.dominance.hand}
              values={['Pravé', 'Levé', 'Nevyhraněné']}
              incrementIndex
            />
          </div>
        </GridTile>
        <GridTile cols={6}>
          <div style={{ margin: '14px 0 18px 0', width: '60%' }}>
            <SelectField
              label="Sportovní *"
              value={this.state.dominance.sport}
              onChange={(event) => {
                const { value } = event.target;
                this.onChange('dominance', 'sport', value);
              }}
              name="sport"
              error={this.state.errors.dominance.sport}
              values={['Pravé', 'Levé']}
              incrementIndex
            />
          </div>
        </GridTile>
      </GridList>
    );
  }

  renderAdaptation() {
    return (
      <GridList cols={12} cellHeight="auto">
        <GridTile cols={12}>
          <h4>Adaptace</h4>
        </GridTile>
        <GridTile key={0} cols={6}>
          <div style={{ margin: '14px 0 18px 0', width: '60%' }}>
            <SelectField
              label="Adaptace *"
              value={this.state.adaptation.value}
              onChange={(event) => {
                const { value } = event.target;

                if (value === 1) {
                  this.setState(
                    {
                      adaptation: { ...this.state.adaptation, note: '' },
                    },
                    () => this.onChange('adaptation', 'value', value)
                  );
                } else {
                  this.onChange('adaptation', 'value', value);
                }
              }}
              name="adaptation"
              error={this.state.errors.adaptation.value}
              values={['V pořádku', 'Potíže']}
              incrementIndex
            />
          </div>
        </GridTile>
        {this.state.adaptation.value === 2 ? (
          <GridTile key={1} cols={6}>
            <TextField
              floatingLabelText="Poznámka"
              value={this.state.adaptation.note}
              onChange={(e) =>
                this.setState({ adaptation: { ...this.state.adaptation, note: e.target.value } })
              }
              name="note"
              errorText={this.state.errors.adaptation.note ? 'Povinné pole' : ''}
            />
          </GridTile>
        ) : (
          <GridTile cols={6} />
        )}
      </GridList>
    );
  }

  renderNote() {
    return (
      <GridTile cols={6}>
        <h4>Poznámka</h4>
        <TextField
          value={this.state.note}
          onChange={(e) => this.setState({ note: e.target.value })}
          name="note"
          multiLine
          fullWidth
        />
        <PageSpace />
      </GridTile>
    );
  }

  renderSubmitButton() {
    return (
      <FlexBlock hcenter>
        <RaisedButton type="submit" label="Uložit" primary />
      </FlexBlock>
    );
  }

  renderAttachments() {
    const { attachments } = this.state;

    return (
      <GridList cols={12} cellHeight="auto">
        <GridTile cols={12}>
          <h4>Fotografie</h4>
          <PageSpace />
          <RaisedButton
            type="button"
            className={uppyTriggerClass}
            label="Nahrát soubor"
            secondary
          />
          <PageSpace />
        </GridTile>
        {attachments.map((file) => {
          const cols = this.state.previewFile === file.id ? 12 : 3;
          return (
            <GridTile
              key={file.id}
              cols={cols}
              onDoubleClick={() =>
                this.setState((prevState) => ({
                  previewFile: prevState.previewFile === file.id ? null : file.id,
                }))
              }
            >
              <FileTile key={file} file={file} onDelete={this.onDeleteFile} />
            </GridTile>
          );
        })}
      </GridList>
    );
  }
}

// const mapDispatch = {
//   onDelete: attachmentsActions.deleteFile,
// };

// export default injectStore(() => ({}), mapDispatch)(withUploadableFile(EntryForm));
export default EntryForm;
