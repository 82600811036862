import moment from 'moment';

const generateInitialMeasurementPrintHTML = (training, client) => {
  const { name } = client;
  const { actualDate } = training;
  const { initialMeasurement = {} } = training.initialData;
  const date = moment(actualDate).format('DD. MM. YYYY');

  let {
    SPH_right,
    CYL_right,
    AX_right,
    ADD_right,
    PRIS1_right,
    BAZE1_right,
    PRIS2_right,
    BAZE2_right,
    PD_right,
    // PACHY_right,
    // TONO_right,
    SPH_left,
    CYL_left,
    AX_left,
    ADD_left,
    PRIS1_left,
    BAZE1_left,
    PRIS2_left,
    BAZE2_left,
    PD_left,
    // PACHY_left,
    // TONO_left,
  } = initialMeasurement;

  SPH_right = parseFloat(SPH_right) || '';
  CYL_right = parseFloat(CYL_right) || '';
  AX_right = parseFloat(AX_right) || '';
  ADD_right = parseFloat(ADD_right) || '';
  PRIS1_right = parseFloat(PRIS1_right) || '';
  BAZE1_right = parseFloat(BAZE1_right) || '';
  PRIS2_right = parseFloat(PRIS2_right) || '';
  BAZE2_right = parseFloat(BAZE2_right) || '';
  PD_right = parseFloat(PD_right) || '';
  // PACHY_right = parseFloat(PACHY_right) || '';
  // TONO_right = parseFloat(TONO_right) || '';
  SPH_left = parseFloat(SPH_left) || '';
  CYL_left = parseFloat(CYL_left) || '';
  AX_left = parseFloat(AX_left) || '';
  ADD_left = parseFloat(ADD_left) || '';
  PRIS1_left = parseFloat(PRIS1_left) || '';
  BAZE1_left = parseFloat(BAZE1_left) || '';
  PRIS2_left = parseFloat(PRIS2_left) || '';
  BAZE2_left = parseFloat(BAZE2_left) || '';
  PD_left = parseFloat(PD_left) || '';
  // PACHY_left = parseFloat(PACHY_left) || '';
  // TONO_left = parseFloat(TONO_left) || '';

  if (SPH_right) SPH_right = SPH_right.toFixed(2);
  if (CYL_right) CYL_right = CYL_right.toFixed(2);
  if (AX_right) AX_right = AX_right.toFixed(2);
  if (ADD_right) ADD_right = ADD_right.toFixed(2);
  if (PRIS1_right) PRIS1_right = PRIS1_right.toFixed(2);
  if (BAZE1_right) BAZE1_right = BAZE1_right.toFixed(2);
  if (PRIS2_right) PRIS2_right = PRIS2_right.toFixed(2);
  if (BAZE2_right) BAZE2_right = BAZE2_right.toFixed(2);
  if (PD_right) PD_right = PD_right.toFixed(2);
  // if (PACHY_right) PACHY_right = PACHY_right.toFixed(2)
  // if (TONO_right) TONO_right = TONO_right.toFixed(2)
  if (SPH_left) SPH_left = SPH_left.toFixed(2);
  if (CYL_left) CYL_left = CYL_left.toFixed(2);
  if (AX_left) AX_left = AX_left.toFixed(2);
  if (ADD_left) ADD_left = ADD_left.toFixed(2);
  if (PRIS1_left) PRIS1_left = PRIS1_left.toFixed(2);
  if (BAZE1_left) BAZE1_left = BAZE1_left.toFixed(2);
  if (PRIS2_left) PRIS2_left = PRIS2_left.toFixed(2);
  if (BAZE2_left) BAZE2_left = BAZE2_left.toFixed(2);
  if (PD_left) PD_left = PD_left.toFixed(2);
  // if (PACHY_left) PACHY_left = PACHY_left.toFixed(2)
  // if (TONO_left) TONO_left = TONO_left.toFixed(2)

  const style = `
  @page {
    size: auto;
    margin: 0mm;
  }

  * {
    font-family: sans-serif;
  }

  .pagebreak {
    page-break-before: always;
  }

  .wrapper {
    display: block;
    margin: 0 auto;
    width: 90%;
  }

  .header {
    margin-left: 5%;
    position: fixed;
    top: 40px;
    width: 90%;
  }

  .header__logo {
    width: 70px;
  }

  .header__img {
    width: 100%;
  }

  .footer {
    border-top: 1px solid #eee;
    bottom: 30px;
    margin-left: 5%;
    position: fixed;
    width: 90%;
  }

  .footer__img {
    width: 100%;
  }

  .footer__hockey_logo {
    bottom: -12px;
    margin: 0 5px 0 0;
    position: absolute;
    right: 0;
    width: 100px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin: 150px 0 0 0;
    width: 22%;
  }

  .description {
    float: right;
    font-size: 14px;
    margin: 150px 0 0 0;
    width: 78%;
  }

  .inline {
    display: inline-block;
  }

  .measurement-table {
    margin-top: 30px;
  }

  table {
    border-collapse: collapse;
  }

  th {
    text-align: left;
  }

  td, th {
    border: 1px solid #ccc;
    padding: 6px 8px;
  }`;

  const html = `
<!DOCTYPE html>
<html>
  <head>
    <title>Úvodní měření</title>
    <style>${style}</style>
  </head>
  <body>
    <div class="header">
      <!-- <img src="/static/images/nevit_logo-color.png" class="header__logo" alt="Nevit"> -->
      <img src="/static/images/printheader.jpg" class="header__img" alt="Print header">
    </div>

    <div class="wrapper">
      <h2 class="title inline">DynaOptic s.r.o.</h2>
      <p class="description inline">
        Riegrovo náměstí 179/14, Kroměříž 767 01, Tel.: 603 750 520
        <br>
         CPMPK WALTROVKA, Walterovo nám. 329/2, Praha Jinonice 158 00               tel: 603 750 520
      </p>
      <table class="measurement-table">
        <tbody>
          <tr>
            <td colspan="3" style="border-color: #000;">
              <b>Zakázkový list<br>-----</b>
            </td>
            <td colspan="8" style="border-color: #000">
              <span class="inline">Určeno výlučně pro</span>
              <span><b>${name}</b></span>
            </td>
            <td style="border-color: #000;">
              <b>Zakázka<br>-----</b>
            </td>
          </tr>
          <tr>
            <td style="border-left-color: #000; border-right-color: #000;">Obruba 0,00</td>
            <td colspan="2">SPH</td>
            <td>CYL</td>
            <td>AX</td>
            <td>ADD</td>
            <td>PRIS1</td>
            <td>BAZE1</td>
            <td>PRIS2</td>
            <td>BAZE2</td>
            <td style="border-right-color: #000;">PD</td>
            <td style="border-color: #000;" rowspan="3"><span style="display: block;">Ze dne ${date}</span><span style="display: block; margin-top: 10px;">Zhotovení ${date}</span></td>
          </tr>
          <tr>
            <td style="border-left-color: #000; border-right-color: #000;">P&nbsp;čočka 0,00</td>
            <td>P</td>
            <td>${SPH_right}</td>
            <td>${CYL_right}</td>
            <td>${AX_right}</td>
            <td>${ADD_right}</td>
            <td>${PRIS1_right}</td>
            <td>${BAZE1_right}</td>
            <td>${PRIS2_right}</td>
            <td>${BAZE2_right}</td>
            <td style="border-right-color: #000;">${PD_right}</td>
          </tr>
          <tr>
            <td style="border-left-color: #000; border-bottom-color: #000; border-right-color: #000;">L&nbsp;čočka 0,00</td>
            <td style="border-bottom-color: #000;">L</td>
            <td style="border-bottom-color: #000;">${SPH_left}</td>
            <td style="border-bottom-color: #000;">${CYL_left}</td>
            <td style="border-bottom-color: #000;">${AX_left}</td>
            <td style="border-bottom-color: #000;">${ADD_left}</td>
            <td style="border-bottom-color: #000;">${PRIS1_left}</td>
            <td style="border-bottom-color: #000;">${BAZE1_left}</td>
            <td style="border-bottom-color: #000;">${PRIS2_left}</td>
            <td style="border-bottom-color: #000;">${BAZE2_left}</td>
            <td style="border-bottom-color: #000; border-right-color: #000;">${PD_left}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="footer">
      <!-- <div style="float: left;">
        <h3 style="margin-bottom: 0;">DynaOptic&nbsp;s.r.o.</h2>
        <p style="margin: 2px 0 0 0;">Riegrovo&nbsp;nám.&nbsp;179&nbsp;|&nbsp;767&nbsp;01&nbsp;Kroměříž</p>
      </div>
      <div style="float: right;">
        <h4 style="margin: 43px 125px 0 0;">Spolupracujeme&nbsp;s&nbsp;ČSLH</h4>
        <img src="/static/images/hockey_logo.jpg" class="footer__hockey_logo" alt="Czech hockey">
      </div> -->
      <img src="/static/images/printfooter.jpg" class="footer__img" alt="Print footer">
    </div
  </body>
</html>
`;
  return html;
};

export default generateInitialMeasurementPrintHTML;
