import * as React from 'react';

// App UI
import { DotIndicator } from '@materialUI';

import DelayedVisibility from '@utilComponents/DelayedVisibility';

// ABOUT
// A set of applied UI components with state information.
// For consistency purposes.

function createTrainingComponent(title) {
  // Can be used in e.g. in RaisedButton label prop.
  return () => (
    <span>
      {title}
      <DelayedVisibility delay={500}>
        <DotIndicator activeColor="black" />
      </DelayedVisibility>
    </span>
  );
}

export const UpdatingLabel = createTrainingComponent('Aktualizuji');
export const LoadingLabel = createTrainingComponent('Načítám');
