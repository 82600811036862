import * as React from 'react';
import cx from 'classnames';

/*
Terms:

CSS object - an object with keys of classnames for a component, values are what
is supported by the classnames npm package.
*/

function validateClassNames(expectedCSS, newCSS) {
  if (!expectedCSS) {
    throw new Error('expectedCSS');
  }
  const validKeys = Object.keys(expectedCSS);
  Object.keys(newCSS).forEach((k) => {
    if (validKeys.indexOf(k) === -1) {
      console.error(`Found invalid CSS class: ${k}`); // eslint-disable-line
      console.info(`Valid keys: ${validKeys.join(' ')}.`); // eslint-disable-line
    }
  });
}

// css - should have all classnames in the object
// extendCSS - can have one or more classnames keys to add classes to
function classnamesExtend(css, extendCSS) {
  const cssObject = {};
  Object.keys(css).forEach((classname) => {
    cssObject[classname] = cx(css[classname], extendCSS[classname]);
  });
  return cssObject;
}

function classnamesExtendDefined(definedCSS, extendCSS) {
  if (process.env.NODE_ENV !== 'production') {
    validateClassNames(definedCSS, extendCSS);
  }
  return classnamesExtend(definedCSS, extendCSS);
}

// Adds initial CSS object to the component (componentWithLayout).
// The CSS object (layoutCss) should have all possible CSS classes that
// are valid for the component.
// Its a HOC. Its returns new component, that can have its own CSS object prop, which
// will be added to existing ones (not overriden).
export function defineCSS(componentWithLayout, layoutCss) {
  if (!layoutCss || Object.keys(layoutCss).length === 0) {
    throw new Error('layoutCss must be an object');
  }
  const ComponentWithCSS = (props) => {
    const css = classnamesExtendDefined(layoutCss, props.css);
    const mergedProps = Object.assign({}, props, { css });
    return React.createElement(componentWithLayout, mergedProps, mergedProps.children);
  };
  ComponentWithCSS.defaultProps = { css: {} };
  return ComponentWithCSS;
}

// Adds new classnames to css prop of a component.
// Its a HOC. Its returns new component, that can have its own CSS object prop, which
// will be added to existing ones (not overriden).
export function applyCSS(componentToAddCSS, newCSS) {
  const ComponentWithAppliedCSS = (props) => {
    const css = classnamesExtend(newCSS, props.css);
    const mergedProps = Object.assign({}, props, { css });
    return React.createElement(componentToAddCSS, mergedProps, mergedProps.children);
  };
  ComponentWithAppliedCSS.defaultProps = { css: {} };
  return ComponentWithAppliedCSS;
}

export function wrapProps(componentToWrapProps, propsToWrap) {
  const ComponentWithWrappedProps = (props) => {
    const mergedProps = Object.assign({}, props, propsToWrap);
    return React.createElement(componentToWrapProps, mergedProps, mergedProps.children);
  };
  return ComponentWithWrappedProps;
}

export function throwErr(msg) {
  throw new Error(msg);
}

export function logErr(msg) {
  // eslint-disable-next-line
  console.error(msg);
}

// re-export common imports
export { cx };
