import { endOfDay } from 'date-fns';

import {
  fromHourString,
  stringifyTime,
  createDurationFromDates,
  parseDate,
  addDuration,
  strSimpleDate,
} from '@oldUtils/dateFormatting';

export function updateEnd(options) {
  const { timeslot, end } = options;

  const start = timeslot.getStartDate();

  const newDuration = createDurationFromDates(end, start);
  timeslot.duration = newDuration;
}

export function updateStart(options) {
  const { timeslot, start } = options;

  const newEndTime = addDuration(start, timeslot.duration);

  // handle when new start would push end to the next day
  if (newEndTime.getDay() !== start.getDay()) {
    // update duration to be max till end of the current day
    const maxDuration = createDurationFromDates(endOfDay(start), start);
    timeslot.duration = maxDuration;
  }

  timeslot.date = start;
  timeslot.start = stringifyTime(start);
}

// type UpdateTimeslotOptions = {|
//   timeslot: EventTimeslot,
//   start?: Date,
//   // only if end has changed too
//   end?: Date,
//   forReservation?: boolean,
// |};

export function updateTimeslot(options) {
  const { timeslot, start, end, forReservation } = options;
  if (start) {
    updateStart({ timeslot, start });
  }

  if (end) {
    updateEnd({ timeslot, end });
  }

  if (forReservation) {
    timeslot.forReservation = forReservation;
  }
}

class TimeslotInternal {
  // id: TutoID;
  // start: string;
  // duration: string;
  // onetime: boolean;
  // forReservation: boolean;
  // date: Date;
  // activeTo: ?Date;

  constructor(props) {
    Object.assign(this, props);
  }

  getStartDate() {
    const newDate = new Date(this.date);

    const [hh, mm] = fromHourString(this.start);

    newDate.setHours(hh);
    newDate.setMinutes(mm);

    // now its start date...
    return newDate;
  }

  getEndDate() {
    return addDuration(this.getStartDate(), this.duration);
  }

  // TUTO_REVIEW: ???
  // Monday is 0 and Sunday is 6
  getDay() {
    return this.getStartDate().getDay();
  }

  toUpdateJSON() {
    return {
      id: this.id,
      date: strSimpleDate(this.getStartDate()),
      start: this.start,
      duration: this.duration,
    };
  }

  toUpdateThisWeekJSON() {
    const newDate = this.getStartDate();
    return {
      timeslot: this.id,
      newDate: strSimpleDate(newDate),
      start: this.start,
      duration: this.duration,
    };
  }

  toCreateJSON() {
    return {
      start: this.start,
      duration: this.duration,
      activeFrom: strSimpleDate(this.getStartDate()),
      activeTo: this.activeTo && strSimpleDate(this.activeTo),
      onetime: this.onetime,
      forReservation: this.forReservation,
      // TUTO_REVIEW (handling onetime)
      date: strSimpleDate(this.getStartDate()),
    };
  }
}

function createTimeslot(props) {
  const model = new TimeslotInternal(props);
  return model;
}

export function createTimeslotFromPayload(payload) {
  return createTimeslot({
    id: payload.id,
    start: payload.start,
    duration: payload.duration,
    onetime: payload.onetime,
    forReservation: payload.forReservation,
    date: parseDate(payload.date),
  });
}

export function copyTimeslot(timeslot) {
  return createTimeslot({
    id: timeslot.id,
    start: timeslot.start,
    duration: timeslot.duration,
    onetime: timeslot.onetime,
    date: timeslot.date,
  });
}

export function createNewTimeslot(opts) {
  const { onetime } = opts;
  return createTimeslot({
    // default is Monday
    onetime: onetime !== undefined ? onetime : true,
    start: opts.start,
    duration: opts.duration,
    date: opts.date,
  });
}
