import * as React from 'react';

import { OutsideClick } from '@utilComponents/clickHelpers';
import { EscapeKeyUp } from '@utilComponents/keyUpListenWrappers';

function emptyFunc() {}

function Closeable(props) {
  const { closeOnEsc, closeOnOutsideClick, children, clickPriority } = props;

  const onOutsideClick = closeOnOutsideClick ? props.onClose : emptyFunc;
  const onEscape = closeOnEsc ? props.onClose : emptyFunc;

  return (
    <OutsideClick onClick={onOutsideClick} capture={clickPriority}>
      <EscapeKeyUp onKeyUp={onEscape}>{children}</EscapeKeyUp>
    </OutsideClick>
  );
}

export default Closeable;
