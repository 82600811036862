import isEmailLib from 'validator/lib/isEmail';

export function isEmailInText(text) {
  // c-p from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
  const re = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/; // eslint-disable-line
  return re.test(text);
}

export function isEmail(anyValue) {
  return anyValue && isEmailLib(anyValue);
}

// max, min parameter should be positive numbers
export function mkLengthValidator(minLength, maxLength) {
  return (text) => {
    if (minLength && text.length < minLength) {
      return false;
    }
    if (maxLength && text.length > maxLength) {
      return false;
    }
    return true;
  };
}

// based on https://github.com/kevva/url-regex
function urlRegexStrict() {
  const protocol = '(?:(?:[a-z]+:)?//)';
  const auth = '(?:\\S+(?::\\S*)?@)?';
  const host = '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)';
  const domain = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
  const tld = '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?';
  const port = '(?::\\d{2,5})?';
  const path = '(?:[/?#][^\\s"]*)?';
  const regex = `^(?:${protocol}|www\\.)${auth}(?:localhost|${host}${domain}${tld})${port}${path}$`;
  return new RegExp(regex, 'i');
}

// based on https://github.com/sindresorhus/is-url-superb
// Returns whether a string is resembling url and can be added to href
// attribute of <a> element.
export function isLinkLike(str) {
  return str && urlRegexStrict().test(str.trim());
}
