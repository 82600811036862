import { parseFloatWithComma } from '@oldUtils/decimal';

const floor = (value) => {
  if (value < 0) {
    return 0;
  } else if (value < 100) {
    return Math.floor(value);
  } else {
    return 100;
  }
};

const calcAcuityPerc = (value) => floor((parseFloatWithComma(value) - 0.1) / 0.019);
const calcMotilityPerc = (value) => (4 - parseFloatWithComma(value)) * 25;
const calcSharpnessPerc = (value) => floor((15 - (parseFloatWithComma(value) - 15)) / 0.15);
const calcConvergenceBifurcationPerc = (value) => {
  const adjustedValue = parseFloatWithComma(value);
  if (adjustedValue < 2) {
    return 75;
  } else if (adjustedValue < 9) {
    return 100;
  } else {
    return floor((12 - (adjustedValue - 8)) / 0.12);
    // return floor((28 - (value - 2)) / 0.28);
  }
};
const calcConvergenceConnectionPerc = (value) => floor(parseFloatWithComma(value) / 0.2);
const calcFlexibilityPerc = (value) => floor(parseFloatWithComma(value) / 0.3);
const calcAccommodationPerc = (value) => floor(parseFloatWithComma(value) / 0.3);

const calcInnerMusclesBlurClosePerc = (value) => floor(parseFloatWithComma(value) / 0.4);
const calcInnerMusclesBlurDistancePerc = (value) => floor(parseFloatWithComma(value) / 0.3);
const calcInnerMusclesBifurcationClosePerc = (value) => floor(parseFloatWithComma(value) / 0.4);
const calcInnerMusclesBifurcationDistancePerc = (value) => floor(parseFloatWithComma(value) / 0.3);
const calcInnerMusclesConnectionClosePerc = (value) => floor(parseFloatWithComma(value) / 0.4);
const calcInnerMusclesConnectionDistancePerc = (value) => floor(parseFloatWithComma(value) / 0.3);

const calcOuterMusclesBifurcationClosePerc = (value) => floor(parseFloatWithComma(value) / 0.4);
const calcOuterMusclesBifurcationDistancePerc = (value) => floor(parseFloatWithComma(value) / 0.3);
const calcOuterMusclesConnectionClosePerc = (value) => floor(parseFloatWithComma(value) / 0.4);
const calcOuterMusclesConnectionDistancePerc = (value) => floor(parseFloatWithComma(value) / 0.3);

const calcNeurotrackerPerc = (value) => floor(parseFloatWithComma(value) / 0.035);

const calcFunctions = {
  acuity: calcAcuityPerc,
  motility: calcMotilityPerc,
  sharpness: calcSharpnessPerc,
  convergence: {
    bifurcation: calcConvergenceBifurcationPerc,
    connection: calcConvergenceConnectionPerc,
  },
  flexibility: calcFlexibilityPerc,
  accommodation: calcAccommodationPerc,
  innerMuscles: {
    blurClose: calcInnerMusclesBlurClosePerc,
    blurDistance: calcInnerMusclesBlurDistancePerc,
    bifurcationClose: calcInnerMusclesBifurcationClosePerc,
    bifurcationDistance: calcInnerMusclesBifurcationDistancePerc,
    connectionClose: calcInnerMusclesConnectionClosePerc,
    connectionDistance: calcInnerMusclesConnectionDistancePerc,
  },
  outerMuscles: {
    bifurcationClose: calcOuterMusclesBifurcationClosePerc,
    bifurcationDistance: calcOuterMusclesBifurcationDistancePerc,
    connectionClose: calcOuterMusclesConnectionClosePerc,
    connectionDistance: calcOuterMusclesConnectionDistancePerc,
  },
  neurotracker: calcNeurotrackerPerc,
};

export const verboseCalcFunctions = {
  acuity_right_value: calcAcuityPerc,
  acuity_left_value: calcAcuityPerc,
  acuity_bino_value: calcAcuityPerc,
  motility_right: calcMotilityPerc,
  motility_left: calcMotilityPerc,
  sharpness_value: calcSharpnessPerc,
  convergence_bifurcation: calcConvergenceBifurcationPerc,
  convergence_connection: calcConvergenceConnectionPerc,
  flexibility_value: calcFlexibilityPerc,
  accommodation_right: calcAccommodationPerc,
  accommodation_left: calcAccommodationPerc,
  accommodation_bino: calcAccommodationPerc,
  inner_muscles_blur_close: calcInnerMusclesBlurClosePerc,
  inner_muscles_blur_distance: calcInnerMusclesBlurDistancePerc,
  inner_muscles_bifurcation_close: calcInnerMusclesBifurcationClosePerc,
  inner_muscles_bifurcation_distance: calcInnerMusclesBifurcationDistancePerc,
  inner_muscles_connection_close: calcInnerMusclesConnectionClosePerc,
  inner_muscles_connection_distance: calcInnerMusclesConnectionDistancePerc,
  outer_muscles_bifurcation_close: calcOuterMusclesBifurcationClosePerc,
  outer_muscles_bifurcation_distance: calcOuterMusclesBifurcationDistancePerc,
  outer_muscles_connection_close: calcOuterMusclesConnectionClosePerc,
  outer_muscles_connection_distance: calcOuterMusclesConnectionDistancePerc,
  neurotracker: calcNeurotrackerPerc,
};

export {
  calcAcuityPerc,
  calcMotilityPerc,
  calcSharpnessPerc,
  calcConvergenceBifurcationPerc,
  calcConvergenceConnectionPerc,
  calcFlexibilityPerc,
  calcAccommodationPerc,
  calcInnerMusclesBlurClosePerc,
  calcInnerMusclesBlurDistancePerc,
  calcInnerMusclesBifurcationClosePerc,
  calcInnerMusclesBifurcationDistancePerc,
  calcInnerMusclesConnectionClosePerc,
  calcInnerMusclesConnectionDistancePerc,
  calcOuterMusclesBifurcationClosePerc,
  calcOuterMusclesBifurcationDistancePerc,
  calcOuterMusclesConnectionClosePerc,
  calcOuterMusclesConnectionDistancePerc,
  calcNeurotrackerPerc,
};

export default calcFunctions;
