import React, { Component } from 'react';

import { Radar } from 'react-chartjs-2';
import generateSenaptecChartData from './generateSenaptecChartData';

const RadarChart = Radar;

const RadarChartWidth = 850;
const RadarChartHeight = 600;

const datasetOptions = {
  fill: false,
  pointBorderWidth: 0,
  pointBorderColor: 'transparent',
  pointRadius: 6,
  pointHitRadius: 6,
  pointHoverBorderWidth: 0,
  pointHoverRadius: 6,
};

const defaultChartOptions = {
  responsive: false,
  layout: {},
  animation: {
    duration: 2000,
  },
  scale: {
    display: true,
    ticks: {
      backdropColor: 'transparent',
      maxTicksLimit: 7,
      suggestedMin: 0,
      suggestedMax: 100,
    },
    pointLabels: {
      // callback: labelName => labelName,
      fontSize: 13,
    },
    gridLines: {
      circular: true,
    },
    angleLines: {
      display: true,
    },
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: true,
    mode: 'nearest',
    intersect: true,

    backgroundColor: 'rgb(240, 240, 240)',
    borderColor: 'rgb(20, 20, 20)',
    borderWidth: 0.5,
    xPadding: 10,
    yPadding: 10,

    titleFontColor: '#333',
    titleFontSize: 16,
    titleFontStyle: 'normal',
    titleSpacing: 10,

    bodyFontColor: '#333',
    bodyFontSize: 14,

    callbacks: {
      title: (actualData, chartData) => {
        const { datasetIndex, index } = actualData[0];
        const labelsLength = chartData.labels.length;

        const label = chartData.datasets[datasetIndex].label;
        let title = chartData.labels[index];
        if (Array.isArray(title)) {
          title = chartData.labels[index].join(' ');
        }

        if (label.indexOf('Optimal') >= 0) {
          // Optimal doesn't need right / left / ...
          if (Array.isArray(title)) {
            return 'Optimální ' + title.join(' ').toLowerCase();
          } else {
            return 'Optimální ' + title.toLowerCase();
          }
        }

        if (label.indexOf('Right') >= 0) {
          title += ' - Pravá';
        } else if (label.indexOf('Left') >= 0) {
          title += ' - Levá';
        } else if (labelsLength === 18 && index === 1) {
          // Right motility
          title += ' - Pravá';
        } else if ((labelsLength === 11 && index === 2) || (labelsLength === 18 && index === 3)) {
          // Convergence
          if (label.indexOf('Main') >= 0) {
            // Bifurcation
            title += ' - Rozdvojení';
          } else {
            // Connection
            title += ' - Spojení';
          }
        } else {
          // Check for acuity or accommodation in main dataset
          if (labelsLength === 11) {
            if (index === 0 || index === 4) {
              title += ' - Bino';
            }
          } else {
            if (index === 0 || index === 5) {
              title += ' - Bino';
            }
          }
        }

        return title;
      },
      label: (actualData) => actualData.yLabel.toString() + '%',
    },
  },
};

// type SenaptecFormChartProps = {|
//   data: Array<TrainingFormData | null>,
//   drawOptimalData: boolean
//   options: Object,
// |};

export default class SenaptecFormChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  componentDidMount() {
    this.setState({ ready: true });
  }

  render() {
    if (!this.state.ready) {
      return null;
    }

    const { data, options } = this.props;

    if (!data || data.length === 0) {
      return null;
    }

    return (
      <RadarChart
        id="SenaptecFormChart"
        height={RadarChartHeight}
        width={RadarChartWidth}
        data={generateSenaptecChartData(data, datasetOptions)}
        options={{
          ...defaultChartOptions,
          ...options,
        }}
      />
    );
  }
}
