import React from 'react';
import styled from 'styled-components';

import styles from './AppForms.module.css';

import ArrowIcon from '@material-ui/icons/ArrowRightAlt';
import { withStyles } from '@material-ui/core/styles';

const EyesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 40px;
`;

const EyeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 30px;
`;

const EyeText = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 20px 0;
  text-align: center;
`;

const ArrowsContainer = styled.div`
  height: 280px;
  position: relative;
  width: 280px;
`;

const EyeImage = styled.img`
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  width: 100px;
  z-index: 2;
`;

const StyledArrow = styled(ArrowIcon)`
  color: ${({ selected }) => (selected ? '#1669af' : '#bbb')};
  font-size: 120px !important;
  position: absolute;

  path {
    transition: color 0.3s ease;
  }
`;

const ArrowTop = withStyles({
  root: {
    bottom: '50%',
    left: '50%',
    transform: 'translateX(-50%) rotate(-90deg)',
  },
})(StyledArrow);

const ArrowTopRight = withStyles({
  root: {
    bottom: '50%',
    left: '50%',
    transform: 'translate(-15px, 15px) rotate(-45deg)',
  },
})(StyledArrow);

const ArrowRight = withStyles({
  root: {
    left: '50%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
})(StyledArrow);

const ArrowBottomRight = withStyles({
  root: {
    left: '50%',
    top: '50%',
    transform: 'translate(-15px, -15px) rotate(45deg)',
  },
})(StyledArrow);

const ArrowBottom = withStyles({
  root: {
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) rotate(90deg)',
  },
})(StyledArrow);

const ArrowBottomLeft = withStyles({
  root: {
    right: '50%',
    top: '50%',
    transform: 'translate(15px, -15px) rotate(135deg)',
  },
})(StyledArrow);

const ArrowLeft = withStyles({
  root: {
    right: '50%',
    top: '50%',
    transform: 'translateY(-50%) rotate(180deg)',
  },
})(StyledArrow);

const ArrowTopLeft = withStyles({
  root: {
    bottom: '50%',
    right: '50%',
    transform: 'translate(15px, 15px) rotate(225deg)',
  },
})(StyledArrow);

const StyledArrowText = styled.p`
  background-color: ${({ selected }) => (selected ? '#1669af' : 'transparent')};
  border-radius: 8px;
  color: ${({ selected }) => (selected ? '#fff' : 'auto')};
  cursor: pointer;
  position: absolute;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 6px;
  transition: background-color 0.3s ease, color 0.3s ease;
  user-select: none;
  z-index: 3;

  &:hover {
    background-color: ${({ selected }) => (selected ? '#1669af' : '#ddd')};
  }
`;

const TopText = styled(StyledArrowText)`
  left: 50%;
  top: 0;
  transform: translateX(-50%);
`;

const TopRightText = styled(StyledArrowText)`
  right: 30px;
  top: 38px;
`;

const RightText = styled(StyledArrowText)`
  right: 0;
  top: 50%;
  transform: translateY(-47%);
`;

const BottomRightText = styled(StyledArrowText)`
  bottom: 28px;
  right: 30px;
`;

const BottomText = styled(StyledArrowText)`
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(4px);
`;

const BottomLeftText = styled(StyledArrowText)`
  bottom: 28px;
  left: 30px;
`;

const LeftText = styled(StyledArrowText)`
  left: 0;
  top: 50%;
  transform: translateY(-47%);
`;

const TopLeftText = styled(StyledArrowText)`
  left: 30px;
  top: 38px;
`;

const rightChoices = ['SR (IO)', 'IO', 'MR', 'SO', 'IR (SO)', 'IR', 'LR', 'SR'];

const leftChoices = ['SR (IO)', 'SR', 'LR', 'IR', 'IR (SO)', 'SO', 'MR', 'IO'];

export default class MotilityProblemPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRightProblems: props.selectedRightProblems || [],
      selectedLeftProblems: props.selectedLeftProblems || [],
    };
  }

  isRightSelected = (index) => {
    const choice = rightChoices[index];
    return this.state.selectedRightProblems.indexOf(choice) > -1;
  };

  isLeftSelected = (index) => {
    const choice = leftChoices[index];
    return this.state.selectedLeftProblems.indexOf(choice) > -1;
  };

  onSelectRight = (index) => {
    if (this.props.clientView) {
      return;
    }

    const choice = rightChoices[index];

    let newSelectedRightProblems = [];
    if (this.isRightSelected(index)) {
      newSelectedRightProblems = this.state.selectedRightProblems.filter((_) => _ !== choice);
    } else {
      newSelectedRightProblems = [...this.state.selectedRightProblems, choice];
    }

    this.setState({ selectedRightProblems: newSelectedRightProblems }, () => {
      this.props.onRightProblemsChange(this.state.selectedRightProblems);
    });
  };

  onSelectLeft = (index) => {
    if (this.props.clientView) {
      return;
    }

    const choice = leftChoices[index];

    let newSelectedLeftProblems = [];
    if (this.isLeftSelected(index)) {
      newSelectedLeftProblems = this.state.selectedLeftProblems.filter((_) => _ !== choice);
    } else {
      newSelectedLeftProblems = [...this.state.selectedLeftProblems, choice];
    }

    this.setState({ selectedLeftProblems: newSelectedLeftProblems }, () => {
      this.props.onLeftProblemsChange(this.state.selectedLeftProblems);
    });
  };

  renderRightEyeAndArrows() {
    return (
      <EyeContainer>
        <EyeText>Pravé oko</EyeText>
        <ArrowsContainer>
          <EyeImage src="/static/images/eye.png" />
          <TopText onClick={() => this.onSelectRight(0)} selected={this.isRightSelected(0)}>
            {rightChoices[0]}
          </TopText>
          <ArrowTop selected={this.isRightSelected(0)} />
          <TopRightText onClick={() => this.onSelectRight(1)} selected={this.isRightSelected(1)}>
            {rightChoices[1]}
          </TopRightText>
          <ArrowTopRight selected={this.isRightSelected(1)} />
          <RightText onClick={() => this.onSelectRight(2)} selected={this.isRightSelected(2)}>
            {rightChoices[2]}
          </RightText>
          <ArrowRight selected={this.isRightSelected(2)} />
          <BottomRightText onClick={() => this.onSelectRight(3)} selected={this.isRightSelected(3)}>
            {rightChoices[3]}
          </BottomRightText>
          <ArrowBottomRight selected={this.isRightSelected(3)} />
          <BottomText onClick={() => this.onSelectRight(4)} selected={this.isRightSelected(4)}>
            {rightChoices[4]}
          </BottomText>
          <ArrowBottom selected={this.isRightSelected(4)} />
          <BottomLeftText onClick={() => this.onSelectRight(5)} selected={this.isRightSelected(5)}>
            {rightChoices[5]}
          </BottomLeftText>
          <ArrowBottomLeft selected={this.isRightSelected(5)} />
          <LeftText onClick={() => this.onSelectRight(6)} selected={this.isRightSelected(6)}>
            {rightChoices[6]}
          </LeftText>
          <ArrowLeft selected={this.isRightSelected(6)} />
          <TopLeftText onClick={() => this.onSelectRight(7)} selected={this.isRightSelected(7)}>
            {rightChoices[7]}
          </TopLeftText>
          <ArrowTopLeft selected={this.isRightSelected(7)} />
        </ArrowsContainer>
      </EyeContainer>
    );
  }

  renderLeftEyeAndArrows() {
    return (
      <EyeContainer>
        <EyeText>Levé oko</EyeText>
        <ArrowsContainer>
          <EyeImage src="/static/images/eye.png" />
          <TopText onClick={() => this.onSelectLeft(0)} selected={this.isLeftSelected(0)}>
            {leftChoices[0]}
          </TopText>
          <ArrowTop selected={this.isLeftSelected(0)} />
          <TopRightText onClick={() => this.onSelectLeft(1)} selected={this.isLeftSelected(1)}>
            {leftChoices[1]}
          </TopRightText>
          <ArrowTopRight selected={this.isLeftSelected(1)} />
          <RightText onClick={() => this.onSelectLeft(2)} selected={this.isLeftSelected(2)}>
            {leftChoices[2]}
          </RightText>
          <ArrowRight selected={this.isLeftSelected(2)} />
          <BottomRightText onClick={() => this.onSelectLeft(3)} selected={this.isLeftSelected(3)}>
            {leftChoices[3]}
          </BottomRightText>
          <ArrowBottomRight selected={this.isLeftSelected(3)} />
          <BottomText onClick={() => this.onSelectLeft(4)} selected={this.isLeftSelected(4)}>
            {leftChoices[4]}
          </BottomText>
          <ArrowBottom selected={this.isLeftSelected(4)} />
          <BottomLeftText onClick={() => this.onSelectLeft(5)} selected={this.isLeftSelected(5)}>
            {leftChoices[5]}
          </BottomLeftText>
          <ArrowBottomLeft selected={this.isLeftSelected(5)} />
          <LeftText onClick={() => this.onSelectLeft(6)} selected={this.isLeftSelected(6)}>
            {leftChoices[6]}
          </LeftText>
          <ArrowLeft selected={this.isLeftSelected(6)} />
          <TopLeftText onClick={() => this.onSelectLeft(7)} selected={this.isLeftSelected(7)}>
            {leftChoices[7]}
          </TopLeftText>
          <ArrowTopLeft selected={this.isLeftSelected(7)} />
        </ArrowsContainer>
      </EyeContainer>
    );
  }

  render() {
    return (
      <EyesContainer>
        {this.renderRightEyeAndArrows()}
        {this.renderLeftEyeAndArrows()}
      </EyesContainer>
    );
  }
}
