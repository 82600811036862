import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';

import calcFunctions from './calcPercentage';

const translations = {
  actual_date: 'Datum',
  acuity_right_value: 'Ostrost - Pravá',
  acuity_left_value: 'Ostrost - Levá',
  acuity_bino_value: 'Ostrost - Bino',
  motility_right: 'Motilita - Pravá',
  motility_left: 'Motilita - Levá',
  sharpness_value: 'Přeostření dálka blízko dálka',
  convergence_bifurcation: 'Maximální bod konvergence - rozdvojení',
  convergence_connection: 'Maximální bod konvergence - spojení',
  flexibility_value: 'Vergenční flexibilita',
  accommodation_right: 'Akomodace - Pravá',
  accommodation_left: 'Akomodace - Levá',
  accommodation_bino: 'Akomodace - Bino',
  inner_muscles_blur_close: 'Konvergence - rozmlžení (blízko)',
  inner_muscles_blur_distance: 'Konvergence - rozmlžení (dálka)',
  inner_muscles_bifurcation_close: 'Konvergence - rozdvojení (blízko)',
  inner_muscles_bifurcation_distance: 'Konvergence - rozdvojení (dálka)',
  inner_muscles_connection_close: 'Konvergence - spojení (blízko)',
  inner_muscles_connection_distance: 'Konvergence - spojení (dálka)',
  outer_muscles_bifurcation_close: 'Divergence - rozdvojení (blízko)',
  outer_muscles_bifurcation_distance: 'Divergence - rozdvojení (dálka)',
  outer_muscles_connection_close: 'Divergence - spojení (blízko)',
  outer_muscles_connection_distance: 'Divergence - spojení (dálka)',
  neurotracker: 'Neurotracker',
};

const generateTrainingPrintHTML = (trainingForms, entryForm, dataURL) => {
  const allSameValues = (array) => {
    const x = array[0];
    return array.every((item) => item === x);
  };

  const renderEntryData = () => {
    const basicChoices = {
      1: 'Pravé',
      2: 'Levé',
    };

    const motoricChoices = { ...basicChoices };
    const sensoricChoices = { ...basicChoices, 3: 'Smíšené' };
    const handLegChoices = { ...basicChoices, 3: 'Nevyhraněné' };
    const sportChoices = { ...basicChoices };
    const adaptationChoices = { 1: 'V pořádku', 2: 'Potíže' };

    const {
      dominance_motoric,
      dominance_sensoric,
      dominance_hand_leg,
      dominance_sports,
      adaptation_adaptation,
    } = entryForm;

    if (dominance_motoric.length === 0) {
      return null;
    }

    return (
      <tbody>
        <tr>
          <th colSpan={4} style={{ textAlign: 'center' }}>
            Dominance
          </th>
          <th colSpan={1} rowSpan={2}>
            Adaptace
          </th>
        </tr>
        <tr>
          <th>Motorická</th>
          <th>Senzorická</th>
          <th>Ruka / noha</th>
          <th>Sportovní</th>
        </tr>
        <tr>
          <td>{motoricChoices[dominance_motoric]}</td>
          <td>{sensoricChoices[dominance_sensoric]}</td>
          <td>{handLegChoices[dominance_hand_leg]}</td>
          <td>{sportChoices[dominance_sports]}</td>
          <td>{adaptationChoices[adaptation_adaptation]}</td>
        </tr>
      </tbody>
    );
  };

  const entryTable = ReactDOMServer.renderToStaticMarkup(
    <table className="entry_table">{renderEntryData()}</table>
  );

  const renderTBody = () => {
    const tfs = trainingForms;

    const rows = {};

    for (let key in tfs[0]) {
      if (
        key.indexOf('measurement') >= 0 ||
        key.indexOf('obruba') >= 0 ||
        key.indexOf('sklo') >= 0 ||
        key.indexOf('problems') >= 0 ||
        key.indexOf('ac_ratio') >= 0 ||
        key.indexOf('correction') >= 0 ||
        key.indexOf('strabismus') >= 0 ||
        key.indexOf('note') >= 0 ||
        key.indexOf('diopter') >= 0
      ) {
        continue;
      } else if (key === 'lesson') {
        rows['actual_date'] = [<th key="lesson" />];
      } else {
        rows[key] = [<th key={translations[key]}>{translations[key]}</th>];
      }
    }

    tfs.forEach((tf, i) => {
      for (let key in tf) {
        const value = tf[key];

        if (
          key.indexOf('measurement') >= 0 ||
          key.indexOf('obruba') >= 0 ||
          key.indexOf('sklo') >= 0 ||
          key.indexOf('problems') >= 0 ||
          key.indexOf('ac_ratio') >= 0 ||
          key.indexOf('correction') >= 0 ||
          key.indexOf('strabismus') >= 0 ||
          key.indexOf('note') >= 0 ||
          key.indexOf('diopter') >= 0
        ) {
          continue;
        } else if (key === 'lesson') {
          const date = moment(value.actual_date);
          rows['actual_date'].push(<th key={date + i}>{date.format('DD. MM. YYYY')}</th>);
        } else if (key.indexOf('motility') >= 0) {
          rows[key].push(<td key={key + i}>{calcFunctions.motility(parseInt(value, 10) - 1)}%</td>);
        } else {
          if (Array.isArray(value)) {
            const correctionsArray = tf[`${key.split('_')[0]}_correction`];

            if (allSameValues(correctionsArray)) {
              rows[key].push(<td key={key + i}>{value[value.length - 1]}</td>);
            } else {
              const normalValue = value[correctionsArray.lastIndexOf(false)];
              const correctedValue = value[correctionsArray.lastIndexOf(true)];

              rows[key].push(
                <td key={key + i}>
                  {normalValue} ({correctedValue})
                </td>
              );
            }
          } else {
            rows[key].push(<td key={key + i}>{value}</td>);
          }
        }
      }
    });

    const rowsArray = [];
    let index = 0;
    for (let key in rows) {
      const isOdd = index % 2;
      index++;
      rowsArray.push(
        <tr className={isOdd ? 'tr-odd' : 'tr-even'} key={key}>
          {rows[key]}
        </tr>
      );
    }

    return rowsArray;
  };

  const trainingTable = ReactDOMServer.renderToStaticMarkup(
    <table className="training_table">
      <tbody>{renderTBody()}</tbody>
    </table>
  );

  const style = `
  @page {
    size: auto;
    margin: 0mm;
  }

  * {
    font-family: sans-serif;
  }

  .pagebreak {
    page-break-before: always;
  }

  .wrapper {
    display: block;
    margin: 0 auto;
    width: 90%;
  }

  .header {
    margin-left: 5%;
    position: fixed;
    top: 40px;
    width: 90%;
  }

  .header__logo {
    width: 70px;
  }

  .header__img {
    width: 100%;
  }

  .footer {
    border-top: 1px solid #eee;
    bottom: 30px;
    margin-left: 5%;
    position: fixed;
    width: 90%;
  }

  .footer__img {
    width: 100%;
  }

  .footer__hockey_logo {
    bottom: -12px;
    margin: 0 5px 0 0;
    position: absolute;
    right: 0;
    width: 100px;
  }

  .entry_table {
    margin-top: 130px;
  }

  .training_table {
    margin-top: 15px;
  }

  table {
    border-collapse: collapse;
  }

  th {
    text-align: left;
  }

  td, th {
    border: 1px solid #000;
    padding: 5px 7px;
  }

  .tr-even {
    background-color: #eee;
  }

  .tr-odd {
    background-color: #fff;
  }

  .chart {
    display: block;
    margin: 0 auto;
    padding-top: 150px;
    width: 95%;
  }`;

  const html = `
<!DOCTYPE html>
<html>
  <head>
    <title>Shrnutí vyšetření</title>
    <style>${style}</style>
  </head>
  <body>
    <div class="header">
      <!-- <img src="/static/images/nevit_logo-color.png" class="header__logo" alt="Nevit"> -->
      <img src="/static/images/printheader.jpg" class="header__img" alt="Print header">
    </div>

    <div class="wrapper">
      ${entryTable}

      ${trainingTable}
    </div>

    <div class="footer">
      <!-- <div style="float: left;">
        <h3 style="margin-bottom: 0;">DynaOptic&nbsp;s.r.o.</h2>
        <p style="margin: 2px 0 0 0;">Riegrovo&nbsp;nám.&nbsp;179&nbsp;|&nbsp;767&nbsp;01&nbsp;Kroměříž</p>
      </div>
      <div style="float: right;">
        <h4 style="margin: 43px 125px 0 0;">Spolupracujeme&nbsp;s&nbsp;ČSLH</h4>
        <img src="/static/images/hockey_logo.jpg" class="footer__hockey_logo" alt="Czech hockey">
      </div> -->
      <img src="/static/images/printfooter.jpg" class="footer__img" alt="Print footer">
    </div>

    <img class="pagebreak chart" src="${dataURL}">
  </body>
</html>
`;
  return html;
};

export default generateTrainingPrintHTML;
