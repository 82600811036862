import * as React from 'react';
import { Link } from 'react-router-dom';

import AppPage from '@oldComponents/AppPage';

function NotFound({ children }) {
  let message = children;
  if (!message) {
    message = <p> We are sorry but page you are looking for does not exist. </p>;
  }
  return (
    <AppPage header="">
      <div className="container text-center">
        <h2> 404 page not found </h2>
        {message}
        <Link to="/">Back to app</Link>
      </div>
    </AppPage>
  );
}

export default NotFound;
