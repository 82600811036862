import { parseFloatWithComma } from '@oldUtils/decimal';

export const generateEntryFormPayload = (dominance, adaptation, note) => {
  const { motor, sensory, sport, hand } = dominance;
  const { value, note: adaptationNote } = adaptation;

  const payload = {
    dominance_motoric: motor,
    dominance_sensoric: sensory,
    dominance_hand_leg: hand,
    dominance_sports: sport,
    adaptation_adaptation: value,
    adaptation_note: adaptationNote,
    note: note,
  };

  return payload;
};

export const generateHealthQuestionnairePayload = (state) => {
  const payload = {
    family_problems: state.familyProblems,

    wears_glasses: state.wearsGlasses,
    what_glasses: state.whatGlasses,
    wears_contact_lenses: state.wearsContactLenses,
    what_contact_lenses: state.whatContactLenses,
    has_headaches: state.hasHeadaches,
    what_headaches: state.whatHeadaches,
    // uses_artificial_tears: state.usesArtificialTears,
    // what_artificial_tears: state.whatArtificialTears,
    takes_antibiotics: state.takesAntibiotics,
    what_antibiotics: state.whatAntibiotics,
    has_eye_inflammation: state.hasEyeInflammation,
    what_eye_inflammation: state.whatEyeInflammation,

    middle_ear_problems: state.middleEarProblems,
    has_balance_problems: state.hasBalanceProblems,
    has_dizziness: state.hasDizziness,
    has_high_blood_pressure: state.hasHighBloodPressure,
    blood_pressure_medicine: state.bloodPressureMedicine,
    diabetes_medicine: state.diabetesMedicine,
    other_problems: state.otherProblems,

    pharmacological_medical_analysis: state.pharmacologicalMedicalAnalysis,
    past_eye_operations: state.pastEyeOperations,
    past_accidents: state.pastAccidents,
    past_hospitalizations: state.pastHospitalizations,
    watched_by_specialist: state.watchedBySpecialist,
    allergies: state.allergies,

    something_else: state.somethingElse,
  };

  return payload;
};

export const generateMeasurementData = (data) => {
  const toReturn = {};

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] || data[key] === 0) {
        toReturn[key] = parseFloatWithComma(data[key]);
      } else {
        toReturn[key] = null;
      }
    }
  }

  return toReturn;
};

export const generateTrainingFormPayload = (state) => {
  let {
    lessonId,
    initialData,
    acuityStrabismus,
    acuity,
    motility,
    sharpness,
    convergence,
    flexibility,
    accommodation,
    acRatio,
    innerMuscles,
    outerMuscles,
    neurotracker,
    note,
  } = state;

  const lesson_id = lessonId;

  const initial_measurement = generateMeasurementData(initialData.initialMeasurement);
  const obruba = initialData.obruba;
  const sklo_cocka_right = initialData.skloCockaRight;
  const sklo_cocka_left = initialData.skloCockaLeft;

  const acuity_strabismus = acuityStrabismus;
  let acuity_measurements = [],
    acuity_right_value = [],
    acuity_right_diopter = [],
    acuity_left_value = [],
    acuity_left_diopter = [],
    acuity_bino_value = [],
    acuity_bino_diopter = [],
    acuity_correction = [];

  for (const index in acuity) {
    const a = acuity[index];
    acuity_measurements.push(generateMeasurementData(a.measurement));
    acuity_right_value.push(parseFloatWithComma(a.rightValue));
    acuity_right_diopter.push(a.rightDiopter);
    acuity_left_value.push(parseFloatWithComma(a.leftValue));
    acuity_left_diopter.push(a.leftDiopter);
    acuity_bino_value.push(parseFloatWithComma(a.binoValue));
    acuity_bino_diopter.push(a.binoDiopter);
    acuity_correction.push(a.correction);
  }

  const motility_right = motility.right + 1;
  const motility_right_problems = motility.rightProblems;
  const motility_left = motility.left + 1;
  const motility_left_problems = motility.leftProblems;

  const sharpness_value = parseFloatWithComma(sharpness.value);
  const sharpness_correction = sharpness.correction;

  let convergence_bifurcation = [],
    convergence_connection = [],
    convergence_note = [],
    convergence_correction = [];

  for (const index in convergence) {
    const c = convergence[index];
    convergence_bifurcation.push(parseFloatWithComma(c.bifurcation));
    convergence_connection.push(parseFloatWithComma(c.connection));
    convergence_note.push(c.note);
    convergence_correction.push(c.correction);
  }

  let flexibility_value = [],
    flexibility_correction = [];

  for (const index in flexibility) {
    const f = flexibility[index];
    flexibility_value.push(parseFloatWithComma(f.value));
    flexibility_correction.push(f.correction);
  }

  let accommodation_left = [],
    accommodation_right = [],
    accommodation_bino = [],
    accommodation_correction = [];

  for (const index in accommodation) {
    const a = accommodation[index];
    accommodation_left.push(parseFloatWithComma(a.left));
    accommodation_right.push(parseFloatWithComma(a.right));
    accommodation_bino.push(parseFloatWithComma(a.bino));
    accommodation_correction.push(a.correction);
  }

  const ac_ratio = acRatio;

  const inner_muscles_blur_distance = parseFloatWithComma(innerMuscles.blurDistance);
  const inner_muscles_blur_close = parseFloatWithComma(innerMuscles.blurClose);
  const inner_muscles_bifurcation_distance = parseFloatWithComma(innerMuscles.bifurcationDistance);
  const inner_muscles_bifurcation_close = parseFloatWithComma(innerMuscles.bifurcationClose);
  const inner_muscles_connection_distance = parseFloatWithComma(innerMuscles.connectionDistance);
  const inner_muscles_connection_close = parseFloatWithComma(innerMuscles.connectionClose);
  const inner_muscles_correction = innerMuscles.correction;

  const outer_muscles_bifurcation_distance = parseFloatWithComma(outerMuscles.bifurcationDistance);
  const outer_muscles_bifurcation_close = parseFloatWithComma(outerMuscles.bifurcationClose);
  const outer_muscles_connection_distance = parseFloatWithComma(outerMuscles.connectionDistance);
  const outer_muscles_connection_close = parseFloatWithComma(outerMuscles.connectionClose);
  const outer_muscles_correction = outerMuscles.correction;

  neurotracker = parseFloatWithComma(neurotracker);

  const payload = {
    lesson_id,

    initial_measurement,
    obruba,
    sklo_cocka_right,
    sklo_cocka_left,

    acuity_measurements,
    acuity_strabismus,
    acuity_right_value,
    acuity_right_diopter,
    acuity_left_value,
    acuity_left_diopter,
    acuity_bino_value,
    acuity_bino_diopter,
    acuity_correction,

    motility_right,
    motility_right_problems,
    motility_left,
    motility_left_problems,

    sharpness_value,
    sharpness_correction,

    convergence_bifurcation,
    convergence_connection,
    convergence_note,
    convergence_correction,

    flexibility_value,
    flexibility_correction,

    accommodation_left,
    accommodation_right,
    accommodation_bino,
    accommodation_correction,

    ac_ratio,

    inner_muscles_blur_distance,
    inner_muscles_blur_close,
    inner_muscles_bifurcation_distance,
    inner_muscles_bifurcation_close,
    inner_muscles_connection_distance,
    inner_muscles_connection_close,
    inner_muscles_correction,

    outer_muscles_bifurcation_distance,
    outer_muscles_bifurcation_close,
    outer_muscles_connection_distance,
    outer_muscles_connection_close,
    outer_muscles_correction,

    neurotracker,
    note,
  };

  return payload;
};

export const generateSenaptecFormPayload = (data) => {
  const payload = {
    lesson_id: data.lessonId,
    visual_clarity_right: data.visualClarityRight === '' ? null : data.visualClarityRight,
    visual_clarity_left: data.visualClarityLeft === '' ? null : data.visualClarityLeft,
    visual_clarity_both: data.visualClarityBoth === '' ? null : data.visualClarityBoth,
    contract_sensitivity: data.contractSensitivity === '' ? null : data.contractSensitivity,
    depth_perception_right: data.depthPerceptionRight === '' ? null : data.depthPerceptionRight,
    depth_perception_left: data.depthPerceptionLeft === '' ? null : data.depthPerceptionLeft,
    depth_perception_primary:
      data.depthPerceptionPrimary === '' ? null : data.depthPerceptionPrimary,
    near_far_quickness: data.nearFarQuickness === '' ? null : data.nearFarQuickness,
    perception_span: data.perceptionSpan === '' ? null : data.perceptionSpan,
    multiobject_tracking: data.multiobjectTracking === '' ? null : data.multiobjectTracking,
    reaction_time: data.reactionTime === '' ? null : data.reactionTime,
  };

  return payload;
};
