import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { message } from 'antd';

import { PageContainer } from '@components';

import { isEmail } from '@oldUtils/validations';

import { request } from '@utils';

const ForgotPasswordPage = () => {
  const [emailValue, setEmailValue] = useState('');

  const history = useHistory();

  const handlePasswordReset = async (event) => {
    event.preventDefault();

    if (isEmail(emailValue)) {
      try {
        await request('/reset-password/reset-password', {
          method: 'POST',
          data: {
            email: emailValue,
          },
        });

        message.success('Email pro obnovu hesla byl odeslán');

        setEmailValue('');
      } catch (error) {
        const { errEmailNotFound } = error.response.data;
        if (errEmailNotFound) {
          message.warning('Uživatel s tímto emailem neexistuje');
        }
      }
    }
  };

  return (
    <div className="signupBackground">
      <Link to="/">
        <img src="/static/images/nevit_logo-sm.png" className="NevitHeaderLogo" alt="Nevit logo" />
      </Link>
      <div className="containerLogin TextCenter">
        <div className="SignupWrap">
          <div>
            <div className="LoginForm">
              <div>
                <div className="FormField has-float-label">
                  <input
                    type="email"
                    name="email"
                    id="emailEl"
                    placeholder=" "
                    required
                    pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]+$"
                    autoFocus
                    value={emailValue}
                    onChange={(event) => setEmailValue(event.target.value)}
                  />
                  <label htmlFor="emailSignup">E-mail</label>
                </div>
                <button
                  type="submit"
                  onClick={handlePasswordReset}
                  className="BtnDefault BtnWide LoginBtn"
                >
                  Obnovit účet
                </button>
              </div>
            </div>
            <button className="SignupLink" onClick={() => history.push('/login')}>
              Přihlásit se
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default () => (
  <PageContainer>
    <ForgotPasswordPage />
  </PageContainer>
);
