import React from 'react';

import {
  TextFileIcon,
  AnyFileIcon,
  VideoFileIcon,
  ImageFileIcon,
  PdfFileIcon,
  AudioFileIcon,
} from '@utilComponents/icons';

export function getIconForUserType(userType) {
  const typeToIconMap = {
    'a custom file': (props) => <AnyFileIcon inline {...props} />,
    pdf: (props) => <PdfFileIcon inline {...props} />,
    audio: (props) => <AudioFileIcon inline {...props} />,
    video: (props) => <VideoFileIcon inline {...props} />,
    text: (props) => <TextFileIcon inline {...props} />,
    image: (props) => <ImageFileIcon inline {...props} />,
  };
  return typeToIconMap[userType];
}
