import { createContext } from 'react';

export const PermissionsContext = createContext();

export const initialPermissionsState = {
  hasFetched: false,
  allowCalendarRead: false,
  allowCalendarWrite: false,
  allowTrainings: false,
  allowClients: false,
  allowFormsAndExports: false,
  allowFiles: false,
  allowReservations: false,
  allowEmployees: false,
  allowBranchAdministration: false,
};

export const permissionsReducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      const { is_superuser } = action.user;
      return {
        ...state,
        hasFetched: true,
        allowCalendarRead: is_superuser || action.branch.allow_calendar_read,
        allowCalendarWrite: is_superuser || action.branch.allow_calendar_write,
        allowTrainings: is_superuser || action.branch.allow_trainings,
        allowClients: is_superuser || action.branch.allow_clients,
        allowFormsAndExports: is_superuser || action.branch.allow_forms_and_exports,
        allowFiles: is_superuser || action.branch.allow_files,
        allowReservations: is_superuser || action.branch.allow_reservations,
        allowEmployees: is_superuser || action.branch.allow_employees,
        allowBranchAdministration: is_superuser || action.branch.allow_branch_administration,
      };
    case 'RESET':
      return initialPermissionsState;
    default:
      return state;
  }
};

export const setPermissions = (user, targetBranch) => ({
  type: 'SET',
  user,
  branch: targetBranch,
});

export const resetPermissions = () => ({
  type: 'RESET',
});
