import * as React from 'react';

import { SingleSelect } from '@oldComponents/selectUIs';

function valueRenderer(option, color = true, isFirst = false) {
  return (
    <div
      className="TrainingSelectOption"
      style={{
        backgroundColor: color ? option.color : '#eee',
        borderTop: !isFirst ? '1px solid #ccc' : null,
      }}
    >
      {option.label}
    </div>
  );
}

export function trainingToSelectOption(label) {
  return {
    color: label.color,
    label: label.name,
    value: label.id,
    id: label.id,
  };
}

// type TrainingSelectState = {|
//   selectedOption: ?ReactSelectOption,
// |};
// type TrainingSelectProps = Object;

// TUTO_NOTE_REACT_SELECT_UNSELECT_IS_NULL: when user removes selection
// in react-select, the value in onChange is null. (Not mentioned in docs.)
export class TrainingSelect extends React.Component {
  // static defaultProps: any;
  // onChange: AnyBoundMethod;
  // state: TrainingSelectState;
  // props: TrainingSelectProps;

  constructor(props) {
    super(props);

    this.state = {
      // TUTO_NOTE_REACT_SELECT_UNSELECT_IS_NULL: so null is on purpose
      selectedOption: props.selected || null,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(selected) {
    this.detectChangeAndNotify(selected);
    this.setState({ selectedOption: selected });
  }

  detectChangeAndNotify(selected) {
    // TUTO_NOTE_REACT_SELECT_UNSELECT_IS_NULL
    if (selected === null) {
      this.props.onReset();
      return;
    }

    const prevSelected = this.state.selectedOption;
    // do nothing, it can actually happen
    if (selected === prevSelected) {
      return;
    }

    // TUTO_NOTE_REACT_SELECT_UNSELECT_IS_NULL
    const hasBeenSelected = prevSelected !== null;

    if (prevSelected !== selected && hasBeenSelected) {
      this.props.onChange(selected);
      return;
    }

    if (prevSelected !== selected && !hasBeenSelected) {
      this.props.onSet(selected);
      return;
    }
  }

  render() {
    const { options, clearable, color = true } = this.props;

    const { selectedOption } = this.state;
    const title = 'Vybrat trénink';

    return (
      <div className="TrainingSelect">
        <SingleSelect
          title={title}
          selected={selectedOption}
          options={options}
          onChange={this.onChange}
          optionRenderer={(option) => {
            const isFirst = options[0].id === option.id;
            return valueRenderer(option, color, isFirst);
          }}
          placeholder="Vyberte vyšetření"
          valueRenderer={(option) => {
            return valueRenderer(option, color);
          }}
          clearable={clearable}
        />
      </div>
    );
  }
}

// TrainingSelect.propTypes = {
//   options: PropTypes.any.isRequired,
//   selected: PropTypes.any,
//   // TUTO_REVIEW: onSet is not necessary probably
//   onSet: PropTypes.func.isRequired,
//   onReset: PropTypes.func.isRequired,
//   onChange: PropTypes.func.isRequired,
// };
