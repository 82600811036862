import * as React from 'react';
import { cx, defineCSS } from '@whysReact';

const ENTER_KEY = 13;

function RequiredInput(props) {
  const { value, minLength, onChange, onEnter, css, ...restProps } = props;
  const isValid = value.length >= Number(minLength);

  const className = cx(css.RequiredInput, {
    [css.InvalidInput]: !isValid,
    [css.ValidInput]: isValid,
  });

  const onKeyUp = (e) => {
    if (e.which === ENTER_KEY) {
      // onEnter is optional
      if (onEnter) {
        onEnter();
      }
    }
  };

  return (
    <input
      {...restProps}
      value={value}
      className={className}
      onChange={(e) => onChange(e.target.value)}
      onKeyUp={onKeyUp}
      minLength={minLength}
      required
    />
  );
}

RequiredInput.defaultProps = {
  value: '',
};

export default defineCSS(RequiredInput, {
  RequiredInput: '',
  InvalidInput: '',
  ValidInput: '',
});
