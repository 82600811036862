import { isToday, isFuture } from 'date-fns';
import isSameWeek from 'date-fns/is_same_week';
import compareDesc from 'date-fns/compare_desc';

export function isInPast(valueToCheck) {
  const now = new Date().getTime();

  if (typeof valueToCheck.getTime === 'function') {
    // valueToCheck is Date object
    return valueToCheck.getTime() < now;
  } else if (typeof valueToCheck === 'number') {
    // valueToCheck is number
    return valueToCheck < now;
  }

  return null;
}

export function isInCurrentWeek(actualDate) {
  return isSameWeek(new Date(), actualDate);
}

export function isTodayOrFuture(actualDate) {
  if (isToday(actualDate) || isFuture(actualDate)) {
    return true;
  }
  return false;
}

export { isSameWeek, compareDesc };
