import { fuzzysearch } from './algorithms';

// <= www.helpforenglish.cz/article/2009100901
// => http://www.helpforenglish.cz/article/2009100901
function coerceProtocolOnLink(link) {
  if (!link.startsWith('www')) {
    return link;
  }
  return `http://${link}`;
}

const punctChars = '.,!?';

// based on https://github.com/kevva/url-regex
function urlRegexWithPunct() {
  const protocol = '(?:(?:[a-z]+:)?//)';
  const auth = '(?:\\S+(?::\\S*)?@)?';
  const host = '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)';
  const domain = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
  const tld = '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?';
  const port = '(?::\\d{2,5})?';
  const path = '(?:[/?#][^\\s"]*)?';
  const regex = `(?:${protocol}|www\\.)${auth}(?:localhost|${host}${domain}${tld})${port}${path}[${punctChars}]?`;
  return new RegExp(regex, 'gi');
}

export function replaceLinksWithTags(text) {
  const urlRegex = urlRegexWithPunct();
  const replacer = (matchedString) => {
    let url = matchedString;
    let slicedPunc = '';
    for (const pChar of punctChars.split('')) {
      if (matchedString.endsWith(pChar)) {
        url = matchedString.slice(0, matchedString.length - 1);
        slicedPunc = pChar;
        break;
      }
    }
    return `<a href="${coerceProtocolOnLink(url)}">${url}</a>${slicedPunc}`;
  };

  const html = text.replace(urlRegex, replacer);
  return html;
}

export function filterItemsByText(list, filterText, stringifyFunc) {
  const filterTextLowerCased = filterText.toLowerCase();
  const filtered = [];
  for (const s of list) {
    const stringified = stringifyFunc(s);
    if (fuzzysearch(filterTextLowerCased, stringified.toLowerCase())) {
      filtered.push(s);
    }
  }
  return filtered;
}
