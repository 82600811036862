import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';

import { verboseCalcFunctions } from './calcPercentage';

const trainingProperties = [
  { key: 'acuity_strabismus', translation: 'Skryté šilhání' },
  { key: 'acuity_right_value', translation: 'Ostrost - Pravá' },
  { key: 'acuity_left_value', translation: 'Ostrost - Levá' },
  { key: 'acuity_bino_value', translation: 'Ostrost - Bino' },
  { key: 'motility_right', translation: 'Motilita - Pravá' },
  { key: 'motility_left', translation: 'Motilita - Levá' },
  { key: 'sharpness_value', translation: 'Přeostření dálka blízko dálka' },
  { key: 'convergence_bifurcation', translation: 'Maximální bod konvergence - rozdvojení' },
  { key: 'convergence_connection', translation: 'Maximální bod konvergence - spojení' },
  { key: 'flexibility_value', translation: 'Vergenční flexibilita' },
  { key: 'accommodation_right', translation: 'Akomodace - Pravá' },
  { key: 'accommodation_left', translation: 'Akomodace - Levá' },
  { key: 'accommodation_bino', translation: 'Akomodace - Bino' },
  { key: 'inner_muscles_blur_close', translation: 'Konvergence - rozmlžení (blízko)' },
  { key: 'inner_muscles_blur_distance', translation: 'Konvergence - rozmlžení (dálka)' },
  { key: 'inner_muscles_bifurcation_close', translation: 'Konvergence - rozdvojení (blízko)' },
  { key: 'inner_muscles_bifurcation_distance', translation: 'Konvergence - rozdvojení (dálka)' },
  { key: 'inner_muscles_connection_close', translation: 'Konvergence - spojení (blízko)' },
  { key: 'inner_muscles_connection_distance', translation: 'Konvergence - spojení (dálka)' },
  { key: 'outer_muscles_bifurcation_close', translation: 'Divergence - rozdvojení (blízko)' },
  { key: 'outer_muscles_bifurcation_distance', translation: 'Divergence - rozdvojení (dálka)' },
  { key: 'outer_muscles_connection_close', translation: 'Divergence - spojení (blízko)' },
  { key: 'outer_muscles_connection_distance', translation: 'Divergence - spojení (dálka)' },
  { key: 'neurotracker', translation: 'Neurotracker' },
];

const senaptecProperties = [
  { key: 'visual_clarity_right', translation: 'Ostrost vidění pravé' },
  { key: 'visual_clarity_left', translation: 'Ostrost vidění levé' },
  { key: 'visual_clarity_both', translation: 'Ostrost vidění bino' },
  { key: 'contract_sensitivity', translation: 'Kontrastní citlivost' },
  { key: 'depth_perception_right', translation: 'Hloubka ostrosti pravé' },
  { key: 'depth_perception_left', translation: 'Hloubka ostrosti levé' },
  { key: 'depth_perception_primary', translation: 'Hloubka ostrosti bino' },
  { key: 'near_far_quickness', translation: 'Přechod dálka / blízko' },
  { key: 'perception_span', translation: 'Vizualizace / krátkodobá paměť' },
  { key: 'multiobject_tracking', translation: 'Různě se pohybující předměty' },
  { key: 'reaction_time', translation: 'Reakční čas' },
];

const generateShortSummaryPrintHTML = (clientData, training_forms, senaptec_forms) => {
  const trainingTableRows = (() => {
    const row1 = [
      <td key="date1">
        <b>DATUM</b>
      </td>,
    ];
    const row2 = [
      <td key="measurement1" className="dark-background">
        Popis vyšetření
      </td>,
    ];
    const otherRows = [];

    trainingProperties.forEach(({ key, translation }) => {
      otherRows.push([<td key="value1">{translation}</td>]);
    });

    training_forms.forEach((training_form, index) => {
      row1.push(
        <td key={`date${index + 2}`}>
          <b>{moment(training_form.lesson.actual_date).format('DD. MM. YYYY')}</b>
        </td>
      );
      row2.push(
        <td key={`measurement${index + 2}`} className="dark-background">
          Naměřená
          <br />
          hodnota
        </td>
      );
      trainingProperties.forEach(({ key }, index) => {
        let value = training_form[key];

        if (key === 'acuity_strabismus') {
          if (value) {
            value = 'Ano';
          } else {
            value = 'Bez nálezu';
          }
        } else {
          if (!value && value !== 0) {
            value = '-';
          } else {
            const calcPercentage = verboseCalcFunctions[key];
            if (calcPercentage) value = calcPercentage(value).toString() + '%';
          }
        }

        otherRows[index].push(<td key={`value${index + 2}`}>{value}</td>);
      });
    });

    return [
      <tr key="row1">{row1}</tr>,
      <tr key="row2">{row2}</tr>,
      ...otherRows.map((contents, index) => <tr key={`row${index + 3}`}>{contents}</tr>),
    ];
  })();

  const senaptecTableRows = (() => {
    const otherRows = [];

    senaptecProperties.forEach(({ key, translation }) => {
      otherRows.push([<td key="value1">{translation}</td>]);
    });

    senaptec_forms.forEach((senaptec_form, index) => {
      senaptecProperties.forEach(({ key }, index) => {
        let value = senaptec_form[key];
        if (!value && value !== 0) {
          value = '-';
        } else {
          value = value.toString() + '%';
        }
        otherRows[index].push(<td key={`value${index + 2}`}>{value}</td>);
      });
    });

    return [...otherRows.map((contents, index) => <tr key={`row${index + 3}`}>{contents}</tr>)];
  })();

  const page1 = ReactDOMServer.renderToStaticMarkup(
    <div style={{ marginTop: 120 }}>
      <p className="title-text dark-background" style={{ fontWeight: 600, width: '100%' }}>
        Diagnostic SUMMARY
      </p>

      <p
        className="title-text dark-background"
        style={{ fontWeight: 600, margin: '14px auto 0 auto', width: 240 }}
      >
        Jméno a příjmení
      </p>

      <p
        className="title-text dark-background"
        style={{ fontWeight: 600, margin: '0 auto 0 auto', width: 240 }}
      >
        {clientData.name}
      </p>

      <table
        cellSpacing="0"
        cellPadding="0"
        style={{
          marginTop: 25,
        }}
      >
        <tbody>
          {trainingTableRows}
          <tr>
            <td style={{ paddingTop: 15 }}>
              <b>Dynamika</b>
            </td>
          </tr>
          {senaptecTableRows}
        </tbody>
      </table>
    </div>
  );

  const page2 = ReactDOMServer.renderToStaticMarkup(
    <div className="information" style={{ marginTop: 120 }}>
      <h3 className="dark-background" style={{ padding: 4, textAlign: 'center' }}>
        Statika
      </h3>
      <h4>
        <b>Vestibulární aparát - Adaptace</b>
      </h4>
      <p>
        Vestibulární aparát dominuje při zajištění rovnováhy hlavy a těla v prostoru v součinnosti s
        ústrojím zrakovým a proprioceptivním. Test udává schopnost udržení rovnováhy těla a hlavy
        při ztrátě zrakového a sluchového vnímání.
      </p>
      <h4>
        <b>Heteroforie - test na skryté šilhání</b>
      </h4>
      <p>
        Jedná se o poruchu binokulární fixace, což se projevuje odchýlením jednoho nebo druhého oka
        od daného směru, při testování spolupráce očí.
      </p>
      <h4>
        <b>Motilita</b>
      </h4>
      <p>
        Motilita zjišťuje, jestli se oči pohybují plynule a souměrně. V případě, že se oči pohybují
        poskokem, může docházet při sportu k výpadku zorného pole. Tento systém je zodpovědný za to,
        jak rychle jsme schopni plynule a rychle se dívat ze strany na stranu a vnímat situaci v
        periferii. A to je ve sportu velice důležité., tréninkem jsme schopni ho dostat lepší
        úroveň.
      </p>
      <h4>
        <b>Přeostření dálka blízko dálka</b>
      </h4>
      <p>
        Zaměření, zaostření (fixace) předmětu zájmu a rychlost přechodu z dálky na blízko nebo z
        blízky na dálku. Schopnost ostře vidět přibližující, nebo vzdalující se předmět po celou
        dráhu pohybu. Rychlost přeostření má zásadní vliv na čtení hry a rychlost a přesnost
        rozhodnutí v klíčových situacích.
      </p>
      <h4>
        <b>Maximální bod konvergence (vnitřní svaly)</b>
      </h4>
      <p>
        Spolupráce okohybných svalů a rychlost přeostření ve spolupráci obou očí z dálky do blízka v
        klidovém stavu. Je důležitá synchronnost očí na celkový vizuální systém.{' '}
      </p>
      <h4>
        <b>Vergenční flexibilita</b>
      </h4>
      <p>
        Určuje, jak rychle se oči dokáží nastavit na pohled do dálky a do blízka. Vergenční
        flexibilita je závislá na rychlosti vnitřních svalů v oku. Tento systém je důležitý hlavně
        při přechodu z dálky do blízka, kdy nastává extrémní námaha na oči.. Správně nastaveným
        tréninkem zlepšíme tuto funkci na hranu možností vizuálního systému (koncentrace, koordinace
        oko – ruka, přesnost, odhad vzdálenosti)
      </p>
      <h4>
        <b>Akomodace</b>
      </h4>
      <p>
        Akomodace je schopnost oka měnit optickou mohutnost svého refrakčního systému. Při akomodaci
        dochází ke změně optické lomivosti do té míry, aby se předměty nacházející se mezi blízkým a
        dalekým bodem zobrazily ostře na sítnici. Zde je zásadní spolupráce, obě oči (binokulárně)
        by se měly stejně rychle přizpůsobovat. V případě že akomodace nefunguje ideálně, má to vliv
        na vnímání prostoru, odhad vzdálenosti, rychlost reakce a čtení hry. Neurovizuální systém je
        namáhán a sportovec nemůže podat maximální výkon.
      </p>
      <h4>
        <b>Fúzní rezervy</b>
      </h4>
      <p>
        Fúzní rezervy zjišťují sílu vnitřních a venkovních svalů. Konvergence je schopnost očí
        rychle a přesně zaměřit blízkou vzdálenost. Divergence je naopak uvolnění očí a napomáhá
        očím k pohledu do dálky. Fúzní rezervy mají návaznost na vergenční flexibilitu viz. výše.
        Nefunkčnost má negativní vliv na koncentraci, fixaci, nepřesné zaměření pohybujících se
        předmětů spolu se špatnou akomodací systému dává nepřesné informace v prostoru, zdržuje
        rozhodnutí s přepočítáváním… Správným tréninkem můžeme tento systém spravit a dostat na
        vyšší úroveň.
      </p>

      <h3 className="dark-background" style={{ padding: 6, textAlign: 'center' }}>
        Dynamika
      </h3>
      <h4>
        <b>Ostrost vidění / Visual clarity</b>
      </h4>
      <p>
        Zraková ostrost v dynamické zátěži je zásadní a rozhodující ve všech vizuálních sportech. Je
        nezbytně nutné maximalizovat zrakovou ostrost sportovce. Tato ostrost vidění nesouvisí s
        ostrostí zjišťovanou u lékaře – jedná se o ostrost vidění v zatížení dynamického pohybu.{' '}
      </p>
      <h4>
        <b>Kontrastní citlivost</b>
      </h4>
      <p>
        Kontrastní citlivost je spolu s ostrostí vidění zásadním faktorem pro správnou a rychlou
        adaptaci vizuálního systému při různých světelných a povětrnostních podmínkách a pro
        adaptaci na různá prostředí, ve kterých činnost probíhá. Je schopnost očí rozlišovat mezi
        objektem a pozadím. – Pokud sportovec vidí dobře, ale nemá dobrou kontrastní citlivost,
        nedokáže odhadnout kde přesně předmět je. Kontrastní citlivost má velký dopad na odhad
        vzdálenosti. Ostrost a kontrast jsou spojené nádoby. Pokud funguje jen jedna, celkový
        visuální vjem se snižuje pouze na 50%.
      </p>
      <h4>
        <b>Hloubka ostrosti / Depth perception</b>
      </h4>
      <p>
        Při tomto testu rozdělujeme obraz obou očí za pomoci speciálních brýlí a navozujeme 3D vjem.
        3D vidění je naučená dovednost, která nás ovlivňuje celý život nejen při sportu. Tento test
        je velice důležitý a poukazuje na to, jestli sportovec má odhad ve vzdálenosti. V návaznosti
        na odhad vzdálenosti jsme schopni odhadnout rychlost. Odhad vzdálenosti máme všichni, ale
        jen u některých je přesný díky očím. V momentě, kdy ho nemáme, naše oči nefungují spolu, ale
        každé zvlášť. Ve sportu je to ta vteřina, kdy se sportovec rozhoduje, jestli má už zahájit
        potřebný pohyb případně protipohyb. Je to ta vteřina, co opakovaně může rozhodnout o tom,
        zda se vyhraje, nebo ne.
      </p>
      <h4>
        <b>Přechod dálka blízko</b>
      </h4>
      <p>
        Přeostření zraku z dálky do blízka je základní dovednost vizuálního systému. V momentě, kdy
        tady je zjištěna nižší hodnota pod 70% dá se předpokládat, že systém pracuje s chybou. Chybu
        odhalíme při mechanické diagnostice. Bez této dovednosti alespoň nad 50 % je velký
        předpoklad i k potížím ve škole, v práci, v běžném životě. Čím rychlejší přechod z dálky do
        blízka sportovec má, tím rychleji a přesněji dokáže sledovat situaci.
      </p>
      <h4>
        <b>Vizualizace / krátkodobá paměť</b>
      </h4>
      <p>
        Zásadní test na koncentraci, čtení prostoru a následně přesně provedeného pohybu. Při tomto
        testu nás zajímá, zda dokážu z toho, co vidím, vyvodit závěr a rozhodnout se. Kolik
        informace je schopný můj mozek nasát z rychlého pohledu? Tato dovednost má zásadní vliv na
        tvorbu rozhodnutí a čtení prostoru a následné rychlosti reakce. Rozhoduje se na základě
        informací, nebo intuice?{' '}
      </p>
      <h4>
        <b>Různě se pohybující předměty</b>
      </h4>
      <p>
        Umění soustředit se na svůj pohyb nebo konkrétní objekt, a přitom dokonale sledovat situaci
        před sebou. Při zlepšení této dovednosti dochází k pocitovému zpomalení situací. Sportovec
        sice vnímá konkrétní objekt, ale nevnímá situaci před sebou. Nebo naopak vnímá situaci před
        sebou, ale nevnímá přesný pohyb nebo konkrétní objekt. Naším cílem je po celou dobu vidět
        přesně a ostře konkrétní objekt, přitom vnímat celou situaci před sebou. Pouze pokud budeme
        vědět, co za situace nás čeká, a nebudeme čekat až nastane, budeme celé zvládnutí plynulejší
        a jistější, můžeme být o krok napřed.
      </p>
      <h4>
        <b>Reakční čas</b>
      </h4>
      <p>
        Jaká je rychlost a přesnost a reakce! Koordinace očí s tělem je jednou ze zásadních
        dovedností pro všechny sporty. Rychlost a přesnost koordinace zraku a těla v reakci na
        vizuální podnět. Můžou být všechny parametry v pořádku a reakční čas je nízký. V tomto
        případě je potřeba vyloučit vliv vizuálního systému. V dalším postupu je na řadě neurolog.{' '}
      </p>
    </div>
  );

  const style = `
  @page {
    size: auto;
    margin: 0mm;
  }

  * {
    font-family: sans-serif;
  }

  .pagebreak {
    page-break-before: always;
  }

  .wrapper {
    display: block;
    margin: 0 auto;
    width: 85%;
  }

  .title-text {
    font-size: 16px;
    padding: 2px;
    text-align: center;
  }

  .dark-background {
    background-color: #44546a !important;
    -webkit-print-color-adjust: exact;
    color: #fff;
  }

  .information h3 {
    font-size: 12px;
  }

  .information p, .information h4 {
    font-size: 9px;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .information h4 {
    margin-top: 11px;
    margin-bottom: 4px;
  }

  .header {
    margin-left: 5%;
    position: fixed;
    top: 30px;
    width: 90%;
  }

  .header__logo {
    width: 55px;
  }

  .header__img {
    width: 100%;
  }

  .footer {
    border-top: 1px solid #eee;
    bottom: 20px;
    margin-left: 5%;
    position: fixed;
    width: 90%;
  }

  .footer__img {
    width: 100%;
  }

  .footer__hockey_logo {
    bottom: -12px;
    margin: 0 4px 0 0;
    position: absolute;
    right: 0;
    width: 100px;
  }

  table {
    border: none;
    width: 100%;
  }

  table tr td {
    font-size: 12px;
    padding: 3px 3px;
  }`;

  const html = `
<!DOCTYPE html>
<html>
  <head>
    <title>Krátké shrnutí vyšetření – ${clientData.name}</title>
    <style>${style}</style>
  </head>
  <body>
    <div class="header">
      <!-- <img src="/static/images/nevit_logo-color.png" class="header__logo" alt="Nevit"> -->
      <img src="/static/images/printheader.jpg" class="header__img" alt="Print header">
    </div>

    <div class="wrapper">
      <div class="pagebreak">
        ${page1}
      </div>

      <div class="pagebreak">
        ${page2}
      </div>
    </div>

    <div class="footer">
      <!-- <div style="float: left;">
        <h3 style="margin-bottom: 0;">DynaOptic&nbsp;s.r.o.</h2>
        <p style="margin: 2px 0 0 0;">Riegrovo&nbsp;nám.&nbsp;179&nbsp;|&nbsp;767&nbsp;01&nbsp;Kroměříž</p>
      </div>
      <div style="float: right;">
        <h4 style="margin: 43px 125px 0 0;">Spolupracujeme&nbsp;s&nbsp;ČSLH</h4>
        <img src="/static/images/hockey_logo.jpg" class="footer__hockey_logo" alt="Czech hockey">
      </div> -->
      <img src="/static/images/printfooter1.PNG" class="footer__img" alt="Print footer">
    </div>
  </body>
</html>
`;
  return html;
};

export default generateShortSummaryPrintHTML;
