import isEmailLib from 'validator/lib/isEmail';

const coerceString = (val) => {
  if (val === undefined || val === null) {
    return '';
  }
  return String(val);
};

export const maxLength = (len) => (value) => {
  if (typeof value !== 'string') {
    return false;
  }
  return value.length <= len;
};

export const minLength = (len) => (value) => {
  if (typeof value !== 'string') {
    return false;
  }
  return value.length >= len;
};

export const minMaxLength = (min, max) => (value) => {
  if (typeof value !== 'string') {
    return false;
  }
  return minLength(min)(value) && maxLength(max)(value);
};

export const matches = (regexp) => (value) => {
  return regexp.test(coerceString(value));
};

const matchesNum = matches(/^[-+]?(?:\d*[.])?\d+$/);

export const isNumeric = (value) => {
  return matchesNum(coerceString(value));
};

export const includesNum = matches(/[0-9]/);
export const isAlpha = matches(/^[A-Z]+$/i);
export const includesAlpha = matches(/[A-Z]/i);

export const isEmail = (val) => isEmailLib(coerceString(val));

// Custom number validators for TrainingForm
// Inspired by https://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric

const lastCharacterValid = (val) => {
  // Checks if last character isn't dot or comma
  const lastCharacter = val.toString()[val.toString().length - 1];
  return lastCharacter !== '.' && lastCharacter !== ',';
};
const validateDecimalPlaces = (val, places) => {
  // Basic decimal places validation, no need for more than that
  const stringVal = val.toString();
  const x = stringVal.indexOf('.');
  const y = stringVal.indexOf(',');

  if (x >= 0 && y >= 0) {
    // Contains both dot and comma
    return false;
  } else if (x < 0 && y < 0) {
    // Doesn't contain dot nor comma
    return true;
  } else if (
    (x >= 0 && stringVal.indexOf('.', x + 1) >= 0) ||
    (y >= 0 && stringVal.indexOf(',', y + 1) >= 0)
  ) {
    // Contains multiple dots or multiple commas
    return false;
  } else {
    // Validate if number of decimal places is smaller or equal
    // to number of accepted decimal places
    const valPlaces =
      stringVal.split('.').length === 2 ? stringVal.split('.')[1] : stringVal.split(',')[1];
    return !(valPlaces.length > places);
  }
};
const validateInt = (val) =>
  !isNaN(parseInt(val, 10)) &&
  Number.isInteger(parseFloat(val)) &&
  isFinite(parseInt(val, 10)) &&
  lastCharacterValid(val);
const validateFloat = (val, places) => {
  return (
    !isNaN(parseFloat(val)) &&
    isFinite(parseFloat(val)) &&
    (places ? validateDecimalPlaces(val, places) : true) &&
    lastCharacterValid(val)
  );
};
const maxValue = (val, max) => parseFloat(val) <= max;
const minValue = (val, min) => parseFloat(val) >= min;

export const validateAcuity = (val) =>
  validateFloat(val, 2) && minValue(val, 0.01) && maxValue(val, 2);
export const validateSharpness = (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 30);
export const validateConvergenceBifurcation = (val) =>
  validateInt(val) && minValue(val, 0) && maxValue(val, 30);
export const validateConvergenceConnection = (val) =>
  validateInt(val) && minValue(val, 0) && maxValue(val, 20);
export const validateFlexibility = (val) =>
  validateFloat(val, 1) && minValue(val, 0) && maxValue(val, 30);
export const validateAccommodation = (val) =>
  validateFloat(val, 1) && minValue(val, 0) && maxValue(val, 35);
export const validateCloseMuscle = (val) =>
  validateInt(val) && minValue(val, 0) && maxValue(val, 40);
export const validateDistanceMuscle = (val) =>
  validateInt(val) && minValue(val, 0) && maxValue(val, 30);
export const validateNeurotracker = (val) => {
  // nullable
  return !val || (validateFloat(val, 1) && minValue(val, 0) && maxValue(val, 3.5));
};

export const measurementValidators = {
  SPH_right: (val) => validateFloat(val, 2) && minValue(val, -20) && maxValue(val, 20),
  CYL_right: (val) => validateFloat(val, 2) && minValue(val, -8) && maxValue(val, 8),
  AX_right: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 180),
  ADD_right: (val) => validateFloat(val, 2) && minValue(val, 0) && maxValue(val, 4),
  PRIS1_right: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 12),
  BAZE1_right: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 360),
  PRIS2_right: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 12),
  BAZE2_right: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 360),
  PD_right: (val) => validateInt(val) && minValue(val, 20) && maxValue(val, 40),
  PACHY_right: (val) => validateInt(val) && minValue(val, 200) && maxValue(val, 1000),
  TONO_right: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 60),
  SPH_left: (val) => validateFloat(val, 2) && minValue(val, -20) && maxValue(val, 20),
  CYL_left: (val) => validateFloat(val, 2) && minValue(val, -8) && maxValue(val, 8),
  AX_left: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 180),
  ADD_left: (val) => validateFloat(val, 2) && minValue(val, 0) && maxValue(val, 4),
  PRIS1_left: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 12),
  BAZE1_left: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 360),
  PRIS2_left: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 12),
  BAZE2_left: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 360),
  PD_left: (val) => validateInt(val) && minValue(val, 20) && maxValue(val, 40),
  PACHY_left: (val) => validateInt(val) && minValue(val, 200) && maxValue(val, 1000),
  TONO_left: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 60),
};

export const senaptecValidators = {
  visualClarityRight: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
  visualClarityLeft: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
  visualClarityBoth: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
  contractSensitivity: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
  depthPerceptionRight: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
  depthPerceptionLeft: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
  depthPerceptionPrimary: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
  nearFarQuickness: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
  perceptionSpan: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
  multiobjectTracking: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
  reactionTime: (val) => validateInt(val) && minValue(val, 0) && maxValue(val, 100),
};
