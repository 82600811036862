import * as React from 'react';
import Block from 'whys-react/ui/Block';

export function FlexBlock(props) {
  const {
    // flexbox props
    vcenter,
    hcenter,
    column,
    spaceBetween,
    baseline,
    fullWidth,
    fullHeight,
    toMainEnd,
    toCrossEnd,
    // common props

    children,
    className,
    title,
    ...restProps
  } = props;

  const style = {
    display: 'flex',
    alignItems:
      (toCrossEnd && 'flex-end') ||
      (vcenter && 'center') ||
      (hcenter && column && 'center') ||
      (baseline && 'baseline'),
    flexDirection: column && 'column',
    justifyContent:
      (toMainEnd && 'flex-end') ||
      (spaceBetween && 'space-between') ||
      (hcenter && !column && 'center'),
    height: fullHeight && '100%',
    width: fullWidth && '100%',
    ...props.style,
  };

  return (
    <Block {...style} {...restProps} className={className} title={title}>
      {children}
    </Block>
  );
}

export function PaddedBlock(props) {
  const paddingBase = props.paddingBase || 1;

  let paddingValue = paddingBase;
  if (props.double) {
    paddingValue = paddingBase * 2;
  } else if (props.tiny) {
    paddingValue = paddingBase / 2;
  }

  const padding = `${paddingValue}rem`;
  const paddingStyles = {
    right: { marginRight: padding },
    left: { marginLeft: padding },
    top: { marginTop: padding },
    bottom: { marginBottom: padding },
  };

  let styles = {
    display: (props.inline && 'inline-block') || (props.flex && 'flex') || 'block',
  };
  for (const direction of ['right', 'left', 'top', 'bottom']) {
    if (props[direction] || props.all) {
      styles = { ...styles, ...paddingStyles[direction] };
    }
  }

  // If no direction specified, add padding to all directions
  if (Object.keys(styles).length === 0) {
    styles = { ...styles, margin: padding };
  }

  return React.createElement(props.tag || 'div', { style: styles }, props.children);
}

export function PaddedAll(props) {
  return <PaddedBlock top bottom left right {...props} />;
}

export function PaddedVertical(props) {
  return <PaddedBlock top {...props} />;
}

export function HSpace(props) {
  const { inline } = props;
  return <PaddedBlock tag={inline ? 'span' : 'div'} left right {...props} />;
}

export function VSpace(props) {
  return <PaddedBlock {...props} top bottom />;
}

export function PageSpace(props) {
  return <PaddedBlock {...props} flex top bottom />;
}

export function TextHSpace(props) {
  return <PaddedBlock paddingBase={0.5} tag="span" left right {...props} />;
}

// TextHeader
// SuperTextHeader
