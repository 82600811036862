import calcFunctions from './calcPercentage';

const translations = {
  acuity: 'Ostrost',
  motility: 'Motilita',
  sharpness: 'Přeostření dálka blízko dálka',
  convergence: 'Maximální bod konvergence',
  flexibility: 'Vergenční flexibilita',
  accommodation: 'Akomodace',
  inner_muscles_blur_close: ['Fůzní rezervy - konvergence', 'rozmlžení (blízko)'],
  inner_muscles_blur_distance: ['Fůzní rezervy - konvergence', 'rozmlžení (dálka)'],
  inner_muscles_bifurcation_close: ['Fůzní rezervy - konvergence', 'rozdvojení (blízko)'],
  inner_muscles_bifurcation_distance: ['Fůzní rezervy - konvergence', 'rozdvojení (dálka)'],
  inner_muscles_connection_close: ['Fůzní rezervy - konvergence', 'spojení (blízko)'],
  inner_muscles_connection_distance: ['Fůzní rezervy - konvergence', 'spojení (dálka)'],
  outer_muscles_bifurcation_close: ['Fůzní rezervy - divergence', 'rozdvojení (blízko)'],
  outer_muscles_bifurcation_distance: ['Fůzní rezervy - divergence', 'rozdvojení (dálka)'],
  outer_muscles_connection_close: ['Fůzní rezervy - divergence', 'spojení (blízko)'],
  outer_muscles_connection_distance: ['Fůzní rezervy - divergence', 'spojení (dálka)'],
  neurotracker: 'Neurotracker',
};

export default (trainingFormsData, datasetOptions, drawOptimalData, simpleGraph) => {
  if (!trainingFormsData || trainingFormsData.length === 0) {
    return;
  }

  let labels = [
    'acuity',
    'sharpness',
    'convergence',
    'flexibility',
    'accommodation',
    'inner_muscles_blur_distance',
    'inner_muscles_bifurcation_distance',
    'inner_muscles_connection_distance',
    'outer_muscles_bifurcation_distance',
    'outer_muscles_connection_distance',
    'neurotracker',
  ];

  let otherLabels = {
    1: 'motility',
    7: 'inner_muscles_blur_close',
    9: 'inner_muscles_bifurcation_close',
    11: 'inner_muscles_connection_close',
    14: 'outer_muscles_bifurcation_close',
    16: 'outer_muscles_connection_close',
  };

  if (!simpleGraph) {
    for (let key in otherLabels) {
      const value = otherLabels[key];
      labels.splice(parseInt(key, 10), 0, value);
    }
  }

  const generateDataset = (labelData, label, color, otherOptions) => {
    let data = [];
    let dataIndexes = [];
    labels.forEach(() => data.push(null));

    for (let key in labelData) {
      const index = labels.indexOf(key);
      const value = labelData[key];
      if (index >= 0) {
        data[index] = value;
        dataIndexes.push(index);
      }
    }

    const returnArrayWithBlank = (value, blankValue) => {
      let array = [];
      labels.forEach(() => array.push(blankValue));
      dataIndexes.forEach((i) => (array[i] = value));
      return array;
    };

    let dataset = {
      ...datasetOptions,
      label,
      data,
      borderColor: 'transparent',
      borderWidth: 0,
      pointBackgroundColor: returnArrayWithBlank(color, 'transparent'),
      pointRadius: returnArrayWithBlank(datasetOptions.pointRadius, 0),
      pointHitRadius: returnArrayWithBlank(datasetOptions.pointHitRadius, 0),
      ...otherOptions,
    };

    return dataset;
  };

  let datasets = [];

  trainingFormsData.forEach((t_f, i) => {
    if (!t_f) {
      return;
    }

    const {
      acuity,
      motility,
      sharpness,
      convergence,
      flexibility,
      accommodation,
      innerMuscles,
      outerMuscles,
      neurotracker,
    } = t_f;

    const mainDataset = generateDataset(
      {
        acuity: calcFunctions.acuity(acuity['0'].binoValue),
        motility: calcFunctions.motility(motility.right),
        sharpness: calcFunctions.sharpness(sharpness.value),
        convergence: calcFunctions.convergence.bifurcation(convergence['0'].bifurcation),
        flexibility: calcFunctions.flexibility(flexibility['0'].value),
        accommodation: calcFunctions.accommodation(accommodation['0'].bino),
        inner_muscles_blur_close: calcFunctions.innerMuscles.blurClose(innerMuscles.blurClose),
        inner_muscles_blur_distance: calcFunctions.innerMuscles.blurDistance(
          innerMuscles.blurDistance
        ),
        inner_muscles_bifurcation_close: calcFunctions.innerMuscles.bifurcationClose(
          innerMuscles.bifurcationClose
        ),
        inner_muscles_bifurcation_distance: calcFunctions.innerMuscles.bifurcationDistance(
          innerMuscles.bifurcationDistance
        ),
        inner_muscles_connection_close: calcFunctions.innerMuscles.connectionClose(
          innerMuscles.connectionClose
        ),
        inner_muscles_connection_distance: calcFunctions.innerMuscles.connectionDistance(
          innerMuscles.connectionDistance
        ),
        outer_muscles_bifurcation_close: calcFunctions.outerMuscles.bifurcationClose(
          outerMuscles.bifurcationClose
        ),
        outer_muscles_bifurcation_distance: calcFunctions.outerMuscles.bifurcationDistance(
          outerMuscles.bifurcationDistance
        ),
        outer_muscles_connection_close: calcFunctions.outerMuscles.connectionClose(
          outerMuscles.connectionClose
        ),
        outer_muscles_connection_distance: calcFunctions.outerMuscles.connectionDistance(
          outerMuscles.connectionDistance
        ),
        neurotracker: calcFunctions.neurotracker(neurotracker),
      },
      `Main training data ${i + 1}`,
      `rgba(22, 105, 175, ${i > 0 ? '.3' : '.7'})`,
      {
        borderColor: `rgba(22, 105, 175, ${i > 0 ? '.25' : '.6'})`,
        borderWidth: 2,
      }
    );

    const rightDataset = generateDataset(
      {
        acuity: calcFunctions.acuity(acuity['0'].rightValue),
        accommodation: calcFunctions.accommodation(accommodation['0'].right),
      },
      `Right training data ${i + 1}`,
      `rgba(212, 203, 0, ${i > 0 ? '.35' : '.9'})`
    );

    const leftDataset = generateDataset(
      {
        acuity: calcFunctions.acuity(acuity['0'].leftValue),
        motility: calcFunctions.motility(motility.left),
        accommodation: calcFunctions.accommodation(accommodation['0'].left),
      },
      `Left training data ${i + 1}`,
      `rgba(255, 0, 0, ${i > 0 ? '.3' : '.7'})`
    );

    const convergenceConnection = generateDataset(
      {
        convergence: calcFunctions.convergence.connection(convergence['0'].connection),
      },
      `Convergence connection data ${i + 1}`,
      `rgba(255, 0, 0, ${i > 0 ? '.3' : '.7'})`
    );

    datasets.push(mainDataset, rightDataset, leftDataset, convergenceConnection);
  });

  if (drawOptimalData) {
    const optimalDataset = generateDataset(
      {
        acuity: calcFunctions.acuity(1.25),
        motility: calcFunctions.motility(0),
        sharpness: calcFunctions.sharpness(15),
        convergence: calcFunctions.convergence.bifurcation(3),
        flexibility: calcFunctions.flexibility(15),
        accommodation: calcFunctions.accommodation(20),
        inner_muscles_blur_close: calcFunctions.innerMuscles.blurClose(28),
        inner_muscles_blur_distance: calcFunctions.innerMuscles.blurDistance(14),
        inner_muscles_bifurcation_close: calcFunctions.innerMuscles.bifurcationClose(34),
        inner_muscles_bifurcation_distance: calcFunctions.innerMuscles.bifurcationDistance(20),
        inner_muscles_connection_close: calcFunctions.innerMuscles.connectionClose(30),
        inner_muscles_connection_distance: calcFunctions.innerMuscles.connectionDistance(16),
        outer_muscles_bifurcation_close: calcFunctions.outerMuscles.bifurcationClose(16),
        outer_muscles_bifurcation_distance: calcFunctions.outerMuscles.bifurcationDistance(10),
        outer_muscles_connection_close: calcFunctions.outerMuscles.connectionClose(14),
        outer_muscles_connection_distance: calcFunctions.outerMuscles.connectionDistance(6),
        neurotracker: calcFunctions.neurotracker(2),
      },
      `Optimal training data`,
      `rgba(150, 150, 150, .6)`,
      {
        borderColor: 'rgba(150, 150, 150, .6)',
        borderWidth: 2,
      }
    );

    datasets.push(optimalDataset);
  }

  return {
    labels: labels.map((label) => translations[label]),
    datasets,
  };
};
