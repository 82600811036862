import React, { Component } from 'react';

import GridList from 'material-ui/GridList/GridList';
import GridTile from 'material-ui/GridList/GridTile';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Toggle from 'material-ui/Toggle/index';

// Utils
import styles from './HealthQuestionnaire.module.css';
import { PageSpace } from '@utilComponents/layoutPrimitives';
import { FlexBlock } from '@utilComponents';

// type HealthQuestionnaireProps = {|
//   data: ?HealthQuestionnaireData,
//   onSubmit: Function,
// |};

// type HealthQuestionnaireState = { ...HealthQuestionnaireData };

class HealthQuestionnaire extends Component {
  constructor(props) {
    super(props);

    const state = {
      familyProblems: '',
      //
      wearsGlasses: false,
      toggledGlasses: false,
      whatGlasses: '',
      //
      wearsContactLenses: false,
      toggledContactLenses: false,
      whatContactLenses: '',
      //
      hasHeadaches: false,
      toggledHeadaches: false,
      whatHeadaches: '',
      //
      // usesArtificialTears: false,
      // toggledArtificialTears: false,
      // whatArtificialTears: '',
      //
      takesAntibiotics: false,
      toggledAntibiotics: false,
      whatAntibiotics: '',
      //
      hasEyeInflammation: false,
      toggledEyeInflammation: false,
      whatEyeInflammation: '',
      //
      middleEarProblems: '',
      hasBalanceProblems: false,
      hasDizziness: false,
      hasHighBloodPressure: false,
      bloodPressureMedicine: '',
      diabetesMedicine: '',
      otherProblems: '',
      pharmacologicalMedicalAnalysis: '',
      pastEyeOperations: '',
      pastAccidents: '',
      pastHospitalizations: '',
      watchedBySpecialist: '',
      allergies: '',
      somethingElse: '',
      ...props.data,
    };

    this.state = state;
  }

  submit = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.state);
  };

  render() {
    return (
      <form onSubmit={this.submit}>
        <GridList cols={12} cellHeight="auto">
          <GridList cols={2} />
          <GridList cols={8} cellHeight="auto">
            {this.renderFamilyAnamnesis()}
            {this.renderPersonalAnamnesis()}
            {this.renderPharmacologicalAnamnesis()}
            <GridTile cols={12}>
              <PageSpace />
              <h5>
                Prohlašuji, že jsem nezamlčel/a závažné skutečnosti týkající se mého zdravotního
                stavu.{' '}
              </h5>
              <PageSpace />
              {this.renderSubmitButton()}
            </GridTile>
          </GridList>
        </GridList>
      </form>
    );
  }

  renderFamilyAnamnesis() {
    return (
      <GridTile cols={12}>
        <h4>Rodinná anamnéza:</h4>
        <PageSpace />
        <h5>
          Uveďte prosím všechny závažnější nebo dlouhodobé choroby ve Vaší rodině, výskyt očních
          onemocnění, glaukom atd.{' '}
        </h5>
        <TextField
          value={this.state.familyProblems}
          onChange={(e) => this.setState({ familyProblems: e.target.value })}
          name="familyProblems"
          multiLine
          fullWidth
        />
        <PageSpace />
      </GridTile>
    );
  }

  renderPersonalAnamnesis() {
    return (
      <GridTile className={styles.PersonalAnamnesis} cols={12}>
        <h4>Osobní anamnéza:</h4>
        <PageSpace />
        <Toggle
          label="Nosíte brýle:"
          toggled={this.state.wearsGlasses}
          onToggle={(e, checked) =>
            this.setState({
              wearsGlasses: checked,
              toggledGlasses: true,
              whatGlasses: '',
            })
          }
        />
        {this.state.wearsGlasses && (
          <TextField
            className={styles.TextField}
            floatingLabelText="Jaké, jak často?"
            value={this.state.whatGlasses}
            onChange={(e) => this.setState({ whatGlasses: e.target.value })}
            name="whatGlasses"
            fullWidth
            autoFocus={this.state.toggledGlasses}
          />
        )}
        <Toggle
          label="Nosíte kontaktní čočky:"
          toggled={this.state.wearsContactLenses}
          onToggle={(e, checked) =>
            this.setState({
              wearsContactLenses: checked,
              toggledContactLenses: true,
              whatContactLenses: '',
            })
          }
        />
        {this.state.wearsContactLenses && (
          <TextField
            className={styles.TextField}
            floatingLabelText="Jaké, jak často?"
            value={this.state.whatContactLenses}
            onChange={(e) => this.setState({ whatContactLenses: e.target.value })}
            name="whatContactLenses"
            fullWidth
            autoFocus={this.state.toggledContactLenses}
          />
        )}
        <Toggle
          label="Bolesti hlavy:"
          toggled={this.state.hasHeadaches}
          onToggle={(e, checked) =>
            this.setState({
              hasHeadaches: checked,
              toggledHeadaches: true,
              whatHeadaches: '',
            })
          }
        />
        {this.state.hasHeadaches && (
          <TextField
            className={styles.TextField}
            floatingLabelText="Kde začínají?"
            value={this.state.whatHeadaches}
            onChange={(e) => this.setState({ whatHeadaches: e.target.value })}
            name="whatHeadaches"
            fullWidth
            autoFocus={this.state.toggledHeadaches}
          />
        )}
        <Toggle
          label="Používáte antibiotika nebo kortikoidy:"
          toggled={this.state.takesAntibiotics}
          onToggle={(e, checked) =>
            this.setState({
              takesAntibiotics: checked,
              toggledAntibiotics: true,
              whatAntibiotics: '',
            })
          }
        />
        {this.state.takesAntibiotics && (
          <TextField
            className={styles.TextField}
            floatingLabelText="Jaké, jak často?"
            value={this.state.whatAntibiotics}
            onChange={(e) => this.setState({ whatAntibiotics: e.target.value })}
            name="whatAntibiotics"
            fullWidth
            autoFocus={this.state.toggledAntibiotics}
          />
        )}
        <Toggle
          label="Míváte záněty v oblasti očí:"
          toggled={this.state.hasEyeInflammation}
          onToggle={(e, checked) =>
            this.setState({
              hasEyeInflammation: checked,
              toggledEyeInflammation: true,
              whatEyeInflammation: '',
            })
          }
        />
        {this.state.hasEyeInflammation && (
          <TextField
            className={styles.TextField}
            floatingLabelText="Jak často?"
            value={this.state.whatEyeInflammation}
            onChange={(e) => this.setState({ whatEyeInflammation: e.target.value })}
            name="whatEyeInflammation"
            fullWidth
            autoFocus={this.state.toggledEyeInflammation}
          />
        )}
        <PageSpace />
        <span className={styles.Label}>Střední ucho:</span>
        <TextField
          className={styles.TextField}
          floatingLabelText="Jakekoliv potíže"
          value={this.state.middleEarProblems}
          onChange={(e) => this.setState({ middleEarProblems: e.target.value })}
          name="middleEarProblems"
          fullWidth
        />
        <Toggle
          label="Potíže s rovnováhou:"
          toggled={this.state.hasBalanceProblems}
          onToggle={(e, checked) => this.setState({ hasBalanceProblems: checked })}
        />
        <Toggle
          label="Závratě:"
          toggled={this.state.hasDizziness}
          onToggle={(e, checked) => this.setState({ hasDizziness: checked })}
        />
        <Toggle
          label="Vysoký krevní tlak:"
          toggled={this.state.hasHighBloodPressure}
          onToggle={(e, checked) =>
            this.setState({ hasHighBloodPressure: checked, bloodPressureMedicine: '' })
          }
        />
        {this.state.hasHighBloodPressure && (
          <TextField
            className={styles.TextField}
            floatingLabelText="Medikace"
            value={this.state.bloodPressureMedicine}
            onChange={(e) => this.setState({ bloodPressureMedicine: e.target.value })}
            name="bloodPressureMedicine"
            fullWidth
            autoFocus
          />
        )}
        <PageSpace />
        <span className={styles.Label}>Cukrovka:</span>
        <TextField
          className={styles.TextField}
          floatingLabelText="Medikace"
          value={this.state.diabetesMedicine}
          onChange={(e) => this.setState({ diabetesMedicine: e.target.value })}
          name="diabetesMedicine"
          fullWidth
        />
        <h5>Jiné</h5>
        <TextField
          className={styles.TextField}
          value={this.state.otherProblems}
          onChange={(e) => this.setState({ otherProblems: e.target.value })}
          name="otherProblems"
          fullWidth
          multiLine
        />
      </GridTile>
    );
  }

  renderPharmacologicalAnamnesis() {
    return (
      <GridTile className={styles.PharmacologicalAnamnesis} cols={12}>
        <h5>Farmakologická anamnéza: (užíváte trvale nebo často nějaké léky?)</h5>
        <TextField
          className={styles.TextField}
          value={this.state.pharmacologicalMedicalAnalysis}
          onChange={(e) => this.setState({ pharmacologicalMedicalAnalysis: e.target.value })}
          name="pharmacologicalMedicalAnalysis"
          multiLine
          fullWidth
        />
        <h5>Operace v oblasti očí: </h5>
        <TextField
          className={styles.TextField}
          value={this.state.pastEyeOperations}
          onChange={(e) => this.setState({ pastEyeOperations: e.target.value })}
          name="pastEyeOperations"
          multiLine
          fullWidth
        />
        <h5>Úrazy (zlomeniny, otřesy mozku apod.): </h5>
        <TextField
          className={styles.TextField}
          value={this.state.pastAccidents}
          onChange={(e) => this.setState({ pastAccidents: e.target.value })}
          name="pastAccidents"
          multiLine
          fullWidth
        />
        <h5>Hospitalizace (pobyt v nemocnici):</h5>
        <TextField
          className={styles.TextField}
          value={this.state.pastHospitalizations}
          onChange={(e) => this.setState({ pastHospitalizations: e.target.value })}
          name="pastHospitalizations"
          multiLine
          fullWidth
        />
        <h5>
          Uveďte prosím, zda jste nyní nebo v minulosti byl sledován/a nějakým specialistou
          (logopedie, ortoptika atd.):{' '}
        </h5>
        <TextField
          className={styles.TextField}
          value={this.state.watchedBySpecialist}
          onChange={(e) => this.setState({ watchedBySpecialist: e.target.value })}
          name="watchedBySpecialist"
          multiLine
          fullWidth
        />
        <h5>Alergie: (trpíte alergiemi – pyly, léky, potraviny, ekzémy, astma?) </h5>
        <TextField
          className={styles.TextField}
          value={this.state.allergies}
          onChange={(e) => this.setState({ allergies: e.target.value })}
          name="allergies"
          multiLine
          fullWidth
        />
        <h5>Případné další skutečnosti, něco co by jsme měli vědět: </h5>
        <TextField
          className={styles.TextField}
          value={this.state.somethingElse}
          onChange={(e) => this.setState({ somethingElse: e.target.value })}
          name="somethingElse"
          multiLine
          fullWidth
        />
      </GridTile>
    );
  }

  renderSubmitButton() {
    return (
      <FlexBlock hcenter>
        <RaisedButton type="submit" label="Uložit" primary />
      </FlexBlock>
    );
  }
}

export default HealthQuestionnaire;
