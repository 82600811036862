import React, { useContext } from 'react';

// Components
import ReservationForm from './ReservationForm';

// Utils
import { request, BranchContext } from '@utils';

export default (props) => {
  const { branchId, publicUrl } = props;

  return (
    <ReservationForm
      {...props}
      // Actions
      reserveLessonPublic={(payload) => {
        return request(`/api_classroom/public-create-reservation/${publicUrl}`, {
          method: 'POST',
          data: payload,
        });
      }}
      reserveAsClient={(payload) => {
        return request(`/api_classroom/client-create-reservation/${branchId}`, {
          method: 'POST',
          data: payload,
        });
      }}
      loginAndReserve={(payload) => {
        return request(`/api_classroom/login-create-reservation/${publicUrl}`, {
          method: 'POST',
          data: payload,
        });
      }}
    />
  );
};
