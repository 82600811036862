import React, { Component } from 'react';

import { OutsideClick } from '@utilComponents/clickHelpers';
import { CommonKeysUp } from '@utilComponents/keyUpListenWrappers';

import { defineCSS, cx } from '@whysReact';
import { placeCursorAtEnd } from '@oldUtils/domHelpers';

const AUTOFOCUS_TIMEOUT = 70;

function autoFocusInputAfterTimeout(input, time) {
  const focusInput = () => {
    if (input) {
      input.focus();
    }
  };
  setTimeout(focusInput, time);
}

const noFormat = (text) => text;

class EditableText extends Component {
  constructor(props) {
    super(props);

    // TUTO_REVIEW: updating initialText in component lifecycle?
    this.state = {
      inputText: props.initialText,
      editModeActive: false,
      showEditIcon: false,
    };

    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onEditDone = this.onEditDone.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onEditCancel = this.onEditCancel.bind(this);
    this.onChangeToEditMode = this.onChangeToEditMode.bind(this);
  }

  componentDidMount() {
    this.focusInputIfShown();
  }

  componentDidUpdate() {
    this.possiblyOverwriteValue();
    this.focusInputIfShown();
  }

  possiblyOverwriteValue() {
    const { editModeActive, inputText } = this.state;
    const { overwrittingValue } = this.props;

    if (typeof overwrittingValue !== 'undefined' && !editModeActive) {
      if (overwrittingValue !== inputText) {
        this.setState({ inputText: overwrittingValue });
      }
    }
  }

  onEditDone() {
    const { inputText } = this.state;
    const { validate, onEdited } = this.props;

    const doDone = () => {
      // notify only if it has actually changed
      if (this.props.initialText !== inputText) {
        onEdited(inputText);
      }
      this.setState({ editModeActive: false });
    };

    // this prop is optional, use it only if provided
    if (validate) {
      if (validate(inputText)) {
        doDone();
      }
      // do not finish if the input is invalid
    } else {
      doDone();
    }
  }

  onEditCancel() {
    const { initialText } = this.props;
    this.setState({ editModeActive: false, inputText: initialText });
  }

  onChangeToEditMode() {
    this.setState({ editModeActive: true });
  }

  onChange(e) {
    const inputText = e.target.value;
    this.setState({ inputText });
  }

  onMouseOver() {
    this.setState({ showEditIcon: true });
  }

  onMouseLeave() {
    this.setState({ showEditIcon: false });
  }

  // We use this instead of autoFocus, because of inversion of control
  // autoFocus fired onKeyUp with ENTER after onClick with ENTER fired onChangeToEditMode.
  focusInputIfShown() {
    if (this.textInputEl) {
      autoFocusInputAfterTimeout(this.textInputEl, AUTOFOCUS_TIMEOUT);
    }
  }

  render() {
    const { editModeActive, inputText } = this.state;
    const { css, validate, inputProps } = this.props;

    let inputOrText;
    let editBtn;

    if (editModeActive) {
      // validate is optional
      const validationResult = validate && validate(inputText);
      const isInputValid = validationResult !== undefined && validationResult;
      const isInputInvalid = validationResult !== undefined && !validationResult;

      const inputClassName = cx(css.TextInput, {
        [css.InvalidTextInput]: isInputInvalid,
        [css.ValidTextInput]: isInputValid,
      });

      inputOrText = (
        <OutsideClick onClick={this.onEditDone} capture>
          <CommonKeysUp onEscape={this.onEditCancel} onEnter={this.onEditDone}>
            <input
              {...inputProps}
              ref={(input) => {
                this.textInputEl = input;
              }}
              type="text"
              value={inputText}
              onChange={this.onChange}
              onFocus={(e) => {
                placeCursorAtEnd(e.target);
              }}
              className={inputClassName}
            />
          </CommonKeysUp>
        </OutsideClick>
      );
    } else {
      const format = this.props.format || noFormat;
      inputOrText = (
        <span style={this.props.spanStyle} onDoubleClick={this.onChangeToEditMode}>
          {format(inputText)}
        </span>
      );
      const { editIcon, editMsg } = this.props;
      const { showEditIcon } = this.state;
      const editBtnClass = showEditIcon ? css.EditButton : css.EditButtonHidden;

      editBtn = (
        <button className={editBtnClass} onClick={this.onChangeToEditMode}>
          <span className={css.EditIcon} title={editMsg}>
            {editIcon}
          </span>
        </button>
      );
    }

    return (
      <div
        className={css.EditableText}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
      >
        {inputOrText}
        {editBtn}
      </div>
    );
  }
}

export default defineCSS(EditableText, {
  EditButton: '',
  EditButtonHidden: '',
  EditIcon: '',
  EditableText: '',
  TextInput: '',
  ValidTextInput: '',
  InvalidTextInput: '',
});
