import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import AppPage from '@oldComponents/AppPage';

import AppForms from '@appForms/AppForms';

import { PageContainer } from '@components';

import { BranchContext } from '@utils';

// type LocalProps = {|
//   // mapToProps
//   isLogged: boolean,
//   fullname: string,
//
//   // redux
//   dispatch: Function,
//
//   // react-router
//   children: *,
//
//   // withRouter
//   params: ReactRouterParams,
//   router: ReactRouterObject,
// |};

const EntryFormPage = (props) => {
  const {
    state: { branchId },
  } = useContext(BranchContext);
  const params = useParams();

  if (!branchId) return null;

  return (
    <AppPage header="">
      <AppForms branchId={branchId} clientId={params.id} />
    </AppPage>
  );
};

export default () => (
  <PageContainer>
    <EntryFormPage />
  </PageContainer>
);
