import { gql } from '@apollo/client';

import { strSimpleDate } from '@oldUtils/dateFormatting';

export const calendarEventAllFragment = `
fragment calendarEventAll on CalendarEvent {
  id
  trainingId
  roomId
  students {
    id
    email
    name
  }
  startTime
  date
  duration
  onetime
  forReservation
  reservation {
    id
    status
    price
    reservedBy
  }
}
`;

export const employeeCalendarQuery = gql`
  query calendarQuery($branchId: String, $forDate: CalendarDate) {
    branchEvents(branchId: $branchId, forDate: $forDate) {
      ...calendarEventAll
    }
    branchTrainings(branchId: $branchId) {
      color
      id
      name
      description
      duration
      priceWithoutVat
      priceWithVat
      isDeleted
      isPublic
      canPayLater
    }
  }
  ${calendarEventAllFragment}
`;

export const clientCalendarQuery = gql`
  query calendarQuery($branchId: String, $forDate: CalendarDate) {
    clientCalendarData(branchId: $branchId, forDate: $forDate) {
      events {
        ...calendarEventAll
      }
      trainings {
        color
        name
        description
        duration
        id
        priceWithoutVat
        priceWithVat
        canPayLater
        isPublic
      }
    }
  }
  ${calendarEventAllFragment}
`;

export const publicCalendarQuery = gql`
  query calendarQuery($publicUrl: String, $forDate: CalendarDate) {
    publicCalendarData(publicUrl: $publicUrl, forDate: $forDate) {
      events {
        ...calendarEventAll
      }
      trainings {
        color
        name
        description
        duration
        id
        priceWithoutVat
        priceWithVat
        canPayLater
        isPublic
      }
      rooms {
        id
        name
        isDefault
      }
    }
  }

  ${calendarEventAllFragment}
`;

export const createEventMutation = gql`
  mutation createEvent(
    $branchId: String
    $roomId: ApplicationID
    $date: CalendarDate!
    $startTime: SimpleTime!
    $duration: SimpleTime
    $trainingId: ApplicationID
    $clientEmails: [Email]
    $clientIds: [ApplicationID]
    $isCreatingFromClientData: Boolean
    $clientEmail: String
    $clientName: String
    $clientPhone: String
    $onetime: Boolean!
    $activeTo: CalendarDate
    $forReservation: Boolean
  ) {
    createEvent(
      branchId: $branchId
      eventData: {
        roomId: $roomId
        date: $date
        startTime: $startTime
        duration: $duration
        trainingId: $trainingId
        clientEmails: $clientEmails
        clientIds: $clientIds
        isCreatingFromClientData: $isCreatingFromClientData
        clientEmail: $clientEmail
        clientName: $clientName
        clientPhone: $clientPhone
        onetime: $onetime
        activeTo: $activeTo
        forReservation: $forReservation
      }
    ) {
      ok
      event {
        ...calendarEventAll
      }
    }
  }
  ${calendarEventAllFragment}
`;

export const cancelEventMutation = gql`
  mutation cancelEvent($branchId: String, $id: ApplicationID!, $forDate: CalendarDate) {
    cancelEvent(branchId: $branchId, id: $id, forDate: $forDate) {
      ok
    }
  }
`;

export const deleteRecurringEventMutation = gql`
  mutation deleteRecurringEvent($branchId: String, $id: ApplicationID!, $forDate: CalendarDate) {
    deleteRecurringEvent(branchId: $branchId, id: $id, forDate: $forDate) {
      ok
    }
  }
`;

export const changeEventMutation = gql`
  mutation changeEvent(
    $branchId: String
    $id: ApplicationID!
    $timesData: EventTimesInput
    $eventData: EventDataInput
    $forDate: CalendarDate
  ) {
    changeEvent(
      branchId: $branchId
      id: $id
      timesData: $timesData
      eventData: $eventData
      forDate: $forDate
    ) {
      ok
      event {
        ...calendarEventAll
      }
    }
  }
  ${calendarEventAllFragment}
`;

export const moveRecurringEventMutation = gql`
  mutation moveRecurringEvent(
    $branchId: String
    $id: ApplicationID!
    $timesData: EventTimesInput!
    $fromDate: CalendarDate!
    $updateAllWeeks: Boolean
  ) {
    moveRecurringEvent(
      branchId: $branchId
      id: $id
      timesData: $timesData
      fromDate: $fromDate
      updateAllWeeks: $updateAllWeeks
    ) {
      ok
      event {
        ...calendarEventAll
      }
    }
  }
  ${calendarEventAllFragment}
`;

export const createEvent = (payload, callMutation) => {
  return callMutation({
    variables: {
      branchId: payload.branchId,
      roomId: payload.roomId,
      date: payload.timeslot.date || payload.timeslot.activeFrom,
      startTime: payload.timeslot.start,
      duration: payload.timeslot.duration,
      activeTo: payload.timeslot.activeTo,
      onetime: payload.timeslot.onetime,
      forReservation: payload.timeslot.forReservation,
      trainingId: payload.trainingId,
      clientEmails: payload.addClientEmails,
      clientIds: payload.clientIds,
      isCreatingFromClientData: payload.isCreatingFromClientData,
      clientEmail: payload.clientEmail,
      clientName: payload.clientName,
      clientPhone: payload.clientPhone,
    },
    update: (cache, result) => {
      const { event } = result.data.createEvent;
      cache.modify({
        fields: {
          branchEvents(existing = []) {
            cache.writeFragment({
              data: event,
              fragment: gql`
                ${calendarEventAllFragment}
              `,
            });
          },
        },
      });
    },
  });
};

export const cancelEvent = (branchId, eventId, currentDate, callMutation) => {
  return callMutation({
    variables: {
      branchId,
      id: eventId,
      forDate: strSimpleDate(currentDate || new Date()),
    },
    update: (cache) => {
      cache.modify({
        fields: {
          branchEvents(existing, { readField }) {
            return existing.filter((_) => eventId !== readField('id', _));
          },
        },
      });
    },
  });
};

export const deleteRecurringEvent = (branchId, eventId, currentDate, callMutation) => {
  return callMutation({
    variables: {
      branchId,
      id: eventId,
      forDate: strSimpleDate(currentDate || new Date()),
    },
    update: (cache) => {
      cache.modify({
        fields: {
          branchEvents(existing, { readField }) {
            return existing.filter((_) => eventId !== readField('id', _));
          },
        },
      });
    },
  });
};

export const updateEvent = (branchId, options, currentDate, callMutation) => {
  const { id, trainingId, clientEmails, clientIds, timeslot } = options;

  return callMutation({
    variables: {
      branchId,
      id,
      forDate: strSimpleDate(currentDate),
      eventData: {
        trainingId,
        clientEmails,
        clientIds,
      },
      timesData: {
        startTime: timeslot.start,
        duration: timeslot.duration,
        date: timeslot.date,
      },
    },
  });
};

export const updateTimeslotAllWeeks = (branchId, id, currentDate, timeslot, callMutation) => {
  return callMutation({
    variables: {
      branchId,
      id,
      forDate: strSimpleDate(currentDate),
      timesData: {
        startTime: timeslot && timeslot.start,
        duration: timeslot && timeslot.duration,
        date: timeslot && timeslot.date,
      },
      eventData: {},
    },
  });
};

export const updateTimeslotOneWeek = (branchId, options, callMutation) => {
  const { fromDate, id, timeslot, updateAllWeeks = false } = options;

  return callMutation({
    variables: {
      branchId,
      id,
      fromDate,
      timesData: {
        startTime: timeslot && timeslot.start,
        duration: timeslot && timeslot.duration,
        date: timeslot && timeslot.date,
      },
      updateAllWeeks,
    },
    update: (cache, result) => {
      const { event } = result.data.moveRecurringEvent;
      cache.modify({
        fields: {
          branchEvents(existing = []) {
            cache.writeFragment({
              data: event,
              fragment: gql`
                ${calendarEventAllFragment}
              `,
            });
          },
        },
      });
    },
  }).then((response) => response.data.moveRecurringEvent.event);
};
