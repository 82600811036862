import * as React from 'react';

import ReservationStatus from './ReservationStatus';

// type LocalState = {|
//   cancelMode: boolean,
//   calendarMode: boolean,
// |};

// type LocalProps = {|
//   reservation: LessonReservation,
//   status: ReservationStatusType,
//   onConfirm: (reservation: LessonReservation, paidOn: Date) => void,
//   onCancel: (reservation: LessonReservation) => void,
// |};

class ReservationStatusContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitState();
  }

  onConfirmDone = (paidOn) => {
    this.setState({ calendarMode: false });
    const { reservation } = this.props;
    this.props.onConfirm(reservation, paidOn);
    this.setState(this.getInitState());
  };

  onCancelDone = () => {
    this.setState({ cancelMode: false });
    const { reservation } = this.props;
    this.props.onCancel(reservation);
    this.setState(this.getInitState());
  };

  getInitState() {
    return { cancelMode: false, calendarMode: false };
  }

  mkChangeState = (key, val) => () => {
    this.setState({ [key]: val });
  };

  render() {
    const { calendarMode, cancelMode } = this.state;
    return (
      <ReservationStatus
        calendarMode={calendarMode}
        cancelMode={cancelMode}
        status={this.props.status}
        reservation={this.props.reservation}
        onCancelExit={this.mkChangeState('cancelMode', false)}
        onCancelDone={this.onCancelDone}
        onCancelInit={this.mkChangeState('cancelMode', true)}
        onConfirmInit={this.mkChangeState('calendarMode', true)}
        onConfirmDone={this.onConfirmDone}
        onConfirmExit={this.mkChangeState('calendarMode', false)}
      />
    );
  }
}

export default ReservationStatusContainer;
