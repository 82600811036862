import * as React from 'react';

import PageSubtitle from '@oldComponents/PageSubtitle';
import LogoutLink from '@oldComponents/LogoutLink';
import * as tutoUI from '@oldUI';

import { Aptform } from '@appForms';
import { createPasswordFieldConfig } from '@appForms/inputs';

import * as materialUI from '@materialUI';

import { ChangePasswordForm } from './commonUI';

const ClientProfile = ({ user, onUserChangesSubmit, onPasswordSubmit }) => {
  const { confirmed } = user;

  const password = confirmed ? null : createPasswordFieldConfig();

  const onRenderPersonalInfoForm = ({ inputs, form }, shouldRenderPassword) => {
    const { name, date_of_birth, residence, zip_code, phone, password } = inputs;

    const passwordField = shouldRenderPassword ? (
      <div>
        <materialUI.TextField
          {...password.getPassProps()}
          autoComplete="new-password"
          type="password"
          errorText={password.showError() && password.errorText}
          floatingLabelText="Heslo"
          style={{ width: 400 }}
        />
      </div>
    ) : null;

    return (
      <div>
        <form {...form.getPassProps()} autoComplete="off">
          <div>
            <materialUI.TextField
              {...name.getPassProps()}
              errorText={name.showError() && name.errorText}
              floatingLabelText="Celé jméno"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <materialUI.TextField
              {...date_of_birth.getPassProps()}
              errorText={date_of_birth.showError() && date_of_birth.errorText}
              floatingLabelText="Datum narození"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <materialUI.TextField
              {...residence.getPassProps()}
              errorText={residence.showError() && residence.errorText}
              floatingLabelText="Bydliště"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <materialUI.TextField
              {...zip_code.getPassProps()}
              errorText={zip_code.showError() && zip_code.errorText}
              floatingLabelText="Poštovní směrovací číslo"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <materialUI.TextField
              {...phone.getPassProps()}
              errorText={phone.showError() && phone.errorText}
              floatingLabelText="Telefonní číslo"
              style={{ marginBottom: 14, width: 400 }}
            />
          </div>
          {passwordField}
          <materialUI.RaisedButton
            primary
            disabled={!form.isValid() || (!form.hasChanged() && confirmed)}
            type="submit"
            onClick={() => {}}
            label="Aktualizovat"
          />
        </form>
      </div>
    );
  };

  const renderChangePass = () => {
    return (
      <div>
        <PageSubtitle>Změna hesla</PageSubtitle>
        <ChangePasswordForm onSubmit={onPasswordSubmit} />
      </div>
    );
  };

  return (
    <div>
      <PageSubtitle>Osobní informace</PageSubtitle>
      <div>
        {confirmed ? null : (
          <tutoUI.Block color={tutoUI.theme.dangerColor}>
            Dokončete prosím registraci vyplněním těchto údajů:
          </tutoUI.Block>
        )}
        <Aptform
          inputs={{
            name: { required: true },
            date_of_birth: { required: false },
            residence: { required: false },
            zip_code: { required: false },
            phone: { required: true },
            password: password,
          }}
          initialValues={{
            name: user.name,
            date_of_birth: user.date_of_birth,
            residence: user.residence,
            zip_code: user.zip_code,
            phone: user.phone,
          }}
          onSubmit={(values) => onUserChangesSubmit(values, !confirmed)}
          render={(config) => onRenderPersonalInfoForm(config, password)}
        />
      </div>

      <tutoUI.PageSpace double />

      {confirmed ? renderChangePass() : null}

      <tutoUI.PageSpace double />

      <PageSubtitle>Odhlášení</PageSubtitle>
      <LogoutLink />
    </div>
  );
};

export default ClientProfile;
