import { useContext } from 'react';
import { message } from 'antd';

import { request, UserContext, updateUser } from '@utils';

const BranchEmployeeProfileContainer = (props) => {
  const {
    state: { user },
    dispatch,
  } = useContext(UserContext);
  const { userId } = user;

  const onUserChangesSubmit = (payload) => {
    return request(`/api_customer/edit-employee-profile`, {
      method: 'PATCH',
      data: payload,
    })
      .then(({ data }) => {
        if (payload.name) {
          dispatch(updateUser({ name: payload.name }));
        }

        message.success('Změna informací proběhla úspěšně');
      })
      .catch(() => {
        message.error('Došlo k chybě, je potřeba vyplnit povinná pole');
      });
  };

  const passProps = {
    ...props,
    user: user,
    onUserChangesSubmit: onUserChangesSubmit,
  };

  return props.children(passProps);
};

export default BranchEmployeeProfileContainer;
