import * as React from 'react';
import { message } from 'antd';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import differenceInHours from 'date-fns/difference_in_hours';

// Material-UI
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';

// Forms
import { Aptform } from '@appForms';
import { isEmail, isNumeric, minMaxLength } from '@appForms/validators';
import { createPasswordFieldConfig } from '@appForms/inputs';

// UI
import * as tutoUI from '@oldUI';

import { TextHSpace, HSpace, VSpace, PaddedAll } from '@utilComponents/layoutPrimitives';

import { InvisibleFormDivider, TrainingsChooser } from '@oldComponents/eventFormUIs';

// Utils
import { strSimpleDate, stringifyTime } from '@oldUtils/dateFormatting';

// When there is no training id - training is unspecified -
// trainingId is set to 0
const unspecifiedTrainingID = '0';

// TUTO_NOTE_CLICK_OUTSIDE_TOOLTIP_CAVEAT:
// Do not propagate the event to another listeners.
// the tooltip get smaller and its recognized as outside click
// when the event is processed in "outside click" component
// This leaks implementation detail here. Another solution is setting
// state later, which is undeterministic, but should work.
// Either ways its not a really a huge problem, the worst case is that
// the tooltip gets closed.

function FormattedLength({ start, end }) {
  /**
   * Returns how many hours and/or minutes are between end and start.
   * Can be used i18n-wise.
   *
   * Cases:
   * 105 min => 1 h 45 min
   * 45 min => 45 min
   * 60 min => 1 h
   **/
  const hours = differenceInHours(end, start);
  const minsWithinHour = differenceInMinutes(end, start) % 60;

  const formattedHours = hours ? `${hours} h` : null;
  const formattedMinutes = minsWithinHour ? `${minsWithinHour} min` : null;

  return (
    <span>
      {formattedHours && formattedMinutes ? `${formattedHours} ${formattedMinutes}` : null}
      {formattedHours && !formattedMinutes ? formattedHours : null}
      {!formattedHours && formattedMinutes ? formattedMinutes : null}
    </span>
  );
}

function DayAndMonth({ date }) {
  // Simple quick formatting dates
  // getMonth returns 0 for first month
  return (
    <span>
      {date.getDate()}
      {'. '}
      {date.getMonth() + 1}
      {'. '}
    </span>
  );
}

// type FormData = {|
//   name: string,
//   email: string,
//   phone: string,
// |};

// type ReservationRequiredAttrs = {|
//   createdOn: string,
//   trainingId: ?TutoID,
//   eventId: TutoID,
//   start: string,
// |};

// export type ReservationLoggedPayload = {|
//   ...ReservationRequiredAttrs,
// |};

// export type ReservationPublicPayload = {|
//   ...ReservationRequiredAttrs,
//   studentName: string,
//   studentEmail: string,
//   studentPhone: string,
// |};

// export type ReservationLoginPayload = {|
//   ...ReservationRequiredAttrs,
//   email: string,
//   password: string,
// |};

// export type ReservationFormProps = {|
//   start: Date,
//   end: Date,
//   eventId: TutoID,
//   trainingId: ?TutoID,
//   trainings: any,
//   isPublic: boolean,
//   onReservationEnd: () => void,
//   onFailed: () => void,
//   onDone: ReservationInfo => void,
//   reserveAsClient: ReservationLoggedPayload => Promise<any>,
//   reserveLessonPublic: ReservationPublicPayload => Promise<any>,
//   loginAndReserve: ReservationLoginPayload => Promise<any>,
//   reservation: ?ReservationInfo,
//
//   ...withUserMessagesProps,
// |};

// type LocalState = {|
//   trainingId: ?TutoID,
//   showLoginForm: boolean,
//   duplicateEmailErr: boolean,
//   hasFailed: boolean | string,
//   failedSubmitData: ?FormData,
// |};

class ReservationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      duplicateEmailErr: false,
      showLoginForm: false,
      failedSubmitData: null,
      hasFailed: false,
      trainingId: props.trainingId ? props.trainingId : 0,
    };
  }

  onValidSubmit = (formData) => {
    const createdOn = new Date().toISOString();
    const { trainingId, eventId, isPublic } = this.props;

    let submitPromise;
    if (isPublic) {
      const { reserveLessonPublic } = this.props;
      submitPromise = reserveLessonPublic({
        trainingId: trainingId === unspecifiedTrainingID ? this.state.trainingId : trainingId,
        eventId,
        createdOn,
        clientName: formData.name,
        clientEmail: formData.email,
        clientPhone: formData.phone,
        start: strSimpleDate(this.props.start),
      });
    } else {
      const { reserveAsClient } = this.props;
      submitPromise = reserveAsClient({
        trainingId: trainingId === unspecifiedTrainingID ? this.state.trainingId : trainingId,
        eventId,
        createdOn,
        start: strSimpleDate(this.props.start),
      });
    }

    const onErr = (error) => {
      const { data } = error.response;
      if (data.duplicateEmailErr) {
        this.setState({ showLoginForm: true, duplicateEmailErr: true, failedSubmitData: formData });
      } else if (data.refusedEmailErr) {
        message.error('Nelze rezervovat trénink s tímto emailem');
        this.setState({ failedSubmitData: formData });
        return { errors: { email_refused: true } };
      } else {
        this.onReservationRejected(data.detail);
      }
    };
    return submitPromise.then((res) => this.onReservationResolved(res), onErr);
  };

  onReservationResolved = (response) => {
    this.props.onDone(response.data);
    this.props.onReservationEnd();
  };

  onReservationRejected = (detail) => {
    this.setState({ hasFailed: detail || 'Došlo k chybě' });
  };

  onLoginSubmit = (formData) => {
    const { trainingId, eventId } = this.props;
    const { failedSubmitData } = this.state;

    const email = failedSubmitData && failedSubmitData.email;
    if (!email) {
      return;
    }

    const createdOn = new Date().toISOString();
    const loginPromise = this.props.loginAndReserve({
      trainingId: trainingId === unspecifiedTrainingID ? this.state.trainingId : trainingId,
      createdOn,
      eventId,
      start: strSimpleDate(this.props.start),
      email,
      password: formData.password,
    });

    const onErr = (error) => {
      const { data } = error.response;
      if (data.wrongPassErr) {
        message.error('Heslo není správné');
        return { errors: { password_wrong: true } };
      } else {
        this.onReservationRejected(data.detail);
      }
    };

    loginPromise.then((res) => this.onReservationResolved(res), onErr);
  };

  renderReservationDetails() {
    const { start, end: defaultEnd } = this.props;
    const label = this.props.trainings[this.state.trainingId];
    let end = defaultEnd;

    if (parseInt(this.state.trainingId, 10) !== 0) {
      // Calculate end based on start and training duration
      end = new Date(start.getTime() + label.duration * 60 * 1000);
    }

    const lessonLength = <FormattedLength start={start} end={end} />;

    return (
      <div>
        {label && label.description ? (
          <tutoUI.FlexBlock column fullWidth>
            <span style={{ maxWidth: '256px' }}>{label.description}</span>
            <VSpace paddingBase={0.2} />
          </tutoUI.FlexBlock>
        ) : (
          <VSpace paddingBase={0.8} />
        )}
        <tutoUI.FlexBlock vcenter spaceBetween>
          <tutoUI.FlexBlock vcenter>
            <DayAndMonth date={start} />
            <TextHSpace tiny />
            {stringifyTime(start)}
            <TextHSpace tiny />
            <tutoUI.Inline color={tutoUI.theme.lightGreyColor}>({lessonLength})</tutoUI.Inline>
          </tutoUI.FlexBlock>
          <strong>
            {parseInt(this.state.trainingId, 10) !== 0
              ? this.props.trainings[this.state.trainingId].priceWithVat
              : 0}{' '}
            Kč
          </strong>
        </tutoUI.FlexBlock>
        <tutoUI.VSpace />
      </div>
    );
  }

  renderLoginForm() {
    const onRenderForm = ({ form, inputs }) => {
      const { failedSubmitData } = this.state;
      const email = failedSubmitData && failedSubmitData.email;

      return (
        <PaddedAll>
          {`Přihlásit se jako ${email}?`}

          <form {...form.getPassProps()}>
            <div>
              <div>
                <TextField
                  name="password"
                  type="password"
                  floatingLabelText="Heslo"
                  {...inputs.password.getPassProps()}
                />
              </div>
              <tutoUI.FlexBlock vcenter spaceBetween fullWidth>
                <RaisedButton primary type="submit" label="Rezervovat" />
                <tutoUI.LinkLikeBtn
                  onClick={(e) => {
                    // See TUTO_NOTE_CLICK_OUTSIDE_TOOLTIP_CAVEAT
                    e.nativeEvent.stopImmediatePropagation();
                    this.setState({ showLoginForm: false });
                  }}
                >
                  Zrušit
                </tutoUI.LinkLikeBtn>
              </tutoUI.FlexBlock>
            </div>
          </form>
        </PaddedAll>
      );
    };

    const passwordConfig = (() => {
      const base = createPasswordFieldConfig();
      return {
        ...base,
        errorTextMap: {
          ...base.errorTextMap,
          password_wrong: 'Heslo není správné.',
        },
      };
    })();

    return (
      <Aptform
        key="loginForm"
        inputs={{
          password: passwordConfig,
        }}
        onSubmit={this.onLoginSubmit}
        render={onRenderForm}
      />
    );
  }

  renderPublicForm() {
    const { showLoginForm } = this.state;
    if (showLoginForm) {
      return this.renderLoginForm();
    }

    const onRenderForm = ({ form, inputs }) => {
      const { name, email, phone } = inputs;
      const isValid =
        parseInt(this.state.trainingId, 10) &&
        name.valid &&
        email.valid &&
        phone.valid &&
        name.value.length > 0 &&
        email.value.length > 0 &&
        phone.value.length > 0;

      return (
        <form {...form.getPassProps()}>
          <div>
            <TextField
              {...name.getPassProps()}
              floatingLabelText="Celé jméno"
              errorText={name.showError() && 'Zadejte prosím jméno'}
            />
          </div>
          <div>
            <TextField
              {...email.getPassProps()}
              floatingLabelText="E-mail"
              errorText={email.showError() && 'Zadejte prosím platnou emailovou adresu'}
            />
          </div>
          <div>
            <TextField
              {...phone.getPassProps()}
              floatingLabelText="Telefonní číslo"
              errorText={phone.showError() && 'Zadejte prosím své telefonní číslo'}
            />
          </div>
          <InvisibleFormDivider />
          {this.props.trainingId === unspecifiedTrainingID && this.renderTrainingSelect()}
          {this.renderReservationDetails()}
          <div style={{ textAlign: 'center' }}>
            <tutoUI.FlexBlock hcenter top={0}>
              <button className="BtnDefault BtnSmall" type="submit" disabled={!isValid}>
                Rezervovat
              </button>
            </tutoUI.FlexBlock>
            <HSpace tiny inline />
            {isValid ? null : <p>Vyplňte vše</p>}
          </div>
        </form>
      );
    };

    return (
      <Aptform
        onSubmit={this.onValidSubmit}
        initialValues={{
          name: null,
          email: null,
          phone: null,
        }}
        inputs={{
          name: {
            required: true,
          },
          email: {
            required: true,
            validations: { isEmail },
          },
          phone: {
            required: true,
            validations: { isNumeric, minMaxLength: minMaxLength(9, 20) },
          },
        }}
        render={onRenderForm}
      />
    );
  }

  renderAuthForm() {
    return (
      <tutoUI.FlexBlock column fullWidth>
        {this.props.trainingId === unspecifiedTrainingID && <VSpace tiny />}
        {this.props.trainingId === unspecifiedTrainingID && this.renderTrainingSelect()}
        {this.renderReservationDetails()}
        <button
          className="BtnDefault BtnSmall"
          type="submit"
          onClick={this.onValidSubmit}
          disabled={!parseInt(this.state.trainingId, 10)}
        >
          Rezervovat
        </button>
      </tutoUI.FlexBlock>
    );
  }

  renderForm() {
    const { isPublic } = this.props;
    return (
      <tutoUI.FlexBlock hcenter vcenter column>
        <span style={{ fontSize: '1.4rem' }}>Závazná rezervace</span>
        <VSpace paddingBase={0.1} />
        {isPublic ? this.renderPublicForm() : this.renderAuthForm()}
      </tutoUI.FlexBlock>
    );
  }

  renderFailed() {
    return (
      <div>
        <p>
          {this.state.hasFailed ||
            'Rezervace nebyla potvrzena. Je možné že událost již není aktuální.'}
        </p>
        <RaisedButton
          primary
          type="submit"
          label="Ok"
          onClick={() => {
            this.props.onFailed();
            this.props.onReservationEnd();
          }}
        />
      </div>
    );
  }

  renderTrainingSelect() {
    const { trainings } = this.props;
    const publicLabels = {};

    for (let key in trainings) {
      const label = trainings[key];
      if (label.isPublic) {
        publicLabels[key] = label;
      }
    }

    return (
      <div>
        <TrainingsChooser
          onChange={(training) => this.setState({ trainingId: training.id })}
          onSet={(training) => this.setState({ trainingId: training.id })}
          onReset={() => this.setState({ trainingId: null })}
          trainings={publicLabels}
          clearable={false}
          color={false}
        />
        <VSpace tiny />
      </div>
    );
  }

  render() {
    const { hasFailed } = this.state;

    if (hasFailed) {
      return (
        <PaddedAll tiny>
          <tutoUI.FlexBlock maxWidth="15rem">{this.renderFailed()}</tutoUI.FlexBlock>
        </PaddedAll>
      );
    }

    return (
      <PaddedAll tiny>
        <tutoUI.FlexBlock maxWidth="18rem">
          {!this.props.isDone && this.renderForm()}
        </tutoUI.FlexBlock>
      </PaddedAll>
    );
  }
}

export default ReservationForm;
