import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  request,
  UserContext,
  logoutUser,
  AttachmentsContext,
  resetAttachments,
  BranchContext,
  resetBranch,
  ClientsContext,
  resetClients,
  PermissionsContext,
  resetPermissions,
  RoomsContext,
  resetRooms,
} from '@utils';

const LogoutPage = () => {
  const { dispatch } = useContext(UserContext);
  const { dispatch: attachmentsDispatch } = useContext(AttachmentsContext);
  const { dispatch: branchDispatch } = useContext(BranchContext);
  const { dispatch: clientsDispatch } = useContext(ClientsContext);
  const { dispatch: permissionsDispatch } = useContext(PermissionsContext);
  const { dispatch: roomsDispatch } = useContext(RoomsContext);

  const history = useHistory();

  useEffect(() => {
    request('/api/logout/', {
      method: 'POST',
    }).then(() => {
      dispatch(logoutUser());
      attachmentsDispatch(resetAttachments());
      branchDispatch(resetBranch());
      clientsDispatch(resetClients());
      permissionsDispatch(resetPermissions());
      roomsDispatch(resetRooms());

      history.replace('/login');
    });
  }, []);

  return null;
};

export default LogoutPage;
