import * as React from 'react';

import { RightDirectionIcon } from '@utilComponents/icons';
import { FlexBlock } from '@utilComponents/layoutPrimitives';

import styles from './accordion.module.css';

function AccordionHeader(props) {
  const { children, onClick, isExpanded, panelId, ariaLevel, isOdd } = props;
  return (
    <div aria-level={ariaLevel}>
      <div
        className={`AccordionHeader ${(isOdd && 'AccordionHeaderOdd') || ''}`}
        role="button"
        onClick={onClick}
        aria-expanded={isExpanded}
        aria-controls={panelId}
      >
        {children}
      </div>
    </div>
  );
}

AccordionHeader.defaultProps = {
  ariaLevel: 0,
};

function AccordionPanel(props) {
  const { children } = props;
  return <div className={'AccordionPanel'}>{children}</div>;
}

const renderAccordionPanel = ({ collapseOn, collapseItemActive, canCollapseAll }) => (
  panel,
  idx
) => {
  const key = panel.key;

  const isExpanded = key === collapseItemActive;
  const isDisabled = panel.props.disabled === true;

  const onClick = () => {
    canCollapseAll && isExpanded ? collapseOn(null) : collapseOn(key);
  };

  return (
    <div>
      <AccordionHeader
        panelId={`__accordion_header_${key}`}
        onClick={isDisabled ? () => {} : onClick}
        isOdd={idx % 2}
        isExpanded={isExpanded}
      >
        <FlexBlock
          className={`AccordionItem ${(isDisabled && 'AccordionDisabledItem') || ''}`}
          fullWidth
          spaceBetween
        >
          {panel.props.header}
          {isExpanded || isDisabled ? (
            <RightDirectionIcon className={styles.AccordionCloseItemIcon} />
          ) : (
            <RightDirectionIcon className={styles.AccordionOpenItemIcon} />
          )}
        </FlexBlock>
      </AccordionHeader>
      {isExpanded && !isDisabled ? panel : null}
    </div>
  );
};

// type ControlledAccordionProps = {
//   className?: string,
//   children: any,
//   initiallyExpanded?: boolean,
//   canCollapseAll?: boolean,
//   activeKey: string,
//   onActiveChange: (key: string) => void,
// };

class ControlledAccordion extends React.Component {
  // static defaultProps: any;
  // props: ControlledAccordionProps;
  // onCollapseOn: *;

  constructor(props) {
    super(props);

    this.onCollapseOn = this.onCollapseOn.bind(this);
  }

  onCollapseOn(key) {
    this.props.onActiveChange(key);
  }

  render() {
    const { className, children } = this.props;
    const childrenIsFunc = typeof children === 'function';
    const firstPanel = childrenIsFunc ? children({ activeKey: undefined })[0] : children[0];

    const activeKey = this.props.activeKey || (this.props.initiallyExpanded && firstPanel.key);

    const actualChildren = childrenIsFunc
      ? children({
          activeKey,
        })
      : children;

    return (
      <div className={className}>
        {React.Children.map(
          actualChildren,
          renderAccordionPanel({
            collapseOn: this.onCollapseOn,
            collapseItemActive: activeKey,
            canCollapseAll: this.props.canCollapseAll,
          })
        )}
      </div>
    );
  }
}

ControlledAccordion.defaultProps = {
  initiallyExpanded: true,
};

// type UncontrolledAccordionProps = {
//   ...ControlledAccordionProps,
//   children?: any,
//   initialActiveKey: string,
//   canCollapseAll?: boolean,
// };

class Accordion extends React.Component {
  // onActiveChange: *;

  constructor(props) {
    super(props);

    this.onActiveChange = this.onActiveChange.bind(this);

    this.state = { activeKey: props.initialActiveKey };
  }

  onActiveChange(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    const { activeKey } = this.state;
    return (
      <ControlledAccordion
        {...this.props}
        activeKey={activeKey}
        onActiveChange={this.onActiveChange}
      />
    );
  }
}

export { Accordion, ControlledAccordion, AccordionPanel };
