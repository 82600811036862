import { minLength, includesNum } from './validators';

export function createPasswordFieldConfig() {
  return {
    required: true,
    validations: {
      minLength: minLength(8),
      includesNum,
    },
    errorTextMap: {
      includesNum: 'Musí obsahovat číslo.',
      minLength: 'Musí mít alespoň 8 znaků',
    },
  };
}
