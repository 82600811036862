import * as React from 'react';
import Inline from 'whys-react/ui/Inline';

class DotIndicator extends React.Component {
  constructor(props) {
    super(props);

    this.state = { activeDotNum: 1 };
  }

  componentDidMount() {
    this.onChangeActiveDelayed();
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onChangeActiveDelayed = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(this.onChangeActive, 500);
  };

  onChangeActive = () => {
    this.setState(
      ({ activeDotNum }) => ({
        activeDotNum: activeDotNum < 3 ? activeDotNum + 1 : 1,
      }),
      this.onChangeActiveDelayed
    );
  };

  render() {
    const { activeDotNum } = this.state;
    const { activeColor } = this.props;
    const activeStyles = { color: activeColor };
    return (
      <Inline fontSize={'1.4em'} display={'inline'} padding={'0 0.4em'}>
        <Inline {...(activeDotNum === 1 ? activeStyles : null)}>.</Inline>
        <Inline {...(activeDotNum === 2 ? activeStyles : null)}>.</Inline>
        <Inline {...(activeDotNum === 3 ? activeStyles : null)}>.</Inline>
      </Inline>
    );
  }
}

export default DotIndicator;
