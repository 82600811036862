import React, { Fragment, Component } from 'react';

// Old Material UI
import IconButton from 'material-ui/IconButton';
import AddIcon from 'material-ui/svg-icons/content/add-circle';
import RemoveIcon from 'material-ui/svg-icons/content/remove-circle';
import GridList from 'material-ui/GridList/GridList';
import GridTile from 'material-ui/GridList/GridTile';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Toggle from 'material-ui/Toggle/index';
import Checkbox from 'material-ui/Checkbox';
import Snackbar from 'material-ui/Snackbar';

import { SelectField } from '@components';

import { defaultTrainingFormData } from './parseAppFormsData';

// Utils
import calcFunctions from './calcPercentage';
import {
  validateAcuity,
  validateSharpness,
  validateConvergenceBifurcation,
  validateConvergenceConnection,
  validateFlexibility,
  validateAccommodation,
  validateCloseMuscle,
  validateDistanceMuscle,
  validateNeurotracker,
} from './validators';

import MeasurementTable from './MeasurementTable';
import MotilityProblemPicker from './MotilityProblemPicker';

import TrainingFormChart from './TrainingFormChart';

import { PageSpace } from '@oldUI';
import { FlexBlock } from '@utilComponents';
import { HSpace } from '@utilComponents/layoutPrimitives';

import styles from './AppForms.module.css';

const getNumericValue = (val) => {
  val = val.replace(',', '.');
  const lastChar = val.slice(-1);
  if (lastChar === '.' || !isNaN(parseFloat(lastChar))) {
    return val;
  } else {
    return val.slice(0, -1);
  }
};

const errorStyle = {
  position: 'absolute',
  bottom: '-10px',
};

// type TrainingFormProps = {|
//   data: TrainingFormData,
//   previousData: TrainingFormData,
//   index: number,
//   onRender: Function,
//   onTrainingFormSubmit: Function,
//   hideAccordionPanel: Function,
//   clientView: boolean,
//   printTraining: Function,
// |};

// type TrainingFormState = {|
//   ...TrainingFormData,
//   acuityErrors: {
//     [key: string]: {
//       left: boolean,
//       right: boolean,
//     },
//   },
//   motilityErrors: {
//     left: boolean,
//     right: boolean,
//   },
//   sharpnessErrors: boolean,
//   convergenceErrors: {
//     [key: string]: {
//       [key: string]: boolean,
//     },
//   },
//   flexibilityErrors: {
//     [key: string]: boolean,
//   },
//   accommodationErrors: {
//     [key: string]: {
//       left: boolean,
//       right: boolean,
//       bino: boolean,
//     },
//   },
//   innerMusclesErrors: {
//     bifurcation: boolean,
//     blur: boolean,
//     connection: boolean,
//   },
//   outerMusclesErrors: {
//     bifurcation: boolean,
//     connection: boolean,
//   },
//   neurotrackerError: boolean,
//   drawOptimalData: boolean,
//   submitError: boolean,
// |};

const RenderWithPastFieldValue = (props) => {
  const renderPastFieldValue = () => {
    if (props.inactive) {
      return props.children;
    }

    if (typeof props.value !== 'undefined' && props.value !== null && !props.error) {
      return (
        <span className={styles.TrainingFormPastFieldValue}>
          {props.value.toString().length > 0 && `Posledně: ${props.value.toString()}`}
        </span>
      );
    }
  };

  let className = styles.TrainingFormTextFieldWrap;
  if (props.className) {
    className += ` ${props.className}`;
  }
  if (!props.noWidthBoost) {
    className += ` ${styles.TrainingFormTextFieldWrap__WidthBoost}`;
  }

  return (
    <div className={className} style={props.style}>
      {props.children}
      {renderPastFieldValue()}
    </div>
  );
};

class TrainingForm extends Component {
  // previousData: TrainingFormData;
  // clientView: boolean;

  constructor(props) {
    super(props);

    this.state = {
      ...props.data,

      initialDataErrors: {},
      acuityErrors: {},
      motilityErrors: {},
      sharpnessErrors: false,
      convergenceErrors: {},
      flexibilityErrors: {},
      accommodationErrors: {},
      innerMusclesErrors: {},
      outerMusclesErrors: {},

      drawOptimalData: !!this.props.clientView,
      submitError: false,
    };

    this.previousData = this.props.previousData || defaultTrainingFormData;
    this.clientView = !!this.props.clientView;
  }

  componentDidMount() {
    if (this.props.onRender) {
      this.props.onRender();
    }
  }

  submit = (e) => {
    e.preventDefault();
    const isError = this.validate();

    if (!isError) {
      this.props.onTrainingFormSubmit(this.state, this.props.index);
    } else {
      this.setState({ submitError: true });
    }
  };

  render() {
    const {
      acuity,
      motility,
      convergence,
      flexibility,
      accommodation,
      drawOptimalData,
    } = this.state;

    return (
      <div className="TrainingFormContainer">
        {this.clientView && motility.right !== '' && (
          <div>
            <PageSpace />
            <Toggle
              label="Ukázat&nbsp;optimální&nbsp;hodnoty:"
              toggled={drawOptimalData}
              onToggle={() => this.setState({ drawOptimalData: !drawOptimalData })}
              style={{ display: 'inline-block', width: 'auto' }}
            />
            <PageSpace tiny />
            <TrainingFormChart
              data={[this.props.data]}
              drawOptimalData={drawOptimalData}
              simpleGraph
              options={{}}
            />
            <PageSpace double />
          </div>
        )}
        {this.clientView && motility.right === '' ? (
          <div>
            <h4>Tento trénink ještě nemá žádný obsah</h4>
          </div>
        ) : (
          <form className={styles.TrainingForm} onSubmit={this.submit}>
            <GridList cols={12} cellHeight="auto">
              <GridTile cols={12}>
                <PageSpace />
                {this.renderInitialData()}
                <PageSpace />
              </GridTile>

              {Object.keys(acuity).map((key) => {
                return this.renderAcuity(key);
              })}

              {!this.clientView ? this.renderNewMeasurement(() => this.addAcuity()) : <div />}
              <PageSpace double={!this.clientView} />

              {this.renderMotility()}

              {this.renderSharpness()}
              <PageSpace />

              {Object.keys(convergence).map((index) => {
                return this.renderConvergence(index);
              })}
              {!this.clientView ? this.renderNewMeasurement(() => this.addConvergence()) : <div />}
              <GridTile cols={12}>
                <PageSpace double={!this.clientView} />
              </GridTile>

              <GridList cols={6} cellHeight="auto">
                {Object.keys(flexibility).map((index) => {
                  return this.renderFlexibility(index);
                })}
                {!this.clientView ? this.renderNewMeasurement(() => this.addFlexibilty()) : <div />}
              </GridList>

              <GridList cols={6} cellHeight="auto">
                {Object.keys(accommodation).map((index) => {
                  return this.renderAccommodation(index);
                })}
                {!this.clientView ? (
                  this.renderNewMeasurement(() => this.addAccommodation())
                ) : (
                  <div />
                )}
              </GridList>

              <GridTile cols={12}>
                <PageSpace />
              </GridTile>

              {this.renderACRatio()}

              <GridTile cols={12}>
                <PageSpace />
              </GridTile>

              <GridTile cols={12}>
                <h4 className={styles.Header}>Fuzní rezervy</h4>
              </GridTile>
              {this.renderInnerMuscles()}
              {this.renderOuterMuscles()}
              {this.renderNeurotracker()}
              <GridTile cols={12}>
                <PageSpace tiny />
              </GridTile>
              {this.renderNote()}
              <GridTile cols={12}>{this.renderSubmitButton()}</GridTile>
            </GridList>
          </form>
        )}
        <Snackbar
          open={this.state.submitError}
          message="Někde je zadaná špatná hodnota, zkontrolujte prosím hodnoty ve formuláři"
          autoHideDuration={5000}
          onRequestClose={() => this.setState({ submitError: false })}
          bodyStyle={{ backgroundColor: '#d40000' }}
        />
      </div>
    );
  }

  renderInitialData() {
    return (
      <Fragment>
        <h4 className={styles.Header} style={{ marginBottom: 20 }}>
          Měření zraku
        </h4>
        <MeasurementTable
          clientView={this.props.clientView}
          initial={true}
          data={this.state.initialData.initialMeasurement}
          onDataChange={(data) => {
            // This function is only called
            // when there are no errors
            const newInitialData = {
              ...this.state.initialData,
              initialMeasurement: data,
            };
            this.setState({
              initialData: newInitialData,
              initialDataErrors: {
                ...this.state.initialDataErrors,
                initialMeasurement: false,
              },
            });
          }}
          onError={() => {
            if (!this.state.initialDataErrors.initialMeasurement) {
              this.setState({
                initialDataErrors: {
                  ...this.state.initialDataErrors,
                  initialMeasurement: true,
                },
              });
            }
          }}
        />
        <PageSpace />
        <RenderWithPastFieldValue
          className={styles.ValueTextField}
          error={this.state.initialDataErrors.obruba}
          value={null}
        >
          <TextField
            floatingLabelText="Obruba"
            value={this.state.initialData.obruba}
            onChange={(e) => this.onChangeInitialData('obruba', e.target.value)}
            onBlur={() => this.validateInitialData()}
            name="obruba"
            errorText={this.state.initialDataErrors.obruba ? 'Maximální délka je 50 znaků' : ''}
            errorStyle={errorStyle}
            readOnly={this.clientView}
            style={{ maxWidth: 300 }}
          />
        </RenderWithPastFieldValue>
        <RenderWithPastFieldValue
          className={styles.ValueTextField}
          error={this.state.initialDataErrors.skloCockaRight}
          value={null}
        >
          <TextField
            floatingLabelText="Sklo / čočka pravé"
            value={this.state.initialData.skloCockaRight}
            onChange={(e) => this.onChangeInitialData('skloCockaRight', e.target.value)}
            onBlur={() => this.validateInitialData()}
            name="skloCockaRight"
            errorText={
              this.state.initialDataErrors.skloCockaRight ? 'Maximální délka je 50 znaků' : ''
            }
            errorStyle={errorStyle}
            readOnly={this.clientView}
            style={{ maxWidth: 300 }}
          />
        </RenderWithPastFieldValue>
        <RenderWithPastFieldValue
          className={styles.ValueTextField}
          error={this.state.initialDataErrors.skloCockaLeft}
          value={null}
        >
          <TextField
            floatingLabelText="Sklo / čočka levé"
            value={this.state.initialData.skloCockaLeft}
            onChange={(e) => this.onChangeInitialData('skloCockaLeft', e.target.value)}
            onBlur={() => this.validateInitialData()}
            name="skloCockaLeft"
            errorText={
              this.state.initialDataErrors.skloCockaLeft ? 'Maximální délka je 50 znaků' : ''
            }
            errorStyle={errorStyle}
            readOnly={this.clientView}
            style={{ maxWidth: 300 }}
          />
        </RenderWithPastFieldValue>
      </Fragment>
    );
  }

  renderNewMeasurement(onClick) {
    return (
      <GridTile cols={12}>
        <div className={styles.AddMeasurement} onClick={() => onClick()}>
          <IconButton className={styles.MeasurementIcon}>
            <AddIcon />
          </IconButton>
          <span>Další měření</span>
        </div>
      </GridTile>
    );
  }

  renderRemoveMeasurement(key, index) {
    return (
      <div>
        <div
          className={styles.RemoveMeasurement}
          onClick={() => this.removeMeasurement(key, index)}
        >
          <IconButton className={styles.MeasurementIcon}>
            <RemoveIcon />
          </IconButton>
          <span>Zrušit měření</span>
        </div>
      </div>
    );
  }

  renderAcuity(index) {
    const fields = [
      { label: 'Pravé:', valueName: 'rightValue', diopterName: 'rightDiopter' },
      { label: 'Levé:', valueName: 'leftValue', diopterName: 'leftDiopter' },
      { label: 'Bino:', valueName: 'binoValue', diopterName: 'binoDiopter' },
    ];

    const previousAcuity = this.previousData.acuity[index] || {};

    return (
      <GridList key={index} cols={12} cellHeight="auto">
        <GridTile cols={5} className={styles.Acuity}>
          {index === '0' && (
            <div>
              <h4 className={styles.Header}>Ostrost</h4>
              <Checkbox
                label="Skryté šilhání"
                className={styles.Checkbox}
                checked={this.state.acuityStrabismus}
                onCheck={() =>
                  this.setState((prevState) => ({
                    acuityStrabismus: !prevState.acuityStrabismus,
                  }))
                }
                iconStyle={{ transform: 'scale(0.8)' }}
                disabled={this.clientView}
              />
            </div>
          )}
          {index !== '0' && !this.clientView && this.renderRemoveMeasurement('acuity', index)}
          {fields.map(({ label, valueName, diopterName }) => {
            const error = this.state.acuityErrors[index]
              ? this.state.acuityErrors[index][valueName]
              : false;
            return (
              <div key={`acuity-${index}-${valueName}`}>
                <span className={styles.Label}>{label}</span>
                <RenderWithPastFieldValue
                  className={styles.ValueTextField}
                  error={error}
                  value={previousAcuity[valueName]}
                >
                  <TextField
                    floatingLabelText="Hodnota *"
                    value={this.state.acuity[index][valueName]}
                    onChange={(e) =>
                      this.onChangeObjectField(
                        'acuity',
                        index,
                        valueName,
                        getNumericValue(e.target.value)
                      )
                    }
                    onBlur={(e) => this.validateAcuityValue(index, valueName)}
                    name={`acuity-${index}-${valueName}`}
                    errorText={error ? 'Platný rozsah: 0.01 - 2.00' : ''}
                    errorStyle={errorStyle}
                    readOnly={this.clientView}
                  />
                </RenderWithPastFieldValue>
                <RenderWithPastFieldValue
                  className={styles.DiopterTextField}
                  value={previousAcuity[diopterName]}
                >
                  <TextField
                    floatingLabelText="Poznámka"
                    value={this.state.acuity[index][diopterName]}
                    onChange={(e) =>
                      this.onChangeObjectField('acuity', index, diopterName, e.target.value)
                    }
                    name={`acuity-${index}-${diopterName}`}
                    readOnly={this.clientView}
                  />
                </RenderWithPastFieldValue>
                <PageSpace paddingBase={0.25} />
              </div>
            );
          })}
          <Checkbox
            label="S korekcí"
            className={styles.Checkbox}
            checked={this.state.acuity[index].correction}
            onCheck={(e, checked) =>
              this.onChangeObjectField('acuity', index, 'correction', checked)
            }
            iconStyle={{ transform: 'scale(0.8)' }}
            disabled={this.clientView}
          />
          <PageSpace />
        </GridTile>
        <GridTile cols={7}>
          <div style={{ marginTop: 30 }}>
            <MeasurementTable
              clientView={this.props.clientView}
              initial={false}
              data={(this.state.acuity[index] && this.state.acuity[index].measurement) || {}}
              onDataChange={(data) => {
                this.onChangeObjectField('acuity', index, 'measurement', data, () => {
                  // onDataChange is only called
                  // when there are no errors
                  this.setState({
                    acuityErrors: {
                      ...this.state.acuityErrors,
                      [index]: {
                        ...this.state.acuityErrors[index],
                        measurement: false,
                      },
                    },
                  });
                });
              }}
              onError={() => {
                if (
                  !this.state.acuityErrors[index] ||
                  !this.state.acuityErrors[index].measurement
                ) {
                  this.setState({
                    acuityErrors: {
                      ...this.state.acuityErrors,
                      [index]: {
                        ...this.state.acuityErrors[index],
                        measurement: true,
                      },
                    },
                  });
                }
              }}
            />
          </div>
        </GridTile>
      </GridList>
    );
  }

  renderMotility() {
    const values = [
      'bez potíží (100%)',
      's mírným poskokem (70%)',
      's poskokem (50%)',
      'ztráty fixace (25%)',
      'tupozrakost, útlum, slepota (0%)',
    ];

    const previousMotolity = this.previousData.motility;

    const translations = {
      right: 'Pravé *',
      left: 'Levé *',
    };

    const types = ['right', 'left'];

    return (
      <GridList cols={12} cellHeight="auto">
        <GridTile cols={4} style={{ paddingBottom: '10px' }}>
          <h4 className={styles.Header} style={{ marginBottom: 26 }}>
            Motilita
          </h4>
          {types.map((type) => (
            <div key={type}>
              <RenderWithPastFieldValue
                error={this.state.motilityErrors[type]}
                value={!isNaN(previousMotolity[type]) && values[previousMotolity[type]]}
                style={{ height: !this.clientView ? 62 : 'auto' }}
              >
                {this.clientView ? (
                  <TextField
                    floatingLabelText={translations[type]}
                    value={values[this.state.motility[type]]}
                    readOnly
                  />
                ) : (
                  <SelectField
                    error={this.state.motilityErrors[type]}
                    label={translations[type]}
                    value={this.state.motility[type]}
                    onChange={(event) => {
                      const { value } = event.target;
                      this.onSelect('motility', type, value, 'motilityErrors');
                    }}
                    name={`motility-${type}`}
                    disabled={this.clientView}
                    values={values}
                  />
                )}
              </RenderWithPastFieldValue>
              <PageSpace paddingBase={1} />
            </div>
          ))}
        </GridTile>
        <GridTile cols={8}>
          <MotilityProblemPicker
            clientView={this.props.clientView}
            selectedRightProblems={this.state.motility.rightProblems}
            selectedLeftProblems={this.state.motility.leftProblems}
            onRightProblemsChange={(newRightProblems) => {
              this.setState({
                motility: {
                  ...this.state.motility,
                  rightProblems: newRightProblems,
                },
              });
            }}
            onLeftProblemsChange={(newLeftProblems) => {
              this.setState({
                motility: {
                  ...this.state.motility,
                  leftProblems: newLeftProblems,
                },
              });
            }}
          />
        </GridTile>
      </GridList>
    );
  }

  renderSharpness() {
    const percentage = calcFunctions.sharpness(this.state.sharpness.value);
    return (
      <GridTile className={styles.Sharpness} cols={12}>
        <h4 className={styles.Header}>Přeostření dálka blízko dálka</h4>

        <RenderWithPastFieldValue
          error={this.state.sharpnessErrors}
          value={this.previousData.sharpness.value}
          noWidthBoost
        >
          <TextField
            floatingLabelText="Hodnota *"
            value={this.state.sharpness.value}
            onChange={(e) => this.onChangeSharpness(e.target.value)}
            onBlur={this.validateSharpness}
            name="sharpness-value"
            style={{ display: 'inline-block' }}
            inputStyle={{ width: '80%' }}
            errorText={this.state.sharpnessErrors ? 'Platný rozsah 0 - 30' : ''}
            errorStyle={errorStyle}
            readOnly={this.clientView}
          />
          {percentage !== null && (
            <span className={styles.Percentage}>
              ({percentage}
              %)
            </span>
          )}
          <Checkbox
            label="S korekcí"
            className={styles.Checkbox}
            checked={this.state.sharpness.correction}
            onCheck={(e, checked) =>
              this.setState({ sharpness: { ...this.state.sharpness, correction: checked } })
            }
            iconStyle={{ transform: 'scale(0.8)' }}
            disabled={this.clientView}
          />
        </RenderWithPastFieldValue>
        <PageSpace />
      </GridTile>
    );
  }

  renderConvergence(index) {
    const { convergenceErrors } = this.state;
    const previousConvergence = this.previousData.convergence[index] || {};

    const errors = {
      bifurcation: convergenceErrors[index] ? convergenceErrors[index].bifurcation : false,
      connection: convergenceErrors[index] ? convergenceErrors[index].connection : false,
    };

    const translations = {
      bifurcation: 'Rozdvojení *',
      connection: 'Spojení *',
    };

    const errorTranslations = {
      bifurcation: 'Platný rozsah: 0 - 30',
      connection: 'Platný rozsah: 0 - 20',
    };

    const types = ['bifurcation', 'connection'];

    return (
      <GridTile className={styles.Convergence} key={index} cols={12}>
        {index === '0' && (
          <GridList cols={1} cellHeight="auto">
            <h4 className={styles.Header}>Maximální bod konvergence</h4>
          </GridList>
        )}
        {index !== '0' && !this.clientView && this.renderRemoveMeasurement('convergence', index)}
        <FlexBlock fullWidth>
          {types.map((type) => (
            <RenderWithPastFieldValue
              className={styles.ValueTextField}
              error={errors[type]}
              key={type}
              style={{ marginRight: '1rem' }}
              value={previousConvergence[type]}
            >
              <TextField
                floatingLabelText={translations[type]}
                value={this.state.convergence[index][type]}
                onChange={(e) => {
                  this.onChangeObjectField(
                    'convergence',
                    index,
                    type,
                    getNumericValue(e.target.value)
                  );
                }}
                onBlur={(e) => this.validateConvergenceValue(index, type)}
                name={`convergence-${index}-${type}`}
                errorText={errors[type] ? errorTranslations[type] : ''}
                errorStyle={errorStyle}
                readOnly={this.clientView}
              />
            </RenderWithPastFieldValue>
          ))}
          <RenderWithPastFieldValue
            className={styles.ValueTextField}
            style={{ marginRight: '1rem' }}
            value={previousConvergence.note}
          >
            <TextField
              floatingLabelText="Poznámka"
              value={this.state.convergence[index].note}
              onChange={(e) =>
                this.onChangeObjectField('convergence', index, 'note', e.target.value)
              }
              name={`convergence-${index}-note`}
              readOnly={this.clientView}
            />
          </RenderWithPastFieldValue>
          <Checkbox
            label="S korekcí"
            className={styles.Checkbox}
            checked={this.state.convergence[index].correction}
            onCheck={(e, checked) =>
              this.onChangeObjectField('convergence', index, 'correction', checked)
            }
            iconStyle={{ transform: 'scale(0.8)' }}
            disabled={this.clientView}
          />
        </FlexBlock>
      </GridTile>
    );
  }

  renderFlexibility(index) {
    const percentage = calcFunctions.flexibility(this.state.flexibility[index].value);
    const previousFlexibility = this.previousData.flexibility[index] || {};

    return (
      <GridTile className={styles.Flexibility} cols={12} key={index}>
        {index === '0' && <h4 className={styles.Header}>Vergenční flexibilita</h4>}
        {index !== '0' && !this.clientView && this.renderRemoveMeasurement('flexibility', index)}
        <RenderWithPastFieldValue
          error={this.state.flexibilityErrors[index]}
          value={previousFlexibility.value}
          noWidthBoost
        >
          <TextField
            floatingLabelText="Počet otočení *"
            value={this.state.flexibility[index].value}
            onChange={(e) => this.onChangeFlexibility(e.target.value, index)}
            onBlur={() => this.validateFlexibility(index)}
            name="flexibility-value"
            inputStyle={{ width: '70%' }}
            errorText={this.state.flexibilityErrors[index] ? 'Platný rozsah 0.0 - 30.0' : ''}
            errorStyle={errorStyle}
            readOnly={this.clientView}
          />
          {percentage !== null && (
            <span className={styles.Percentage}>
              ({percentage}
              %)
            </span>
          )}
          <Checkbox
            label="S korekcí"
            className={styles.Checkbox}
            checked={this.state.flexibility[index].correction}
            onCheck={(e, checked) =>
              this.onChangeObjectField('flexibility', index, 'correction', checked)
            }
            iconStyle={{ transform: 'scale(0.8)' }}
            disabled={this.clientView}
          />
        </RenderWithPastFieldValue>
      </GridTile>
    );
  }

  renderAccommodation(index) {
    const { accommodationErrors } = this.state;
    const previousAccommodation = this.previousData.accommodation[index] || {};

    const errors = {
      right: accommodationErrors[index] ? accommodationErrors[index].right : false,
      left: accommodationErrors[index] ? accommodationErrors[index].left : false,
      bino: accommodationErrors[index] ? accommodationErrors[index].bino : false,
    };

    const translations = {
      right: 'Pravá *',
      left: 'Levá *',
      bino: 'Bino *',
    };

    const types = ['right', 'left', 'bino'];

    return (
      <GridTile className={styles.Accommodation} cols={12} key={index}>
        {index === '0' && <h4 className={styles.Header}>Akomodace</h4>}
        {index !== '0' && !this.clientView && this.renderRemoveMeasurement('accommodation', index)}
        <FlexBlock fullWidth baseline>
          {types.map((type) => (
            <RenderWithPastFieldValue
              className={styles.ValueTextField}
              error={errors[type]}
              key={type}
              value={previousAccommodation[type]}
            >
              <TextField
                floatingLabelText={translations[type]}
                value={this.state.accommodation[index][type]}
                onChange={(e) => this.onChangeAccommodation(index, type, e.target.value)}
                onBlur={() => this.validateAccommodationValue(index, type)}
                name={`accommodation-${type}`}
                errorText={errors[type] ? 'Platný rozsah 0.0 - 35.0' : ''}
                errorStyle={{ position: 'absolute', bottom: '-21px' }}
                readOnly={this.clientView}
              />
            </RenderWithPastFieldValue>
          ))}
          <Checkbox
            label="S korekcí"
            className={styles.Checkbox}
            checked={this.state.accommodation[index].correction}
            onCheck={(e, checked) =>
              this.onChangeObjectField('accommodation', index, 'correction', checked)
            }
            iconStyle={{ transform: 'scale(0.8)' }}
            disabled={this.clientView}
          />
        </FlexBlock>
      </GridTile>
    );
  }

  renderACRatio() {
    return (
      <GridTile cols={6} className={styles.LargeTextField}>
        <h4 className={styles.Header}>AC/A poměr</h4>
        <RenderWithPastFieldValue className={styles.ValueTextField} value={null}>
          <TextField
            floatingLabelText="Hodnota"
            value={this.state.acRatio}
            onChange={(e) => this.setState({ acRatio: e.target.value })}
            name="ac_ratio"
            readOnly={this.clientView}
            style={{ maxWidth: 200 }}
          />
        </RenderWithPastFieldValue>
      </GridTile>
    );
  }

  renderInnerMuscles() {
    const fields = [
      { label: 'Bod rozmlžení:', name: 'blur' },
      { label: 'Bod rozdvojení:', name: 'bifurcation' },
      { label: 'Bod spojení:', name: 'connection' },
    ];

    const previousInnerMuscles = this.previousData.innerMuscles;

    return (
      <GridTile className={styles.Muscles} cols={6}>
        <h4 className={styles.SubHeader}>Vnitřní svaly (konvergence)</h4>
        {fields.map(({ label, name }) => {
          const closeName = name + 'Close';
          const distanceName = name + 'Distance';

          const renderField = (fieldName) => {
            const percentage = calcFunctions.innerMuscles[fieldName](
              this.state.innerMuscles[fieldName]
            );

            const errorText =
              fieldName === closeName ? 'Platný rozsah: 0 - 40' : 'Platný rozsah: 0 - 30';

            return (
              <RenderWithPastFieldValue
                className={styles.TextField}
                error={this.state.innerMusclesErrors[fieldName]}
                value={previousInnerMuscles[fieldName]}
              >
                <TextField
                  floatingLabelText={`${fieldName.indexOf('Close') >= 0 ? 'Blízko' : 'Dálka'}`}
                  value={this.state.innerMuscles[fieldName]}
                  onChange={(e) => this.onChangeInnerMuscles(fieldName, e.target.value)}
                  onBlur={(e) => {
                    const nullable = fieldName === closeName;
                    this.validateMusclesValue('inner', fieldName, e.target.value, nullable);
                  }}
                  name={`inner-${fieldName}`}
                  errorText={this.state.innerMusclesErrors[fieldName] ? errorText : ''}
                  errorStyle={errorStyle}
                  readOnly={this.clientView}
                />
                {percentage !== null && (
                  <span className={styles.Percentage}>
                    ({percentage}
                    %)
                  </span>
                )}
              </RenderWithPastFieldValue>
            );
          };

          return (
            <div key={`inner-${name}`}>
              <span className={styles.Label}>{label}</span>
              {distanceName in this.state.innerMuscles && renderField(distanceName)}
              {closeName in this.state.innerMuscles && renderField(closeName)}
              <PageSpace paddingBase={0.25} />
            </div>
          );
        })}
        <Checkbox
          label="S korekcí"
          className={styles.Checkbox}
          checked={this.state.innerMuscles.correction}
          onCheck={(e, checked) =>
            this.setState({ innerMuscles: { ...this.state.innerMuscles, correction: checked } })
          }
          iconStyle={{ transform: 'scale(0.8)' }}
          disabled={this.clientView}
        />
        <PageSpace />
      </GridTile>
    );
  }

  renderOuterMuscles() {
    const fields = [
      { label: 'Bod rozdvojení:', name: 'bifurcation' },
      { label: 'Bod spojení:', name: 'connection' },
    ];

    const previousOuterMuscles = this.previousData.outerMuscles;

    return (
      <GridTile className={styles.Muscles} cols={6}>
        <h4 className={styles.SubHeader}>Venkovní svaly (divergence)</h4>
        {fields.map(({ label, name }) => {
          const closeName = name + 'Close';
          const distanceName = name + 'Distance';

          const renderField = (fieldName) => {
            const percentage = calcFunctions.outerMuscles[fieldName](
              this.state.outerMuscles[fieldName]
            );

            const errorText =
              fieldName === closeName ? 'Platný rozsah: 0 - 40' : 'Platný rozsah: 0 - 30';

            return (
              <RenderWithPastFieldValue
                className={styles.TextField}
                error={this.state.outerMusclesErrors[fieldName]}
                value={previousOuterMuscles[fieldName]}
              >
                <TextField
                  floatingLabelText={`${fieldName.indexOf('Close') >= 0 ? 'Blízko' : 'Dálka'}`}
                  value={this.state.outerMuscles[fieldName]}
                  onChange={(e) => this.onChangeOuterMuscles(fieldName, e.target.value)}
                  onBlur={(e) => {
                    const nullable = fieldName === closeName;
                    this.validateMusclesValue('outer', fieldName, e.target.value, nullable);
                  }}
                  name={`outer-${fieldName}`}
                  errorText={this.state.outerMusclesErrors[fieldName] ? errorText : ''}
                  errorStyle={errorStyle}
                  readOnly={this.clientView}
                />
                {percentage !== null && (
                  <span className={styles.Percentage}>
                    ({percentage}
                    %)
                  </span>
                )}
              </RenderWithPastFieldValue>
            );
          };

          return (
            <div key={`outer-${name}`}>
              <span className={styles.Label}>{label}</span>
              {distanceName in this.state.outerMuscles && renderField(distanceName)}
              {closeName in this.state.outerMuscles && renderField(closeName)}
              <PageSpace paddingBase={0.25} />
            </div>
          );
        })}
        <Checkbox
          label="S korekcí"
          className={styles.Checkbox}
          checked={this.state.outerMuscles.correction}
          onCheck={(e, checked) =>
            this.setState({ outerMuscles: { ...this.state.outerMuscles, correction: checked } })
          }
          iconStyle={{ transform: 'scale(0.8)' }}
          disabled={this.clientView}
        />
      </GridTile>
    );
  }

  renderNeurotracker() {
    const previousNeurotracker = this.previousData.neurotracker;

    return (
      <GridTile cols={6} className={styles.LargeTextField}>
        <h4 className={styles.Header}>Neurotracker</h4>
        <RenderWithPastFieldValue
          // className={styles.TextField}
          className={styles.ValueTextField}
          error={this.state.neurotrackerError}
          value={previousNeurotracker}
        >
          <TextField
            floatingLabelText="Hodnota"
            value={this.state.neurotracker}
            onChange={(e) => this.setState({ neurotracker: e.target.value })}
            onBlur={() => this.validateNeurotracker()}
            name="neurotracker"
            errorText={this.state.neurotrackerError ? 'Platný rozsah: 0.0 - 3.5' : ''}
            errorStyle={errorStyle}
            readOnly={this.clientView}
          />
        </RenderWithPastFieldValue>
      </GridTile>
    );
  }

  renderNote() {
    return (
      <GridTile cols={6}>
        <h4>Poznámka</h4>
        <TextField
          value={this.state.note}
          onChange={(e) => this.setState({ note: e.target.value })}
          name="note"
          multiLine
          fullWidth
          readOnly={this.clientView}
        />
        <PageSpace />
      </GridTile>
    );
  }

  renderSubmitButton() {
    if (this.clientView) {
      return (
        <FlexBlock hcenter>
          <RaisedButton label="Vytisknout" onClick={this.props.printTraining} primary />
          <HSpace tiny />
          <RaisedButton label="Zavřít" onClick={() => this.props.hideAccordionPanel()} primary />
        </FlexBlock>
      );
    } else {
      return (
        <FlexBlock hcenter>
          <RaisedButton type="submit" label="Ukončit trénink" primary />
        </FlexBlock>
      );
    }
  }

  addAcuity() {
    const acuity = { ...this.state.acuity };
    const index = Object.keys(acuity).length;
    acuity[index] = {
      rightValue: '',
      rightDiopter: '',
      leftValue: '',
      leftDiopter: '',
      binoValue: '',
      binoDiopter: '',
      correction: false,
    };
    this.setState({ acuity });
  }

  addConvergence() {
    const convergence = { ...this.state.convergence };
    const index = Object.keys(convergence).length;
    convergence[index] = {
      bifurcation: null,
      connection: null,
      note: '',
      correction: false,
    };
    this.setState({ convergence });
  }

  addFlexibilty() {
    const flexibility = { ...this.state.flexibility };
    const index = Object.keys(flexibility).length;
    flexibility[index] = {
      value: '',
      percentage: null,
      correction: false,
    };
    this.setState({ flexibility });
  }

  addAccommodation() {
    const accommodation = { ...this.state.accommodation };
    const index = Object.keys(accommodation).length;
    accommodation[index] = {
      left: '',
      right: '',
      bino: '',
      correction: false,
    };
    this.setState({ accommodation });
  }

  removeMeasurement = (key, index) => {
    const state = { ...this.state };
    delete state[key][index];
    delete state[key + 'Errors'][index];
    this.setState(state);
  };

  onSelect = (name, field, value, errorField) => {
    this.setState({
      [name]: { ...this.state[name], [field]: value },
    });
    this.setState({
      [errorField]: { ...this.state[errorField], [field]: false },
    });
  };

  onChangeInitialData = (field, value) => {
    this.setState({
      initialData: {
        ...this.state.initialData,
        [field]: value,
      },
    });
  };

  onChangeObjectField = (field, index, name, value, callback = null) => {
    const newValue = { ...this.state[field][index], [name]: value };
    this.setState({ [field]: { ...this.state[field], [index]: newValue } }, () => {
      if (callback) callback();
    });
  };

  onChangeSharpness = (value) => {
    this.setState({ sharpness: { ...this.state.sharpness, value: getNumericValue(value) } });
  };

  onChangeFlexibility = (value, index) => {
    const newValue = { ...this.state.flexibility[index], value: getNumericValue(value) };
    this.setState({ flexibility: { ...this.state.flexibility, [index]: newValue } });
  };

  onChangeAccommodation = (index, name, value) => {
    this.onChangeObjectField('accommodation', index, name, value);
  };

  onChangeInnerMuscles = (field, value) => {
    this.setState({
      innerMuscles: { ...this.state.innerMuscles, [field]: getNumericValue(value) },
    });
  };

  onChangeOuterMuscles = (field, value) => {
    this.setState({
      outerMuscles: { ...this.state.outerMuscles, [field]: getNumericValue(value) },
    });
  };

  validateAcuityValue = (index, name) => {
    const value = this.state.acuity[index][name];
    const hasError = !validateAcuity(value);
    this.setState((prevState) => ({
      acuityErrors: {
        ...prevState.acuityErrors,
        [index]: { ...prevState.acuityErrors[index], [name]: hasError },
      },
    }));
  };

  validateConvergenceValue = (index, name) => {
    const value = this.state.convergence[index][name];
    let hasError = true;
    if (name === 'bifurcation') {
      hasError = !validateConvergenceBifurcation(value);
    } else {
      hasError = !validateConvergenceConnection(value);
    }
    this.setState((prevState) => ({
      convergenceErrors: {
        ...prevState.convergenceErrors,
        [index]: { ...prevState.convergenceErrors[index], [name]: hasError },
      },
    }));
  };

  validateAccommodationValue = (index, name) => {
    const value = this.state.accommodation[index][name];
    const hasError = !validateAccommodation(value);
    this.setState((prevState) => ({
      accommodationErrors: {
        ...prevState.accommodationErrors,
        [index]: { ...prevState.accommodationErrors[index], [name]: hasError },
      },
    }));
  };

  validateMusclesValue = (type, name, value, nullable) => {
    const state = { ...this.state };

    if (!value && nullable) {
      state[type + 'MusclesErrors'][name] = false;
      return;
    }

    let hasError = false;
    if (name.indexOf('Distance') >= 0) {
      hasError = !validateDistanceMuscle(value);
    } else {
      hasError = !validateCloseMuscle(value);
    }

    state[type + 'MusclesErrors'][name] = hasError;
    this.setState(state);
  };

  validateInitialData = () => {
    const { initialData } = this.state;
    const initialDataErrors = { ...this.state.initialDataErrors };

    let hasObrubaError = false;
    if (initialData.obruba && initialData.obruba.length > 50) {
      hasObrubaError = true;
    }
    initialDataErrors.obruba = hasObrubaError;

    let hasSkloCockaRightError = false;
    if (initialData.skloCockaRight && initialData.skloCockaRight.length > 50) {
      hasSkloCockaRightError = true;
    }
    initialDataErrors.skloCockaRight = hasSkloCockaRightError;

    let hasSkloCockaLeftError = false;
    if (initialData.skloCockaLeft && initialData.skloCockaLeft.length > 50) {
      hasSkloCockaLeftError = true;
    }
    initialDataErrors.skloCockaLeft = hasSkloCockaLeftError;

    this.setState({ initialDataErrors });

    return (
      hasObrubaError ||
      hasSkloCockaRightError ||
      hasSkloCockaLeftError ||
      initialDataErrors.initialMeasurement
    );
  };

  validateAcuityData = () => {
    const { acuity } = this.state;
    const acuityErrors = { ...this.state.acuityErrors };

    let anyError = false;

    const validateValue = (key, name) => {
      const a = acuity[key];
      const val = a[name];

      let hasError;
      if (name === 'measurement') {
        // Validation happens previously
        hasError = acuityErrors[key][name];
      } else {
        hasError = !validateAcuity(val);
      }

      if (hasError) {
        anyError = true;
      }
      if (acuityErrors[key]) {
        acuityErrors[key][name] = hasError;
      } else {
        acuityErrors[key] = {};
        acuityErrors[key][name] = hasError;
      }
    };

    for (const key in acuity) {
      const names = ['rightValue', 'leftValue', 'binoValue', 'measurement'];

      names.forEach((name) => validateValue(key, name));
    }

    this.setState({ acuityErrors });

    return anyError;
  };

  validateMotility = () => {
    const { motility } = this.state;
    const motilityErrors = { ...this.state.motilityErrors };

    let hasMotilityRightError = false;
    if (motility.right === null || motility.right === '' || isNaN(motility.right)) {
      hasMotilityRightError = true;
    }
    motilityErrors.right = hasMotilityRightError;

    let hasMotilityLeftError = false;
    if (motility.left === null || motility.left === '' || isNaN(motility.left)) {
      hasMotilityLeftError = true;
    }
    motilityErrors.left = hasMotilityLeftError;

    this.setState({ motilityErrors });

    return hasMotilityRightError || hasMotilityLeftError;
  };

  validateSharpness = () => {
    const value = this.state.sharpness.value;
    const hasError = !validateSharpness(value);
    this.setState({ sharpnessErrors: hasError });
    return hasError;
  };

  validateConvergence = () => {
    const { convergence } = this.state;
    const convergenceErrors = { ...this.state.convergenceErrors };

    let anyError = false;

    for (const key in convergence) {
      const bifurcation = convergence[key].bifurcation;
      const connection = convergence[key].connection;

      const bifError = !validateConvergenceBifurcation(bifurcation);
      const connError = !validateConvergenceConnection(connection);

      if (bifError || connError) {
        anyError = true;
      }

      convergenceErrors[key] = {
        bifurcation: bifError,
        connection: connError,
      };
    }

    this.setState({ convergenceErrors });

    return anyError;
  };

  validateFlexibility = () => {
    const { flexibility } = this.state;
    const flexibilityErrors = { ...this.state.flexibilityErrors };

    let anyError = false;

    for (const key in flexibility) {
      const f = flexibility[key];

      const hasError = !validateFlexibility(f.value);
      if (hasError) {
        anyError = true;
      }

      flexibilityErrors[key] = hasError;
    }

    this.setState({ flexibilityErrors });

    return anyError;
  };

  validateAccommodation = () => {
    const { accommodation } = this.state;
    const accommodationErrors = { ...this.state.accommodationErrors };

    let anyError = false;

    const validateValue = (key, name) => {
      const a = accommodation[key];
      const hasError = !validateAccommodation(a[name]);
      if (hasError) {
        anyError = true;
      }

      if (accommodationErrors[key]) {
        accommodationErrors[key][name] = hasError;
      } else {
        accommodationErrors[key] = {};
        accommodationErrors[key][name] = hasError;
      }
    };

    for (const key in accommodation) {
      const names = ['right', 'left', 'bino'];

      names.forEach((name) => validateValue(key, name));
    }

    this.setState({ accommodationErrors });

    return anyError;
  };

  validateMuscles = () => {
    const { innerMuscles, outerMuscles, innerMusclesErrors, outerMusclesErrors } = this.state;

    const muscles = {
      inner: innerMuscles,
      outer: outerMuscles,
    };

    let muscleErrors = {
      inner: { ...innerMusclesErrors },
      outer: { ...outerMusclesErrors },
    };

    let anyError = false;

    // close values can be null, all we need is to check them after user types
    const muscleKeysObject = {
      inner: [
        'blurDistance',
        // 'blurClose',
        'bifurcationDistance',
        // 'bifurcationClose',
        'connectionDistance',
        // 'connectionClose',
      ],
      outer: [
        'bifurcationDistance',
        // 'bifurcationClose',
        'connectionDistance',
        // 'connectionClose',
      ],
    };

    const checkMuscleValue = (type, key) => {
      const value = muscles[type][key];
      const hasError = !validateDistanceMuscle(value);
      if (hasError) {
        anyError = true;
      }

      muscleErrors[type][key] = hasError;
    };

    for (let type in muscleKeysObject) {
      const muscleKeys = muscleKeysObject[type];

      muscleKeys.forEach((key) => {
        checkMuscleValue(type, key);
      });
    }

    this.setState({
      innerMusclesErrors: muscleErrors.inner,
      outerMusclesErrors: muscleErrors.outer,
    });

    return anyError;
  };

  validateNeurotracker(nullable) {
    const { neurotracker } = this.state;
    if (nullable && !neurotracker) {
      return false;
    }
    const hasError = !validateNeurotracker(neurotracker);
    this.setState({ neurotrackerError: hasError });
    return hasError;
  }

  validate = () => {
    const hasInitialDataError = this.validateInitialData();
    const hasAcuityError = this.validateAcuityData();
    const hasMotilityError = this.validateMotility();
    const hasSharpnessError = this.validateSharpness();
    const hasConvergenceError = this.validateConvergence();
    const hasFlexibilityError = this.validateFlexibility();
    const hasAccommodationError = this.validateAccommodation();
    const hasMuscleError = this.validateMuscles();
    const hasNeurotrackerError = this.validateNeurotracker(true);

    return (
      hasInitialDataError ||
      hasAcuityError ||
      hasMotilityError ||
      hasSharpnessError ||
      hasConvergenceError ||
      hasFlexibilityError ||
      hasAccommodationError ||
      hasMuscleError ||
      hasNeurotrackerError
    );
  };
}

export default TrainingForm;
