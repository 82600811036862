import React, { Component } from 'react';

import GridTile from 'material-ui/GridList/GridTile';

import { FlexBlock } from '@oldUI';
import { PreviewFile } from '@oldComponents/filesComponents';
import { DownloadIcon, TrashIcon } from '@utilComponents/icons';
import styles from './AppForms.module.css';
import { InvisibleButton } from '@oldUI/buttons';

class FileTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
    };
  }

  render() {
    const { file } = this.props;
    let icons = (
      <FlexBlock vcenter>
        <a target="_blank" rel="noopener noreferrer" download href={file.url}>
          <DownloadIcon className={styles.DownloadFileIcon} title="Stáhnout soubor" />
        </a>
        <InvisibleButton type="button" onClick={() => this.props.onDelete(file)}>
          <TrashIcon className={styles.DeleteFileIcon} title="Smazat" />
        </InvisibleButton>
      </FlexBlock>
    );

    return (
      <GridTile
        cols={12}
        title={file.name}
        actionIcon={this.state.showButtons ? icons : null}
        onMouseEnter={() => this.setState({ showButtons: true })}
        onMouseLeave={() => this.setState({ showButtons: false })}
      >
        <PreviewFile file={file} />
      </GridTile>
    );
  }
}

export default FileTile;
