import React, { useContext, useState, useEffect } from 'react';

import { PageContainer, ClientsList } from '@components';

import {
  request,
  ClientsContext,
  setClients,
  addClient,
  editClient,
  filterClients,
  stopClientsFilter,
  BranchContext,
  PermissionsContext,
} from '@utils';

const ClientsListPage = ({ branchId }) => {
  if (!branchId) return null;

  const {
    state: { hasFetched, clients, targetPage, isFiltering, filteredClients, filteredPage },
    dispatch,
  } = useContext(ClientsContext);
  const { state: permissions } = useContext(PermissionsContext);

  const [currentBranchId, setCurrentBranchId] = useState(branchId);
  const [filterText, setFilterText] = useState('');

  const fetchClients = (resetData = false, usedBranchId = currentBranchId) => {
    if ((resetData || targetPage) && branchId) {
      request(`/api_customer/clients/${usedBranchId}?page=${resetData ? 1 : targetPage}`).then(
        ({ data }) => {
          const { clients: clientsData, nextPageExists } = data;

          let newClients,
            newTargetPage = 2;
          if (resetData) {
            newClients = clientsData;
            if (!nextPageExists) newTargetPage = null;
          } else {
            newClients = [...clients, ...clientsData];
            newTargetPage = nextPageExists ? targetPage + 1 : null;
          }

          setCurrentBranchId(branchId);
          dispatch(
            setClients({
              clients: newClients,
              targetPage: newTargetPage,
            })
          );
        }
      );
    }
  };

  useEffect(() => {
    if (!hasFetched || currentBranchId !== branchId) {
      fetchClients(true, branchId);
    }
  }, [hasFetched, branchId]);

  useEffect(() => {
    if (isFiltering) {
      dispatch(stopClientsFilter());
      setFilterText('');
    }
  }, [branchId]);

  const onSearch = (maintainData = false) => {
    request(
      `/api_customer/client-search/${branchId}/?page=${
        !maintainData ? 1 : filteredPage
      }&query=${filterText}`
    ).then(({ data }) => {
      const { clients: clientsData, nextPageExists } = data;

      let newClients,
        newTargetPage = 2;
      if (!maintainData) {
        newClients = clientsData;
        if (!nextPageExists) newTargetPage = null;
      } else {
        newClients = [...filteredClients, ...clientsData];
        newTargetPage = nextPageExists ? filteredPage + 1 : null;
      }

      dispatch(
        filterClients({
          clients: newClients,
          targetPage: newTargetPage,
        })
      );
    });
  };

  useEffect(() => {
    if (filterText) {
      onSearch();
    } else {
      dispatch(stopClientsFilter());
    }
  }, [filterText]);

  const onLoadMore = () => {
    if (isFiltering) {
      onSearch(true);
    } else {
      fetchClients();
    }
  };

  const onClientCreate = (values) => {
    return request(`/api_customer/create-client/${branchId}`, {
      method: 'POST',
      data: values,
    }).then(({ data }) => dispatch(addClient(data)));
  };

  const onClientInvite = (email) => {
    return request(`/api_customer/invite-client/${branchId}`, {
      method: 'POST',
      data: { email },
    }).then(({ data }) => dispatch(addClient(data)));
  };

  const reinviteClient = (client) => {
    const { id } = client;
    return request(`/api_customer/reinvite-client/${branchId}/${id}`, {
      method: 'PATCH',
    }).then(({ data }) => dispatch(editClient(id, data)));
  };

  const updateClient = (client, changes) => {
    const { id } = client;

    return request(`/api_customer/change-client/${branchId}/${id}`, {
      method: 'PATCH',
      data: changes,
    }).then(() => dispatch(editClient(id, changes)));
  };

  const onTextFilter = (newFilterText) => {
    setFilterText(newFilterText);
  };

  return (
    <ClientsList
      branchId={branchId}
      permissions={permissions}
      dataLoaded={hasFetched}
      hasMoreClients={isFiltering ? !!filteredPage : !!targetPage}
      clients={isFiltering ? filteredClients : clients}
      onLoadMore={onLoadMore}
      isFiltering={isFiltering}
      filterText={filterText}
      onTextFilter={onTextFilter}
      onClientCreate={onClientCreate}
      onClientInvite={onClientInvite}
      reinviteClient={reinviteClient}
      updateClient={updateClient}
    />
  );
};

export default () => {
  const {
    state: { branchId },
  } = useContext(BranchContext);

  return (
    <PageContainer>
      <ClientsListPage branchId={branchId} />
    </PageContainer>
  );
};
