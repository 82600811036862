import fetch from 'cross-fetch';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { readCookie } from '@utils';

const createApolloClient = (uri) => {
  const httpLink = createHttpLink({
    fetch,
    uri,
    // credentials: 'include',
  });

  const csrfLink = setContext((_, { headers }) => {
    const csrftoken = readCookie('csrftoken', '');
    return {
      headers: {
        ...headers,
        'X-CSRFToken': csrftoken ? `${csrftoken}` : null,
      },
    };
  });

  const apolloclient = new ApolloClient({
    link: csrfLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return apolloclient;
};

export const apolloclient = createApolloClient('/graphql');

export const publicApolloClient = createApolloClient('/publicgql');
