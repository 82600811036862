export const getTopOffset = (element) => {
  let distance = 0;

  if (element.offsetParent) {
    do {
      distance += element.offsetTop;
      element = element.offsetParent;
    } while (element);
  }

  return distance < 0 ? 0 : distance;
};
