import { createContext } from 'react';

export const BranchContext = createContext();

export const initialBranchState = {
  branches: [],
  hasFetched: false,
  branchId: null,
};

export const branchReducer = (state, action) => {
  switch (action.type) {
    case 'SET_BRANCHES':
      return {
        ...state,
        branches: action.branches || [],
        hasFetched: true,
      };
    case 'SET_BRANCH_ID':
      localStorage.setItem('branch_id', action.newBranchId);
      return {
        ...state,
        branchId: action.newBranchId,
      };
    case 'CALCULATE_BRANCH':
      const lastBranchId = parseInt(localStorage.getItem('branch_id'));

      let branchId = null;
      if (action.userData && action.userData.branches) {
        if (action.userData.branches.length > 0) {
          if (action.userData.type === 'superuser') {
            if (
              lastBranchId &&
              action.userData.branches.map((_) => parseInt(_.id)).indexOf(lastBranchId) > -1
            ) {
              branchId = lastBranchId;
            } else {
              branchId = action.userData.branches[0].id;
            }
          } else if (action.userData.type === 'employee') {
            if (
              lastBranchId &&
              action.userData.branches.map((_) => parseInt(_.id)).indexOf(lastBranchId) > -1
            ) {
              branchId = lastBranchId;
            } else {
              branchId = action.userData.branches[0].id;
            }
          } else if (action.userData.type === 'client') {
            if (
              lastBranchId &&
              action.userData.branches.map((_) => parseInt(_.id)).indexOf(lastBranchId) > -1
            ) {
              branchId = lastBranchId;
            } else {
              branchId = action.userData.branches[0].id;
            }
          }
        }
      }

      localStorage.setItem('branch_id', branchId);

      return {
        ...state,
        branchId,
      };
    case 'RESET':
      return initialBranchState;
    default:
      return state;
  }
};

export const setBranches = (branches) => ({
  type: 'SET_BRANCHES',
  branches,
});

export const setBranchId = (newBranchId) => ({
  type: 'SET_BRANCH_ID',
  newBranchId,
});

export const calculateBranchId = (userData) => ({
  type: 'CALCULATE_BRANCH',
  userData,
});

export const resetBranch = () => ({
  type: 'RESET',
});
