import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { message } from 'antd';
import R from 'ramda';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import querystring from 'query-string';

import { NoTextLoader } from '@utilComponents/loaders';

import * as calendarEvent from '@oldModels/calendarEvent';
import * as clientIdentity from '@oldModels/clientIdentity';

import { parseSimpleDate, strSimpleDate } from '@oldUtils/dateFormatting';
import * as obj from '@oldUtils/object';

import { publicApolloClient } from '@app/apollo';
import { UserContext, BranchContext, RoomsContext } from '@utils';

import CalendarWithRooms from './CalendarWithRooms';

// import {
//   withCalendarMutations,
//   withCalendarData,
//   withPublicCalendarData,
//   // withStudentCalendarData,
//   // withPublicCalendarMutations,
// } from './graphql';

import {
  employeeCalendarQuery,
  clientCalendarQuery,
  publicCalendarQuery,
  createEventMutation,
  createEvent,
  cancelEventMutation,
  cancelEvent,
  deleteRecurringEventMutation,
  deleteRecurringEvent,
  changeEventMutation,
  updateEvent,
  updateTimeslotAllWeeks,
  moveRecurringEventMutation,
  updateTimeslotOneWeek,
} from './gql';

moment.locale('cs_CZ');

BigCalendar.momentLocalizer(moment);

const momentLocaleData = moment.localeData();

const CalendarContainer = (props) => {
  const {
    state: { user, loggedIn },
  } = useContext(UserContext);
  const {
    state: { branchId },
  } = useContext(BranchContext);

  const location = useLocation();
  const params = useParams();

  const { date, time } = querystring.parse(location.search);
  const currentDate = parseSimpleDate(date) || new Date();
  const currentTime = time;

  // We have to start rendering the calendar as soon as possible.
  // It takes a lot of time (around 800 ms) for react-big-calendar to render.
  // So we dispatch data-fetching and start rendering "concurrently".
  // When events are fetched, only cheap update is made.

  // We want to display nothing until public calendar data are loaded, because the url
  // can easily be broken leading to 404. It would display Calendar, then 404.
  // But for logged employee, we want to display an empty calendar and re-render it after data
  // are loaded.

  const isPublic = props.isPublic || !loggedIn;
  const isReallyPublic = !loggedIn;
  const isClient = !isPublic && loggedIn && user.type === 'client';
  const isEmployee =
    !isPublic && loggedIn && (user.type === 'superuser' || user.type === 'employee');
  const loggedUserId = !isPublic && loggedIn && user.userId;
  const viewOnlyMode = isPublic || isClient;

  // Wait for data to load, because we're on "selected event" page
  const eventId = params.eventId;
  if (!props.calDataLoaded && eventId) {
    return <NoTextLoader />;
  }

  return (
    <CalendarWithRooms
      {...props}
      user={user}
      branchId={branchId}
      currentDate={currentDate}
      currentTime={currentTime}
      momentLocaleData={momentLocaleData}
      viewOnlyMode={viewOnlyMode}
      isPublic={isPublic}
      isReallyPublic={isReallyPublic}
      isClient={isClient}
      isEmployee={isEmployee}
      loggedUserId={loggedUserId}
    />
  );
};

export const EmployeeCalendarContainer = ({ branchId }) => {
  const {
    state: { currentRoom },
  } = useContext(RoomsContext);
  const location = useLocation();

  const { date, time } = querystring.parse(location.search);
  const currentDate = parseSimpleDate(date) || new Date();

  const {
    data: calendarData = {},
    loading: calendarDataLoading,
    refetch: refetchCalendarData,
  } = useQuery(employeeCalendarQuery, {
    variables: {
      branchId,
      forDate: currentDate && strSimpleDate(currentDate),
    },
    fetchPolicy: 'network-only',
  });

  const [callCreateEventMutation, { error }] = useMutation(createEventMutation, {
    onError: (error) => message.error(error.message),
  });
  const [callCancelEventMutation] = useMutation(cancelEventMutation, {
    onError: (error) => message.error(error.message),
  });
  const [callDeleteRecurringEventMutation] = useMutation(deleteRecurringEventMutation, {
    onError: (error) => message.error(error.message),
  });
  const [callChangeEventMutation] = useMutation(changeEventMutation, {
    onError: (error) => message.error(error.message),
  });
  const [callMoveRecurringEventMutation] = useMutation(moveRecurringEventMutation, {
    onError: (error) => message.error(error.message),
  });

  const branchEvents = calendarData.branchEvents || [];
  const branchTrainings = calendarData.branchTrainings || [];

  let calEvents = calendarDataLoading ? [] : branchEvents.map(calendarEvent.createFromPayload);
  const trainingsMap = calendarDataLoading
    ? {}
    : obj.makeMapWithIDS(branchTrainings.filter((l) => !l.isDeleted));
  const allTrainingsMap = calendarDataLoading ? {} : obj.makeMapWithIDS(branchTrainings);
  const defaultTrainingId = calendarDataLoading ? '' : branchTrainings[0] && branchTrainings[0].id;

  return (
    <CalendarContainer
      // GraphQL
      calDataLoaded={!calendarDataLoading}
      calEvents={calEvents}
      trainingsMap={trainingsMap}
      allTrainingsMap={allTrainingsMap}
      defaultTrainingId={defaultTrainingId}
      refetchCalEvents={refetchCalendarData}
      // Mutations
      createEvent={({ payload }) =>
        createEvent({ ...payload, branchId, roomId: currentRoom }, callCreateEventMutation)
      }
      cancelEvent={({ eventId }) =>
        cancelEvent(branchId, eventId, currentDate, callCancelEventMutation)
      }
      deleteRecurringEvent={(eventId) =>
        deleteRecurringEvent(branchId, eventId, currentDate, callDeleteRecurringEventMutation)
      }
      updateEvent={(options) =>
        updateEvent(branchId, options, currentDate, callChangeEventMutation)
      }
      updateTimeslotAllWeeks={(id, timeslot) =>
        updateTimeslotAllWeeks(branchId, id, currentDate, timeslot, callChangeEventMutation)
      }
      updateTimeslotOneWeek={(options) =>
        updateTimeslotOneWeek(branchId, options, callMoveRecurringEventMutation)
      }
    />
  );
};

export const ClientCalendarContainer = ({ branchId }) => {
  const {
    state: { currentRoom },
  } = useContext(RoomsContext);
  const location = useLocation();

  const { date, time } = querystring.parse(location.search);
  const currentDate = parseSimpleDate(date) || new Date();

  const {
    data: calendarData = {},
    loading: calendarDataLoading,
    refetch: refetchCalendarData,
  } = useQuery(clientCalendarQuery, {
    variables: {
      branchId,
      forDate: currentDate && strSimpleDate(currentDate),
    },
    fetchPolicy: 'network-only',
  });

  const [callChangeEventMutation] = useMutation(changeEventMutation, {
    onError: (error) => message.error(error.message),
  });

  const { clientCalendarData = {} } = calendarData;
  const { events = [], trainings = [] } = clientCalendarData;

  const calEvents = calendarDataLoading ? [] : events.map(calendarEvent.createFromPayload);
  const trainingsMap = calendarDataLoading
    ? {}
    : obj.makeMapWithIDS(trainings.filter((l) => !l.isDeleted));
  const allTrainingsMap = calendarDataLoading ? {} : obj.makeMapWithIDS(trainings);
  const defaultTrainingId = calendarDataLoading ? '' : trainings[0] && trainings[0].id;

  return (
    <CalendarContainer
      // GraphQL
      calDataLoaded={!calendarDataLoading}
      calEvents={calEvents}
      trainingsMap={trainingsMap}
      allTrainingsMap={allTrainingsMap}
      defaultTrainingId={defaultTrainingId}
      refetchCalEvents={refetchCalendarData}
      // Mutations
      updateEvent={(options) =>
        updateEvent(branchId, options, currentDate, callChangeEventMutation)
      }
      updateTimeslotAllWeeks={(id, timeslot) =>
        updateTimeslotAllWeeks(branchId, id, timeslot, callChangeEventMutation)
      }
    />
  );
};

export const PublicCalendarContainer = ({ publicUrl }) => {
  const {
    state: { currentRoom },
  } = useContext(RoomsContext);
  const location = useLocation();
  const params = useParams();

  const { date, time } = querystring.parse(location.search);
  const currentDate = parseSimpleDate(date) || new Date();

  const {
    data: calendarData = {},
    loading: calendarDataLoading,
    refetch: refetchCalendarData,
  } = useQuery(publicCalendarQuery, {
    variables: {
      publicUrl,
      forDate: currentDate && strSimpleDate(currentDate),
    },
    fetchPolicy: 'network-only',
    client: publicApolloClient,
  });

  const [callChangeEventMutation] = useMutation(changeEventMutation, {
    onError: (error) => message.error(error.message),
  });

  const { publicCalendarData = {} } = calendarData;
  const { rooms = [], events = [], trainings = [] } = publicCalendarData;

  const calEvents = calendarDataLoading ? [] : events.map(calendarEvent.createFromPayload);
  const trainingsMap = calendarDataLoading
    ? {}
    : obj.makeMapWithIDS(trainings.filter((l) => !l.isDeleted));
  const allTrainingsMap = calendarDataLoading ? {} : obj.makeMapWithIDS(trainings);
  const defaultTrainingId = calendarDataLoading ? '' : trainings[0] && trainings[0].id;

  return (
    <CalendarContainer
      isPublic={true}
      publicUrl={publicUrl}
      // GraphQL
      publicRooms={rooms}
      calDataLoaded={!calendarDataLoading}
      calEvents={calEvents}
      trainingsMap={trainingsMap}
      allTrainingsMap={allTrainingsMap}
      defaultTrainingId={defaultTrainingId}
      refetchCalEvents={refetchCalendarData}
      // // Mutations
      // updateEvent={(options) => updateEvent(branchId, options, currentDate, callChangeEventMutation)}
      // updateTimeslotAllWeeks={(id, timeslot) =>
      //   updateTimeslotAllWeeks(branchId, id, timeslot, callChangeEventMutation)
      // }
    />
  );
};
