import querystring from 'querystring';

// TUTO_REFACTOR_FLOW_IGNORE
import mapValues from 'lodash/mapValues';
// TUTO_REFACTOR_FLOW_IGNORE
import cloneDeepLib from 'lodash/cloneDeep';
// TUTO_REFACTOR_FLOW_IGNORE
import pick from 'lodash/pick';
// TUTO_REFACTOR_FLOW_IGNORE
import set from 'lodash/set';

// returns array of object with undeterministic order
export function toArray(obj) {
  const list = [];
  for (const k of Object.keys(obj)) {
    list.push(obj[k]);
  }
  return list;
}

export function isEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
}

export function hasNoTrueValue(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (!hasNoTrueValue(obj[key])) {
        return false;
      }
    } else if (obj[key]) {
      return false;
    }
  }

  return true;
}

export function simpleObjectsAreEqual(obj1, obj2) {
  if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
    return true;
  } else {
    return false;
  }
}

// returns array of object with deterministic order by orderIter
export function toOrderedArray(obj, orderIter) {
  const list = [];
  for (const k of orderIter) {
    list.push(obj[k]);
  }
  return list;
}

// Returns new object with only the specified keys.
// (obj: Object, keys: Array<string>): Object
export function createFiltered(obj, keys) {
  return pick(obj, keys);
}

// Returns array of values of `obj` by provided `keys`.
// If keys are not provided, it returns all values.
export function values(obj, ...keys) {
  if (keys.length === 0) {
    return toArray(obj);
  }

  const result = [];
  for (const key of keys) {
    result.push(obj[key]);
  }
  return result;
}

export function anyValueNonEmpty(obj, ...keys) {
  return !!values(obj, ...keys).find((v) => !!v);
}

export function createDeepCopy(obj) {
  return cloneDeepLib(obj);
}

export function createStub(path, value) {
  return set({}, path, value);
}

function createObjectMapFromArray(sourceArray, key) {
  const obj = {};
  for (const item of sourceArray) {
    const keyVal = item[key];
    obj[keyVal] = item;
  }
  return obj;
}

export function makeMapWithIDS(objectList) {
  return createObjectMapFromArray(objectList, 'id');
}

// Convenient function that creates map-like structure of array of objects indexed by id.
// If mapFunc is provided it also remaps the objects.
export function normalizeToMap(objectList, mapFunc) {
  const mappedById = createObjectMapFromArray(objectList, 'id');
  if (mapFunc) {
    return mapValues(mappedById, mapFunc);
  }
  return mappedById;
}

export function queryStringify(obj) {
  return querystring.stringify(obj);
}

export { mapValues };
