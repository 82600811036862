import React from 'react';

export class NoSSR extends React.Component {
  constructor(props) {
    super(props);

    this.state = { shouldRender: false };
  }

  componentDidMount() {
    this.setState({ shouldRender: true });
  }

  render() {
    return this.state.shouldRender ? this.props.children : null;
  }
}
