import axios from 'axios';

import { readCookie } from './cookie';

export async function request(url, { data = {}, method = 'get', headers = {} } = {}) {
  const clientCSRFtoken = readCookie('csrftoken', '');

  const headersToUse = {
    Accept: 'application/json; charset=UTF-8',
    ...headers,
  };

  if (clientCSRFtoken) {
    headersToUse['X-CSRFToken'] = clientCSRFtoken;
  }

  return axios({
    headers: headersToUse,
    url,
    data,
    method,
  });
}
