import React, { useContext } from 'react';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';

import { LinkLikeBtn } from '@oldUI/buttons';
import * as tutoUI from '@oldUI';
import SimpleTable from '@oldUI/SimpleTable';

import AppPage from '@oldComponents/AppPage';
import PageTitle from '@oldComponents/PageTitle';
import PageSubtitle from '@oldComponents/PageSubtitle';

import { strSimpleDate, parseDate, strFormatDate, stringifyTime } from '@oldUtils/dateFormatting';
import { queryStringify } from '@oldUtils/object';

import { VSpace } from '@utilComponents/layoutPrimitives';

import * as ramdalist from '@whysRamda/list';

import { PageContainer, ReservationStatusContainer } from '@components';

import { request, BranchContext } from '@utils';

// type LessonReservation = {
//   status: ReservationStatusType,
//   price: string,
//   currency: string,
//   id: TutoID,
//   lessonDate: string,
//   createdOn: string,
//   dueDate: string,
//   varSymbol: string,
//   price: string,
//   reservedBy: string,
// };

const DateAndTime = ({ value, ...props }) => {
  return (
    <span {...props}>
      {strFormatDate(value)} {stringifyTime(value)}
    </span>
  );
};

const RelativeDateWithTitle = ({ value, ...props }) => {
  return (
    <span {...props} title={`${strFormatDate(value)} ${stringifyTime(value)}`}>
      {moment(value).fromNow()}
    </span>
  );
};

const RelativeDatetimeWithTitle = ({ value, ...props }) => {
  return (
    <span {...props} title={`${strFormatDate(value)} ${stringifyTime(value)}`}>
      {moment(value).fromNow()}
    </span>
  );
};

const reservationAPI = {
  isOpened: (r) => r.status === 'reserved',
  isClosed: (r) => r.status !== 'reserved',
};

const wrapHelpCursor = (el) => <div style={{ cursor: 'help' }}>{el}</div>;

function dateToCalendarPageQuery(d) {
  // => '2017-08-29T10:52:32.334Z'
  // <= 'date=2017-08-29&time=1052'
  // or
  // => '2017-08-29T00:00:00.334Z'
  // <= 'date=2017-08-29'
  // Helper that returns a query to open calendar at specific
  // date and time. If time is not set/null (00:00), then its not set
  // so rather default time can be used.
  const dateStr = strSimpleDate(d);
  if (d.getHours() === 0 && d.getMinutes() === 0) {
    return queryStringify({ date: dateStr });
  }

  const timeStr = stringifyTime(d);
  return queryStringify({ date: dateStr, time: timeStr });
}

const renderDateAndTime = (dateStr, rowData, idx) => {
  return (
    <Link to={`/calendar?${dateToCalendarPageQuery(parseDate(dateStr))}`}>
      <DateAndTime value={dateStr} />
    </Link>
  );
};
const renderCreatedOn = (date, rowData, idx) =>
  wrapHelpCursor(<RelativeDatetimeWithTitle value={date} />);

// TUTO_RULE_RESERVATIONS_DUE_TO_END_OF_DAY: we don't care about time, only date
const renderDueTo = (date, rowData, idx) => wrapHelpCursor(<RelativeDateWithTitle value={date} />);

const renderPrice = (date, rowData, idx) => (
  <span>
    {rowData.price} {rowData.currency || ''}
  </span>
);

function createTableData(data) {
  return {
    ...data,
    key: String(data.id),
  };
}

function TableView({ columns, data }) {
  const rowData = data[0];
  return (
    <table className="rc-table">
      <tbody>
        {columns.map((c, idx) => {
          const colData = rowData[c.dataIndex] || '';

          return (
            <tr className="rc-table-row" key={`tr${c.key}idx${idx}`}>
              <th key={`th${c.key}`}>{c.title}</th>
              <td key={`td${c.key}`}>{c.render ? c.render(colData, rowData, idx) : colData}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

class ReservationsPage extends React.Component {
  // columns: *;
  // reduceHeaderReservationsCount: AnyBoundMethod;

  constructor(props) {
    super(props);

    this.state = { data: [], dataLoaded: false, nextUrl: null };

    this.defineColumns();
  }

  componentDidMount() {
    const { branchId } = this.props;

    if (branchId) {
      this.fetchData(`/api_classroom/reservations/${branchId}`, true);
    }
  }

  componentDidUpdate(prevProps) {
    const { branchId } = this.props;

    if (branchId && branchId !== prevProps.branchId) {
      this.fetchData(`/api_classroom/reservations/${branchId}`, true);
    }
  }

  fetchData(dataUrl, resetData = false) {
    request(dataUrl).then((response) => {
      const { results, next } = response.data;
      const { data } = this.state;

      let url = null;
      if (next) {
        url = next.replace(/(^\w+:|^)\/\//, '');
        const protocol = window.location.href.split('/')[0] + '//';
        url = protocol + url;
      }

      let newData;
      if (resetData) {
        newData = results;
      } else {
        newData = [...data, ...results];
      }

      this.setState({ data: newData, nextUrl: url, dataLoaded: true });
    });
  }

  onFetchMore = () => {
    const { nextUrl } = this.state;
    if (nextUrl) {
      this.fetchData(nextUrl);
    }
  };

  // Reduce reservationsCount by 1, manually because of no refs
  reduceHeaderReservationsCount() {
    const reservationsCount = document.getElementsByClassName('HeaderReservationCount')[0];
    if (reservationsCount) {
      reservationsCount.innerHTML = (parseInt(reservationsCount.innerHTML, 10) - 1).toString();
    }
  }

  onCancel = (reservation) => {
    request(`/api_classroom/reservations/${this.props.branchId}/${reservation.id}/cancel`, {
      method: 'PATCH',
    }).then((response) => {
      const updatedModel = response.data;
      this.setState({ data: ramdalist.updateById(updatedModel, this.state.data) });
      this.reduceHeaderReservationsCount();
    });
  };

  onConfirm = (reservation, paidOn) => {
    request(`/api_classroom/reservations/${this.props.branchId}/${reservation.id}/confirm`, {
      method: 'PATCH',
      data: { paidOn },
    }).then((response) => {
      const updatedModel = response.data;
      this.setState({ data: ramdalist.updateById(updatedModel, this.state.data) });
      this.reduceHeaderReservationsCount();
    });
  };

  onRenderStatusCol = (status, rowModel, idx) => {
    return (
      <ReservationStatusContainer
        reservation={rowModel}
        status={status}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
      />
    );
  };

  defineColumns() {
    const columns = [
      {
        title: 'Vytvořeno',
        key: 'createdOn',
        dataIndex: 'createdOn',
        width: 100,
        render: renderCreatedOn,
      },
      {
        title: 'Lekce',
        key: 'lessonDate',
        dataIndex: 'lessonDate',
        width: 100,
        render: renderDateAndTime,
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 100,
        render: this.onRenderStatusCol,
      },
      { title: 'VS', key: 'varSymbol', dataIndex: 'varSymbol', width: 100 },
      { title: 'Částka', key: 'price', dataIndex: 'price', width: 100, render: renderPrice },
      { title: 'Od koho', key: 'reservedBy', dataIndex: 'reservedBy', width: 100 },
      {
        title: 'Splatnost do',
        key: 'dueDate',
        dataIndex: 'dueDate',
        width: 100,
        render: renderDueTo,
      },
    ];

    this.columns = columns;
  }

  hasMoreData() {
    return !!this.state.nextUrl;
  }

  renderDetail(columns, detail) {
    return (
      <div>
        <PageTitle>Detail rezervace</PageTitle>
        <Link to="/reservations">Zobrazit všechny rezervace</Link>
        <VSpace double />

        <TableView columns={columns} data={[detail]} />
      </div>
    );
  }

  renderMoreData() {
    return (
      <tutoUI.FlexBlock fullWidth hcenter>
        <LinkLikeBtn onClick={this.onFetchMore}>Zobrazit starší</LinkLikeBtn>
      </tutoUI.FlexBlock>
    );
  }

  render() {
    const { data, dataLoaded } = this.state;

    if (!dataLoaded) {
      return null;
    }

    const {
      params: { id },
    } = this.props;
    if (id) {
      const detail = data.filter(ramdalist.findById(id)).map(createTableData)[0];
      if (detail) {
        return (
          <AppPage header="" vcenter hcenter>
            {this.renderDetail(this.columns, detail)}
          </AppPage>
        );
      }
    }

    const openReservations = data.filter(reservationAPI.isOpened).map(createTableData);
    const closedReservations = data.filter(reservationAPI.isClosed).map(createTableData);

    return (
      <AppPage header="">
        <div>
          <PageSubtitle>Otevřené rezervace</PageSubtitle>
          {openReservations.length ? (
            <SimpleTable columns={this.columns} data={openReservations} />
          ) : (
            <span>Nemáte žádné rezervované tréninky. Počkejte, než se někdo přihlásí.</span>
          )}
        </div>

        <div>
          <PageSubtitle>Uzavřené rezervace</PageSubtitle>
          {closedReservations.length ? (
            <SimpleTable columns={this.columns} data={closedReservations} />
          ) : (
            <span>Nemáte žádné uzavřené tréninky.</span>
          )}
        </div>

        <VSpace double />

        {this.hasMoreData() ? this.renderMoreData() : null}
      </AppPage>
    );
  }
}

export default () => {
  const {
    state: { branchId },
  } = useContext(BranchContext);
  const params = useParams();

  return (
    <PageContainer>
      <ReservationsPage branchId={branchId} params={params} />
    </PageContainer>
  );
};
