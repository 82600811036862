import * as React from 'react';

import design from './buttons.module.css';
import { cx } from '@whysReact';

import { TutoCancelFullIcon } from '@oldUI/icons';
import { FlexBlock, PaddedBlock } from '@utilComponents/layoutPrimitives';

export function PrimaryBtn(props) {
  const { small, large, fullWidth, ...passProps } = props;
  const classes = cx(props.className, design.PrimaryButton, {
    [design.PrimaryButtonLarge]: !!large,
    [design.PrimaryButtonSmall]: !!small,
  });
  return (
    <button
      {...passProps}
      style={{ width: fullWidth && '100%', ...props.style }}
      className={classes}
    />
  );
}
export function DangerBtn(props) {
  const { fullWidth, ...passProps } = props;
  const className = cx('BtnDefault BtnDanger BtnSmall', props.className);
  return (
    <button
      {...passProps}
      className={className}
      style={{ width: fullWidth && '100%', ...props.style }}
    />
  );
}

export function InvisibleButton(props) {
  return <button {...props} className={cx(design.InvisibleButton, props.className)} />;
}

export function CloseIconButton(props) {
  return (
    <InvisibleButton {...props}>
      <TutoCancelFullIcon />
    </InvisibleButton>
  );
}

export function IconButton(props) {
  const { icon, children, pos, ...restProps } = props;

  let iconLeft;
  let iconRight;

  if (pos === 'left') {
    iconLeft = (
      <PaddedBlock tiny right>
        {icon}
      </PaddedBlock>
    );
  } else if (pos === 'right') {
    iconRight = (
      <PaddedBlock tiny left>
        {icon}
      </PaddedBlock>
    );
  }

  return (
    <InvisibleButton {...restProps}>
      <FlexBlock vcenter>
        {iconLeft}
        <FlexBlock vcenter>{children}</FlexBlock>
        {iconRight}
      </FlexBlock>
    </InvisibleButton>
  );
}

IconButton.defaultProps = {
  pos: 'left',
};

export function LinkLikeBtn(props) {
  const { danger, ...passProps } = props;
  return (
    <button
      {...passProps}
      className={cx(props.className, design.LinkLikeBtn, {
        [design.LinkLikeBtnDanger]: danger,
      })}
    />
  );
}
