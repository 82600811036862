import React, { useContext } from 'react';

import { PageContainer, Employees } from '@components';

import { UserContext, BranchContext, PermissionsContext } from '@utils';

const EmployeesPage = () => {
  const {
    state: { user },
  } = useContext(UserContext);
  const {
    state: { hasFetched, branchId },
  } = useContext(BranchContext);
  const {
    state: { allowBranchAdministration },
  } = useContext(PermissionsContext);

  if (!user) {
    return null;
  }

  const targetBranch = user.branches.filter((_) => _.id === branchId)[0];

  if (!hasFetched) {
    return null;
  }

  if (user.userType !== 'superuser') {
    if (!targetBranch || !allowBranchAdministration) {
      return null;
    }
  }

  return <Employees branchId={branchId} />;
};

export default () => (
  <PageContainer>
    <EmployeesPage />
  </PageContainer>
);
