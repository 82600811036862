import * as React from 'react';

// Forms
import { Aptform } from '@appForms';
import { createPasswordFieldConfig } from '@appForms/inputs';

// App UI
import * as materialUI from '@materialUI';
import { UpdatingLabel } from '@oldUI/asyncLabels';

export function ChangePasswordForm(props) {
  const renderFormValues = ({ inputs, form }) => {
    const { password } = inputs;

    return (
      <form {...form.getPassProps()}>
        <div>
          <materialUI.TextField
            {...password.getPassProps()}
            type="password"
            errorText={password.showError() ? password.errorText : ''}
            floatingLabelText="Nové heslo"
            style={{ marginBottom: 14, width: 400 }}
          />
        </div>
        <materialUI.RaisedButton
          primary
          type="submit"
          disabled={!form.hasChanged() || !form.isValid() || form.submitting}
          labelPosition="before"
          label={form.submitting ? <UpdatingLabel /> : 'Aktualizovat'}
          onClick={form.onSubmit}
        />
      </form>
    );
  };

  return (
    <Aptform
      inputs={{ password: createPasswordFieldConfig() }}
      onSubmit={props.onSubmit}
      render={renderFormValues}
    />
  );
}
