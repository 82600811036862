import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import ClearIcon from '@material-ui/icons/Clear';
import styled from 'styled-components';

import { isEmail } from '@oldUtils/validations';

import { request, useEventListener } from '@utils';

const SearchInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
  padding: 7px 6px;
  width: 100%;

  ::placeholder {
    color: #bbb;
    font-weight: 300;
  }
`;

const StyledClearIcon = styled(ClearIcon)`
  color: #999;
  cursor: pointer;
  font-size: 20px !important;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &:hover,
  &:active {
    color: #cc0000;
  }
`;

const SearchResults = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 350px;
  overflow-y: scroll;
  position: absolute;
  z-index: 10;
  width: 300px;
`;

export const SearchResult = styled.div`
  align-items: center;
  border-bottom: ${({ isLast }) => (isLast ? 'none' : '1px solid #ddd')};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 10px 10px 10px 10px;
  transition: background-color 0.2s ease;
  width: 100%;

  &:hover,
  &:focus {
    background-color: #eee;
  }
`;

export const Name = styled.p`
  color: #3c364c;
  font-size: 18px;
  font-weight: 700;
  line-height: 125%;
  margin: 0;
`;

export const Email = styled.p`
  color: #666;
  font-size: 16px;
  line-height: 125%;
  margin: 0;
`;

const ClientSelect = ({ branchId, selected, onReset, onAdd, onSelect }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState([]);
  const [hiddenResults, setHiddenResults] = useState(false);

  useEventListener('click', ({ target }) => {
    const container = document.getElementById('client-search-container');

    if (container && !container.contains(target)) {
      setHiddenResults(true);
    }
  });

  useEffect(() => {
    if (searchValue) {
      request(`/api_customer/client-search/${branchId}?query=${searchValue}`).then(({ data }) => {
        setResults(data.clients);
      });
    } else {
      setResults([]);
    }
  }, [searchValue]);

  const onSelectClient = (client) => {
    setHiddenResults(true);

    if (selected) {
      message.error('Nelze vybrat více než jednoho klienta');
      return;
    }

    // if (!selected || parseInt(client.id) !== parseInt(selected.id)) {
    //   onSelect(client);
    // }

    onSelect(client);
    setSearchValue(client.name);
  };

  const onSelectEmail = () => {
    setHiddenResults(true);

    if (isEmail(searchValue)) {
      if (selected) {
        message.error('Nelze vybrat více než jednoho klienta');
        return;
      } else {
        onAdd(searchValue);
      }
    } else {
      message.error('Neplatný email');
    }
  };

  return (
    <div id="client-search-container" style={{ position: 'relative' }}>
      <SearchInput
        placeholder="Přidejte klienta"
        value={isSearching ? searchValue : selected ? selected.label : searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        onFocus={() => {
          setIsSearching(true);
          setHiddenResults(false);
        }}
        onBlur={() => {
          setIsSearching(false);
        }}
      />
      {selected && (
        <StyledClearIcon
          onClick={() => {
            setIsSearching(false);
            setSearchValue('');
            setResults([]);
            setHiddenResults(false);

            onReset();
          }}
        />
      )}
      {!hiddenResults && searchValue && (
        <SearchResults>
          {results.map((client, index) => {
            const { id, name } = client;

            return (
              <SearchResult
                key={id}
                // isLast={index === results.length - 1}
                onClick={() => onSelectClient(client)}
              >
                <Name>{name}</Name>
              </SearchResult>
            );
          })}
          <SearchResult key={0} isLast onClick={onSelectEmail}>
            <Email>Přidat přes email: {searchValue}</Email>
          </SearchResult>
        </SearchResults>
      )}
    </div>
  );
};

export default ClientSelect;
