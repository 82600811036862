import * as React from 'react';
import RCTooltip from 'rc-tooltip';

import { RightDirectionIcon, LeftDirectionIcon } from '@utilComponents/icons';

function Tooltip(props) {
  const { children, placement, overlay, ...restProps } = props;

  const isPlacementLeft = placement === 'left';
  const isPlacementTop = placement.startsWith('top');

  let arrowIconOnLeft;
  let arrowIconOnRight;
  if (isPlacementLeft) {
    arrowIconOnRight = <RightDirectionIcon large />;
  } else {
    arrowIconOnLeft = <LeftDirectionIcon large />;
  }

  const arrowInlStyle = isPlacementTop ? { position: 'relative', top: 0 } : {};
  const overlayStyle = isPlacementTop
    ? { display: 'flex', position: 'absolute' }
    : { display: 'flex', alignItems: 'center' };

  const sameProps = { placement, ...restProps };
  return (
    <RCTooltip
      trigger="click"
      mouseEnterDelay={0}
      mouseLeaveDelay={0.1}
      destroyTooltipOnHide={false}
      align={{
        offset: [0, 0],
      }}
      overlay={
        <div style={overlayStyle}>
          <div className="ArrowIcon" style={arrowInlStyle}>
            {arrowIconOnLeft}
          </div>
          {overlay}
          <div className="ArrowIcon" style={arrowInlStyle}>
            {arrowIconOnRight}
          </div>
        </div>
      }
      {...sameProps}
    >
      {children}
    </RCTooltip>
  );
}

export default Tooltip;
