import * as React from 'react';

import layout from './icons.module.css';

import { defineCSS, cx } from '@whysReact';

function createIconComponent(children) {
  const component = ({ css, inline, large, title, className, fill }) => {
    const classes = cx(className || css.TutoIcon, {
      [css.InlineTutoIcon]: inline,
      [css.LargeTutoIcon]: large,
    });
    const clonedProps = { ...children.props };
    if (fill) {
      clonedProps.fill = fill;
    }
    return (
      <div title={title} className={classes}>
        {React.cloneElement(children, clonedProps, clonedProps.children)}
      </div>
    );
  };

  return defineCSS(component, {
    InlineTutoIcon: layout.InlineTutoIcon,
    LargeTutoIcon: layout.LargeTutoIcon,
    TutoIcon: layout.TutoIcon,
  });
}

export const TrashIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M18.984 3.984v2.016h-13.969v-2.016h3.469l1.031-0.984h4.969l1.031 0.984h3.469zM6 18.984v-12h12v12c0 1.078-0.938 2.016-2.016 2.016h-7.969c-1.078 0-2.016-0.938-2.016-2.016z" />
  </svg>
);

export const EditIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M20.719 7.031l-1.828 1.828-3.75-3.75 1.828-1.828c0.375-0.375 1.031-0.375 1.406 0l2.344 2.344c0.375 0.375 0.375 1.031 0 1.406zM3 17.25l11.063-11.063 3.75 3.75-11.063 11.063h-3.75v-3.75z" />
  </svg>
);

export const OnetimeIcon = createIconComponent(
  <svg viewBox="0 0 28 26.594">
    <polygon points="13.427,16.625 15.092,16.625 15.092,9.964 14,9.964 11.761,11.057 11.761,12.204 13.427,12.204" />
    <path d="M5,5.546v15.502h18V5.546H5z M20.813,18.861H7.185V7.731h13.629v11.13H20.813z" />
  </svg>
);

export const RecurringIcon = createIconComponent(
  <svg viewBox="0 0 30.5 32.243">
    <path d="M15.25,23.233v-3.555l4.723,4.723l-4.723,4.777v-3.555c-5.222,0-9.5-4.278-9.5-9.5c0-1.833,0.556-3.611,1.5-5.056 l1.723,1.723c-0.555,1-0.833,2.112-0.833,3.334C8.14,20.067,11.306,23.233,15.25,23.233z M15.25,6.623c5.223,0,9.5,4.277,9.5,9.5 c0,1.833-0.556,3.611-1.5,5.054l-1.723-1.724c0.557-1,0.835-2.111,0.835-3.333c0-3.945-3.168-7.11-7.111-7.11v3.555l-4.723-4.722 l4.723-4.778L15.25,6.623L15.25,6.623z" />
  </svg>
);

export const SearchIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M9.516 14.016c2.484 0 4.5-2.016 4.5-4.5s-2.016-4.5-4.5-4.5-4.5 2.016-4.5 4.5 2.016 4.5 4.5 4.5zM15.516 14.016l4.969 4.969-1.5 1.5-4.969-4.969v-0.797l-0.281-0.281c-1.125 0.984-2.625 1.547-4.219 1.547-3.609 0-6.516-2.859-6.516-6.469s2.906-6.516 6.516-6.516 6.469 2.906 6.469 6.516c0 1.594-0.563 3.094-1.547 4.219l0.281 0.281h0.797z" />
  </svg>
);

export const CheckmarkIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z" />
  </svg>
);

export const CancelIcon = createIconComponent(
  <svg viewBox="0 0 25 25">
    <path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z" />
  </svg>
);

export const CancelFullIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M17.016 15.609l-3.609-3.609 3.609-3.609-1.406-1.406-3.609 3.609-3.609-3.609-1.406 1.406 3.609 3.609-3.609 3.609 1.406 1.406 3.609-3.609 3.609 3.609zM12 2.016c5.531 0 9.984 4.453 9.984 9.984s-4.453 9.984-9.984 9.984-9.984-4.453-9.984-9.984 4.453-9.984 9.984-9.984z" />
  </svg>
);

export const RemoveIcon = createIconComponent(
  <svg viewBox="0 0 768 768">
    <path d="M607.5 205.5l-178.5 178.5 178.5 178.5-45 45-178.5-178.5-178.5 178.5-45-45 178.5-178.5-178.5-178.5 45-45 178.5 178.5 178.5-178.5z" />
  </svg>
);

export const RightDirectionIcon = createIconComponent(
  <svg viewBox="0 0 25 25">
    <path d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z" />
  </svg>
);

export const LeftDirectionIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M15.422 16.078l-1.406 1.406-6-6 6-6 1.406 1.406-4.594 4.594z" />
  </svg>
);

export const TodayIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M6.984 9.984h5.016v5.016h-5.016v-5.016zM18.984 18.984v-10.969h-13.969v10.969h13.969zM18.984 3c1.078 0 2.016 0.938 2.016 2.016v13.969c0 1.078-0.938 2.016-2.016 2.016h-13.969c-1.125 0-2.016-0.938-2.016-2.016v-13.969c0-1.078 0.891-2.016 2.016-2.016h0.984v-2.016h2.016v2.016h7.969v-2.016h2.016v2.016h0.984z" />
  </svg>
);

export const ArrowBackIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M20.016 11.016v1.969h-12.188l5.578 5.625-1.406 1.406-8.016-8.016 8.016-8.016 1.406 1.406-5.578 5.625h12.188z" />
  </svg>
);

export const ArrowNextIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z" />
  </svg>
);

export const LogOutIcon = createIconComponent(
  <svg viewBox="0 0 768 768">
    <path d="M 160.5,96 C 126,96 96,126 96,160.5 l 0,447 c 0,34.5 30,64.5 64.5,64.5 l 447,0 c 36,0 64.5,-30 64.5,-64.5 l 0,-127.5 -64.5,0 0,127.5 -447,0 0,-447 447,0 0,127.5 64.5,0 0,-127.5 C 672,126 643.5,96 607.5,96 Z" />
    <path d="m 437.62849,499.5 82.5,-84 -309,0 0,-63 309,0 -82.5,-84 45,-45 160.5,160.5 -160.5,160.5 z" />
  </svg>
);

export const TextFileIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path
      d="M15.182,2.296h-8c-1.101,0-1.99,0.9-1.99,2l-0.01,16c0,1.1,0.89,2,1.99,2h12.01c1.1,0,2-0.9,2-2v-12L15.182,2.296z
   M17.182,18.296h-8v-2h8V18.296z M17.182,14.296h-8v-2h8V14.296z M14.182,9.296v-5.5l5.5,5.5H14.182z"
    />
  </svg>
);

export const AnyFileIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M16.5 6h1.5v11.484c0 3.047-2.438 5.531-5.484 5.531s-5.531-2.484-5.531-5.531v-12.469c0-2.203 1.828-4.031 4.031-4.031s3.984 1.828 3.984 4.031v10.5c0 1.359-1.125 2.484-2.484 2.484s-2.531-1.125-2.531-2.484v-9.516h1.5v9.516c0 0.563 0.469 0.984 1.031 0.984s0.984-0.422 0.984-0.984v-10.5c0-1.359-1.125-2.531-2.484-2.531s-2.531 1.172-2.531 2.531v12.469c0 2.203 1.828 4.031 4.031 4.031s3.984-1.828 3.984-4.031v-11.484z" />
  </svg>
);

export const VideoFileIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M15.984 11.016l-6.984 3.984v-8.016zM21 17.016v-12h-18v12h18zM21 3c1.078 0 2.016 0.891 2.016 2.016l-0.047 12c0 1.078-0.891 1.969-1.969 1.969h-5.016v2.016h-7.969v-2.016h-5.016c-1.125 0-2.016-0.891-2.016-1.969v-12c0-1.125 0.891-2.016 2.016-2.016h18z" />
  </svg>
);

export const ImageFileIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M8.484 13.5l-3.469 4.5h13.969l-4.5-6-3.469 4.5zM21 18.984c0 1.078-0.938 2.016-2.016 2.016h-13.969c-1.078 0-2.016-0.938-2.016-2.016v-13.969c0-1.078 0.938-2.016 2.016-2.016h13.969c1.078 0 2.016 0.938 2.016 2.016v13.969z" />
  </svg>
);

export const PdfFileIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M14.016 11.484v-3h0.984v3h-0.984zM3.984 6v14.016h14.016v1.969h-14.016c-1.078 0-1.969-0.891-1.969-1.969v-14.016h1.969zM9 9.516v-1.031h0.984v1.031h-0.984zM20.484 8.484v-1.5h-3v6h1.5v-1.969h1.5v-1.5h-1.5v-1.031h1.5zM16.5 11.484v-3c0-0.844-0.656-1.5-1.5-1.5h-2.484v6h2.484c0.844 0 1.5-0.656 1.5-1.5zM11.484 9.516v-1.031c0-0.844-0.656-1.5-1.5-1.5h-2.484v6h1.5v-1.969h0.984c0.844 0 1.5-0.656 1.5-1.5zM20.016 2.016c1.078 0 1.969 0.891 1.969 1.969v12c0 1.078-0.891 2.016-1.969 2.016h-12c-1.078 0-2.016-0.938-2.016-2.016v-12c0-1.078 0.938-1.969 2.016-1.969h12z" />
  </svg>
);

export const AudioFileIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M14.016 3.234c4.031 0.891 6.984 4.5 6.984 8.766s-2.953 7.875-6.984 8.766v-2.063c2.906-0.844 4.969-3.516 4.969-6.703s-2.063-5.859-4.969-6.703v-2.063zM16.5 12c0 1.781-0.984 3.281-2.484 4.031v-8.063c1.5 0.75 2.484 2.25 2.484 4.031zM3 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6z" />
  </svg>
);

export const DownloadIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M5.016 18h13.969v2.016h-13.969v-2.016zM18.984 9l-6.984 6.984-6.984-6.984h3.984v-6h6v6h3.984z" />
  </svg>
);

// This is actually `public` icon
export const PublicIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      fill="#454545"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"
    />
  </svg>
);

export const DoneIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
  </svg>
);

export const BookedIcon = createIconComponent(
  <svg viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 4h5v8l-2.5-1.5L6 12V4z" />
  </svg>
);
