import * as React from 'react';
import moment from 'moment';

import DayPicker from 'react-day-picker';
import Calendar from 'rc-calendar';

const MONDAY_DAYN = 1;

// type CalendarDayPickerProps = {|
//   selectedDate: Date,
//   onSelect: Date => void,
// |};

export function CalendarDayPicker(props) {
  return (
    <DayPicker
      firstDayOfWeek={MONDAY_DAYN}
      selectedDays={props.selectedDate}
      month={props.selectedDate}
      onDayClick={(day) => {
        props.onSelect(day);
      }}
    />
  );
}

export function CalendarDateInput(props) {
  const defaultValue = props.defaultDate ? moment(props.defaultDate) : moment();

  const onSelect = (momentDate) => {
    // We receive it in momentDate if selection has been made or null
    // on clear.
    props.onSelect((momentDate && momentDate.toDate()) || null);
  };

  return (
    <Calendar
      showDateInput={false}
      showToday={false}
      onSelect={onSelect}
      style={{ zIndex: 1000 }}
      defaultValue={defaultValue}
    />
  );
}
