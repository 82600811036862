import * as React from 'react';

import { cx } from '@whysReact';

import Tooltip from '@utilComponents/Tooltip';
import Closeable from '@utilComponents/Closeable';
import { CloseIconButton } from '@oldUI/buttons';

import * as tutoUI from '@oldUI';

import design from './dialogs.module.css';

export function CloseableTutoTooltip(props) {
  const { closeOnRight, onClose } = props;

  const closeableOverlay = (
    <Closeable closeOnEsc closeOnOutsideClick onClose={onClose} clickPriority>
      <div
        className={cx(design.CloseableTooltip, {
          [design.WithCloseOnRight]: closeOnRight,
        })}
      >
        <CloseIconButton onClick={onClose} className={design.CloseButton} />
        <tutoUI.PaddedBlock all>{props.overlay}</tutoUI.PaddedBlock>
      </div>
    </Closeable>
  );

  const tooltipProps = {
    placement: props.placement,
    visible: props.visible,
    children: props.children,
  };

  return <Tooltip {...tooltipProps} destroyTooltipOnHide overlay={closeableOverlay} />;
}
