import React from 'react';

// New Material UI
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

const StyledFormControl = withStyles({
  root: {
    width: '100%',
  },
})(FormControl);

const StyledInputLabel = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.3)',
    fontSize: 16,
  },
  focused: {
    color: 'rgb(107, 205, 100) !important',
  },
})(InputLabel);

const StyledSelect = withStyles({
  select: {
    fontSize: 16,
  },
})(Select);

const StyledInput = withStyles({
  root: {
    '&:before': {
      borderColor: 'rgb(224, 224, 224) !important',
    },
    '&:after': {
      borderColor: 'rgb(107, 205, 100)',
    },
  },
})(Input);

const StyledMenuItem = withStyles({
  root: {
    fontSize: 16,
  },
})(MenuItem);

const MultipleSelectField = ({
  error,
  label,
  value,
  onChange,
  name,
  disabled,
  values,
  shouldUseValue,
  renderMenuItemChildren,
}) => {
  return (
    <StyledFormControl error={error}>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledSelect
        value={value}
        onChange={onChange}
        name={name}
        multiple
        input={<StyledInput />}
        disabled={disabled}
        renderValue={(selected) => {
          const optionsLeft = values.filter((_, index) => selected.indexOf(index) > -1);
          return optionsLeft.join(', ');
        }}
      >
        {values.map((value, index) => (
          <StyledMenuItem key={value} value={index}>
            {renderMenuItemChildren(value, index)}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {error && <FormHelperText>Povinné pole</FormHelperText>}
    </StyledFormControl>
  );
};

export default MultipleSelectField;
