import React, { Component } from 'react';

const ESC_KEY = 27;
const ENTER_KEY = 13;

function noop() {}

class GenericKeyUp extends Component {
  constructor(props) {
    super(props);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyUp, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp, true);
  }

  onKeyUp(e) {
    const { ctrlKey } = this.props;

    // if ctrlKey prop is true, the modifier must have been used
    if (ctrlKey && !e.ctrlKey) {
      return;
    }

    if (e.which === this.props.keyCode) {
      if (this.props.anywhere) {
        this.props.onKeyUp(e);
      } else if (this.containerEl.contains(e.target)) {
        this.props.onKeyUp(e);
      }
    }
  }

  render() {
    const restProps = Object.assign({}, this.props);
    delete restProps.onKeyUp;
    delete restProps.keyCode;
    delete restProps.anywhere;
    delete restProps.ctrlKey;

    return (
      <span
        ref={(ref) => {
          this.containerEl = ref;
        }}
        {...restProps}
      />
    );
  }
}

export default GenericKeyUp;

export function EscapeKeyUp(props) {
  return <GenericKeyUp anywhere keyCode={ESC_KEY} {...props} />;
}

export function EnterKeyUp(props) {
  return <GenericKeyUp keyCode={ENTER_KEY} {...props} />;
}

export function CommonKeysUp(props) {
  const { onEnter, onEscape, children } = props;
  return (
    <EscapeKeyUp onKeyUp={onEscape || noop}>
      <EnterKeyUp onKeyUp={onEnter || noop}>{children}</EnterKeyUp>
    </EscapeKeyUp>
  );
}
