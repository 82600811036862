import React, { useContext } from 'react';
import cn from 'classnames';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import { TodayIcon, ArrowNextIcon, ArrowBackIcon } from '@utilComponents/icons';
import { IconButton } from '@oldUI/buttons';

import { UserContext, RoomsContext, setCurrentRoom } from '@utils';

import styles from './CalendarToolbar.module.css';

const navigateDict = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
};

const defaultMessages = {
  date: 'Date',
  time: 'Time',
  event: 'Event',
  allDay: 'all day',
  week: 'week',
  day: 'day',
  month: 'month',
  previous: 'back',
  next: 'next',
  yesterday: 'yesterday',
  tomorrow: 'tomorrow',
  today: 'today',
  agenda: 'agenda',
  showMore: (total) => `+${total} more`,
};

const createMessages = (msgs) => {
  return {
    ...defaultMessages,
    ...msgs,
  };
};

const StyledFormControl = withStyles({
  root: {
    position: 'absolute',
    right: 20,
    width: '200px',
  },
})(FormControl);

const CalendarToolbar = (props) => {
  const {
    state: { user },
  } = useContext(UserContext);
  const {
    state: { hasFetched, currentRoom, rooms },
    dispatch,
  } = useContext(RoomsContext);

  const navigate = (action) => {
    props.onNavigate(action);
  };

  // const viewNamesGroup = (messages) => {
  //   const viewNames = props.views;
  //   const view = props.view;
  //
  //   if (viewNames.length > 1) {
  //     return viewNames.map((name) => (
  //       <button
  //         type="button"
  //         key={name}
  //         className={cn({ 'rbc-active': view === name })}
  //         onClick={() => props.onViewChange(name)}
  //       >
  //         {messages[name]}
  //       </button>
  //     ));
  //   }
  //   return null;
  // };

  const messages = createMessages(props.messages);
  const mkOnNavigate = (direction) => () => {
    navigate(direction);
  };

  let availableRooms = [...rooms];

  if (user && user.userType === 'employee') {
    const availableRoomIds = availableRooms.map((_) => parseInt(_.id));

    user.userExcludedRooms.forEach((excludedRoomId) => {
      const targetRoomIndex = availableRoomIds.indexOf(excludedRoomId);
      if (targetRoomIndex > -1) {
        availableRooms.splice(targetRoomIndex, 1);
        availableRoomIds.splice(targetRoomIndex, 1);
      }
    });
  }

  // Sort alphabetically
  availableRooms.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <IconButton
          icon={<TodayIcon inline className={styles.TodayIcon} />}
          onClick={mkOnNavigate(navigateDict.TODAY)}
          children={messages.today}
        />

        <IconButton
          onClick={mkOnNavigate(navigateDict.PREVIOUS)}
          icon={<ArrowBackIcon inline className={styles.BackIcon} />}
          children={messages.previous}
        />

        <IconButton
          onClick={mkOnNavigate(navigateDict.NEXT)}
          pos="right"
          icon={<ArrowNextIcon inline className={styles.NextIcon} />}
          children={messages.next}
        />
      </span>

      <span className="rbc-toolbar-label">{props.label}</span>

      {/* <span className="rbc-btn-group">{viewNamesGroup(messages)}</span> */}

      {hasFetched && rooms && (
        <StyledFormControl>
          <InputLabel>Místnost</InputLabel>
          <Select
            value={currentRoom}
            onChange={(event) => {
              dispatch(setCurrentRoom(event.target.value));
            }}
          >
            {availableRooms.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
            {/* <MenuItem key={0} value={0}>Všechny místnosti</MenuItem> */}
          </Select>
        </StyledFormControl>
      )}
    </div>
  );
};

export default CalendarToolbar;
