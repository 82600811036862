const parseTrainingFormFloat = (val) => {
  if (isNaN(parseFloat(val))) return '';
  else return parseFloat(val);
};

export default (data) => {
  const clientData = parseClientData(data.client_data);

  const entryForm = parseEntryFormData(data.entry_form);

  const attachments = parseAttachmentsData(data.attachments);

  const healthQuestionnaire = parseHealthQuestionnaireData(data.health_questionnaire);

  const trainingForms = parseTrainingFormsData(data.training_forms);

  const senaptecForms = parseSenaptecFormsData(data.senaptec_forms);

  return {
    dataFetched: true,
    profession: data.profession && data.profession.name,
    clientData,
    entryForm,
    attachments,
    healthQuestionnaire,
    trainingForms,
    senaptecForms,
  };
};

export const parseClientData = (data) => {
  if (!data) {
    return {};
  }

  const { email, name, date_of_birth, residence, zip_code, phone } = data;

  return {
    email: email || '',
    name: name || '',
    date_of_birth: date_of_birth || '',
    residence: residence || '',
    zip_code: zip_code || '',
    phone: phone || '',
  };
};

export const parseEntryFormData = (data) => {
  if (!data) {
    return null;
  }

  const {
    dominance_motoric,
    dominance_sensoric,
    dominance_hand_leg,
    dominance_sports,
    adaptation_adaptation,
    adaptation_note,
    note,
  } = data;

  return {
    dominance: {
      motor: dominance_motoric ? parseInt(dominance_motoric, 10) : '',
      sensory: dominance_sensoric ? parseInt(dominance_sensoric, 10) : '',
      hand: dominance_hand_leg ? parseInt(dominance_hand_leg, 10) : '',
      sport: dominance_sports ? parseInt(dominance_sports, 10) : '',
    },
    adaptation: {
      value: adaptation_adaptation ? parseInt(adaptation_adaptation, 10) : '',
      note: adaptation_note || '',
    },
    note: note || '',
  };
};

export const parseAttachmentsData = (data) => {
  if (!data) {
    return null;
  }

  let attachments = [];

  data.forEach((attachment) => {
    const { created_on, id, name, file_size, file_type, uploaded_file } = attachment;

    attachments.push({
      createdOn: created_on,
      id,
      name,
      size: file_size,
      type: file_type,
      url: '/media/' + uploaded_file,
    });
  });

  return attachments;
};

export const parseHealthQuestionnaireData = (data) => {
  if (!data) {
    return null;
  }

  return {
    familyProblems: data.family_problems,
    wearsGlasses: data.wears_glasses,
    whatGlasses: data.what_glasses,
    wearsContactLenses: data.wears_contact_lenses,
    whatContactLenses: data.what_contact_lenses,
    hasHeadaches: data.has_headaches,
    whatHeadaches: data.what_headaches,
    // usesArtificialTears: data.uses_artificial_tears,
    // whatArtificialTears: data.what_artificial_tears,
    takesAntibiotics: data.takes_antibiotics,
    whatAntibiotics: data.what_antibiotics,
    hasEyeInflammation: data.has_eye_inflammation,
    whatEyeInflammation: data.what_eye_inflammation,
    middleEarProblems: data.middle_ear_problems,
    hasBalanceProblems: data.has_balance_problems,
    hasDizziness: data.has_dizziness,
    hasHighBloodPressure: data.has_high_blood_pressure,
    bloodPressureMedicine: data.blood_pressure_medicine,
    diabetesMedicine: data.diabetes_medicine,
    otherProblems: data.other_problems,
    pharmacologicalMedicalAnalysis: data.pharmacological_medical_analysis,
    pastEyeOperations: data.past_eye_operations,
    pastAccidents: data.past_accidents,
    pastHospitalizations: data.past_hospitalizations,
    watchedBySpecialist: data.watched_by_specialist,
    allergies: data.allergies,
    somethingElse: data.something_else,
  };
};

export const defaultMeasurementData = {
  SPH_right: '',
  CYL_right: '',
  AX_right: '',
  ADD_right: '',
  PRIS1_right: '',
  BAZE1_right: '',
  PRIS2_right: '',
  BAZE2_right: '',
  PD_right: '',
  PACHY_right: '',
  TONO_right: '',
  SPH_left: '',
  CYL_left: '',
  AX_left: '',
  ADD_left: '',
  PRIS1_left: '',
  BAZE1_left: '',
  PRIS2_left: '',
  BAZE2_left: '',
  PD_left: '',
  PACHY_left: '',
  TONO_left: '',
};

export const defaultTrainingFormData = {
  initialData: {
    initialMeasurement: {
      ...defaultMeasurementData,
    },
    obruba: '',
    skloCockaRight: '',
    skloCockaLeft: '',
  },
  acuity: {
    0: {
      measurement: {
        ...defaultMeasurementData,
      },
      rightValue: '',
      rightDiopter: '',
      leftValue: '',
      leftDiopter: '',
      binoValue: '',
      binoDiopter: '',
      correction: false,
    },
  },
  motility: {
    right: null,
    rightProblems: [],
    left: null,
    leftProblems: [],
  },
  sharpness: { value: '', correction: false },
  convergence: {
    0: { bifurcation: '', connection: '', note: '', correction: false },
  },
  flexibility: {
    0: { value: '', correction: false },
  },
  accommodation: {
    0: { left: '', right: '', bino: '', correction: false },
  },
  innerMuscles: {
    blurDistance: '',
    blurClose: '',
    bifurcationDistance: '',
    bifurcationClose: '',
    connectionDistance: '',
    connectionClose: '',
    correction: false,
  },
  outerMuscles: {
    bifurcationDistance: '',
    bifurcationClose: '',
    connectionDistance: '',
    connectionClose: '',
    correction: false,
  },
};

export const parseTrainingFormsData = (data) => {
  if (!data) {
    return [];
  }

  let trainingForms = [];

  data.forEach((trainingForm, i) => {
    const {
      lesson,
      initial_measurement,
      obruba,
      sklo_cocka_right,
      sklo_cocka_left,
      acuity_measurements,
      acuity_measurement_ids,
      acuity_strabismus,
      acuity_right_value,
      acuity_right_diopter,
      acuity_left_value,
      acuity_left_diopter,
      acuity_bino_value,
      acuity_bino_diopter,
      acuity_correction,
      motility_right,
      motility_right_problems,
      motility_left,
      motility_left_problems,
      sharpness_value,
      sharpness_correction,
      convergence_bifurcation,
      convergence_connection,
      convergence_note,
      convergence_correction,
      flexibility_value,
      flexibility_correction,
      accommodation_left,
      accommodation_right,
      accommodation_bino,
      accommodation_correction,
      ac_ratio,
      inner_muscles_blur_distance,
      inner_muscles_blur_close,
      inner_muscles_bifurcation_distance,
      inner_muscles_bifurcation_close,
      inner_muscles_connection_distance,
      inner_muscles_connection_close,
      inner_muscles_correction,
      outer_muscles_bifurcation_distance,
      outer_muscles_bifurcation_close,
      outer_muscles_connection_distance,
      outer_muscles_connection_close,
      outer_muscles_correction,
      neurotracker,
      note,
    } = trainingForm;

    let operableTrainingForm = JSON.parse(JSON.stringify(defaultTrainingFormData));

    let {
      initialData,
      acuity,
      motility,
      sharpness,
      convergence,
      flexibility,
      accommodation,
      innerMuscles,
      outerMuscles,
    } = operableTrainingForm;

    initialData = {
      initialMeasurement: initial_measurement || {},
      obruba: obruba || '',
      skloCockaRight: sklo_cocka_right || '',
      skloCockaLeft: sklo_cocka_left || '',
    };

    if (acuity_right_value) {
      acuity_right_value.forEach((a, i) => {
        acuity[i] = {
          measurement: (() => {
            const targetId = acuity_measurement_ids && acuity_measurement_ids[i];
            if (targetId) {
              const possible_measurements = acuity_measurements.filter((_) => _.id === targetId);
              if (possible_measurements) {
                return possible_measurements[0];
              }
            }
            return {};
          })(),
          rightValue: parseTrainingFormFloat(acuity_right_value[i]),
          rightDiopter: acuity_right_diopter[i] || '',
          leftValue: parseTrainingFormFloat(acuity_left_value[i]),
          leftDiopter: acuity_left_diopter[i] || '',
          binoValue: parseTrainingFormFloat(acuity_bino_value[i]),
          binoDiopter: acuity_bino_diopter[i] || '',
          correction: typeof acuity_correction[i] === 'boolean' ? acuity_correction[i] : true,
        };
      });
    }

    motility = {
      right: motility_right ? parseInt(motility_right - 1, 10) : '',
      rightProblems: motility_right_problems || [],
      left: motility_left ? parseInt(motility_left - 1, 10) : '',
      leftProblems: motility_left_problems || [],
    };

    sharpness = {
      value: parseTrainingFormFloat(sharpness_value, 10),
      correction: sharpness_correction || false,
    };

    if (convergence_bifurcation) {
      convergence_bifurcation.forEach((c, i) => {
        convergence[i] = {
          bifurcation: parseTrainingFormFloat(convergence_bifurcation[i]),
          connection: parseTrainingFormFloat(convergence_connection[i]),
          note: convergence_note[i] || '',
          correction:
            typeof convergence_correction[i] === 'boolean' ? convergence_correction[i] : true,
        };
      });
    }

    if (flexibility_value) {
      flexibility_value.forEach((f, i) => {
        flexibility[i] = {
          value: parseTrainingFormFloat(flexibility_value[i]),
          correction:
            typeof flexibility_correction[i] === 'boolean' ? flexibility_correction[i] : true,
        };
      });
    }

    if (accommodation_left) {
      accommodation_left.forEach((a, i) => {
        accommodation[i] = {
          left: parseTrainingFormFloat(accommodation_left[i]),
          right: parseTrainingFormFloat(accommodation_right[i]),
          bino: parseTrainingFormFloat(accommodation_bino[i]),
          correction:
            typeof accommodation_correction[i] === 'boolean' ? accommodation_correction[i] : true,
        };
      });
    }

    innerMuscles = {
      blurDistance: parseTrainingFormFloat(inner_muscles_blur_distance),
      blurClose: parseTrainingFormFloat(inner_muscles_blur_close),
      bifurcationDistance: parseTrainingFormFloat(inner_muscles_bifurcation_distance),
      bifurcationClose: parseTrainingFormFloat(inner_muscles_bifurcation_close),
      connectionDistance: parseTrainingFormFloat(inner_muscles_connection_distance),
      connectionClose: parseTrainingFormFloat(inner_muscles_connection_close),
      correction: inner_muscles_correction || false,
    };

    outerMuscles = {
      bifurcationDistance: parseTrainingFormFloat(outer_muscles_bifurcation_distance),
      bifurcationClose: parseTrainingFormFloat(outer_muscles_bifurcation_close),
      connectionDistance: parseTrainingFormFloat(outer_muscles_connection_distance),
      connectionClose: parseTrainingFormFloat(outer_muscles_connection_close),
      correction: outer_muscles_correction || false,
    };

    trainingForms.push({
      actualDate: lesson.actual_date,
      lessonId: parseInt(lesson.id, 10),
      initialData,
      acuityStrabismus: acuity_strabismus || false,
      acuity,
      motility,
      sharpness,
      convergence,
      flexibility,
      accommodation,
      acRatio: ac_ratio,
      innerMuscles,
      outerMuscles,
      neurotracker: neurotracker || '',
      note: note || '',
    });
  });

  return trainingForms.sort((a, b) => new Date(b.actualDate) - new Date(a.actualDate));
};

export const defaultSenaptecFormData = {
  visualClarityRight: '',
  visualClarityLeft: '',
  visualClarityBoth: '',
  contractSensitivity: '',
  depthPerceptionRight: '',
  depthPerceptionLeft: '',
  depthPerceptionPrimary: '',
  nearFarQuickness: '',
  perceptionSpan: '',
  multiobjectTracking: '',
  reactionTime: '',
};

export const parseSenaptecFormsData = (data) => {
  if (!data) {
    return [];
  }

  let senaptecForms = [];

  data.forEach((senaptecForm, i) => {
    senaptecForms.push({
      actualDate: senaptecForm.lesson.actual_date,
      lessonId: parseInt(senaptecForm.lesson.id, 10),
      visualClarityRight: senaptecForm.visual_clarity_right,
      visualClarityLeft: senaptecForm.visual_clarity_left,
      visualClarityBoth: senaptecForm.visual_clarity_both,
      contractSensitivity: senaptecForm.contract_sensitivity,
      depthPerceptionRight: senaptecForm.depth_perception_right,
      depthPerceptionLeft: senaptecForm.depth_perception_left,
      depthPerceptionPrimary: senaptecForm.depth_perception_primary,
      nearFarQuickness: senaptecForm.near_far_quickness,
      perceptionSpan: senaptecForm.perception_span,
      multiobjectTracking: senaptecForm.multiobject_tracking,
      reactionTime: senaptecForm.reaction_time,
    });
  });

  return senaptecForms.sort((a, b) => new Date(b.actualDate) - new Date(a.actualDate));
};
