import * as React from 'react';

export function NoTextLoader() {
  return <div />;
}

export function SubtleLoader({ text }) {
  return <div>{text}</div>;
}

SubtleLoader.defaultProps = {
  text: 'Loading',
};
