import * as React from 'react';

// A set of applied UI components with state information.
// For consistency purposes.

class DelayedVisibility extends React.Component {
  /*
   * This component hides a `children` prop for given `delay` prop time. Its useful if
   * you want to show some info later but render it right aways because of spacing (no jumps
   * after new width/height).
   */

  constructor(props) {
    super(props);

    this.state = { shouldRender: false };
  }

  componentDidMount() {
    this.delayTimerId = setTimeout(() => {
      this.setState({ shouldRender: true });
    }, this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.delayTimerId);
  }

  render() {
    const { children } = this.props;
    if (this.state.shouldRender) {
      return children;
    }

    const clonedProps = {
      ...children.props,
      style: { visibility: 'hidden' },
    };

    return React.cloneElement(children, clonedProps, clonedProps.children);
  }
}

export default DelayedVisibility;
