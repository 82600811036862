import * as React from 'react';

import TextareaAutosize from 'react-autosize-textarea';

import { EditIcon } from '@utilComponents/icons';
import { placeCursorAtEnd } from '@oldUtils/domHelpers';

import editableTextDefaultStyle from './inputs.editableText.default.module.css';

import styles from './inputs.module.css';

import EditableText from '@utilComponents/EditableText';

import RequiredInput from '@utilComponents/RequiredInput';

export function EditableTextDefault(props) {
  const css = {
    // leverage same names
    ...editableTextDefaultStyle,

    // No need to style valid input, its just a text
    // ValidTextInput: '',
    InvalidTextInput: 'InvalidInput',
  };
  const passProps = { editMsg: 'Edit!!', ...props };
  const editIcon = <EditIcon inline />;
  return <EditableText {...passProps} css={css} editIcon={editIcon} editIconOnOver />;
}

export function StyledRequiredInput(props) {
  const css = {
    RequiredInput: props.className,
    InvalidInput: props.className,
    ValidInput: 'ValidInput',
  };
  return <RequiredInput {...props} css={css} />;
}

export class TutoTextarea extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      const textareaEl = this.textareaAutosize.textarea;
      // Undocummented property, test it first
      if (textareaEl && textareaEl.focus) {
        textareaEl.focus();
      }
    }
  }

  onChange(e) {
    const { onChange } = this.props;
    onChange(e.target.value);
  }

  render() {
    const { rows, ...restProps } = this.props;

    return (
      <TextareaAutosize
        {...restProps}
        ref={(component) => {
          this.textareaAutosize = component;
        }}
        onChange={this.onChange}
        className={styles.TutoTextarea}
        rows={Number(rows)}
        onFocus={(e) => {
          placeCursorAtEnd(e.target);
        }}
      />
    );
  }
}
