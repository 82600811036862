import moment from 'moment';

if (!Date.prototype.toISOString) {
  (function () {
    function pad(number) {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    }

    Date.prototype.toISOString = function () {
      return (
        this.getUTCFullYear() +
        '-' +
        pad(this.getUTCMonth() + 1) +
        '-' +
        pad(this.getUTCDate()) +
        'T' +
        pad(this.getUTCHours()) +
        ':' +
        pad(this.getUTCMinutes()) +
        ':' +
        pad(this.getUTCSeconds()) +
        '.' +
        (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
        'Z'
      );
    };
  })();
}

// returns a hour string e.g. '12:30'
// (date: Date): string
export function toHourString(date) {
  return moment(date.toISOString()).format('HH:mm');
}

// returns [HH, mm]
// for '12:30' e.g. [12, 30]
// (date: string): [string, string]
export function fromHourString(date) {
  return date.split(':');
}

// dateStr - ISO stringified date
export function parseDate(dateStr) {
  return moment(dateStr).toDate();
}

// dateStr - just YYYY-MM-DD e.g. 2017-01-25
export function parseSimpleDate(dateStr) {
  return moment(dateStr).toDate();
}

// // timeStr - either with second '02:30:00' or without '02:30'
// // returns the time string without seconds
// export function consolidateTime(timeStr/* : string */) /* : string */ {
//   return moment(timeStr, ['hh:mm:ss', 'hh:mm']).format('hh:mm');
// }

// returns string with only date (no time)
// in format 2017-01-25
export function strSimpleDate(date) {
  return moment(date.toISOString()).format('Y-MM-DD');
}

// 25. 6. 2002
export function strFormatDate(date) {
  return moment(parseDate(date).toISOString()).format('DD. MM. Y');
}

// returns string with only time
// in format 20:30
export function stringifyTime(date) {
  return moment(parseDate(date).toISOString()).format('HH:mm');
}

function timeStringToTimeTuple(timeString) {
  return timeString.split(':').map((s) => Number(s));
}

// Returns duration for the moment package in format 20:30
// TUTO_NOTE_EXPORTED_ONLY_FOR_TESTS
export function createTimeTuple(laterDate, earlierDate) {
  const [hoursLater, minutesLater] = timeStringToTimeTuple(stringifyTime(laterDate));
  const [hoursEarlier, minutesEarlier] = timeStringToTimeTuple(stringifyTime(earlierDate));

  let minutes = minutesLater - minutesEarlier;
  let hours = hoursLater - hoursEarlier;

  if (minutes < 0) {
    minutes = 60 + minutes;
    hours -= 1;
  }
  return [hours, minutes];
}

// TUTO_PROTOTYPE: commented out, remove later if not needed
// // laterDate - earlierDate
// // (laterDate: Date, earlierDate: Date): Date
// function substractDate(laterDate/* : Date */, earlierDate/* : Date */) /* : Date */ {
//   const momentDate = moment(laterDate.toISOString());

//   const [hours, minutes] = createTimeTuple(laterDate, earlierDate);

//   momentDate.subtract('hours', hours);
//   momentDate.subtract('minute', minutes);

//   return momentDate.toDate();
// }

// 1 => '01'
// 12 => '12'
function prependZeroToLowNum(num) {
  return num <= 9 ? `0${num}` : String(num);
}

function formatTimeFromTuple(timeTuple) {
  const [hours, minutes] = timeTuple;
  return `${prependZeroToLowNum(hours)}:${prependZeroToLowNum(minutes)}`;
}

// laterDate - earlierDate
export function createDurationFromDates(laterDate, earlierDate) {
  const timeTuple = createTimeTuple(laterDate, earlierDate);
  return formatTimeFromTuple(timeTuple);
}

// duration should be in format 02:30 for 2 hours and 30 min
// (date: Date, duration: string): Date
export function addDuration(date, duration) {
  const newDate = moment(date.toISOString());
  newDate.add(moment.duration(duration));
  return newDate.toDate();
}
