import React from 'react';
import Waypoint from 'react-waypoint';

import { getIconForUserType } from '@oldComponents/componentHelpers';
import SearchForm from '@oldComponents/SearchForm';
import AppPage from '@oldComponents/AppPage';
import NoContentPage from '@oldComponents/NoContentPage';
import { MediaFilterSelect, PreviewFile } from '@oldComponents/filesComponents';

import * as tutoUI from '@oldUI';
import SimpleTable from '@oldUI/SimpleTable';
import { HighlightedText } from '@oldUI/stylePrimitives';
import { PrimaryBtn, InvisibleButton } from '@oldUI/buttons';
import { NormalAndRelativeDate } from '@oldUI/intlFormatters';

import { withActiveItem, compose } from '@whysReact/hocs';

import { StopClick } from '@utilComponents/clickHelpers';
import { FlexBlock } from '@utilComponents/layoutPrimitives';
import { TrashIcon, DownloadIcon } from '@utilComponents/icons';

import styles from './FileManager.module.css';

const uppyTriggerClass = 'upload-file-btn';

function getUserFileType(filetype) {
  const [type, subtype] = filetype.split('/');
  if (['audio', 'video', 'text', 'image'].includes(type)) {
    return type;
  }

  if (subtype === 'pdf') {
    return 'pdf';
  }

  return 'a custom file';
}

function hasPreview(attachment) {
  const userType = getUserFileType(attachment.type);
  return ['audio', 'video', 'text', 'image', 'pdf'].includes(userType);
}

// type FileManagerProps = {|
//   ...withFilterableFilesProps,
//   ...withUploadableFileProps,
//
//   previewToggle: Function,
//   previewItemActive: any,
// |};
//
// type FileManagerState = {|
//   shouldInjectUppy: boolean,
// |};

class FileManager extends React.Component {
  /*
  TUTO_NOTE_REINJECT_UPPY: Uppy has no API for close event. When there's an empty state we
  activate uppy on a btn. If new file is added, we must re-initialize uppy on different btn DOM.
  Or if a file exists (no empty state) and all files are deleted, we re-initialize uppy on different
  btn again (empty state is rendered).
  */

  constructor(props) {
    super(props);
    this.defineColumns();
    // this.state = { shouldInjectUppy: false };
  }

  componentDidMount() {
    this.reinjectUppy();
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   // TUTO_NOTE_REINJECT_UPPY: set state if uppy trigger will re-rendered
  //   const fileExistsNow = this.anyFileExists(this.props);
  //   const fileWillExist = this.anyFileExists(nextProps);
  //
  //   if (!fileExistsNow && fileWillExist) {
  //     this.setState({ shouldInjectUppy: true });
  //   } else if (fileExistsNow && !fileWillExist) {
  //     this.setState({ shouldInjectUppy: true });
  //   }
  // }

  componentDidUpdate(prevProps) {
    const { branchId: previousBranchId } = prevProps;

    // TUTO_NOTE_REINJECT_UPPY: restart uppy, it will be triggered with a different DOM
    // const { shouldInjectUppy } = this.state;
    // if (shouldInjectUppy) {
    //   this.reinjectUppy();
    // }

    if (this.props.branchId !== previousBranchId) {
      this.reinjectUppy();
    }
  }

  componentWillUnmount() {
    if (this.closeUppy) {
      this.closeUppy();
    }
  }

  reinjectUppy() {
    document.body.className = ' ';
    const { onInitUppy } = this.props;
    if (this.closeUppy) {
      this.closeUppy();
    }
    setTimeout(() => {
      this.closeUppy = onInitUppy({ selector: uppyTriggerClass });
    }, 500);
  }

  defineColumns() {
    this.columns = [
      {
        title: 'Název',
        key: 'name',
        dataIndex: 'name',
        width: 50,
        render: (value, record, idx) => {
          const file = record;
          const { files, hasMoreFiles } = this.props;

          const userType = getUserFileType(file.type);
          const typeIcon = getIconForUserType(userType)({
            title: userType,
            className: styles.FileTypeIcon,
          });

          return {
            children: (
              <FlexBlock vcenter>
                {typeIcon}
                {hasMoreFiles && files.length - 1 === idx ? (
                  <Waypoint bottomOffset="-200px" onEnter={this.props.onLoadMore} />
                ) : null}
                <HighlightedText isActive={hasPreview(file)}>{file.name}</HighlightedText>
              </FlexBlock>
            ),
          };
        },
      },
      {
        title: 'Nahráno',
        key: 'createdOn',
        dataIndex: 'createdOn',
        width: 30,
        render: (value, record, idx) => ({
          children: <NormalAndRelativeDate date={record.createdOn} />,
        }),
      },
      {
        title: 'Velikost',
        key: 'size',
        dataIndex: 'size',
        width: 10,
        render: (value, record, idx) => ({
          children: <span>{value}</span>,
        }),
      },
      {
        title: '',
        key: 'actionCol',
        width: 10,
        render: (value, record, idx) => {
          return {
            children: <StopClick>{this.renderActions(record)}</StopClick>,
          };
        },
      },
    ];
  }

  onRowClick = (record, idx) => {
    this.props.previewToggle(record);
  };

  onPreviewRender = (record) => {
    const file = record;
    return <PreviewFile file={file} key={`createActiveFileRow-${file.id}`} />;
  };

  createTableRows(files) {
    // withActiveItem('preview')
    const { previewItemActive } = this.props;

    const fileRows = [];
    for (const [, file] of files.entries()) {
      fileRows.push(file);

      const previewMode = file === previewItemActive;
      if (previewMode) {
        const id = `previewedFile-${file.id}`;
        fileRows.push({
          id,
          fullRow: <PreviewFile file={file} key={`createActiveFileRow-${file.id}`} />,
          isActive: true,
        });
      }
    }

    return fileRows;
  }

  anyFileExists(props) {
    const { files, hasAnyFilter } = props;
    if (hasAnyFilter) {
      // This can be false positive, which is better than false negative
      // i.e. we can't know in this component if there is NOT any file if filter
      // is active.
      return true;
    }

    return files.length !== 0;
  }

  renderActions(file) {
    const onDelete = () => {
      this.props.onDelete(file);
    };

    return (
      <FlexBlock vcenter>
        <a target="_blank" rel="noopener noreferrer" download href={file.url}>
          <DownloadIcon className={styles.DownloadFileIcon} title="Stáhnout soubor" />{' '}
        </a>
        <InvisibleButton onClick={onDelete}>
          <TrashIcon className={styles.DeleteFileIcon} title="Smazat" />
        </InvisibleButton>
      </FlexBlock>
    );
  }

  renderUploadBtn() {
    return <PrimaryBtn className={uppyTriggerClass}>Nahrát soubor</PrimaryBtn>;
  }

  render() {
    const { files, hasMoreFiles, filterText, filterType } = this.props;
    const { hasAnyFilter } = this.props;

    const pageHeader = (
      <div className={styles.FileManagerHeader}>
        <div className={styles.SearchState}>
          <SearchForm onFilter={this.props.onTextFilter} filterText={filterText} />
        </div>
        {this.renderUploadBtn()}
      </div>
    );

    const noFileExists = !this.anyFileExists(this.props);
    if (noFileExists) {
      return (
        <NoContentPage
          primaryActionBtn={this.renderUploadBtn()}
          strMessages={{
            msgNoContent: 'Nenahráli jste žádný soubor.',
            msgPrimaryAction: 'Chcete nahrát první?',
          }}
        />
      );
    }

    const { previewItemActive } = this.props;

    return (
      <AppPage header={pageHeader}>
        <MediaFilterSelect filterType={filterType} onFilter={this.props.onTypeFilter} />
        <tutoUI.VSpace large />
        <SimpleTable
          className="FilesTable"
          columns={this.columns}
          data={files}
          rowClassName={(record, index, indent) => {
            return record === this.props.previewItemActive ? 'rc-table-row-hover' : '';
          }}
          expandedRowKeys={previewItemActive ? [previewItemActive.id] : []}
          expandedRowRender={this.onPreviewRender}
          emptyText={
            files.length === 0 && hasAnyFilter
              ? 'Žádný soubor nenalezen.'
              : 'Nenahráli jste žádný soubor.'
          }
          onRowClick={this.onRowClick}
        />
      </AppPage>
    );
  }
}

// FileManager.propTypes = {
//   // FilesContainer
//   hasMoreFiles: PropTypes.bool.isRequired,
//   onLoadMore: PropTypes.func.isRequired,
//   onDelete: PropTypes.func.isRequired,
//
//   // FilterableFilesContainer
//   onInitUppy: PropTypes.func.isRequired,
//
//   files: PropTypes.array.isRequired,
//   searchPending: PropTypes.bool.isRequired,
//   filterText: PropTypes.string.isRequired,
//   filterType: PropTypes.string.isRequired,
//   onTextFilter: PropTypes.func.isRequired,
//   onTypeFilter: PropTypes.func.isRequired,
//   hasAnyFilter: PropTypes.bool.isRequired,
//   hasTypeFilter: PropTypes.bool.isRequired,
//
//   previewToggle: PropTypes.func.isRequired,
//   previewItemActive: PropTypes.any,
// };

export default compose(withActiveItem('preview'))(FileManager);
