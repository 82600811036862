import React, { Fragment } from 'react';
import { message } from 'antd';

import { cx } from '@whysReact';

import AddIcon from 'material-ui/svg-icons/content/add-circle';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import TextField from 'material-ui/TextField';

import { CloseIconButton, InvisibleButton, PrimaryBtn } from '@oldUI/buttons';
import { CalendarDayPicker } from '@oldUI/calendarInputs';

import {
  InvisibleFormDivider,
  EditEventTimes,
  TrainingsChooser,
} from '@oldComponents/eventFormUIs';

import { OutsideClick } from '@utilComponents/clickHelpers';
import { FlexBlock, PaddedBlock } from '@utilComponents/layoutPrimitives';
import { RecurringIcon, OnetimeIcon, DoneIcon } from '@utilComponents/icons';

import { isEmail } from '@oldUtils/validations';
import { strSimpleDate, strFormatDate } from '@oldUtils/dateFormatting';

import { ClientSelect, ReservationStatusInfo } from '@components';

import { request } from '@utils';

export function clientToSelectOption(client) {
  return {
    label: client.name || client.email,
    value: client.id || client.email,
    ...client,
  };
}

// type EventFormProps = {|
//   isEditing: boolean,
//
//   // data
//   eventData: EventDataModel,
//   trainings: { [id: TutoID]: LabelModel },
//
//   onCancel: Function,
//   onDeleteEvent: Function,
//   onSubmit: Function,
//   onCreateReservation: Function,
//   onTrainingChange: Function,
//
//   addClientWithEmail: Function,
//   addClientWithData: Function,
//   removeClient: Function,
// |};

// type EventFormState = {|
//   isInitialized: boolean,
//   datePickActive: boolean,
//   activeToPickActive: boolean,
//   viewMode: 'LANDING_MODE' | 'RESERVATION_MODE' | 'MANUAL_MODE',
// |};

const LANDING_MODE = 'LANDING_MODE';
const RESERVATION_MODE = 'RESERVATION_MODE';
const MANUAL_MODE = 'MANUAL_MODE';

// TUTO_RULE_EVENT_TOOLTIP_CLOSE_OUTSIDE_CLICK
// - Its handled in this component because we must accept outside clicks only
// when the component is mounted and after some timeout.
// - Without timeout outside clicks are fired when selecting slots on calendar.

class EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);

    // TUTO_RULE_EVENT_TOOLTIP_CLOSE_OUTSIDE_CLICK
    this.state = {
      isInitialized: false,
      viewMode: props.isEditing ? MANUAL_MODE : LANDING_MODE,
      datePickActive: false,
      activeToPickActive: false,
      isCreatingClient: false,
      clientEmail: '',
      clientName: '',
      clientPhone: '',
    };
  }

  componentDidMount() {
    // TUTO_RULE_EVENT_TOOLTIP_CLOSE_OUTSIDE_CLICK
    const initializedTimeout = () => {
      this.setState({ isInitialized: true });
    };

    setTimeout(initializedTimeout, 100);
  }

  onSubmit() {
    const forReservation = this.state.viewMode === RESERVATION_MODE;
    this.props.onSubmit({ forReservation });
  }

  onChange(changeset) {
    this.props.onTrainingChange(changeset);
  }

  // TUTO_RULE_EVENT_TOOLTIP_CLOSE_OUTSIDE_CLICK
  onOutsideClick = () => {
    if (this.state.isInitialized) {
      this.props.onCancel();
    }
  };

  isTrainingRequired = () => {
    const { eventData } = this.props;
    const { viewMode } = this.state;
    const isManualMode = viewMode === MANUAL_MODE;
    const hasClients = !!eventData.students.length || eventData.clientData;

    return isManualMode && hasClients && !eventData.trainingId;
  };

  isClientRequired = () => {
    const { eventData } = this.props;
    const { viewMode } = this.state;
    const isManualMode = viewMode === MANUAL_MODE;
    const hasClients = !!eventData.students.length || eventData.clientData;

    return isManualMode && !hasClients;
  };

  renderHasClient() {
    return (
      <div className="PaidInfo">
        <DoneIcon className="PaidInfoIcon" />
        Obsazeno
      </div>
    );
  }

  renderReserved() {
    return (
      <div className="PaidInfo">
        <DoneIcon className="PaidInfoIcon" />
        Zaplacený trénink
      </div>
    );
  }

  renderOnetime() {
    const {
      isEditing,
      eventData: { onetime },
    } = this.props;

    // Just show info
    if (isEditing) {
      return (
        <div>
          <div className="OccurrenceInfo">
            {onetime ? (
              <OnetimeIcon className="OccurrenceIcon" />
            ) : (
              <RecurringIcon className="OccurrenceIcon" />
            )}
            {onetime ? <div>Jednorázová událost</div> : <div>Pravidelná událost</div>}
          </div>
        </div>
      );
    }

    return (
      <div>
        <input
          className="OneTimeMeeting"
          id="OneTimeMeeting"
          type="checkbox"
          onClick={() => {
            this.onChange({ onetime: !onetime });
          }}
          checked={!onetime}
        />
        <label htmlFor="OneTimeMeeting">Pravidelná událost</label>
        {!onetime ? this.renderActiveTo() : null}
      </div>
    );
  }

  renderDeleteAction() {
    const { eventId } = this.props.eventData;

    const onDelete = () => {
      this.props.onDeleteEvent(eventId);
    };
    const btnTitle = 'Smazat událost z tohoto týdne.';
    return (
      <InvisibleButton className="deleteEventBtn" onClick={onDelete} title={btnTitle}>
        Smazat
      </InvisibleButton>
    );
  }

  renderDatePicker() {
    const { start } = this.props.eventData;
    return (
      <FlexBlock column hcenter>
        <span>Vyberte datum</span>
        <CalendarDayPicker
          selectedDate={start}
          onSelect={(newDate) => {
            const newStart = new Date(start);
            newStart.setDate(newDate.getDate());
            newStart.setMonth(newDate.getMonth());
            this.props.onTrainingChange({ start: newStart });
            this.setState({ datePickActive: false });
          }}
        />
      </FlexBlock>
    );
  }

  renderActiveToPicker() {
    const { activeTo } = this.props.eventData;
    return (
      <FlexBlock column hcenter>
        <div>Opakovaná do</div>
        <CalendarDayPicker
          selectedDate={activeTo}
          onSelect={(selectedDate) => {
            this.onChange({ activeTo: selectedDate });
            this.setState({ activeToPickActive: false });
          }}
        />
      </FlexBlock>
    );
  }

  renderActiveTo = () => {
    const {
      eventData: { activeTo },
    } = this.props;

    return (
      <div>
        <span>Opakovaná do</span>
        <InvisibleButton
          onClick={() => {
            this.setState({ activeToPickActive: true });
          }}
        >
          {strFormatDate(activeTo)}
          {/* <FormattedDateDefault value={activeTo} /> */}
        </InvisibleButton>
      </div>
    );
  };

  renderDate() {
    const { isEditing } = this.props;
    if (!isEditing) {
      return null;
    }

    const {
      eventData: { start },
    } = this.props;
    return (
      <div>
        <span>Datum</span>
        <InvisibleButton
          onClick={() => {
            this.setState({ datePickActive: true });
          }}
        >
          {strFormatDate(start)}
          {/* <FormattedDateDefault value={start} /> */}
        </InvisibleButton>
      </div>
    );
  }

  renderTrainings = () => {
    const { eventData, trainings } = this.props;
    const canChooseTraining = Object.keys(trainings).length > 0;
    const lblsEditor = canChooseTraining ? (
      <TrainingsChooser
        defaultTrainingId={eventData.trainingId}
        onChange={(training) => this.onChange({ trainingId: training.id })}
        onSet={(training) => this.onChange({ trainingId: training.id })}
        onReset={() => this.onChange({ trainingId: null })}
        trainings={trainings}
        clearable={true}
      />
    ) : null;
    return lblsEditor;
  };

  renderClients = () => {
    const { branchId, eventData } = this.props;

    if (!this.state.isCreatingClient) {
      const clients = eventData.students.map(clientToSelectOption);
      const onReset = () => clients.forEach((client) => this.props.removeClient(client));

      return (
        <div style={{ position: 'relative' }}>
          <ClientSelect
            branchId={branchId}
            selected={clients[0]}
            onReset={onReset}
            onAdd={(email) => this.props.addClientWithEmail(email)}
            onSelect={(client) => this.props.addClientById(client)}
          />
          <div
            style={{
              cursor: 'pointer',
              position: 'absolute',
              right: 7,
              top: 7,
              zIndex: 10,
            }}
          >
            <AddIcon
              onClick={() => {
                this.setState({ isCreatingClient: true }, () => {
                  onReset();
                });
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <Fragment>
          <div
            style={{
              cursor: 'pointer',
              position: 'absolute',
              right: 7,
              top: 100,
              zIndex: 10,
            }}
          >
            <CloseIcon
              onClick={() => {
                this.setState({ isCreatingClient: false }, () => {
                  this.props.removeClientData();
                });
              }}
            />
          </div>
          <div>
            <TextField
              name="email"
              style={{ height: 50, width: 210 }}
              placeholder="E-mail"
              value={this.state.clientEmail}
              onChange={(event) => {
                const value = event.target.value;
                this.setState({ clientEmail: value }, () => {
                  if (isEmail(this.state.clientEmail)) {
                    this.props.addClientWithData({
                      email: this.state.clientEmail,
                      name: this.state.clientName,
                      phone: this.state.clientPhone,
                    });
                  }
                });
              }}
            />
          </div>
          <div>
            <TextField
              name="name"
              style={{ height: 50, width: 210 }}
              placeholder="Celé jméno"
              value={this.state.clientName}
              onChange={(event) => {
                const value = event.target.value;
                this.setState({ clientName: value }, () => {
                  if (isEmail(this.state.clientEmail)) {
                    this.props.addClientWithData({
                      email: this.state.clientEmail,
                      name: this.state.clientName,
                      phone: this.state.clientPhone,
                    });
                  }
                });
              }}
            />
          </div>
          <div>
            <TextField
              name="phone"
              style={{ height: 50, width: 210 }}
              placeholder="Telefonní číslo"
              value={this.state.clientPhone}
              onChange={(event) => {
                const value = event.target.value;
                this.setState({ clientPhone: value }, () => {
                  if (isEmail(this.state.clientEmail)) {
                    this.props.addClientWithData({
                      email: this.state.clientEmail,
                      name: this.state.clientName,
                      phone: this.state.clientPhone,
                    });
                  }
                });
              }}
            />
          </div>
        </Fragment>
      );
    }
  };

  renderTimes() {
    const { eventData } = this.props;
    const { start, end } = eventData;
    const editTimes = (
      <EditEventTimes
        startAt={start}
        endAt={end}
        onEndChange={(newEnd) => this.onChange({ end: newEnd })}
        onStartChange={(startEnd) => this.onChange({ start: startEnd })}
      />
    );

    return editTimes;
  }

  renderClientRequired() {
    return (
      <FlexBlock width="80%" vcenter hcenter>
        Přidejte klienta
      </FlexBlock>
    );
  }

  renderTrainingRequired() {
    return (
      <FlexBlock width="80%" vcenter hcenter>
        Vyberte vyšetření
      </FlexBlock>
    );
  }

  renderForm() {
    const {
      isEditing,
      // change callbacks
      onCancel,
    } = this.props;

    if (this.state.datePickActive) {
      return this.renderDatePicker();
    }

    if (this.state.activeToPickActive) {
      return this.renderActiveToPicker();
    }

    const { viewMode } = this.state;
    const isReservating = viewMode === RESERVATION_MODE;
    const { eventData } = this.props;
    const { isPaid } = eventData;

    const hasClients = !!eventData.students.length;
    const isManuallyDone = !isPaid && hasClients;
    const clientIsRequired = this.isClientRequired();
    const trainingIsRequired = this.isTrainingRequired();

    return (
      <FlexBlock column>
        <CloseIconButton className="CancelActionBtn" onClick={onCancel} />
        {this.renderTimes()}

        <InvisibleFormDivider />
        {this.renderDate()}

        <InvisibleFormDivider />
        {isReservating ? null : this.renderClients()}

        <InvisibleFormDivider />
        {this.renderTrainings()}

        <InvisibleFormDivider />
        {this.renderOnetime()}
        {isPaid ? this.renderReserved() : null}
        {isManuallyDone && isEditing ? this.renderHasClient() : null}

        <InvisibleFormDivider />
        <div className="SubmitBtns">
          {isEditing && this.props.permissions.allowCalendarWrite
            ? this.renderDeleteAction()
            : null}
          {clientIsRequired ? this.renderClientRequired() : null}
          {trainingIsRequired ? this.renderTrainingRequired() : null}
          <button
            disabled={clientIsRequired || trainingIsRequired}
            className="BtnDefault BtnSmall"
            onClick={this.onSubmit}
          >
            {isEditing ? 'Aktualizovat' : 'Vytvořit'}
          </button>
        </div>
      </FlexBlock>
    );
  }

  renderLandingMode() {
    return (
      <FlexBlock fullWidth column hcenter>
        <PrimaryBtn
          fullWidth
          onClick={() => {
            this.setState({ viewMode: RESERVATION_MODE });
            this.props.onTrainingChange({ forReservation: true });
          }}
        >
          K rezervaci
        </PrimaryBtn>
        <PaddedBlock top tiny />
        <PrimaryBtn
          fullWidth
          onClick={() => {
            this.setState({ viewMode: MANUAL_MODE }, () => {
              // TUTO_NOTE_HACK: This will "force update" the event component and the tooltip will
              // adjust to a new height
              this.props.onTrainingChange({});
            });
          }}
        >
          Obsadit
        </PrimaryBtn>
      </FlexBlock>
    );
  }

  render() {
    const { isEditing, eventData } = this.props;

    const wrapContent = (content) => (
      <OutsideClick onClick={this.onOutsideClick} capture>
        <div className={cx('EventForm', { EventFormEditing: isEditing })}>{content}</div>
      </OutsideClick>
    );

    if (eventData.isReserved || eventData.isOpen) {
      const status = eventData.isReserved ? 'reserved' : 'open';

      const { eventId, reservationId } = eventData;

      const onDelete = () => {
        this.props.onDeleteEvent(eventId);
      };

      const onCancel = () => {
        if (reservationId) {
          request(`/api_classroom/reservations/${reservationId}/cancel`, {
            method: 'PATCH',
          }).then((response) => {
            this.props.onDeleteEvent(eventId);

            // Reduce reservationsCount by 1, manually because of no refs
            const reservationsCount = document.getElementsByClassName('HeaderReservationCount')[0];
            reservationsCount.innerHTML = (
              parseInt(reservationsCount.innerHTML, 10) - 1
            ).toString();
          });
        }
      };

      const onSubmit = () => {
        const { branchId } = this.props;
        const { trainingId, eventId, students, clientData, start } = eventData;
        const createdOn = new Date().toISOString();

        request(`/api_classroom/employee-create-reservation/${branchId}`, {
          method: 'POST',
          data: {
            trainingId: trainingId,
            eventId,
            createdOn,
            clientEmail: students[0].email || (clientData && clientData.email),
            clientName: clientData && clientData.name,
            clientPhone: clientData && clientData.phone,
            start: strSimpleDate(start),
          },
        })
          .then(() => {
            return this.props.onCreateReservation();
          })
          .catch(({ response }) => {
            const { detail } = response.data;
            if (detail) {
              message.error(detail);
            } else {
              message.error('Došlo k chybě');
            }
          });
      };

      return wrapContent(
        <ReservationStatusInfo
          permissions={this.props.permissions}
          status={status}
          onCancel={onCancel}
          onDelete={onDelete}
          renderTrainings={this.renderTrainings}
          renderClients={this.renderClients}
          isTrainingRequired={this.isTrainingRequired()}
          isClientRequired={this.isClientRequired()}
          onSubmit={onSubmit}
        />
      );
    }

    const { viewMode } = this.state;
    if (viewMode === MANUAL_MODE || viewMode === RESERVATION_MODE) {
      return wrapContent(this.renderForm());
    }

    return wrapContent(this.renderLandingMode());
  }
}

export default EventForm;
