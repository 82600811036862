// This is complementary component and code for TutoCalendar component and related
// code. Its know a lot of logic from the calendar.

import * as React from 'react';

import { toHourString } from '@oldUtils/dateFormatting';
import { FlexBlock } from '@oldUI';

import { isInPast } from '@oldUtils/dateQueries';

import {
  BookedIcon,
  RecurringIcon,
  OnetimeIcon,
  PublicIcon,
  DoneIcon,
} from '@utilComponents/icons';

const StudentInfo = ({ clientIsIn, renderNames, students }) => {
  const renderName = (student) => (
    <strong key={student.id || student.email}>{student.name || student.email}</strong>
  );

  if (!renderNames) {
    if (clientIsIn) {
      return <strong>Můj trénink</strong>;
    }
    return <strong>Obsazeno</strong>;
  }

  if (!students.length) {
    // accessing first one under
    return null;
  }

  const firstStudent = students[0];
  if (students.length > 1) {
    const extra = students.length - 1;
    return (
      <strong>
        {renderName(firstStudent)} {`a ${extra} další`}
      </strong>
    );
  }

  return renderName(firstStudent);
};

// time to wait before recognizing normal click and double click
const DOUBLE_CLICK_TIMEOUT = 350;

export function createEventComponent(
  callbacks,
  info // { isEmployee: boolean, isClient: boolean, isPublic: boolean }
) {
  const { isEmployee, isClient, isPublic } = info;

  return class EventComponent extends React.Component {
    // TUTO_NOTE_STOP_PROPAGATION_NO_REASON: stoped propagation because we don't need the
    // event anymore, not for any other specific reason.

    // onMouseEnter: AnyBoundMethod;
    // onMouseLeave: AnyBoundMethod;
    // onMouseOver: AnyBoundMethod;
    // onDraggingDone: AnyBoundMethod;
    // onDoubleClick: AnyBoundMethod;
    // onClick: AnyBoundMethod;
    // onClickTimerId: number;
    // getEditingEventClients: AnyBoundMethod;
    // props: EventComponentProps;

    constructor(props) {
      super(props);

      this.onMouseEnter = this.onMouseEnter.bind(this);
      // We want to use mouseover event when DND is finished (event has been droped)
      // but user want to correct the DND with another dragging.
      this.onMouseOver = this.onMouseEnter;

      this.onMouseLeave = this.onMouseLeave.bind(this);
      this.onDraggingDone = this.onDraggingDone.bind(this);
      this.onDoubleClick = this.onDoubleClick.bind(this);
      this.onClick = this.onClick.bind(this);

      const { getEditingEventClients } = callbacks;

      this.getEditingEventClients = getEditingEventClients;
    }

    // TUTO_RULE_DND_IS_ACTIVATED_ON_MOUSE_OVER_EVENT (enable)
    onMouseEnter(e) {
      callbacks.changeModeToMoving();

      // TUTO_NOTE_STOP_PROPAGATION_NO_REASON
      e.stopPropagation();
    }

    // TUTO_RULE_DND_IS_ACTIVATED_ON_MOUSE_OVER_EVENT (disable)
    onMouseLeave(e) {
      callbacks.changeModeToSelecting();

      // TUTO_NOTE_STOP_PROPAGATION_NO_REASON
      e.stopPropagation();
    }

    onDraggingDone(e) {
      callbacks.changeModeToSelecting();

      // TUTO_NOTE_STOP_PROPAGATION_NO_REASON
      e.stopPropagation();
    }

    onDoubleClick(e) {
      if (this.onClickTimerId) {
        clearTimeout(this.onClickTimerId);
      }

      const { event } = this.props;
      if (event.reservation && event.reservation.status === 'reserved') {
        // TUTO_RULE_DBL_CLICK_ON_RESERVED_EVENT: do it!
        callbacks.openReservation(event.reservation.id);
      } else {
        callbacks.openLessonPane(event);
      }

      // TUTO_NOTE_STOP_PROPAGATION_NO_REASON
      e.stopPropagation();
    }

    onClick(e) {
      const rbcEvent = this.props.event;
      const { isDayView, isViewOnly, start } = rbcEvent;

      if (isViewOnly) {
        if (rbcEvent.isOpenToReservate && !isInPast(start)) {
          callbacks.onReservate(rbcEvent);
        }
      }

      const openOnSingleClick = isDayView;
      if (openOnSingleClick) {
        callbacks.openLessonPane(rbcEvent);
      }

      const onEditDelayed = () => {
        callbacks.onEditEvent(rbcEvent);
      };

      // do not propagate to react-big-calendar handler (it selects the event by default)
      e.stopPropagation();

      if (this.onClickTimerId) {
        clearTimeout(this.onClickTimerId);
      }
      this.onClickTimerId = setTimeout(onEditDelayed, DOUBLE_CLICK_TIMEOUT);
    }

    getTitle() {
      const { start, end, labelName } = this.props.event;

      // title shows time range e.g. 10:00 — 14:00
      // also it shows the label, that is typically one
      const timeSpan = `${toHourString(start)} — ${toHourString(end)}`;
      return `${timeSpan} (${labelName})`;
    }

    renderWho(students) {
      const { event } = this.props;

      // Generic "Reserved" text
      if (
        !event.clientIsIn &&
        !event.clientReserved &&
        (event.isReserved || (!isEmployee && !event.isOpenToReservate))
      ) {
        return <strong>Rezervováno</strong>;
      }

      // Reserved by current student
      const reservationPaid = event.reservation && event.reservation.status !== 'paid';
      if (event.clientReserved && reservationPaid) {
        return <strong>Rezervováno mnou</strong>;
      }

      if (!isEmployee && isInPast(event.start)) {
        return <strong>Rezervováno</strong>;
      }

      const renderPriceOrForRsrv = () => {
        if (event.reservation) {
          if (parseInt(event.reservation.price, 10) || event.price) {
            // For unspecified training we don't want to display price
            let price = null;
            if (typeof event.price !== 'undefined') {
              // Display 2 decimals
              price = event.price.toFixed(2);
            }
            return `${price ? price + ' Kč' : 'Není přiřazen trénink'}`;
          }
        }

        if (event.trainingId !== '0' && event.price === 0) {
          // Event has specified training but costs 0
          return <strong>Zadarmo</strong>;
        }

        return (
          <div className="EventReservateText">
            K rezervaci
            {!isEmployee && <div>{'(Vyberte trénink)'}</div>}
          </div>
        );
      };

      // Its for reservation and price (TUTO_REVIEW: if available)
      if (event.isOpenToReservate) {
        return (
          <div>
            <strong>{renderPriceOrForRsrv()}</strong>
          </div>
        );
      }

      const isClientInOrHasReserved = event.clientIsIn || event.clientReserved;
      return (
        <StudentInfo
          students={students}
          renderNames={isEmployee}
          clientIsIn={isClientInOrHasReserved}
        />
      );
    }

    renderStatusIcon() {
      const { event } = this.props;

      if (event.isOpenToReservate) {
        return <PublicIcon className="EventIcon BookingIcon" title="K rezervaci" />;
      }

      // For public we only need whether its available or not to reserve
      if (isPublic) {
        return null;
      }

      const clientIsIn = event.clientIsIn;
      const clientReserved = event.clientReserved;

      if (clientIsIn || (isEmployee && event.isDone)) {
        return <DoneIcon className="EventIcon DoneIcon" />;
      }

      if (clientReserved || (isEmployee && event.isReserved)) {
        return <BookedIcon className="EventIcon ReservedIcon" />;
      }

      return null;
    }

    renderReservationAction() {
      // This should be rendered for students and on public calendar.
      // The purpose is to tell user the event can be reserved (has an action, should click).
      const { event } = this.props;

      return (
        <FlexBlock
          hcenter
          className="EventReservate"
          style={{ backgroundColor: event.labelColor || '#97c8f2' }}
        >
          <strong>Rezervovat</strong>
        </FlexBlock>
      );
    }

    render() {
      const { event } = this.props;
      const { isEditing, isViewOnly, isOpenToReservate, start } = event;
      const { getEditingEventClients } = this;

      const students = isEditing ? getEditingEventClients() : event.students;

      const title = this.getTitle();

      let eventListeners;
      if (isViewOnly) {
        eventListeners = {
          onClick: this.onClick,
        };
      } else {
        eventListeners = {
          onMouseUp: this.onDraggingDone,
          onMouseEnter: this.onMouseEnter,
          onDoubleClick: this.onDoubleClick,
          onMouseOver: this.onMouseOver,
          onMouseLeave: this.onMouseLeave,
          onClick: this.onClick,
        };
      }

      const occurrenceIcon = event.onetime ? (
        <OnetimeIcon className="EventIcon OccurrenceIcon" title="Jednorázová událost" />
      ) : (
        <RecurringIcon className="EventIcon OccurrenceIcon" title="Pravidelná událost" />
      );

      const eventTitle = (
        <div className="EventActionBtns">
          {occurrenceIcon}
          {this.renderStatusIcon()}
        </div>
      );

      let timeslotClassName = 'TutoTimeslot';
      if (isOpenToReservate && (isPublic || isClient)) {
        timeslotClassName += ' TutoTimeslot--Reserve';
      }

      return (
        <div
          {...eventListeners}
          className={timeslotClassName}
          id={`tutoevent-${event.id}`}
          title={title}
        >
          <div className="EventValues">
            <div className="EventTitle">{eventTitle}</div>
            {isOpenToReservate && event.labelName && !isInPast(event.start) && (
              <p className="EventName">
                {event.labelName} ({toHourString(event.start)})
              </p>
            )}
            <div className="EventStudents">{this.renderWho(students)}</div>
          </div>
          {isOpenToReservate && !isEmployee && !isInPast(start)
            ? this.renderReservationAction()
            : null}
        </div>
      );
    }
  };

  // EventComponent.propTypes = {
  //   // We receive { event, title } from react-big-calendar (we dont need title)
  //   event: PropTypes.object.isRequired,
  //   callbacks: PropTypes.object.isRequired, // from: createEventComponent
  // };
}
