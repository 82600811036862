import React from 'react';
import moment from 'moment';

import styles from './intlFormatters.module.css';

import { strFormatDate } from '@oldUtils/dateFormatting';

export function NormalAndRelativeDate({ date }) {
  // const dateFormatted = <FormattedDateDefault value={date} />;
  // const dateRelativeFormatted = <FormattedRelativeDateDefault value={date} />;

  const dateFormatted = strFormatDate(date);
  const dateRelativeFormatted = moment(date).fromNow();

  return (
    <span className={styles.NormalAndRelativeDate}>
      <div className={styles.NormalDate}>{dateFormatted}</div>
      <span className={styles.RelativeDate}>{dateRelativeFormatted}</span>
    </span>
  );
}
