import React, { useState } from 'react';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';

import PageSubtitle from '@oldComponents/PageSubtitle';
import LogoutLink from '@oldComponents/LogoutLink';

import * as tutoUI from '@oldUI';

import { ChangePasswordForm } from './commonUI';

const notNilVal = (val, defaultVal) => (val !== undefined && val !== null ? val : defaultVal);

const BranchEmployeeProfile = ({ user, onUserChangesSubmit, onPasswordSubmit }) => {
  const [userChanges, setUserChanges] = useState({});

  const onUserChange = (key) => (e, value) => {
    setUserChanges({ ...userChanges, [key]: value });
  };

  const anyUserChanges = !!Object.keys(userChanges).length;

  return (
    <div>
      <PageSubtitle>Osobní informace</PageSubtitle>
      <div>
        <div>
          <TextField
            name="name"
            value={notNilVal(userChanges.name, user.name)}
            onChange={onUserChange('name')}
            errorText={''}
            floatingLabelText="Celé jméno *"
            style={{ marginBottom: 14, width: 400 }}
          />
        </div>
        <RaisedButton
          primary
          disabled={!anyUserChanges}
          type="submit"
          onClick={() => {
            onUserChangesSubmit(userChanges).then(() => {
              setUserChanges({});
            });
          }}
          label="Aktualizovat"
        />
      </div>

      <tutoUI.PageSpace double />

      <PageSubtitle>Změna hesla</PageSubtitle>

      <ChangePasswordForm onSubmit={onPasswordSubmit} />

      <tutoUI.PageSpace double />

      <PageSubtitle>Odhlášení</PageSubtitle>
      <LogoutLink />

      <tutoUI.PageSpace double />
    </div>
  );
};

export default BranchEmployeeProfile;
