import calcFunctions from './calcPercentage';

const translations = {
  visualClarityRight: 'Ostrost vidění pravé',
  visualClarityLeft: 'Ostrost vidění levé',
  visualClarityBoth: 'Ostrost vidění bino',
  contractSensitivity: 'Kontrastní citlivost',
  depthPerceptionRight: 'Hloubka ostrosti pravé',
  depthPerceptionLeft: 'Hloubka ostrosti levé',
  depthPerceptionPrimary: 'Hloubka ostrosti bino',
  nearFarQuickness: 'Přechod dálka / blízko',
  perceptionSpan: 'Vizualizace / krátkodobá paměť',
  multiobjectTracking: 'Různě se pohybující předměty',
  reactionTime: 'Reakční čas',
};

export default (senaptecData, datasetOptions) => {
  if (!senaptecData) {
    return;
  }

  let labels = [
    'visualClarityRight',
    'visualClarityLeft',
    'visualClarityBoth',
    'contractSensitivity',
    'depthPerceptionRight',
    'depthPerceptionLeft',
    'depthPerceptionPrimary',
    'nearFarQuickness',
    'perceptionSpan',
    'multiobjectTracking',
    'reactionTime',
  ];

  const generateDataset = (labelData, label, color, otherOptions) => {
    let data = [];
    let dataIndexes = [];
    labels.forEach(() => data.push(null));

    for (let key in labelData) {
      const index = labels.indexOf(key);
      const value = labelData[key];
      if (index >= 0) {
        data[index] = value;
        dataIndexes.push(index);
      }
    }

    const returnArrayWithBlank = (value, blankValue) => {
      let array = [];
      labels.forEach(() => array.push(blankValue));
      dataIndexes.forEach((i) => (array[i] = value));
      return array;
    };

    let dataset = {
      ...datasetOptions,
      label,
      data,
      borderColor: 'transparent',
      borderWidth: 0,
      pointBackgroundColor: returnArrayWithBlank(color, 'transparent'),
      pointRadius: returnArrayWithBlank(datasetOptions.pointRadius, 0),
      pointHitRadius: returnArrayWithBlank(datasetOptions.pointHitRadius, 0),
      ...otherOptions,
    };

    return dataset;
  };

  let datasets = [];

  const {
    visualClarityRight,
    visualClarityLeft,
    visualClarityBoth,
    contractSensitivity,
    depthPerceptionRight,
    depthPerceptionLeft,
    depthPerceptionPrimary,
    nearFarQuickness,
    perceptionSpan,
    multiobjectTracking,
    reactionTime,
  } = senaptecData;

  const mainDataset = generateDataset(
    {
      visualClarityRight: parseInt(visualClarityRight),
      visualClarityLeft: parseInt(visualClarityLeft),
      visualClarityBoth: parseInt(visualClarityBoth),
      contractSensitivity: parseInt(contractSensitivity),
      depthPerceptionRight: parseInt(depthPerceptionRight),
      depthPerceptionLeft: parseInt(depthPerceptionLeft),
      depthPerceptionPrimary: parseInt(depthPerceptionPrimary),
      nearFarQuickness: parseInt(nearFarQuickness),
      perceptionSpan: parseInt(perceptionSpan),
      multiobjectTracking: parseInt(multiobjectTracking),
      reactionTime: parseInt(reactionTime),
    },
    `Senaptec data`,
    `rgba(22, 105, 175, .7)`,
    {
      borderColor: `rgba(22, 105, 175, .6)`,
      borderWidth: 2,
    }
  );

  datasets.push(mainDataset);

  // const optimalDataset = generateDataset(
  //   {
  //     acuity: calcFunctions.acuity(1.25),
  //     motility: calcFunctions.motility(0),
  //     sharpness: calcFunctions.sharpness(15),
  //     convergence: calcFunctions.convergence.bifurcation(3),
  //     flexibility: calcFunctions.flexibility(15),
  //     accommodation: calcFunctions.accommodation(20),
  //     inner_muscles_blur_close: calcFunctions.innerMuscles.blurClose(28),
  //     inner_muscles_blur_distance: calcFunctions.innerMuscles.blurDistance(14),
  //     inner_muscles_bifurcation_close: calcFunctions.innerMuscles.bifurcationClose(34),
  //     inner_muscles_bifurcation_distance: calcFunctions.innerMuscles.bifurcationDistance(20),
  //     inner_muscles_connection_close: calcFunctions.innerMuscles.connectionClose(30),
  //     inner_muscles_connection_distance: calcFunctions.innerMuscles.connectionDistance(16),
  //     outer_muscles_bifurcation_close: calcFunctions.outerMuscles.bifurcationClose(16),
  //     outer_muscles_bifurcation_distance: calcFunctions.outerMuscles.bifurcationDistance(10),
  //     outer_muscles_connection_close: calcFunctions.outerMuscles.connectionClose(14),
  //     outer_muscles_connection_distance: calcFunctions.outerMuscles.connectionDistance(6),
  //     neurotracker: calcFunctions.neurotracker(2),
  //   },
  //   `Optimal training data`,
  //   `rgba(150, 150, 150, .6)`,
  //   {
  //     borderColor: 'rgba(150, 150, 150, .6)',
  //     borderWidth: 2,
  //   }
  // );
  //
  // datasets.push(optimalDataset);

  return {
    labels: labels.map((label) => translations[label]),
    datasets,
  };
};
