import { useContext } from 'react';
import { message } from 'antd';

import { request, UserContext, updateUser } from '@utils';

const ClientProfileContainer = (props) => {
  const {
    state: { user },
    dispatch,
  } = useContext(UserContext);
  const { userId } = user;

  const onUserChangesSubmit = (payload, isConfirm) => {
    const endpoint = `/api_customer/edit-client-profile?confirm=${isConfirm ? 1 : 0}`;
    return request(endpoint, {
      method: 'PATCH',
      data: payload,
    }).then(({ data }) => {
      const { name, date_of_birth, residence, zip_code, phone, confirmed } = data;
      const wasConfirmedRightNow = isConfirm && confirmed;

      dispatch(
        updateUser({
          name: name,
          date_of_birth: date_of_birth,
          residence: residence,
          zip_code: zip_code,
          phone: phone,
          confirmed: confirmed,
        })
      );

      if (!wasConfirmedRightNow) {
        message.success('Změna informací proběhla úspěšně');
      } else {
        message.success('Váš účet je nyní aktivován. Děkujeme!');
      }
    });
  };

  const passProps = {
    ...props,
    user: user,
    onUserChangesSubmit: onUserChangesSubmit,
  };

  return props.children(passProps);
};

export default ClientProfileContainer;
