import React from 'react';

import styles from './NoContentPage.module.css';

// type NoContentPageProps = {|
//   strMessages: {|
//     msgNoContent: string,
//     msgPrimaryAction?: string,
//   |},
//   // react element
//   primaryActionBtn?: Object,
// |};

function NoContentPage(props) {
  const withAction = !!props.strMessages.msgPrimaryAction || !!props.primaryActionBtn;
  return (
    <div className={styles.NoContentPage}>
      <p>{props.strMessages.msgNoContent}</p>
      {withAction ? (
        <p className={styles.FirstItemHighlighted}>{props.strMessages.msgPrimaryAction}</p>
      ) : null}
      {withAction ? <div>{props.primaryActionBtn}</div> : null}
    </div>
  );
}

export default NoContentPage;
