import stampit from 'stampit';

import { assignInit, generateID } from '@oldModels';

// conventional import: clientIdentity.createNew

const createClientIdentity = stampit()
  .methods({
    // returns user identifable student name
    getTitle() {
      return this.name || this.email;
    },
    // returns a long title, if possible, otherwise normal
    // getLongTitle() {
    //   if (this.email) {
    //     return `${this.name} (${this.email})`;
    //   }
    //   return this.getTitle();
    // },
    getUniqueAttr() {
      return this.id || this.email;
    },
    // newly created students has only email
    // isPersisted() {
    //   return !this.email;
    // },
  })
  .init(assignInit);

function create(props = {}) {
  return createClientIdentity({
    id: props.id || '',
    name: props.name || '',
    email: props.email || '',
    phone: props.phone || '',
  });
}

export function createWithID(id) {
  return create({ id });
}

export function createWithEmail(email) {
  return create({ email });
}

export function createWithData(data) {
  return create(data);
}

export function createNew(props) {
  const initialProps = {
    ...props,
    id: generateID(),
  };
  return create(initialProps);
}

export function createFromExisting(props) {
  return create(props);
}
