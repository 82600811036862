import React from 'react';
import { Link } from 'react-router-dom';
import querystring from 'query-string';

import { PageContainer } from '@components';

const localMessages = {
  failureDescription:
    'S největší pravděpodobností je problém s Vašimi údaji nebo na straně platební brány ČSOB',
  failureOptional: 'Pokud kliknete na následující tlačítko, můžete znova zkusit provést platbu',
  failureTitle: 'Vaše platba neproběhla úspěšně',
  retryPayment: 'Zkusit znovu zaplatit',
  successDescription: 'Byl Vám poslán email s detaily Vaší rezervace',
  successOptional:
    'Pokud ještě nejste zaregistrovaní, byl Vám také poslán email s odkazem na registraci',
  successOnlineTitle: 'Vaše platba byla úspěšná',
  successOfflineTitle: 'Vše proběhlo úspěšně',
};

class PaymentStatusPage extends React.Component {
  constructor(props) {
    super(props);

    const { status } = props;
    const { online } = querystring.parse(props.location.search);

    let type = 'Online';
    if (online === 'false') {
      type = 'Offline';
    }

    this.status = status;
    this.type = type;
  }

  onRetryPayment = () => {
    const { payment_id } = querystring.parse(this.props.location.search);
    if (payment_id) {
      window.location.replace(`/payments/handle?payment_id=${payment_id}`);
    }
  };

  render() {
    const { status, type } = this;
    const isStudent = this.props.user && this.props.user.userType === 'student';

    if (status) {
      return (
        <div className="signupBackground PaymentStatusPage">
          <Link to="/">
            <img
              src="/static/images/nevit_logo-sm.png"
              className="NevitHeaderLogo"
              alt="Nevit logo"
            />
          </Link>
          <div className="PaymentStatusPageContent">
            <h2 className="PaymentStatusPageTitle">
              {status === 'success'
                ? localMessages[status + type + 'Title']
                : localMessages.failureTitle}
            </h2>
            <h4 className="PaymentStatusPageDescription">
              {localMessages[status + 'Description']}
            </h4>
            {status === 'success' && !isStudent && (
              <p className="PaymentStatusPageOptional">{localMessages.successOptional}</p>
            )}
            {status === 'failure' && (
              <div>
                <p className="PaymentStatusPageOptional">{localMessages.failureOptional}</p>
                <button
                  className="PaymentStatusPageRetryPaymentButton"
                  onClick={this.onRetryPayment}
                >
                  {localMessages.retryPayment}
                </button>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default (props) => (
  <PageContainer isPublic={true}>
    <PaymentStatusPage {...props} />
  </PageContainer>
);
