import { preconfigure } from 'react-aptform';

export const Aptform = preconfigure({
  typeTimeout: 800,
  msgInvalid: 'Toto pole není platné.',
  msgRequired: 'Toto pole je povinné.',
  msgFormInvalid: 'Formulář obsahuje chyby.',
  msgUnknownError: 'Došlo k chybě. Zkuste to znovu později.',
  resetOnSubmit: true,
  initialValid: true,
});
