import React from 'react';
import ReactDOM from 'react-dom';

class ListenOnClick extends React.Component {
  // A component to call either onClick or onDoubleClick prop when clicked on
  // children element.
  // It doesn't add any extra HTML elements, so there is no problem with styles.
  // Its useful with components, that does not pass onClick or onDoubleClick props
  // to underlying HTML elements.

  componentDidMount() {
    const domEl = ReactDOM.findDOMNode(this);
    domEl.addEventListener('click', this.props.onClick);
    domEl.addEventListener('dblclick', this.props.onDoubleClick);
  }

  componentWillUnmount() {
    const domEl = ReactDOM.findDOMNode(this);
    domEl.removeEventListener('click', this.props.onClick);
    domEl.removeEventListener('dblclick', this.props.onDoubleClick);
  }

  render() {
    return this.props.children;
  }
}

export default ListenOnClick;
