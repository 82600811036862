import * as React from 'react';
import { FlexBlock } from '@oldUI';

const CenteredContent = (props) => {
  return (
    <FlexBlock fullWidth {...props}>
      {props.children}
    </FlexBlock>
  );
};

export default CenteredContent;
