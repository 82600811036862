import Uppy from 'uppy/lib/core';
import Dashboard from 'uppy/lib/plugins/Dashboard';
import Informer from 'uppy/lib/plugins/Informer';
import XHRUpload from 'uppy/lib/plugins/XHRUpload';

export function initUppy(options) {
  const { selector, endpointURL, onUpload, requestHeaders } = options;

  const uppyInstance = Uppy({ autoProceed: false }); // eslint-disable-line new-cap
  uppyInstance
    .use(Dashboard, {
      locale: {
        strings: {
          dropPaste: 'Přetáhněte soubory sem, vložte nebo %{browse}',
          browse: 'procházejte',
          selectToUpload: 'Vyberte soubory, které chcete nahrát',
          closeModal: 'Zavřít okno Modal',
          upload: 'Nahrát',
          importFrom: 'Importovat z %{name}',
          dashboardTitle: 'Nahrávací okno',
          copyLinkToClipboardSuccess: 'Odkaz byl zkopírován',
          copyLink: 'Zkopírovat odkaz',
          fileSource: 'Zdroj souboru: %{name}',
          done: 'Hotovo',
          name: 'Jméno',
          removeFile: 'Odstranit soubor',
          editFile: 'Upravit soubor',
          editing: 'Upravujete soubor %{file}',
          finishEditingFile: 'Dokončit upravování souboru',
          saveChanges: 'Uložit změny',
          cancel: 'Zrušit',
          localDisk: 'Lokální disk',
          myDevice: 'Můj přístroj',
          fileProgress: 'Průběh: rychlost nahrávání a odhadovaná doba dokončení',
          numberOfSelectedFiles: 'Počet vybraných souborů',
          uploadAllNewFiles: 'Nahrát všechny nové soubory',
          emptyFolderAdded: 'Žádné soubory nebyly přidány z prázdné složky',
          uploadComplete: 'Nahrávání dokončeno',
          resumeUpload: 'Obnovit nahrávání',
          pauseUpload: 'Pozastavit nahrávání',
          retryUpload: 'Znovu zkusit nahrát',
          uploadXFiles: {
            0: 'Nahrát %{smart_count} soubor',
            1: 'Nahrát soubory (%{smart_count})',
          },
          uploadXNewFiles: {
            0: 'Nahrát +%{smart_count} soubor',
            1: 'Nahrát všechny soubory (+%{smart_count})',
          },
          folderAdded: {
            0: 'Přidán %{smart_count} soubor z %{folder}',
            1: 'Přidány soubory z %{folder} (%{smart_count})',
          },
        },
      },
      trigger: selector,
    })
    .use(Informer, { target: Dashboard })
    .use(XHRUpload, {
      endpoint: endpointURL,
      fieldName: 'file',
      headers: requestHeaders,
    })
    .run();

  // uppyInstance.on('core:upload-success', (fileId, response) => {
  uppyInstance.on('complete', ({ successful }) => {
    const files = [];

    successful.forEach(({ response }) => {
      files.push(response.body);
    });

    onUpload(files);
  });

  return () => {
    uppyInstance.close();
  };
}
