import React from 'react';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';

import * as clientIdentity from '@oldModels/clientIdentity';

import CenteredContent from '@oldComponents/CenteredContent';
import TutoCalendar from '@oldComponents/TutoCalendar';

import { strSimpleDate } from '@oldUtils/dateFormatting';
import { isInCurrentWeek, isSameWeek } from '@oldUtils/dateQueries';

import { FlexBlock } from '@utilComponents';

function createStudentStub(id) {
  return clientIdentity.createWithID(id);
}

const CalendarWithFunctions = (props) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const eventId = params.eventId;

  const onSelectLightenColor = (hexColor) => {
    // used color-alpha npm package
    const mapLighten = {
      '#acc2ff': 'rgba(172,194,255,0.20)',
      '#92f7d2': 'rgba(146,247,210,0.20)',
      '#86eaff': 'rgba(134,234,255,0.20)',
      '#ffff9a': 'rgba(255,255,154,0.20)',
      '#f4ccff': 'rgba(244,204,255,0.20)',
      '#ff9b8b': 'rgba(255,155,139,0.20)',
    };
    return mapLighten[hexColor] || hexColor;
  };

  const selectEvent = (id) => {
    const { search } = location;
    history.push(`/calendar/events/${id}${search}`);
  };

  const unselectEvent = () => {
    const { search } = location;
    history.push(`/calendar${search}`);
  };

  const changeCurrentDate = (newDate) => {
    let actualDate = newDate;

    // In current week the current day is highlighted, so we don't want
    // to change currentDate to set an another day as "selected". Selected
    // should be only the today date.
    if (isInCurrentWeek(newDate)) {
      actualDate = new Date();
    }

    // Optimization: if the currentDate is already in the query, don't update.
    const { currentDate } = props;
    if (isSameWeek(newDate, currentDate)) {
      return;
    }

    // Keep the pathname, it can be used on /calendar or /pobocka (public calendar)
    history.push(`${location.pathname}?date=${strSimpleDate(actualDate)}`);
  };

  const findTrainingById = (id) => {
    // Unspecified events have no trainings so no need to log error
    return props.allTrainingsMap[id];
  };

  const findEventByID = (id) => {
    for (const event of props.calEvents) {
      if (String(event.id) === id) {
        return event;
      }
    }
    console.error('Event not found');
    return null;
  };

  const openReservation = (id) => {
    history.push(`/reservations/${id}`);
  };

  // Render 404 page if we don't have the event because we're
  // on "selected event" page

  const renderEventNotFound = () => {
    return (
      <CenteredContent>
        <FlexBlock column hcenter>
          <p>Událost nenalezena.</p>
          <div>
            <Link to="/calendar">Zpět na kalendář</Link>
          </div>
        </FlexBlock>
      </CenteredContent>
    );
  };

  const tryFindEventByID = (id) => {
    try {
      return findEventByID(id);
    } catch (e) {
      console.error(`Event with id ${id} not found.`);
    }

    return null;
  };

  const selectedEvent = eventId && tryFindEventByID(eventId);
  if (eventId && !selectedEvent) {
    return renderEventNotFound();
  }

  return (
    <TutoCalendar
      selectedEvent={selectedEvent}
      // Functions
      selectLightenColor={onSelectLightenColor}
      selectEvent={selectEvent}
      unselectEvent={unselectEvent}
      changeCurrentDate={changeCurrentDate}
      findTrainingById={findTrainingById}
      findEventByID={findEventByID}
      openReservation={openReservation}
      // Router
      history={history}
      location={location}
      params={params}
      {...props}
    />
  );
};

export default CalendarWithFunctions;
