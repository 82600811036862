import { createContext } from 'react';

export const ClientsContext = createContext();

export const initialClientsState = {
  hasFetched: false,
  clients: [],
  targetPage: 1,
  isFiltering: false,
  filteredClients: [],
  filteredPage: 1,
};

export const clientsReducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        hasFetched: true,
        clients: action.clients,
        targetPage: action.targetPage,
      };
    case 'RESET':
      return initialClientsState;
    case 'ADD':
      const newClients = [...state.clients, action.client];
      return {
        ...state,
        clients: newClients,
      };
    case 'EDIT':
      const adjustedClients = [...state.clients];
      const targetIndex = adjustedClients.map((_) => _.id).indexOf(action.id);
      if (targetIndex > -1) {
        adjustedClients[targetIndex] = {
          ...adjustedClients[targetIndex],
          ...action.client,
        };
      }
      return {
        ...state,
        clients: adjustedClients,
      };
    case 'FILTER':
      return {
        ...state,
        isFiltering: true,
        filteredClients: action.clients,
        filteredPage: action.targetPage,
      };
    case 'STOP_FILTER':
      return {
        ...state,
        isFiltering: false,
        filteredClients: [],
        filteredPage: 1,
      };
    default:
      return state;
  }
};

export const setClients = ({ clients, targetPage }) => ({
  type: 'SET',
  clients,
  targetPage,
});

export const resetClients = () => ({
  type: 'RESET',
});

export const addClient = (client) => ({
  type: 'ADD',
  client,
});

export const editClient = (id, client) => ({
  type: 'EDIT',
  id,
  client,
});

export const filterClients = ({ clients, targetPage }) => ({
  type: 'FILTER',
  clients,
  targetPage,
});

export const stopClientsFilter = () => ({
  type: 'STOP_FILTER',
});
