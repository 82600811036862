import React from 'react';

import styles from './stylePrimitives.module.css';

export function HighlightedText({ children, isActive }) {
  const style = { color: isActive && '#337ab7' };
  return <span style={style}>{children}</span>;
}

export function HorizontalLine() {
  return <hr className={styles.HorizontalLine} />;
}
