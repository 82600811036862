import React from 'react';

// New Material UI
import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const StyledFormControl = withStyles({
  root: {
    marginRight: 20,
    width: '100%',
  },
})(FormControl);

// const StyledInputLabel = withStyles({
//   root: {
//     color: 'rgba(0, 0, 0, 0.3)',
//     fontSize: 16,
//   },
//   focused: {
//     color: 'rgb(107, 205, 100) !important',
//   },
// })(InputLabel);

const StyledSelect = withStyles({
  select: {
    color: '#fff',
    fontSize: 16,
  },
  icon: {
    fill: '#fff',
  },
})(Select);

const StyledInput = withStyles({
  root: {
    '&:before': {
      borderColor: '#fff !important',
    },
    '&:after': {
      borderColor: '#fff',
    },
  },
})(Input);

const StyledMenuItem = withStyles({
  root: {
    fontSize: 16,
  },
})(MenuItem);

const BranchSelect = ({ value, onChange, options }) => (
  <StyledFormControl>
    {/* <StyledInputLabel>Výběr pobočky</StyledInputLabel> */}
    <StyledSelect
      defaultValue={value || ''}
      value={value || ''}
      onChange={onChange}
      name="branch-select"
      input={<StyledInput />}
    >
      {options.map(({ id, name }) => (
        <StyledMenuItem key={id} value={id}>
          {name}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  </StyledFormControl>
);

export default BranchSelect;
