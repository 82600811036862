import React, { useState } from 'react';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';

import PageSubtitle from '@oldComponents/PageSubtitle';
import LogoutLink from '@oldComponents/LogoutLink';
import CopyToClipboard from '@oldComponents/CopyToClipboard';

import * as tutoUI from '@oldUI';

import { ChangePasswordForm } from './commonUI';

const notNilVal = (val, defaultVal) => (val !== undefined && val !== null ? val : defaultVal);

const BranchManagerProfile = ({
  user,
  branch,
  branchInfo,
  onUserChangesSubmit,
  onBranchInfoChangesSubmit,
  onPasswordSubmit,
}) => {
  const [branchInfoChanges, setBranchInfoChanges] = useState({});
  const [userChanges, setUserChanges] = useState({});

  const onUserChange = (key) => (e, value) => {
    setUserChanges({ ...userChanges, [key]: value });
  };

  const onBranchInfoChange = (key) => (e, value) => {
    setBranchInfoChanges({ ...branchInfoChanges, [key]: value });
  };

  const {
    name,
    public_url,
    default_price,
    default_duration,
    default_vat,
    hours_to_due,
    confirm_info,
    currency,
    organization_name,
    bank_code,
  } = branchInfo;

  const anyUserChanges = !!Object.keys(userChanges).length;
  const anyBranchInfoFieldChanged = !!Object.keys(branchInfoChanges).length;
  const fullUrl = `${window.location.origin}/pobocka/${public_url}`;

  return (
    <div>
      <PageSubtitle>Veřejný kalendář</PageSubtitle>
      <div>
        <tutoUI.FlexBlock vcenter>
          <span>Odkaz pro sdílení</span>
          <tutoUI.HSpace tiny />
          <input
            type="text"
            value={fullUrl}
            readOnly
            style={{
              border: 0,
              outline: 0,
              backgroundColor: '#f2f4fb',
              padding: '0.2rem 0.4rem',
              display: 'inline',
              width: '20rem',
            }}
          />
          <tutoUI.HSpace tiny />
          <CopyToClipboard value={fullUrl} />
        </tutoUI.FlexBlock>
        <tutoUI.VSpace tiny />
        <div>
          <a href={fullUrl}>Otevřít můj kalendář</a>
        </div>
      </div>

      <tutoUI.PageSpace double />

      <PageSubtitle>Osobní informace</PageSubtitle>
      <div>
        <div>
          <TextField
            name="name"
            value={notNilVal(userChanges.name, user.name)}
            onChange={onUserChange('name')}
            errorText={''}
            floatingLabelText="Celé jméno *"
            style={{ marginBottom: 14, width: 400 }}
          />
        </div>
        <RaisedButton
          primary
          disabled={!anyUserChanges}
          type="submit"
          onClick={() => {
            onUserChangesSubmit(userChanges).then(() => {
              setUserChanges({});
            });
          }}
          label="Aktualizovat"
        />
      </div>

      <tutoUI.PageSpace double />

      <PageSubtitle>Nastavení pobočky</PageSubtitle>
      <div>
        <div>
          <div>
            <TextField
              name="name"
              value={notNilVal(branchInfoChanges.name, branchInfo.name)}
              onChange={onBranchInfoChange('name')}
              errorText={''}
              floatingLabelText="Název pobočky *"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <TextField
              name="organization_name"
              value={notNilVal(branchInfoChanges.organization_name, branchInfo.organization_name)}
              onChange={onBranchInfoChange('organization_name')}
              errorText={''}
              floatingLabelText="Název organizace"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <TextField
              name="public_url"
              value={notNilVal(branchInfoChanges.public_url, branchInfo.public_url)}
              onChange={onBranchInfoChange('public_url')}
              errorText={''}
              floatingLabelText="Veřejná URL adresa (text za lomítkem) *"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <TextField
              name="default_duration"
              value={notNilVal(branchInfoChanges.default_duration, branchInfo.default_duration)}
              onChange={onBranchInfoChange('default_duration')}
              type="number"
              min="0"
              errorText={''}
              floatingLabelText="Výchozí délka (v minutách) *"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <TextField
              name="default_price"
              value={notNilVal(branchInfoChanges.default_price, branchInfo.default_price)}
              onChange={onBranchInfoChange('default_price')}
              type="number"
              min="0"
              errorText={''}
              floatingLabelText="Výchozí cena *"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <TextField
              name="default_vat"
              value={notNilVal(branchInfoChanges.default_vat, branchInfo.default_vat)}
              onChange={onBranchInfoChange('default_vat')}
              type="number"
              min="0"
              errorText={''}
              floatingLabelText="DPH (v procentech) *"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <TextField
              name="hours_to_due"
              value={notNilVal(branchInfoChanges.hours_to_due, branchInfo.hours_to_due)}
              onChange={onBranchInfoChange('hours_to_due')}
              type="number"
              min="0"
              errorText={''}
              floatingLabelText="Čas na zaplacení rezervace (v hodinách) *"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <TextField
              name="bank_code"
              value={notNilVal(branchInfoChanges.bank_code, branchInfo.bank_code)}
              onChange={onBranchInfoChange('bank_code')}
              type="number"
              min="0"
              errorText={''}
              floatingLabelText="Číslo bankovního účtu"
              style={{ width: 400 }}
            />
          </div>
          <div>
            <TextField
              name="confirm_info"
              value={notNilVal(branchInfoChanges.confirm_info, branchInfo.confirm_info)}
              onChange={onBranchInfoChange('confirm_info')}
              rows={2}
              rowsMax={4}
              multiLine
              errorText={''}
              floatingLabelText="Text po potvrzení"
              style={{ width: 400 }}
            />
            <div>Zpráva klientovi v potvrzovacím emailu po potvrzení rezervace.</div>
            <tutoUI.VSpace />
          </div>
        </div>
        <RaisedButton
          primary
          type="submit"
          disabled={!anyBranchInfoFieldChanged}
          label="Aktualizovat"
          onClick={() => {
            onBranchInfoChangesSubmit(branchInfoChanges).then(() => {
              setBranchInfoChanges({});
            });
          }}
        />
      </div>

      <tutoUI.PageSpace double />

      <PageSubtitle>Změna hesla</PageSubtitle>

      <ChangePasswordForm onSubmit={onPasswordSubmit} />

      <tutoUI.PageSpace double />

      <PageSubtitle>Odhlášení</PageSubtitle>
      <LogoutLink />

      <tutoUI.PageSpace double />
    </div>
  );
};

export default BranchManagerProfile;
