import React from 'react';

import NotFound from '@oldComponents/NotFound';

const NotFoundPage = () => {
  return (
    <div className="TutoAppIndex">
      <NotFound />
    </div>
  );
};

export default NotFoundPage;
