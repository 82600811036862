import * as React from 'react';

import TimeInput from '@utilComponents/TimeInput';

import { TrainingSelect, trainingToSelectOption } from '@oldComponents/TrainingSelect';
import * as obj from '@oldUtils/object';

export function TrainingsChooser(props) {
  const { trainings, defaultTrainingId, clearable, ...restProps } = props;
  const selected = trainings[defaultTrainingId];
  const selectedOption = selected && trainingToSelectOption(selected);
  const trainingsList = obj.toArray(trainings).map(trainingToSelectOption);
  return (
    <div>
      <TrainingSelect
        clearable={clearable}
        options={trainingsList}
        selected={selectedOption}
        {...restProps}
      />
    </div>
  );
}

// TrainingsChooser.propTypes = {
//   defaultTrainingId: PropTypes.any,
//   trainings: PropTypes.object.isRequired,
// };

export function EditEventTimes({ startAt, endAt, onStartChange, onEndChange }) {
  const css = { MinuteInput: 'EventTimeInput', HourInput: 'EventTimeInput' };
  return (
    <div className="EventTimes">
      <div>
        <span>Od</span>
        <TimeInput initialTime={startAt} onChange={onStartChange} css={css} />
      </div>
      <div>
        <span>Do</span>
        <TimeInput
          initialTime={endAt}
          onChange={(newEnd) => {
            if (newEnd.getTime() > startAt.getTime()) {
              onEndChange(newEnd);
            }
          }}
          css={css}
        />
      </div>
    </div>
  );
}

// EditEventTimes.propTypes = {
//   startAt: PropTypes.object.isRequired,
//   endAt: PropTypes.object.isRequired,
//   onStartChange: PropTypes.func.isRequired,
//   onEndChange: PropTypes.func.isRequired,
// };

export function InvisibleFormDivider() {
  return <hr className="InvisibleFormDivider" />;
}
