import React, { Fragment } from 'react';

import styles from './AppForms.module.css';

import { measurementValidators } from './validators';

import { parseFloatWithComma } from '@oldUtils/decimal';

const rightKeys = [
  'SPH_right',
  'CYL_right',
  'AX_right',
  'ADD_right',
  'PRIS1_right',
  'BAZE1_right',
  'PRIS2_right',
  'BAZE2_right',
  'PD_right',
];

const initialRightKeys = [...rightKeys, 'PACHY_right', 'TONO_right'];

const leftKeys = [
  'SPH_left',
  'CYL_left',
  'AX_left',
  'ADD_left',
  'PRIS1_left',
  'BAZE1_left',
  'PRIS2_left',
  'BAZE2_left',
  'PD_left',
];

const initialLeftKeys = [...leftKeys, 'PACHY_left', 'TONO_left'];

const getNumericValue = (val) => {
  val = val.replace(/\+/, '');
  val = val.replace(',', '.');
  const lastChar = val.slice(-1);

  if (val.length === 1) {
    if (lastChar === '-') {
      return val;
    }
  }

  if (lastChar === '.' || !isNaN(parseFloat(lastChar))) {
    return val;
  } else {
    return val.slice(0, -1);
  }
};

export default class MeasurementTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      numberOfErrors: 0,
      errors: {},
    };
  }

  onChangeValue = (key, inputValue) => {
    const numericValue = getNumericValue(inputValue);

    this.setState(
      {
        data: {
          ...this.state.data,
          [key]: numericValue,
        },
      },
      () => {
        const parsedValue = parseFloatWithComma(numericValue);

        const previouslyHadError = this.state.errors[key] || false;
        const validate = measurementValidators[key];
        const isValid = (parsedValue !== 0 && !parsedValue) || validate(parsedValue);

        // let newStateValue = numericValue;
        // if (isValid) {
        //   // Parse it here instead of in payload.js
        //   newStateValue = parsedValue;
        // }

        let { numberOfErrors } = this.state;
        if (!isValid && !previouslyHadError) {
          numberOfErrors++;
        } else if (isValid && previouslyHadError) {
          numberOfErrors--;
        }

        this.setState({
          // data: {
          //   ...this.state.data,
          //   [key]: parsedValue,
          // },
          numberOfErrors,
          errors: {
            ...this.state.errors,
            [key]: !isValid,
          },
        });

        if (numberOfErrors === 0) {
          this.props.onDataChange(this.state.data);
        } else {
          this.props.onError();
        }
      }
    );
  };

  getInputValue = (key) => {
    let value = this.state.data[key];

    if (!value && value !== 0) {
      value = '';
    }

    if (key.indexOf('SPH') >= 0 || key.indexOf('CYL') >= 0) {
      if (value && !isNaN(value) && value > 0) {
        value = '+' + value;
      }
    }

    return value;
  };

  renderInput = (key) => {
    let className = '';

    if (this.state.errors[key]) {
      className += styles.MeasurementError;
    }

    return (
      <td key={key}>
        <input
          readOnly={this.props.clientView}
          className={className}
          name={key}
          value={this.getInputValue(key)}
          onChange={(e) => this.onChangeValue(key, e.target.value)}
        />
      </td>
    );
  };

  render() {
    return (
      <table className={styles.MeasurementTable}>
        <tbody>
          <tr>
            <th></th>
            <th>SPH</th>
            <th>CYL</th>
            <th>AX</th>
            <th>ADD</th>
            <th>PRIS1</th>
            <th>BAZE1</th>
            <th>PRIS2</th>
            <th>BAZE2</th>
            <th>PD</th>
            {this.props.initial && (
              <Fragment>
                <th>PACHY</th>
                <th>TONO</th>
              </Fragment>
            )}
          </tr>
          <tr>
            <td style={{ padding: 4 }}>
              <b>P&nbsp;čočka</b>
            </td>
            {(this.props.initial ? initialRightKeys : rightKeys).map(this.renderInput)}
          </tr>
          <tr>
            <td style={{ padding: 4 }}>
              <b>L&nbsp;čočka</b>
            </td>
            {(this.props.initial ? initialLeftKeys : leftKeys).map(this.renderInput)}
          </tr>
        </tbody>
      </table>
    );
  }
}
