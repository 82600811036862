import stampit from 'stampit';

// returns undefined for convenience
export function invariant(msg) {
  throw new Error(msg);
}

export function assignInit(props = {}) {
  Object.assign(this, props);
}

export function requiredAttrs(model, ...attrs) {
  for (const attribute of attrs) {
    const value = model[attribute];
    if (value !== 0 && !value) {
      const className = model.constructor.name;
      throw new Error(`Model of type <${className}> must have the <${attribute}> attribute.`);
    }
  }
}

export function generateID() {
  const x = 2147483648;
  return (
    Math.floor(Math.random() * x).toString(36) +
    Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36)
  );
}

export function defaultStampit() {
  return stampit().init(assignInit);
}
