import * as React from 'react';

import Checkbox from 'material-ui/Checkbox';

import AppPage from '@oldComponents/AppPage';

import { CloseableTutoTooltip } from '@oldUI/dialogs';
import * as tutoUI from '@oldUI';
import { EditableTextDefault } from '@oldUI/inputs';
import { IconButton } from '@oldUI/buttons';
import { TutoTrashFullIcon } from '@oldUI/icons';
import SimpleTable from '@oldUI/SimpleTable';

import AddTrainingForm from './AddTrainingForm';
import { ColorSelect } from './ColorSelect';
import styles from './Trainings.module.css';

import { request } from '@utils';

const TRAINING_TYPE_NAME_MAX_LENGTH = 50;

function validateTrainingTypeName(name) {
  return name && name.length <= TRAINING_TYPE_NAME_MAX_LENGTH && name.length >= 2;
}

function validateTrainingTypePrice(price) {
  return price && !isNaN(parseFloat(price)) && parseFloat(price) >= 0;
}

function NoTrainingsMessage({ msg }) {
  return <div className={styles.NoTrainingsMessage}>{msg}</div>;
}

class TrainingsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showTooltip: false, columns: [] };
    this.defineColumns();
  }

  toggleTooltip = () => {
    this.setState({ showTooltip: !this.state.showTooltip });
  };

  defineColumns() {
    request(`/api_customer/get-branch-info/${this.props.branchId}`).then((response) => {
      this.setState({
        columns: [
          {
            title: 'Barva',
            key: 'color',
            dataIndex: 'color',
            width: 100,
            render: (value, rowValues, idx) => (
              <ColorSelect
                selectedColor={value}
                onChange={(color) => this.props.changeTraining(rowValues, { color })}
              />
            ),
          },
          {
            title: 'Název',
            key: 'name',
            dataIndex: 'name',
            width: 100,
            render: (value, rowValues, idx) => (
              <EditableTextDefault
                initialText={value}
                editMsg="Upravit"
                onEdited={(name) => this.props.changeTraining(rowValues, { name })}
                inputProps={{ maxLength: TRAINING_TYPE_NAME_MAX_LENGTH }}
                validate={validateTrainingTypeName}
              />
            ),
          },
          {
            title: 'Popis tréninku',
            key: 'description',
            dataIndex: 'description',
            width: 150,
            render: (value, rowValues, idx) => (
              <EditableTextDefault
                initialText={value}
                editMsg="Upravit"
                onEdited={(description) => this.props.changeTraining(rowValues, { description })}
                inputProps={{ maxLength: 200 }}
                spanStyle={{ maxWidth: '150px', overflowX: 'hidden' }}
              />
            ),
          },
          {
            title: 'Délka (v minutách)',
            key: 'duration',
            dataIndex: 'duration',
            width: 100,
            render: (value, rowValues, idx) => (
              <EditableTextDefault
                initialText={value.toString()}
                editMsg="Upravit"
                onEdited={(duration) => this.props.changeTraining(rowValues, { duration })}
                inputProps={{
                  maxLength: 3,
                }}
                validate={validateTrainingTypePrice}
              />
            ),
          },
          {
            title: 'Cena bez DPH (v Kč)',
            key: 'priceWithoutVat',
            dataIndex: 'priceWithoutVat',
            width: 100,
            render: (value, rowValues, idx) => {
              return (
                <EditableTextDefault
                  initialText={rowValues.priceWithoutVat.toString()}
                  editMsg="Upravit"
                  onEdited={(priceWithoutVat) => {
                    // Round to 2 decimals
                    const priceWithVat = Math.round(priceWithoutVat * 1.21 * 100) / 100;
                    this.props.changeTraining(rowValues, {
                      priceWithoutVat: priceWithoutVat.toString(),
                      priceWithVat: priceWithVat.toString(),
                    });
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                  overwrittingValue={rowValues.priceWithoutVat.toString()}
                  validate={validateTrainingTypePrice}
                />
              );
            },
          },
          {
            title: 'Cena s DPH (v Kč)',
            key: 'priceWithVat',
            dataIndex: 'priceWithVat',
            width: 100,
            render: (value, rowValues, idx) => (
              <EditableTextDefault
                initialText={rowValues.priceWithVat.toString()}
                editMsg="Upravit"
                onEdited={(priceWithVat) => {
                  // Round to 2 decimals
                  const priceWithoutVat = Math.round((priceWithVat / 121) * 100 * 100) / 100;
                  this.props.changeTraining(rowValues, {
                    priceWithoutVat: priceWithoutVat.toString(),
                    priceWithVat: priceWithVat.toString(),
                  });
                }}
                inputProps={{
                  maxLength: 10,
                }}
                overwrittingValue={rowValues.priceWithVat.toString()}
                validate={validateTrainingTypePrice}
              />
            ),
          },
          {
            title: 'Platba na místě',
            key: 'payLater',
            width: 10,
            render: (value, rowValues, idx) => (
              <Checkbox
                checked={rowValues.canPayLater}
                onCheck={(e, checked) =>
                  this.props.changeTraining(rowValues, {
                    canPayLater: checked,
                  })
                }
              />
            ),
          },
          {
            title: 'Veřejný',
            key: 'isPublic',
            width: 10,
            render: (value, rowValues, idx) => (
              <Checkbox
                checked={rowValues.isPublic}
                onCheck={(e, checked) =>
                  this.props.changeTraining(rowValues, {
                    isPublic: checked,
                  })
                }
              />
            ),
          },
          {
            title: 'Smazat',
            key: 'deleteRow',
            width: 10,
            render: (value, rowValues, idx) => {
              const canRemove = this.props.trainings.length > 1;
              return canRemove ? (
                <IconButton
                  icon={<TutoTrashFullIcon large inline />}
                  onClick={() => this.props.deleteTraining(rowValues)}
                  title="Smazat"
                />
              ) : null;
            },
          },
        ],
      });
    });
  }

  render() {
    const { trainings, createTraining } = this.props;

    const { showTooltip } = this.state;

    const addLabelBtn = (
      <tutoUI.PrimaryBtn onClick={this.toggleTooltip}>Přidat trénink</tutoUI.PrimaryBtn>
    );

    const pageHeader = (
      <div className={styles.TrainingsManagementHeader}>
        <CloseableTutoTooltip
          overlay={
            <AddTrainingForm
              onAdd={(labelData) => {
                createTraining(labelData);
                this.toggleTooltip();
              }}
            />
          }
          onClose={this.toggleTooltip}
          placement="left"
          visible={showTooltip}
          destroyTooltipOnHide
        >
          {addLabelBtn}
        </CloseableTutoTooltip>
      </div>
    );

    return (
      <AppPage header={pageHeader}>
        <SimpleTable className="LabelsTable" data={trainings} columns={this.state.columns} />
        {!trainings || trainings.length === 0 ? (
          <NoTrainingsMessage msg="Nemáte žádné tréninky" />
        ) : null}
      </AppPage>
    );
  }
}

export default TrainingsManagement;
