import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import { ChipMultiSelect } from '@components';

import { request, RoomsContext } from '@utils';

const permissions = [
  'allow_calendar_read',
  'allow_calendar_write',
  'allow_trainings',
  'allow_clients',
  'allow_forms_and_exports',
  'allow_files',
  'allow_reservations',
  // 'allow_employees',
  // 'allow_branch_administration',
];

const permissionsTexts = {
  allow_calendar_read: 'Kal: čtení',
  allow_calendar_write: 'Kal: zápis',
  allow_trainings: 'Tréninky',
  allow_clients: 'Klienti',
  allow_forms_and_exports: 'Formuláře',
  allow_files: 'Soubory',
  allow_reservations: 'Rezervace',
  // 'allow_employees': "",
  // 'allow_branch_administration': "",
};

const NoEmployeesText = styled.p`
  color: #666;
  font-size: 20px;
  margin-top: 120px;
  text-align: center;
`;

const StyledTableContainer = withStyles({
  root: {
    margin: '120px auto 0 auto',
    width: '90%',
    // maxWidth: '900px',
  },
})(TableContainer);

const StyledTable = withStyles({
  root: {
    width: '100%',
  },
})(Table);

const StyledHeaderTableRow = withStyles({
  root: {
    backgroundColor: '#f3f3f3',
  },
})(TableRow);

const StyledHeaderTableCell = withStyles({
  root: {
    color: '#666',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
})(TableCell);

const StyledCheckbox = withStyles({
  root: {
    color: '#1669af',
    '&$checked': {
      color: '#1669af',
    },
  },
})(Checkbox);

const EmployeePermissions = ({ branchId }) => {
  const {
    state: { rooms },
  } = useContext(RoomsContext);

  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    request(`/api_customer/get-employees/${branchId}`).then(({ data }) => setEmployees(data));
  }, [branchId]);

  const onChangeRooms = (id, newValue) => {
    // Calculate excluded rooms
    const newExcludedRooms = [];

    rooms.forEach(({ id }) => {
      if (newValue.indexOf(id) === -1) {
        newExcludedRooms.push(id);
      }
    });

    request(`/api_customer/set-excluded-rooms/${branchId}/${id}`, {
      method: 'POST',
      data: { excluded_rooms: newExcludedRooms },
    }).then(() => {
      const newEmployees = [...employees];
      const targetEmployeeIndex = newEmployees.map((_) => _.id).indexOf(id);
      newEmployees[targetEmployeeIndex].excluded_rooms = newExcludedRooms;
      setEmployees(newEmployees);
    });
  };

  const onChangePermission = (employee, permission, newValue) => {
    employee[permission] = newValue;
    request(`/api_customer/change-employee-permissions/${branchId}/${employee.id}`, {
      method: 'POST',
      data: { [permission]: newValue },
    }).then(() => {
      const newEmployees = [...employees];
      const targetEmployeeIndex = newEmployees.map((_) => _.id).indexOf(employee.id);
      newEmployees[targetEmployeeIndex] = employee;
      setEmployees(newEmployees);
    });
  };

  // const onChangeReadPermission = (id, newValue) => {
  //   request(`/api_customer/change-read-permission/${branchId}/${id}`, {
  //     method: 'POST',
  //     data: { can_read: newValue },
  //   }).then(() => {
  //     const newEmployees = [...employees];
  //     const targetEmployeeIndex = newEmployees.map((_) => _.id).indexOf(id);
  //     newEmployees[targetEmployeeIndex].can_read = newValue;
  //     setEmployees(newEmployees);
  //   });
  // };

  // const onChangeEditPermission = (id, newValue) => {
  //   request(`/api_customer/change-edit-permission/${branchId}/${id}`, {
  //     method: 'POST',
  //     data: { can_edit: newValue },
  //   }).then(() => {
  //     const newEmployees = [...employees];
  //     const targetEmployeeIndex = newEmployees.map((_) => _.id).indexOf(id);
  //     newEmployees[targetEmployeeIndex].can_edit = newValue;
  //     setEmployees(newEmployees);
  //   });
  // };

  const roomIds = rooms.map((_) => _.id);

  if (!employees || !employees.length || employees.length === 0) {
    return <NoEmployeesText>Tato pobočka zatím žádné vyšetřovatele nemá</NoEmployeesText>;
  }

  return (
    <StyledTableContainer component={Paper}>
      <StyledTable>
        <TableHead>
          <StyledHeaderTableRow>
            <StyledHeaderTableCell>Jméno</StyledHeaderTableCell>
            <StyledHeaderTableCell>E-mail</StyledHeaderTableCell>
            <StyledHeaderTableCell>Povolené místnosti</StyledHeaderTableCell>
            {permissions.map((permission) => (
              <StyledHeaderTableCell key={permission}>
                {permissionsTexts[permission]}
              </StyledHeaderTableCell>
            ))}
            {/* <StyledHeaderTableCell>Může číst</StyledHeaderTableCell> */}
            {/* <StyledHeaderTableCell>Může editovat</StyledHeaderTableCell> */}
          </StyledHeaderTableRow>
        </TableHead>
        <TableBody>
          {employees.map((employee) => {
            const { id, name, email, excluded_rooms } = employee;

            let value = [...roomIds];
            excluded_rooms.forEach((roomId) => {
              const index = value.indexOf(roomId);
              if (index > -1) value.splice(index, 1);
            });

            return (
              <TableRow key={id}>
                <TableCell>{name}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>
                  <ChipMultiSelect
                    value={value}
                    onChange={(newValue) => onChangeRooms(id, newValue)}
                    options={rooms}
                  />
                </TableCell>
                {permissions.map((permission) => (
                  <TableCell key={permission}>
                    <StyledCheckbox
                      color="default"
                      checked={employee[permission]}
                      onChange={(event) =>
                        onChangePermission(employee, permission, event.target.checked)
                      }
                    />
                  </TableCell>
                ))}
                {/* <TableCell>
                  <StyledCheckbox
                    color="default"
                    checked={can_read}
                    onChange={(event) => onChangeReadPermission(id, event.target.checked)}
                  />
                </TableCell> */}
                {/* <TableCell>
                  <StyledCheckbox
                    color="default"
                    checked={can_edit}
                    onChange={(event) => onChangeEditPermission(id, event.target.checked)}
                  />
                </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};

export default EmployeePermissions;
