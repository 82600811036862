import React, { useContext, useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { PageContainer } from '@components';

import {
  request,
  UserContext,
  setUser,
  setUserToken,
  BranchContext,
  setBranches,
  calculateBranchId,
} from '@utils';

const LoginPage = () => {
  const { dispatch } = useContext(UserContext);
  const { dispatch: branchDispatch } = useContext(BranchContext);
  const history = useHistory();

  const [error, setError] = useState(false);
  const emailElement = useRef(null);
  const passwordElement = useRef(null);

  const onLoginFormSubmit = async (event) => {
    event.preventDefault();

    const email = emailElement.current.value;
    const password = passwordElement.current.value;

    try {
      await request('/api/login/', {
        method: 'POST',
        data: {
          email: email,
          password: password,
        },
      });

      const { data } = await request('/api/user/');
      const { user_data, csrf_token } = data;

      dispatch(setUser(user_data));
      dispatch(setUserToken(csrf_token));
      branchDispatch(setBranches(user_data && user_data.branches));
      branchDispatch(calculateBranchId(user_data));

      if (user_data.type === 'client') {
        history.push('/training-forms');
      } else {
        history.push('/calendar');
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <div className="signupBackground">
      <Link to="/">
        <img src="/static/images/nevit_logo-sm.png" className="NevitHeaderLogo" alt="Nevit logo" />
      </Link>
      <div className="containerLogin TextCenter">
        <div>
          <div className="NotLogged TextCenter">
            <form onSubmit={onLoginFormSubmit} className="LoginForm">
              {error && (
                <div className="errorContainer">
                  <span className="InlineError">Email nebo heslo není správné</span>
                </div>
              )}
              <div className="FormField has-float-label">
                <input
                  type="email"
                  name="email"
                  ref={emailElement}
                  id="emailElement"
                  placeholder=" "
                  required
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]+$"
                  autoFocus
                />
                <label htmlFor="user">Email</label>
              </div>

              <div className="FormField has-float-label">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder=" "
                  ref={passwordElement}
                  required
                  minLength="8"
                />
                <label htmlFor="password">Heslo</label>
              </div>

              <button type="submit" className="BtnDefault BtnWide LoginBtn">
                Přihlásit se
              </button>
            </form>
          </div>
          <button className="SignupLink" onClick={() => history.push('/forgot-password')}>
            Ztracené heslo
          </button>
        </div>
      </div>
    </div>
  );
};

export default () => (
  <PageContainer>
    <LoginPage />
  </PageContainer>
);
