import React, { useContext, useState, useEffect } from 'react';

import CalendarWithFunctions from './CalendarWithFunctions';

import {
  RoomsContext,
  setUsedBranchId,
  setRooms,
  setDefaultRoom,
  setCurrentRoom,
  BranchContext,
  PermissionsContext,
} from '@utils';

const CalendarWithRooms = (props) => {
  const {
    state: { hasFetched, usedBranchId, currentRoom, defaultRoom, rooms },
    dispatch,
  } = useContext(RoomsContext);
  const {
    state: { branchId },
  } = useContext(BranchContext);
  const { state: permissions } = useContext(PermissionsContext);

  const { calDataLoaded, isPublic, publicRooms } = props;
  let { calEvents } = props;

  if (isPublic) {
    if (!defaultRoom && !currentRoom && publicRooms.length > 0) {
      let newDefaultRoom = '';
      const target = publicRooms.filter((_) => _.isDefault)[0];
      if (target) {
        newDefaultRoom = parseInt(target.id);
      } else if (publicRooms.length > 0) {
        newDefaultRoom = parseInt(publicRooms[0].id);
      }

      dispatch(setRooms(publicRooms));
      dispatch(setDefaultRoom(newDefaultRoom));
      dispatch(setCurrentRoom(newDefaultRoom));
    }
  } else {
    if (calDataLoaded) {
      if (branchId && branchId !== usedBranchId) {
        let newDefaultRoom = '';
        const target = rooms.filter((_) => _.isDefault)[0];
        if (target) {
          newDefaultRoom = parseInt(target.id);
        } else if (rooms.length > 0) {
          newDefaultRoom = parseInt(rooms[0].id);
        }

        dispatch(setUsedBranchId(branchId));
        dispatch(setDefaultRoom(newDefaultRoom));
        dispatch(setCurrentRoom(newDefaultRoom));
      }
    }
  }

  // If currentRoom === 0 we return all events
  if (currentRoom) {
    calEvents = calEvents.filter(({ roomId }) => {
      return parseInt(roomId) === parseInt(currentRoom);
    });
  }

  return (
    <CalendarWithFunctions
      {...props}
      calEvents={calEvents}
      currentRoom={currentRoom}
      permissions={permissions}
    />
  );
};

export default CalendarWithRooms;
