import Cookies from 'js-cookie';

export function readCookie(name, defaultValue) {
  const val = Cookies.get(name);
  return val === undefined ? defaultValue : val;
}

export function setCookie(name, value, expires = null) {
  if (name && value) {
    if (expires) {
      Cookies.set(name, value, { expires });
    } else {
      Cookies.set(name, value);
    }
  }
}

export function removeCookie(name) {
  Cookies.remove(name);
}
