import React from 'react';
import Loadable from 'react-loadable';

import { getIconForUserType } from '@oldComponents/componentHelpers';

import { HighlightedText } from '@oldUI/stylePrimitives';
import { InvisibleButton } from '@oldUI/buttons';

import { StopClick } from '@utilComponents/clickHelpers';
import { FlexBlock } from '@utilComponents/layoutPrimitives';
import { TrashIcon, DownloadIcon } from '@utilComponents/icons';

import { cx } from '@whysReact';
import { withActiveItem, compose } from '@whysReact/hocs';
import { NoSSR } from '@whysReact/render';

import styles from './filesComponents.module.css';

function getType(mediaType) {
  const type = mediaType.split('/')[0];
  return type;
}

export function isVideo(attachment) {
  return getType(attachment.type) === 'video';
}

export function isText(attachment) {
  return getType(attachment.type) === 'text';
}

export function isAudio(attachment) {
  return getType(attachment.type) === 'audio';
}

export function isPDF(attachment) {
  const subtype = attachment.type.split('/')[1];
  return subtype === 'pdf';
}

export function isImage(attachment) {
  return getType(attachment.type) === 'image';
}

export function getUserFileType(filetype) {
  const [type, subtype] = filetype.split('/');
  if (['audio', 'video', 'text', 'image'].includes(type)) {
    return type;
  }

  if (subtype === 'pdf') {
    return 'pdf';
  }

  return 'a custom file';
}

export function hasPreview(attachment) {
  const userType = getUserFileType(attachment.type);
  return ['audio', 'video', 'text', 'image', 'pdf'].includes(userType);
}

function ReactLoadableLoading() {
  return <div>Načítám</div>;
}

const TextViewer = Loadable({
  loader: () => import('../mediaplayer/TextViewer'),
  loading: ReactLoadableLoading,
});

const PDFViewer = Loadable({
  loader: () => import('../mediaplayer/PDFViewer'),
  loading: ReactLoadableLoading,
});
const AudioPlayer = Loadable({
  loader: () => import('../mediaplayer/AudioPlayer'),
  loading: ReactLoadableLoading,
});
const VideoPlayer = Loadable({
  loader: () => import('../mediaplayer/VideoPlayer'),
  loading: ReactLoadableLoading,
});

function AccordionHeader(props) {
  const { children, onClick, isActive } = props;
  return (
    <div
      role="button"
      onClick={onClick}
      className={cx(styles.AccordionHeader, { [styles.AccordionHeaderActive]: isActive })}
    >
      {children}
    </div>
  );
}

// AccordionHeader.propTypes = {
//   onClick: PropTypes.func.isRequired,
//   children: PropTypes.any.isRequired,
//   isActive: PropTypes.bool,
// };

// type MediaFilterSelectProps = {|
//   filterType: MediaFilterType,
//   onFilter: Function,
// |};
export const MediaFilterSelect = (props) => {
  const { filterType, onFilter } = props;

  const renderFilter = (type) => {
    return (
      <InvisibleButton
        key={type}
        onClick={() => {
          onFilter(type);
        }}
      >
        <span className={filterType === type ? styles.TypeFilterActive : ''}>
          {type === 'all'
            ? 'všechno'
            : type === 'audio'
            ? 'audio'
            : type === 'video'
            ? 'video'
            : type === 'image'
            ? 'obrázky'
            : type === 'text'
            ? 'text'
            : 'ostatní'}
        </span>
      </InvisibleButton>
    );
  };

  return (
    <div className={styles.MediaFilterSelect}>
      {['all', 'audio', 'video', 'image', 'text'].map(renderFilter)}
    </div>
  );
};

export function InlinedFile(props) {
  const { file, clickable } = props;

  const userType = getUserFileType(file.type);
  const typeIcon = getIconForUserType(userType)({
    title: userType,
    className: styles.FileTypeIcon,
  });

  const fileDescription = (
    <FlexBlock vcenter title={file.name}>
      {typeIcon}
      {file.name}
    </FlexBlock>
  );

  return (
    <div
      className={cx(styles.InlinedFile, {
        [styles.InlinedFileClickable]: clickable,
      })}
    >
      {fileDescription}
    </div>
  );
}

// InlinedFile.propTypes = {
//   file: PropTypes.object.isRequired,
//   clickable: PropTypes.bool,
// };

export function InlinedFileRow(props) {
  const { file } = props;
  return (
    <HighlightedText isActive={hasPreview(file)}>
      <InlinedFile file={file} />
    </HighlightedText>
  );
}

// InlinedFileRow.propTypes = {
//   file: PropTypes.object.isRequired,
// };

function ImagePreview(props) {
  const { url, title } = props;
  return (
    <div className={styles.ImagePreviewWrap}>
      <img className={styles.ImagePreview} alt={title} src={url} />
    </div>
  );
}

// ImagePreview.propTypes = {
//   url: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
// };

// function TextPreview(props) {
//   const { url } = props;
//   return <p>{url}</p>;
// }

// TextPreview.propTypes = {
//   url: PropTypes.string.isRequired,
//   // title: PropTypes.string.isRequired,
// };

export function PreviewFile(props) {
  const { file } = props;

  if (isImage(file)) {
    return <ImagePreview title={file.name} url={file.url} />;
  }
  if (isText(file)) {
    return <TextViewer src={file.url} />;
  }
  if (isAudio(file)) {
    return <AudioPlayer src={file.url} />;
  }
  if (isVideo(file)) {
    return <VideoPlayer src={file.url} />;
  }
  if (isPDF(file)) {
    return <PDFViewer src={file.url} />;
  }

  return <div>Tento soubor nemá náhled, můžete si jej stáhnout.</div>;
}

// PreviewFile.propTypes = {
//   file: PropTypes.object.isRequired,
// };

export function FileAttachment(props) {
  const { attachment, onDelete, activeMode, editMode, previewMode, onPreview } = props;

  const showActions = previewMode || activeMode;

  const removeBtn = showActions && editMode && (
    <InvisibleButton
      onClick={(e) => {
        e.stopPropagation();
        onDelete(attachment);
      }}
      className="deleteAttachmentBtn"
    >
      <TrashIcon className={styles.RemoveAttachmentIcon} title="Odebrat" />
    </InvisibleButton>
  );

  const downloadBtn = showActions && (
    <a target="_blank" rel="noopener noreferrer" download href={attachment.url}>
      <DownloadIcon title="Stáhnout soubor" />{' '}
    </a>
  );

  return (
    <AccordionHeader
      isActive={previewMode}
      onClick={() => {
        onPreview(attachment);
      }}
    >
      <FlexBlock vcenter spaceBetween>
        <InlinedFileRow file={attachment} />
        <StopClick>
          <FlexBlock>
            {downloadBtn}
            {removeBtn}
          </FlexBlock>
        </StopClick>
      </FlexBlock>
      <div>
        {previewMode ? (
          <StopClick>
            <PreviewFile file={attachment} />
          </StopClick>
        ) : null}
      </div>
    </AccordionHeader>
  );
}

// FileAttachment.propTypes = {
//   attachment: PropTypes.object.isRequired,
//   // must be provided if editMode is true
//   onDelete: PropTypes.func,
//   onPreview: PropTypes.func,
//   activeMode: PropTypes.bool,
//   editMode: PropTypes.bool,
//   previewMode: PropTypes.bool,
// };

function AttachmentListView(props) {
  const { attachments, editMode, onDelete, className } = props;

  // withActiveItem('focus')
  const { focusItemActive, focusOn, focusOff } = props;

  // withActiveItem('preview')
  const { previewItemActive, previewToggle } = props;

  return (
    <NoSSR>
      {() => (
        <div className={className} onMouseLeave={focusOff}>
          {attachments.map((attachment) => (
            <div
              key={attachment.id}
              onMouseEnter={() => {
                focusOn(attachment);
              }}
            >
              <FileAttachment
                attachment={attachment}
                onDelete={onDelete}
                activeMode={attachment === focusItemActive}
                onPreview={previewToggle}
                previewMode={attachment === previewItemActive}
                editMode={editMode}
              />
            </div>
          ))}
        </div>
      )}
    </NoSSR>
  );
}

// AttachmentListView.propTypes = {
//   attachments: PropTypes.any.isRequired,
//   onDelete: PropTypes.func,
//   editMode: PropTypes.bool,
//   className: PropTypes.string,
//
//   previewItemActive: PropTypes.object,
//   previewToggle: PropTypes.func.isRequired,
//   // previewOff: PropTypes.func.isRequired,
//
//   focusItemActive: PropTypes.object,
//   focusOn: PropTypes.func.isRequired,
//   focusOff: PropTypes.func.isRequired,
// };

export const AttachmentList = compose(
  withActiveItem('focus'),
  withActiveItem('preview')
)(AttachmentListView);
