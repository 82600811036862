import React, { Fragment, useContext, useEffect } from 'react';
import { useHistory, useLocation, Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { pagesConfig } from '@config';
import * as oldUI from '@oldUI';

// import { request, UserContext, BranchContext, setBranches, setBranchId } from '@utils';
import {
  request,
  UserContext,
  BranchContext,
  setBranchId,
  PermissionsContext,
  setPermissions,
  RoomsContext,
  setRooms,
} from '@utils';

import BranchSelect from './BranchSelect';

const PaddingElement = styled.div`
  padding-bottom: ${({ noPadding }) => (noPadding ? 0 : 0)};
`;

const HeaderLink = ({ children, to }) => (
  <h4 role="presentation">
    <NavLink className="HeaderLink" activeClassName="HeaderLinkActive" to={to}>
      {children}
    </NavLink>
  </h4>
);

const PageContainer = ({ isPublic, children }) => {
  const {
    state: { hasFetched, loggedIn, user },
  } = useContext(UserContext);
  const { state: permissions, dispatch: permissionsDispatch } = useContext(PermissionsContext);
  const {
    state: { branches, hasFetched: hasFetchedBranches, branchId },
    dispatch: branchDispatch,
  } = useContext(BranchContext);
  const { dispatch: roomsDispatch } = useContext(RoomsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const targetBranchIndex = branches.map((_) => _.id).indexOf(branchId);
    if (targetBranchIndex > -1) {
      const targetBranch = branches[targetBranchIndex];
      if (hasFetched && hasFetchedBranches) {
        permissionsDispatch(setPermissions(user, targetBranch));
      }
      roomsDispatch(setRooms(targetBranch.rooms));
    }
  }, [branchId]);

  // useEffect(() => {
  //   if (user && user.userIsSuperuser && hasFetched && !hasFetchedBranches) {
  //     request('/api_customer/branches')
  //     .then(({ data }) => branchDispatch(setBranches(data)));
  //   }
  // }, [hasFetched, user]);

  const history = useHistory();
  const location = useLocation();

  // Params are ALWAYS last in all routes
  // Remove first '/' by using .slice(1)
  const pathname = location.pathname;
  const possibleIndexOfForwardSlash = pathname.slice(1).lastIndexOf('/');

  let pathnameWithoutParams = pathname;
  if (possibleIndexOfForwardSlash && possibleIndexOfForwardSlash > -1) {
    // Add + 1 because we used .slice(1) before
    pathnameWithoutParams = pathname.slice(0, possibleIndexOfForwardSlash + 1);
  }

  const replace = (path) => {
    if (process.browser) {
      history.replace(path);
    }
  };

  let { checkAccess = null, noHeader = false, noPadding = false } =
    pagesConfig[pathnameWithoutParams] || {};

  if (checkAccess && hasFetched && (permissions.hasFetched || !loggedIn)) {
    const isValid = checkAccess({ loggedIn, user, permissions }, replace);
    if (!isValid) return null;
  }

  if (!hasFetched) return <div></div>;

  return (
    <Fragment>
      {!isPublic && loggedIn && !noHeader && (
        <div className="NevitHeader">
          <oldUI.FlexBlock>
            <Link to="/">
              <img
                src="/static/images/nevit_logo-sm.png"
                className="NevitHeaderLogo"
                alt="Nevit logo"
              />
            </Link>
            <div className="HeaderMenu">
              {user.type === 'superuser' || user.type === 'employee' ? (
                <Fragment>
                  <HeaderLink to="/calendar">Kalendář</HeaderLink>
                  {permissions.allowTrainings && <HeaderLink to="/trainings">Tréninky</HeaderLink>}
                  {permissions.allowClients && <HeaderLink to="/clients">Klienti</HeaderLink>}
                  {permissions.allowFiles && <HeaderLink to="/files">Soubory</HeaderLink>}
                  {permissions.allowReservations && (
                    <h4 role="presentation" className="HeaderReservationCountContainer">
                      <NavLink
                        className="HeaderLink"
                        activeClassName="HeaderLinkActive"
                        to="/reservations"
                      >
                        Rezervace
                      </NavLink>
                      {/* {reservationsCount > 0 && (
                        <div className="HeaderReservationCount">{reservationsCount}</div>
                      )} */}
                    </h4>
                  )}
                  {permissions.allowEmployees && (
                    <HeaderLink to="/employees">Vyšetřovatelé</HeaderLink>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <HeaderLink to="/calendar">Kalendář</HeaderLink>
                  <HeaderLink to="/training-forms">Tréninky</HeaderLink>
                </Fragment>
              )}
            </div>
          </oldUI.FlexBlock>
          <div className="HeaderRightMenu">
            <BranchSelect
              onChange={(event) => {
                const newBranchId = event.target.value;
                branchDispatch(setBranchId(newBranchId));
              }}
              value={branchId}
              options={branches}
            />
            <div>
              <HeaderLink to="/profile">{user.name || 'Můj profil'}</HeaderLink>
            </div>
          </div>
        </div>
      )}
      <div className={`TutoAppIndex ${(!isPublic && 'TutoAppIndex--margin') || ''}`}>
        <PaddingElement noPadding={noPadding}>{children}</PaddingElement>
      </div>
    </Fragment>
  );
};

export default PageContainer;
