import * as React from 'react';
import { Creatable } from 'react-select';

export function isCustomOption(option) {
  return option.className === 'Select-create-option-placeholder';
}

export class ReactSelect extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(selected) {
    this.props.onChange(selected);
  }

  render() {
    const { selected, multi, title, ...reactSelectProps } = this.props;

    return (
      <div className="MultiSelect" title={title}>
        <Creatable {...reactSelectProps} multi={multi} value={selected} />
      </div>
    );
  }
}

export function createReactSelect(isMulti) {
  const ReactSelectGeneric = (props) => {
    const mergedProps = Object.assign({}, props, { multi: isMulti });
    return React.createElement(ReactSelect, mergedProps, mergedProps.children);
  };
  return ReactSelectGeneric;
}

export const MultiSelect = createReactSelect(true);
export const SingleSelect = createReactSelect(false);
