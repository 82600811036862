import { createContext } from 'react';

export const RoomsContext = createContext();

export const initialRoomsState = {
  hasFetched: false,
  usedBranchId: null,
  currentRoom: '',
  defaultRoom: '',
  rooms: [],
};

export const roomsReducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        hasFetched: true,
        rooms: action.rooms,
      };
    case 'SET_USED_BRANCH_ID':
      return {
        ...state,
        usedBranchId: action.usedBranchId,
      };
    case 'RESET':
      return initialRoomsState;
    case 'SET_DEFAULT_ROOM':
      return {
        ...state,
        defaultRoom: action.defaultRoom,
      };
    case 'SET_CURRENT_ROOM':
      return {
        ...state,
        currentRoom: action.currentRoom,
      };
    default:
      return state;
  }
};

export const setRooms = (rooms) => ({
  type: 'SET',
  rooms,
});

export const setUsedBranchId = (usedBranchId) => ({
  type: 'SET_USED_BRANCH_ID',
  usedBranchId,
});

export const resetRooms = () => ({
  type: 'RESET',
});

export const setDefaultRoom = (defaultRoom) => ({
  type: 'SET_DEFAULT_ROOM',
  defaultRoom,
});

export const setCurrentRoom = (currentRoom) => ({
  type: 'SET_CURRENT_ROOM',
  currentRoom,
});
