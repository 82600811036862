import React from 'react';

import { InvisibleButton } from '@oldUI/buttons';

import { SearchIcon } from '@utilComponents/icons';

import { cx } from '@whysReact';

import styles from './SearchForm.module.css';

function SearchBtn() {
  return (
    <InvisibleButton>
      <SearchIcon large className={styles.SearchIcon} />
    </InvisibleButton>
  );
}

function SearchForm({ onFilter, filterText }) {
  return (
    <div className={cx(styles.SearchBar, 'has-float-label')}>
      <SearchBtn />
      <input
        className={cx(styles.SearchInput)}
        type="search"
        placeholder=" "
        value={filterText}
        onChange={(e) => {
          onFilter(e.target.value);
        }}
        id="studentSearch"
      />
      <label htmlFor="studentSearch">Hledat</label>
    </div>
  );
}

// SearchForm.propTypes = {
//   onFilter: PropTypes.func.isRequired,
//   filterText: PropTypes.string.isRequired,
// };

export default SearchForm;
