import React from 'react';

import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const StyledFormControl = withStyles({
  root: {
    width: '100%',
  },
})(FormControl);

const StyledSelect = withStyles({
  root: {
    minHeight: 36,
    minWidth: 200,
  },
})(Select);

const StyledChip = withStyles({
  root: {
    margin: 2,
  },
})(Chip);

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledMenuItem = withStyles({
  root: {
    padding: 0,
  },
})(MenuItem);

const StyledCheckbox = withStyles({
  root: {
    color: '#1669af',
    '&$checked': {
      color: '#1669af',
    },
  },
})(Checkbox);

const StyledListItemText = withStyles({
  primary: {
    fontSize: 16,
  },
})(ListItemText);

const ChipMultiSelect = (props) => {
  const { value, onChange, options } = props;

  return (
    <StyledFormControl>
      {/* <InputLabel id="demo-mutiple-chip-label">Povolené místnosti</InputLabel> */}
      <StyledSelect
        labelId="demo-mutiple-chip-label"
        id="demo-mutiple-chip"
        multiple
        value={value}
        onChange={(event) => onChange(event.target.value)}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selected.map((value) => {
              const targetOption = options.filter((_) => _.id === value)[0];
              const label = targetOption && targetOption.name;
              return <StyledChip key={value} label={label} />;
            })}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {options.map(({ id, name }) => {
          const isSelected = value.indexOf(id) > -1;
          return (
            <StyledMenuItem key={id} value={id}>
              <StyledCheckbox color="default" checked={isSelected} />
              <StyledListItemText primary={name} />
            </StyledMenuItem>
          );
        })}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default ChipMultiSelect;
