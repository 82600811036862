import React, { useContext } from 'react';

import { PageContainer, TrainingForms } from '@components';

import { request, BranchContext } from '@utils';

const TrainingFormsPage = () => {
  const {
    state: { branchId },
  } = useContext(BranchContext);

  if (!branchId) return null;

  return (
    <TrainingForms
      fetchEntryFormData={() => {
        return request(`/api_lesson_forms/get-entry-form-data/${branchId}`).then((res) => res.data);
      }}
      fetchTrainingFormsData={() => {
        return request(`/api_lesson_forms/get-training-form-data/${branchId}`).then(
          (res) => res.data
        );
      }}
    />
  );
};

export default () => (
  <PageContainer>
    <TrainingFormsPage />
  </PageContainer>
);
