import * as React from 'react';

import CenteredContent from '@oldComponents/CenteredContent';
import * as tutoUI from '@oldUI';

const AppPage = (props) => {
  const { children, header, vcenter, hcenter } = props;

  // TUTO_FIX_BEFORE_MERGE_REQUEST:
  const centerPassProps = {};
  if ('vcenter' in props) {
    centerPassProps.vcenter = vcenter;
  }
  if ('hcenter' in props) {
    centerPassProps.hcenter = hcenter;
  }

  const shouldCenter = vcenter || hcenter;

  return (
    <div className="AppPage">
      {header ? <div className="AppPageHeader">{header}</div> : <tutoUI.PageSpace large />}
      {shouldCenter ? <CenteredContent {...centerPassProps}>{children}</CenteredContent> : children}

      <tutoUI.VSpace large />
    </div>
  );
};

export default AppPage;
