import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import Waypoint from 'react-waypoint';
import { ExportToCsv } from 'export-to-csv';

import FlatButton from 'material-ui/FlatButton';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import ForwardSvgIcon from 'material-ui/svg-icons/content/forward';
import IconButton from 'material-ui/IconButton';

import AppPage from '@oldComponents/AppPage';
import SearchForm from '@oldComponents/SearchForm';
import NoContentPage from '@oldComponents/NoContentPage';
import CopyToClipboard from '@oldComponents/CopyToClipboard';

import * as tutoUI from '@oldUI';
import { EditableTextDefault } from '@oldUI/inputs';
import { TutoCancelFullIcon } from '@oldUI/icons';
import { PrimaryBtn, InvisibleButton } from '@oldUI/buttons';
import { CloseableTutoTooltip } from '@oldUI/dialogs';
import { LoadingLabel } from '@oldUI/asyncLabels';
import SimpleTable from '@oldUI/SimpleTable';

import { Aptform } from '@appForms';

import * as materialUI from '@materialUI';

import { isEmail, mkLengthValidator } from '@oldUtils/validations';
import { filterItemsByText } from '@oldUtils/textManipulation';

import { CancelIcon, CheckmarkIcon } from '@utilComponents/icons';
import LabeledIcon from '@utilComponents/LabeledIcon';

import { request } from '@utils';

function stringifyForSearch(model) {
  return `${model.name} ${model.email} ${model.phone || ''}`.toLowerCase();
}

function VerifiedOK() {
  return (
    <tutoUI.FlexBlock vcenter>
      <LabeledIcon icon={<CheckmarkIcon large fill="#70dc67" />} text="Ověřen" pos="left" />
    </tutoUI.FlexBlock>
  );
}

function NotVerified() {
  return (
    <tutoUI.FlexBlock vcenter>
      <LabeledIcon icon={<CancelIcon large fill="#f7465e" />} text="Neověřen" pos="left" />
    </tutoUI.FlexBlock>
  );
}

function UserVerified({ user }) {
  return user.confirmed ? <VerifiedOK /> : <NotVerified />;
}

const renderAddClientForm = ({ form, inputs: { email, name, phone } }) => {
  return (
    <form {...form.getPassProps()}>
      <tutoUI.FlexBlock column>
        <materialUI.TextField
          {...email.getPassProps()}
          autoFocus
          type="email"
          errorText={email.showError() && email.errorText}
          floatingLabelText="Email klienta"
        />
        <materialUI.TextField
          {...name.getPassProps()}
          type="name"
          errorText={name.showError() && name.errorText}
          floatingLabelText="Jméno klienta"
        />
        <materialUI.TextField
          {...phone.getPassProps()}
          type="phone"
          errorText={phone.showError() && phone.errorText}
          floatingLabelText="Telefonní číslo"
        />
        <button className="BtnDefault BtnSmall" style={{ marginTop: 16 }}>
          Odeslat
        </button>
      </tutoUI.FlexBlock>
    </form>
  );
};

function CreateClientTooltip(props) {
  return (
    <tutoUI.PaddedBlock>
      <Aptform
        onSubmit={(values) => props.onSubmit(values)}
        inputs={{
          email: {
            required: true,
            validations: { isValidEmail: isEmail },
            getErrorText: () => 'Zadejte prosím platný email.',
          },
          name: {
            required: true,
            validations: { isValidName: mkLengthValidator(3, 50) },
            getErrorText: () => 'Zadejte prosím platné jméno',
          },
          phone: {
            required: false,
            validations: { isValidPhone: mkLengthValidator(9, 20) },
            getErrorText: () => 'Zadejte prosím platné telefonní číslo',
          },
        }}
        render={renderAddClientForm}
      />
    </tutoUI.PaddedBlock>
  );
}

const renderInviteClientForm = ({ form, inputs: { email } }) => {
  return (
    <form {...form.getPassProps()}>
      <tutoUI.FlexBlock column>
        <materialUI.TextField
          {...email.getPassProps()}
          autoFocus
          type="email"
          errorText={email.showError() && email.errorText}
          floatingLabelText="Email klienta"
        />
        <button className="BtnDefault BtnSmall" style={{ marginTop: 16 }}>
          Odeslat
        </button>
      </tutoUI.FlexBlock>
    </form>
  );
};

function InviteClientTooltip(props) {
  return (
    <tutoUI.PaddedBlock>
      <Aptform
        onSubmit={({ email }) => props.onSubmit(email)}
        inputs={{
          email: {
            required: true,
            validations: { isEmail },
            getErrorText: () => 'Zadejte prosím platný email.',
          },
        }}
        render={renderInviteClientForm}
      />
    </tutoUI.PaddedBlock>
  );
}

// type LocalProps = {|
//   clients: Array<BranchClient>,
//   onStudentAdd: (student: $Shape<BranchClient>) => Promise<any>,
//   onClientInvite: onClientInviteCallback,
//   updateClient: ReduxDispatchableAction,
//   reinviteClient: ReduxDispatchableAction,
//
//   notifyOk: UserMessageFunc,
//   dataLoaded: boolean,
// |};

class ClientsList extends React.Component {
  constructor(props) {
    super(props);
    this.onFilter = this.onFilter.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onCreateClientSubmit = this.onCreateClientSubmit.bind(this);
    this.onInviteClientSubmit = this.onInviteClientSubmit.bind(this);
    this.state = {
      createTooltipActive: false,
      inviteTooltipActive: false,
    };
  }

  componentDidMount() {
    this.defineColumns();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.permissions, this.props.permissions)) {
      this.defineColumns();
    }
  }

  onClear() {
    this.props.onTextFilter('');
  }

  onFilter(filterText) {
    this.props.onTextFilter(filterText);
  }

  onCreateClientSubmit(values) {
    const { onClientCreate } = this.props;
    return onClientCreate(values)
      .then(() => {
        this.setState({ createTooltipActive: false });
        message.success('Klient byl úspěšně přidán');
      })
      .catch((error) => {
        message.error('Klient s tímto emailem už existuje');
      });
  }

  onInviteClientSubmit(email) {
    const { onClientInvite } = this.props;
    return onClientInvite(email)
      .then(() => {
        this.setState({ inviteTooltipActive: false });
        message.success('Klient byl úspěšně pozván');
      })
      .catch((error) => {
        const { detail } = error.response.data;
        if (detail) {
          message.error(detail);
        }
      });
  }

  defineColumns() {
    const updateClientAttribute = (attr, client) => (value) => {
      this.props.updateClient(client, { [attr]: value });
    };
    const noText = '(nezadáno)';
    this.columns = [
      {
        title: 'Celé jméno',
        key: 'name',
        dataIndex: 'name',
        width: 120,
        render: (value, rowValues, idx) => (
          <EditableTextDefault
            initialText={value || noText}
            validate={mkLengthValidator(3, 50)}
            onEdited={updateClientAttribute('name', rowValues)}
            inputProps={{
              maxLength: 50,
            }}
          />
        ),
      },
      {
        title: 'E-mail',
        key: 'email',
        dataIndex: 'email',
        width: 120,
        render: (value, rowValues, idx) => (
          <EditableTextDefault
            initialText={value}
            validate={isEmail}
            onEdited={updateClientAttribute('email', rowValues)}
            inputProps={{
              name: 'email',
            }}
          />
        ),
      },
      {
        title: 'Telefonní číslo',
        key: 'phone',
        dataIndex: 'phone',
        width: 150,
        render: (value, rowValues, idx) => (
          <EditableTextDefault
            initialText={value}
            validate={mkLengthValidator(9, 20)}
            onEdited={updateClientAttribute('phone', rowValues)}
            inputProps={{
              maxLength: 50,
            }}
          />
        ),
      },
      {
        title: 'Ověřen',
        key: 'verified',
        dataIndex: 'verified',
        width: 150,
        render: (value, record, idx) => <UserVerified user={record} />,
      },
      {
        key: 'confirmLink',
        title: 'Link pro ověření',
        width: 200,
        render: this.onRenderAccessLink,
      },
    ];

    if (this.props.permissions.allowFormsAndExports) {
      this.columns.push({
        title: 'Vyšetření',
        key: 'entryForm',
        width: 100,
        render: (value, record, idx) => (
          <Link to={{ pathname: `/examination/${record.id}` }}>
            <IconButton>
              <ForwardSvgIcon />
            </IconButton>
          </Link>
        ),
      });
    }
  }

  onRenderAccessLink = (value, record, idx) => {
    const client = record;
    if (!client.confirmed) {
      return client.access_link_valid && client.access_link ? (
        <CopyToClipboard value={client.access_link} />
      ) : (
        <FlatButton
          icon={<EmailIcon />}
          labelPosition="before"
          primary
          onClick={() => {
            this.props.reinviteClient(client).then(() => {
              message.success('Klient byl právě znovu pozván.');
            });
          }}
        >
          Znovu pozvat
        </FlatButton>
      );
    }

    return (
      <tutoUI.FlexBlock color={tutoUI.theme.lightGreyColor} hcenter>
        N/A
      </tutoUI.FlexBlock>
    );
  };

  renderExportBtn() {
    return (
      <PrimaryBtn
        onClick={() => {
          request(`/api_lesson_forms/export/${this.props.branchId}`).then((res) => {
            const { rows } = res.data;

            const options = {
              filename: 'klienti-export',
              fieldSeparator: ';',
              quoteStrings: '"',
              decimalSeparator: '.',
              showLabels: true,
              // showTitle: true,
              // title: clientData.name,
              useTextFile: false,
              useBom: true,
              // useKeysAsHeaders: true,
              headers: [],
            };

            const exporter = new ExportToCsv(options);
            const stuff = exporter.generateCsv(rows);
          });
        }}
      >
        CSV Export
      </PrimaryBtn>
    );
  }

  renderAddClientBtn() {
    return (
      <CloseableTutoTooltip
        overlay={<CreateClientTooltip onSubmit={this.onCreateClientSubmit} />}
        placement="top"
        visible={this.state.createTooltipActive}
        onClose={() => {
          this.setState({ createTooltipActive: false });
        }}
        closeOnRight
      >
        <PrimaryBtn
          onClick={() => {
            this.setState({ createTooltipActive: true });
          }}
          style={{ marginLeft: 10 }}
        >
          Přidat klienta
        </PrimaryBtn>
      </CloseableTutoTooltip>
    );
  }

  renderInviteClientBtn() {
    return (
      <CloseableTutoTooltip
        overlay={<InviteClientTooltip onSubmit={this.onInviteClientSubmit} />}
        placement="left"
        visible={this.state.inviteTooltipActive}
        onClose={() => {
          this.setState({ inviteTooltipActive: false });
        }}
        closeOnRight
      >
        <PrimaryBtn
          onClick={() => {
            this.setState({ inviteTooltipActive: true });
          }}
          style={{ marginLeft: 10 }}
        >
          Pozvat klienta
        </PrimaryBtn>
      </CloseableTutoTooltip>
    );
  }

  renderHeader() {
    return (
      <tutoUI.FlexBlock spaceBetween>
        <SearchForm onFilter={this.onFilter} filterText={this.props.filterText} />
        <div style={{ height: 36 }}>
          {this.renderExportBtn()}
          {this.renderAddClientBtn()}
          {this.renderInviteClientBtn()}
        </div>
      </tutoUI.FlexBlock>
    );
  }

  renderFilterInfo() {
    return (
      <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
        <InvisibleButton onClick={this.onClear}>
          <TutoCancelFullIcon inline />
        </InvisibleButton>
        <p style={{ paddingTop: '4px' }}>Vyfiltrováno textem: {this.props.filterText}</p>
      </div>
    );
  }

  render() {
    const { clients, dataLoaded, isFiltering } = this.props;

    const noClientsExist = clients.length === 0;
    if (noClientsExist && dataLoaded && !isFiltering) {
      return (
        <NoContentPage
          primaryActionBtn={this.renderInviteClientBtn()}
          strMessages={{
            msgNoContent: 'Nemáte žádného klienta.',
            msgPrimaryAction: 'Chcete pozvat prvního?',
          }}
        />
      );
    }

    const { filterText, hasMoreClients } = this.props;
    const hasFilterText = !!filterText;

    const filteredClients = hasFilterText
      ? filterItemsByText(clients, filterText, stringifyForSearch)
      : clients;

    return (
      <AppPage header={this.renderHeader()}>
        {hasFilterText ? this.renderFilterInfo() : null}
        <SimpleTable
          className="LabelsTable"
          data={filteredClients}
          columns={this.columns}
          emptyText={dataLoaded ? undefined : <LoadingLabel />}
        />
        {hasMoreClients && <Waypoint bottomOffset="-300px" onEnter={this.props.onLoadMore} />}
      </AppPage>
    );
  }
}

export default ClientsList;
