import React from 'react';

import copy from 'copy-to-clipboard';
import Tooltip from 'rc-tooltip';

// material-ui
import FlatButton from 'material-ui/FlatButton';
import ContentCopyIcon from 'material-ui/svg-icons/content/content-copy';
import ArrowUPIcon from 'material-ui/svg-icons/navigation/arrow-drop-up';

import Block from 'whys-react/ui/Block';

import * as tutoUI from '@oldUI';

function TooltipBox(props) {
  // A sipmle component to render a tooltip box.
  const { children, bottom } = props;
  if (!bottom) {
    throw new Error('Only bottom implemented');
  }
  return (
    <div>
      <tutoUI.FlexBlock hcenter fontSize="2rem">
        <ArrowUPIcon />
      </tutoUI.FlexBlock>
      <Block background={'#484242'} color={'white'} padding={'.2rem .4rem'}>
        {children}
      </Block>
    </div>
  );
}

class CopyToClipboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showTooltip: false };
  }

  render() {
    const { showTooltip } = this.state;
    const { value } = this.props;

    return (
      <Tooltip
        visible={showTooltip}
        placement="bottom"
        mouseEnterDelay={0}
        mouseLeaveDelay={0.1}
        destroyTooltipOnHide
        overlay={
          <TooltipBox bottom>
            <div>Link můžete vložit!</div>
          </TooltipBox>
        }
      >
        <FlatButton
          primary
          labelPosition="before"
          icon={<ContentCopyIcon />}
          onClick={() => {
            copy(value);
            this.setState({ showTooltip: true });
            const hideTooltip = () => {
              this.setState({ showTooltip: false });
            };
            this.hideTooltipTimerId = setTimeout(hideTooltip, 1500);
          }}
        >
          Zkopírovat
        </FlatButton>
      </Tooltip>
    );
  }
}

export default CopyToClipboard;
