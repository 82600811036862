import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';

import { verboseCalcFunctions } from './calcPercentage';

const translations = {
  actual_date: 'Datum',
  acuity_right_value: 'Ostrost - Pravá',
  acuity_left_value: 'Ostrost - Levá',
  acuity_bino_value: 'Ostrost - Bino',
  motility_right: 'Motilita - Pravá',
  motility_left: 'Motilita - Levá',
  sharpness_value: 'Přeostření dálka blízko dálka',
  convergence_bifurcation: 'Maximální bod konvergence - rozdvojení',
  convergence_connection: 'Maximální bod konvergence - spojení',
  flexibility_value: 'Vergenční flexibilita',
  accommodation_right: 'Akomodace - Pravá',
  accommodation_left: 'Akomodace - Levá',
  accommodation_bino: 'Akomodace - Bino',
  inner_muscles_blur_close: 'Konvergence - rozmlžení (blízko)',
  inner_muscles_blur_distance: 'Konvergence - rozmlžení (dálka)',
  inner_muscles_bifurcation_close: 'Konvergence - rozdvojení (blízko)',
  inner_muscles_bifurcation_distance: 'Konvergence - rozdvojení (dálka)',
  inner_muscles_connection_close: 'Konvergence - spojení (blízko)',
  inner_muscles_connection_distance: 'Konvergence - spojení (dálka)',
  outer_muscles_bifurcation_close: 'Divergence - rozdvojení (blízko)',
  outer_muscles_bifurcation_distance: 'Divergence - rozdvojení (dálka)',
  outer_muscles_connection_close: 'Divergence - spojení (blízko)',
  outer_muscles_connection_distance: 'Divergence - spojení (dálka)',
  neurotracker: 'Neurotracker',

  visual_clarity_right: 'Ostrost vidění pravé',
  visual_clarity_left: 'Ostrost vidění levé',
  visual_clarity_both: 'Ostrost vidění bino',
  contract_sensitivity: 'Kontrastní citlivost',
  depth_perception_right: 'Hloubka ostrosti pravé',
  depth_perception_left: 'Hloubka ostrosti levé',
  depth_perception_primary: 'Hloubka ostrosti bino',
  near_far_quickness: 'Přechod dálka / blízko',
  perception_span: 'Vizualizace / krátkodobá paměť',
  multiobject_tracking: 'Různě se pohybující předměty',
  reaction_time: 'Reakční čas',
};

const generateScore = (value) => {
  let number = 5;
  if (value > 24) number = 4;
  if (value > 49) number = 3;
  if (value > 75) number = 2;
  if (value > 99) number = 1;

  return (
    <div className="score">
      <img className="score__smiley" src={`/static/images/score${number}.png`} />
      <img className="score__star" src={`/static/images/star${number}.png`} />
    </div>
  );
};

const generateSection = (title, description, properties, form, noPercentage) => {
  const propertyRows = [];

  properties.forEach((property) => {
    const value = form[property];

    if (!value && value !== 0) return;

    const percentage = noPercentage ? value : verboseCalcFunctions[property](value);

    propertyRows.push(
      <div>
        <p style={{ display: 'inline-block', fontSize: 12, margin: '4px 0', width: 270 }}>
          {translations[property]}
        </p>
        <p style={{ display: 'inline-block', fontSize: 12, margin: '4px 0', width: 110 }}>{percentage}%</p>
        {generateScore(percentage)}
      </div>
    );
  });

  return (
    <div className="section">
      {title && (
        <p className="section__title">
          <b>{title}</b>
        </p>
      )}
      {description && <p className="section__description">{description}</p>}
      {propertyRows}
    </div>
  );
};

const generateTrainingPrintHTML = (
  clientData,
  trainingForm,
  trainingChartDataURL,
  senaptecForm,
  senaptecChartDataURL
) => {
  const page1 = ReactDOMServer.renderToStaticMarkup(
    <div style={{ marginTop: 140 }}>
      <p
        style={{
          borderBottom: '2px solid #000',
          fontSize: 30,
          paddingBottom: 10,
          textAlign: 'center',
        }}
      >
        Diagnostic summary for
      </p>
      <p
        className="light-blue-background"
        style={{ fontSize: 24, padding: '6px 40px', textAlign: 'center' }}
      >
        {clientData.name}
      </p>
      <p style={{ fontSize: 14, margin: '130px auto 0 auto', textAlign: 'center', maxWidth: 480 }}>
        Pokud sportovec s dobře vytrénovaným tělem není schopen využít svůj vizuální systém na
        maximum, nevyužije svůj maximální potenciál.
      </p>
      <p style={{ fontSize: 14, margin: '130px auto 0 auto', textAlign: 'center', maxWidth: 480 }}>
        Trénink vizuálního systému je jedna z věcí, které dělá z dobrého sportovce elitního.
      </p>
      <img
        src="/static/images/dynaoptic.PNG"
        alt="Dynaoptic"
        style={{ display: 'block', margin: '140px auto 0 auto', width: 440 }}
      />
    </div>
  );

  const page2 = ReactDOMServer.renderToStaticMarkup(
    <div style={{ fontSize: 12, marginTop: 140, textAlign: 'center' }}>
      <p style={{ fontSize: 12 }}>
        <b>
          Dobrý den, na základě našeho diagnostického testu Vám posíláme výsledky a vyhodnocení.
        </b>
      </p>

      <div style={{ marginBottom: 20 }}>
        <div className="score" style={{ display: 'inline-block', width: '33.33%' }}>
          <img className="score__smiley" src="/static/images/score1.png" />
          <img className="score__star" src="/static/images/star1.png" />
          <p className="score__text">100%</p>
        </div>
        <div className="score" style={{ display: 'inline-block', width: '33.33%' }}>
          <img className="score__smiley" src="/static/images/score2.png" />
          <img className="score__star" src="/static/images/star2.png" />
          <p className="score__text">76-99%</p>
        </div>
        <div className="score" style={{ display: 'inline-block', width: '33.33%' }}>
          <img className="score__smiley" src="/static/images/score3.png" />
          <img className="score__star" src="/static/images/star3.png" />
          <p className="score__text">50-75%</p>
        </div>
        <div className="score" style={{ display: 'inline-block', width: '33.33%' }}>
          <img className="score__smiley" src="/static/images/score4.png" />
          <img className="score__star" src="/static/images/star4.png" />
          <p className="score__text">25-49%</p>
        </div>
        <div className="score" style={{ display: 'inline-block', width: '33.33%' }}>
          <img className="score__smiley" src="/static/images/score5.png" />
          <img className="score__star" src="/static/images/star5.png" />
          <p className="score__text">0-24%</p>
        </div>
      </div>

      <p
        className="light-blue-background"
        style={{ display: 'block', fontSize: 12, marginBottom: 0, padding: '6px', width: 130 }}
      >
        DATUM {moment(trainingForm.lesson.actual_date).format('DD. MM. YYYY')}
      </p>
      <p className="title">Statický test (klidový režim)</p>

      <div style={{ border: '1px solid #ddd', borderRadius: 20 }}>
        <div>
          <h2 style={{ color: '#666', display: 'inline-block', fontSize: 18, width: '40%' }}>
            Graf statické diagnostiky
          </h2>
          <div style={{ display: 'inline-block', width: '27.5%' }} />
          <div style={{ display: 'inline-block', width: '30%' }}>
            <div
              className="background-color--measured"
              style={{ borderRadius: 4, height: 8, display: 'inline-block', width: 8 }}
            />
            <span style={{ color: '#666', fontSize: 12, margin: '0 15px 0 3px' }}>Naměřená hodnota %</span>
            <div
              className="background-color--normal"
              style={{ borderRadius: 4, height: 8, display: 'inline-block', width: 8 }}
            />
            <span style={{ color: '#666', fontSize: 12, margin: '0 0 0 3px' }}>Norma %</span>
          </div>
          <div style={{ display: 'inline-block', width: '2.5%' }} />
        </div>
        <img class="chart" src={trainingChartDataURL} />
      </div>

      {generateSection(
        'Ostrost vidění VISUS',
        null,
        ['acuity_right_value', 'acuity_left_value', 'acuity_bino_value'],
        trainingForm
      )}
    </div>
  );

  const page3 = ReactDOMServer.renderToStaticMarkup(
    <div style={{ marginTop: 140 }}>
      {generateSection(
        'Motilita',
        'Motilita zjišťuje, jestli se oči pohybují plynule a souměrně. V případě, že se oči pohybují poskokem, může docházet při sportu k výpadku zorného pole. Tento systém je zodpovědný za to, jak rychle jsme schopni plynule a rychle se dívat ze strany na stranu a vnímat situaci v periferii. A to je ve sportu velice důležité., tréninkem jsme schopni ho dostat lepší úroveň',
        ['motility_right', 'motility_left'],
        trainingForm
      )}
      {generateSection(
        'Přeostření dálka blízko dálka',
        'Zaměření, zaostření (fixace) předmětu zájmu a rychlost přechodu z dálky na blízko nebo z blízky na dálku. Schopnost ostře vidět přibližující, nebo vzdalující se předmět po celou dráhu pohybu. Rychlost přeostření má zásadní vliv na čtení hry a rychlost a přesnost rozhodnutí v klíčových situacích',
        ['sharpness_value'],
        trainingForm
      )}
      {generateSection(
        'Maximální bod konvergence (vnitřní svaly)',
        'Spolupráce okohybných svalů a rychlost přeostření ve spolupráci obou očí z dálky do blízka v klidovém stavu. Je důležitá synchronnost očí na celkový vizuální systém.',
        ['convergence_bifurcation', 'convergence_connection'],
        trainingForm
      )}
      {generateSection(
        'Vergenční flexibilita',
        'Určuje, jak rychle se oči dokáží nastavit na pohled do dálky a do blízka. Vergenční flexibilita je závislá na rychlosti vnitřních svalů v oku. Tento systém je důležitý hlavně při přechodu z dálky do blízka, kdy nastává extrémní námaha na oči. Správně nastaveným tréninkem zlepšíme tuto funkci na hranu možností vizuálního systému (koncentrace, koordinace oko – ruka, přesnost, odhad vzdálenosti)',
        ['flexibility_value'],
        trainingForm
      )}
    </div>
  );

  const page4 = ReactDOMServer.renderToStaticMarkup(
    <div style={{ marginTop: 140 }}>
      {generateSection(
        'Akomodace',
        'Akomodace je schopnost oka měnit optickou mohutnost svého refrakčního systému. Při akomodaci dochází ke změně optické lomivosti do té míry, aby se předměty nacházející se mezi blízkým a dalekým bodem zobrazily ostře na sítnici. Zde je zásadní spolupráce, obě oči (binokulárně) by se měly stejně rychle přizpůsobovat. V případě že akomodace nefunguje ideálně, má to vliv na vnímání prostoru, odhad vzdálenosti, rychlost reakce a čtení hry. Neurovizuální systém je namáhán a sportovec nemůže podat maximální výkon.',
        ['accommodation_right', 'accommodation_left', 'accommodation_bino'],
        trainingForm
      )}
      {generateSection(
        'Fúzní rezervy',
        'Fúzní rezervy zjišťují sílu vnitřních a venkovních svalů. Konvergence je schopnost očí rychle a přesně zaměřit blízkou vzdálenost. Divergence je naopak uvolnění očí a napomáhá očím k pohledu do dálky. Fúzní rezervy mají návaznost na vergenční flexibilitu viz. výše. Nefunkčnost má negativní vliv na koncentraci, fixaci, nepřesné zaměření pohybujících se předmětů spolu se špatnou akomodací systému dává nepřesné informace v prostoru, zdržuje rozhodnutí s přepočítáváním… Správným tréninkem můžeme tento systém spravit a dostat na vyšší úroveň.',
        [
          'inner_muscles_blur_close',
          'inner_muscles_blur_distance',
          'inner_muscles_bifurcation_close',
          'inner_muscles_bifurcation_distance',
          'inner_muscles_connection_close',
          'inner_muscles_connection_distance',
          'outer_muscles_bifurcation_close',
          'outer_muscles_bifurcation_distance',
          'outer_muscles_connection_close',
          'outer_muscles_connection_distance',
        ],
        trainingForm
      )}
      {generateSection(
        'Neurotracker',
        'Lepší výkon začíná s mozkem. Neurotracker zvyšuje duševní schopnosti, které vedou k lepší výkonosti. Věda ukazuje, že špičkové kognitivní schopnosti dávají sportovcům konkurenční výhodu. Neurotracker je navržen tak, aby rychle zvyšoval schopnosti kognitivních funkcí na vysokou úroveň, které jsou potřebné pro lepší výkon. Neurotracker trénuje pozornost a soustředění. Udržení dlouhodobé koncentrace při vysoké zátěži, zlepšuje čtení hry, vidět více příležitostí v každé situaci, rychlejší a efektivnější reakce. Při tomto testu je stav běžného člověka 1,0 u vrcholových sportovců je 2,0 až 2,5.',
        ['neurotracker'],
        trainingForm
      )}
      <p className="section__description" style={{ marginTop: 40 }}>
        {
          'V případě, že je výsledek nižší než 2,0 doporučujeme tento trénink zařadit do Vaší individuální přípravy. Pro trénink je možné mít toto cvičení kdykoliv a kdekoliv sebou (v režimu aplikace). Studie ukázaly, že nejúspěšnější sportovci v týmu jsou ti, kteří mají nejlepší neuro vizuální, motorické a senzorické schopnosti, to znamená přenos vjemu z očí do mozku a následně z mozku do pohybu těla. “OČI VEDOU TĚLO“. Tyto schopnost i je možné diagnostikovat, vyhodnotit a následně tréninkem zlepšovat.'
        }
      </p>
    </div>
  );

  const page5 = ReactDOMServer.renderToStaticMarkup(
    <div style={{ marginTop: 140, textAlign: 'center' }}>
      <p className="title">Dynamický test (testování v dynamické zátěži)</p>

      <div style={{ border: '1px solid #ddd', borderRadius: 20 }}>
        <div>
          <h2 style={{ color: '#666', display: 'inline-block', fontSize: 18, width: '40%' }}>
            Graf dynamické diagnostiky
          </h2>
          <div style={{ display: 'inline-block', width: '34%' }} />
          <div style={{ display: 'inline-block', width: '26%' }}>
            <div
              className="background-color--measured"
              style={{ borderRadius: 4, height: 8, display: 'inline-block', width: 8 }}
            />
            <span style={{ color: '#666', fontSize: 12, margin: '0 15px 0 3px' }}>Naměřená hodnota %</span>
            {/* <div
              className="background-color--normal"
              style={{ borderRadius: 4, height: 8, display: 'inline-block', width: 8 }}
            />
            <span style={{ color: '#666', fontSize: 12, margin: '0 0 0 3px' }}>Norma %</span> */}
          </div>
          <div style={{ display: 'inline-block', width: '0%' }} />
        </div>
        <img class="chart" src={senaptecChartDataURL} />
      </div>

      {generateSection(
        'Ostrost vidění / Visual clarity',
        'Zraková ostrost v dynamické zátěži je zásadní a rozhodující ve všech vizuálních sportech. Je nezbytně nutné maximalizovat zrakovou ostrost sportovce. Tato ostrost vidění nesouvisí s ostrostí zjišťovanou u lékaře – jedná se o ostrost vidění v zatížení dynamického pohybu.',
        ['visual_clarity_right', 'visual_clarity_left', 'visual_clarity_both'],
        senaptecForm,
        true
      )}
    </div>
  );

  const page6 = ReactDOMServer.renderToStaticMarkup(
    <div style={{ marginTop: 140 }}>
      {generateSection(
        'Kontrastní citlivost',
        'Kontrastní citlivost je spolu s ostrostí vidění zásadním faktorem pro správnou a rychlou adaptaci vizuálního systému při různých světelných a povětrnostních podmínkách a pro adaptaci na různá prostředí, ve kterých činnost probíhá. Je schopnost očí rozlišovat mezi objektem a pozadím. – Pokud sportovec vidí dobře, ale nemá dobrou kontrastní citlivost, nedokáže odhadnout kde přesně předmět je. Kontrastní citlivost má velký dopad na odhad vzdálenosti. Ostrost a kontrast jsou spojené nádoby. Pokud funguje jen jedna, celkový visuální vjem se snižuje pouze na 50%.',
        ['contract_sensitivity'],
        senaptecForm,
        true
      )}
      {generateSection(
        'Hloubka ostrosti / Depth perception',
        'Při tomto testu rozdělujeme obraz obou očí za pomoci speciálních brýlí a navozujeme 3D vjem. 3D vidění je naučená dovednost, která nás ovlivňuje celý život nejen při sportu. Tento test je velice důležitý a poukazuje na to, jestli sportovec má odhad ve vzdálenosti. V návaznosti na odhad vzdálenosti jsme schopni odhadnout rychlost. Odhad vzdálenosti máme všichni, ale jen u některých je přesný díky očím. V momentě, kdy ho nemáme, naše oči nefungují spolu, ale každé zvlášť. Ve sportu je to ta vteřina, kdy se sportovec rozhoduje, jestli má už zahájit potřebný pohyb případně protipohyb. Je to ta vteřina, co opakovaně může rozhodnout o tom, zda se vyhraje, nebo ne.',
        ['depth_perception_right', 'depth_perception_left', 'depth_perception_primary'],
        senaptecForm,
        true
      )}
      {generateSection(
        'Přechod dálka / blízko',
        'Přeostření zraku z dálky do blízka je základní dovednost vizuálního systému. V momentě, kdy tady je zjištěna nižší hodnota pod 70% dá se předpokládat, že systém pracuje s chybou. Chybu odhalíme při mechanické diagnostice. Bez této dovednosti alespoň nad 50 % je velký předpoklad i k potížím ve škole, v práci, v běžném životě. Čím rychlejší přechod z dálky do blízka sportovec má, tím rychleji a přesněji dokáže sledovat situaci.',
        ['near_far_quickness'],
        senaptecForm,
        true
      )}
    </div>
  );

  const page7 = ReactDOMServer.renderToStaticMarkup(
    <div style={{ marginTop: 140, textAlign: 'center' }}>
      <p className="title">Neurologická část - dokáže mozek využít informaci z očí</p>

      {generateSection(
        'Vizualizace / krátkodobá paměť',
        'Zásadní test na koncentraci, čtení prostoru a následně přesně provedeného pohybu. Při tomto testu nás zajímá, zda dokážu z toho, co vidím, vyvodit závěr a rozhodnout se. Kolik informace je schopný můj mozek nasát z rychlého pohledu? Tato dovednost má zásadní vliv na tvorbu rozhodnutí a čtení prostoru a následné rychlosti reakce. Rozhoduje se na základě informací, nebo intuice?',
        ['perception_span'],
        senaptecForm,
        true
      )}
      {generateSection(
        'Různě se pohybující předměty',
        'Umění soustředit se na svůj pohyb nebo konkrétní objekt, a přitom dokonale sledovat situaci před sebou. Při zlepšení této dovednosti dochází k pocitovému zpomalení situací. Sportovec sice vnímá konkrétní objekt, ale nevnímá situaci před sebou. Nebo naopak vnímá situaci před sebou, ale nevnímá přesný pohyb nebo konkrétní objekt. Naším cílem je po celou dobu vidět přesně a ostře konkrétní objekt, přitom vnímat celou situaci před sebou. Pouze pokud budeme vědět, co za situace nás čeká, a nebudeme čekat až nastane, budeme celé zvládnutí plynulejší a jistější, můžeme být o krok napřed.',
        ['multiobject_tracking'],
        senaptecForm,
        true
      )}
      {generateSection(
        'Reakční čas',
        'Jaká je rychlost a přesnost a reakce! Koordinace očí s tělem je jednou ze zásadních dovedností pro všechny sporty. Rychlost a přesnost koordinace zraku a těla v reakci na vizuální podnět. Můžou být všechny parametry v pořádku a reakční čas je nízký. V tomto případě je potřeba vyloučit vliv vizuálního systému. V dalším postupu je na řadě neurolog.',
        ['reaction_time'],
        senaptecForm,
        true
      )}
    </div>
  );

  const page8 = ReactDOMServer.renderToStaticMarkup(
    <div>
      <img
        src="/static/images/dynaoptic.PNG"
        alt="Dynaoptic"
        style={{ display: 'block', margin: '450px auto 0 auto', width: 650 }}
      />
    </div>
  );

  const style = `
  @page {
    size: auto;
    margin: 0mm;
  }

  * {
    font-family: sans-serif;
  }

  .pagebreak {
    page-break-before: always;
  }

  .wrapper {
    display: block;
    margin: 0 auto;
    width: 85%;
  }

  .title {
    background-color: #d9e1f2 !important;
    -webkit-print-color-adjust: exact;
    font-size: 22px;
    padding: 2px;
    margin-top: 0;
    text-align: center;
  }

  .section {
    margin-top: 40px;
    text-align: left;
  }

  .section__title {
    font-size: 12px;
  }

  .section__description {
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 20px;
  }

  .light-blue-background {
    background-color: #d9e1f2 !important;
    -webkit-print-color-adjust: exact;
  }

  .background-color--normal {
    background-color: #c0c0c0;
    -webkit-print-color-adjust: exact;
  }

  .background-color--measured {
    background-color: #5491c5;
    -webkit-print-color-adjust: exact;
  }

  .header {
    margin-left: 5%;
    position: fixed;
    top: 30px;
    width: 90%;
  }

  .header__logo {
    width: 55px;
  }

  .header__img {
    width: 100%;
  }

  .footer {
    border-top: 1px solid #eee;
    bottom: 20px;
    margin-left: 5%;
    position: fixed;
    width: 90%;
  }

  .footer__img {
    width: 100%;
  }

  .footer__hockey_logo {
    bottom: -12px;
    margin: 0 5px 0 0;
    position: absolute;
    right: 0;
    width: 100px;
  }

  .score {
    display: inline-block;
    height: 15px;
  }

  .score__smiley {
    display: inline-block;
    height: 15px;
  }

  .score__star {
    display: inline-block;
    height: 15px;
    margin: 0 0 1px 8px;
  }

  .score__text {
    display: inline-block;
    font-size: 15px;
    margin-left: 12px;
    transform: translateY(-2px);
  }

  .chart {
    display: block;
    margin: 0 auto;
    padding-top: 8px;
    width: 95%;
  }`;

  const html = `
<!DOCTYPE html>
<html>
  <head>
    <title>Celkové shrnutí vyšetření – ${clientData.name}</title>
    <style>${style}</style>
  </head>
  <body>
    <div class="header">
      <!-- <img src="/static/images/nevit_logo-color.png" class="header__logo" alt="Nevit"> -->
      <img src="/static/images/printheader.jpg" class="header__img" alt="Print header">
    </div>

    <div class="wrapper">
      <div class="pagebreak">
        ${page1}
      </div>

      <div class="pagebreak">
        ${page2}
      </div>

      <div class="pagebreak">
        ${page3}
      </div>

      <div class="pagebreak">
        ${page4}
      </div>

      <div class="pagebreak">
        ${page5}
      </div>

      <div class="pagebreak">
        ${page6}
      </div>

      <div class="pagebreak">
        ${page7}
      </div>

      <div class="pagebreak">
        ${page8}
      </div>
    </div>

    <div class="footer">
      <!-- <div style="float: left;">
        <h3 style="margin-bottom: 0;">DynaOptic&nbsp;s.r.o.</h2>
        <p style="margin: 2px 0 0 0;">Riegrovo&nbsp;nám.&nbsp;179&nbsp;|&nbsp;767&nbsp;01&nbsp;Kroměříž</p>
      </div>
      <div style="float: right;">
        <h4 style="margin: 43px 125px 0 0;">Spolupracujeme&nbsp;s&nbsp;ČSLH</h4>
        <img src="/static/images/hockey_logo.jpg" class="footer__hockey_logo" alt="Czech hockey">
      </div> -->
      <img src="/static/images/printfooter1.PNG" class="footer__img" alt="Print footer">
    </div>
  </body>
</html>
`;
  return html;
};

export default generateTrainingPrintHTML;
