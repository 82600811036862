import React, { Component } from 'react';
import { defineCSS } from '@whysReact';

const ENTER_KEY = 13;

function validateHourStr(timeStr) {
  const time = parseInt(timeStr, 10);
  if (Number.isInteger(time)) {
    return time >= 0 && time < 24;
  }
  return false;
}

function validateMinuteStr(timeStr) {
  const time = parseInt(timeStr, 10);
  if (Number.isInteger(time)) {
    return time >= 0 && time < 60;
  }
  return false;
}

// returns '0x' for 0..9
// returns 'xx' for 10..59
// returns 'xx' for 'axx' (replaces the first digit, thus update the time on input)
function coerceTimeInput(input) {
  let coercedInput = String(input);
  // 020 => 20
  // 335 => 35
  if ((input.startsWith('0') && input.length > 2) || input.length >= 3) {
    coercedInput = input.slice(1);
  }
  if (input.length === 1) {
    coercedInput = `0${input}`;
  }
  return coercedInput;
}

// (timeStr: string)
function formatTT(timeStr) {
  return coerceTimeInput(timeStr);
}

class TimeInput extends Component {
  // Limitations
  // - a lot of functionality is provided by input type="number", however
  //   its not possible to make a selection (e.g. on focus) of the value,
  //   it works only for text inputs.
  constructor(props) {
    super(props);
    const [h, m] = [props.initialTime.getHours(), props.initialTime.getMinutes()];
    this.state = { hInput: String(h), mInput: String(m) };
    this.onHoursChange = this.onHoursChange.bind(this);
    this.onMinutesChange = this.onMinutesChange.bind(this);
    this.updateTime = this.updateTime.bind(this);

    this.onKeyUp = this.onKeyUp.bind(this);
  }

  // This component keeps the input in state so user can actually
  // write invalid input (and fix it later).
  // When the props are updated, we must manually update the state.
  UNSAFE_componentWillReceiveProps(nextProps) {
    const [h, m] = [nextProps.initialTime.getHours(), nextProps.initialTime.getMinutes()];
    this.setState({ hInput: String(h), mInput: String(m) });
  }

  onMinutesChange(e) {
    const mInput = coerceTimeInput(e.target.value);
    this.setState({ mInput });
  }

  onHoursChange(e) {
    const hInput = coerceTimeInput(e.target.value);
    this.setState({ hInput });
  }

  onKeyUp(updateStateFunc) {
    return (e) => {
      updateStateFunc(e);

      if (e.which === ENTER_KEY) {
        this.updateTime();
      }
    };
  }

  updateTime() {
    const { hInput, mInput } = this.state;

    // make a copy
    const time = new Date(this.props.initialTime);

    if (validateMinuteStr(mInput) && validateHourStr(hInput)) {
      const hours = parseInt(hInput, 10);
      time.setHours(hours);

      const minutes = parseInt(mInput, 10);
      time.setMinutes(minutes);

      this.props.onChange(time);
    }
  }

  render() {
    const { css } = this.props;
    const { hInput, mInput } = this.state;

    const commonProps = { type: 'number', min: 0, size: 2 };

    return (
      <div>
        <input
          {...commonProps}
          className={css.HourInput}
          max="23"
          step="1"
          value={formatTT(hInput)}
          onChange={this.onHoursChange}
          onKeyUp={this.onKeyUp(this.onHoursChange)}
          onBlur={this.updateTime}
        />
        <span className={css.ColonSeparator}>:</span>
        <input
          {...commonProps}
          className={css.MinuteInput}
          max="59"
          step="5"
          onKeyUp={this.onKeyUp(this.onMinutesChange)}
          value={formatTT(mInput)}
          onChange={this.onMinutesChange}
          onBlur={this.updateTime}
        />
      </div>
    );
  }
}

export default defineCSS(TimeInput, {
  MinuteInput: '',
  HourInput: '',
  ColonSeparator: '',
});
