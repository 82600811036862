import * as React from 'react';
import { Link } from 'react-router-dom';

import IconButton from 'material-ui/IconButton';
import ExitSvgIcon from 'material-ui/svg-icons/action/exit-to-app';

import * as tutoUI from '@oldUI';

function LogoutLink({ children }) {
  return (
    <Link to="/logout">
      <tutoUI.FlexBlock vcenter>
        Odhlásit se
        <IconButton title="Odhlásit se">
          <ExitSvgIcon color="#6bcd64" />
        </IconButton>
      </tutoUI.FlexBlock>
    </Link>
  );
}

export default LogoutLink;
