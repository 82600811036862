import * as React from 'react';

import { Aptform } from '@appForms';
import * as materialUI from '@materialUI';
import * as tutoUI from '@oldUI';

import { ColorSelect, getDefaultColor } from './ColorSelect';

class AddTrainingForm extends React.Component {
  // onAdd = ({ color, name, priceWithoutVAT, priceWithVat }) => {
  onAdd = ({ color, name, description, duration, priceWithoutVat }) => {
    const { onAdd } = this.props;
    if (name && color && duration && priceWithoutVat && description) {
      const changedPriceWithoutVat = Math.round(parseInt(priceWithoutVat, 10) * 100) / 100;
      const priceWithVat = Math.round(changedPriceWithoutVat * 121) / 100;

      onAdd({
        name,
        color,
        description,
        duration,
        priceWithoutVat: changedPriceWithoutVat,
        priceWithVat,
      });

      // NOTE_PROTOTYPE:
      // reset state for adding a next label
      // this.setState({ name: '', color: getDefaultColor() });
    }
  };

  renderForm = ({ inputs, form }) => {
    const { color, name, description, duration, priceWithoutVat } = inputs;
    return (
      <form {...form.getPassProps()}>
        <tutoUI.FlexBlock column style={{ marginTop: '40px' }}>
          <ColorSelect
            selectedColor={color.value}
            onChange={(newColor) => {
              form.changeInput('color', newColor);
            }}
          />
          <materialUI.TextField
            {...name.getPassProps()}
            autoFocus
            minLength="2"
            errorText={name.showError() && name.errorText}
            floatingLabelText="Název"
          />
          <materialUI.TextField
            {...description.getPassProps()}
            minLength="5"
            errorText={description.showError() && description.errorText}
            floatingLabelText="Krátký popis"
          />
          <materialUI.TextField
            {...duration.getPassProps()}
            errorText={duration.showError() && duration.errorText}
            floatingLabelText="Délka (v minutách)"
          />
          <materialUI.TextField
            {...priceWithoutVat.getPassProps()}
            errorText={priceWithoutVat.showError() && priceWithoutVat.errorText}
            floatingLabelText="Cena bez DPH"
          />
          {/* <materialUI.TextField
            {...priceWithVat.getPassProps()}
            errorText={priceWithVat.showError() && priceWithVat.errorText}
            floatingLabelText={"Cena včetně DPH (nepovinné)"}
          /> */}
          <button className="BtnDefault BtnSmall">Vytvořit</button>
        </tutoUI.FlexBlock>
      </form>
    );
  };

  render() {
    return (
      <Aptform
        onSubmit={this.onAdd}
        initialValues={{
          name: this.props.initialName,
          color: getDefaultColor(),
          description: this.props.description,
          duration: this.props.duration,
          priceWithoutVat: this.props.priceWithoutVat,
        }}
        inputs={{
          name: { required: true },
          color: { required: true },
          description: { required: true },
          duration: { required: true },
          priceWithoutVat: { required: true },
          // priceWithVat: {},
        }}
        render={this.renderForm}
      />
    );
  }
}

AddTrainingForm.defaultProps = {
  initialName: '',
  description: '',
  duration: 45,
  priceWithoutVat: 0,
};

export default AddTrainingForm;
