import * as React from 'react';
import Modal from 'react-modal';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ToolTip from 'react-portal-tooltip';

// dnd_functionality
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

// material-ui
import CreditCardIcon from 'material-ui/svg-icons/action/credit-card';
import PayLaterIcon from 'material-ui/svg-icons/action/home';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import Dialog from 'material-ui/Dialog';

import AppForms from '@appForms/AppForms';

import { cx } from '@whysReact';

import { request } from '@utils';

// components
import EventForm from './EventForm';
import { createEventComponent } from './EventComponent';
import CalendarToolbar from './CalendarToolbar';
import Closeable from '@utilComponents/Closeable';
import { FlexBlock, PaddedBlock, PageSpace } from '@utilComponents/layoutPrimitives';

import { CloseIconButton, PrimaryBtn } from '@oldUI/buttons';
import { ListenOnClick } from '@utilComponents/clickHelpers';
import { theme } from '@oldUI';

// models and logic
import * as calendarEvent from '@oldModels/calendarEvent';
import * as clientIdentity from '@oldModels/clientIdentity';
import { updateTimeslot, updateStart } from '@oldModels/timeslot';

import { stringifyTime } from '@oldUtils/dateFormatting';
import { isInCurrentWeek, isSameWeek, isInPast } from '@oldUtils/dateQueries';
import { addYears } from '@oldUtils/dateManipulation';

import * as mk from '@whysFn/mk';
import * as filters from '@whysFn/filters';
import * as whysfn from '@whysFn';

import { ReservationFormContainer } from '@components';

const MIN_HOUR_VALUE = 7;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
  },
  overlay: {
    zIndex: 10,
  },
};

const DndBigCalendar = withDragAndDrop(BigCalendar);

const SUN_DAYN = 0;
const MON_DAYN = 1;
const FRI_DAYN = 5;
const SAT_DAYN = 6;

// constants
const SMALL_VIEW_MODE = 'small';
const BIG_VIEW_MODE = 'big';
// const SCROLL_HOUR = 8;

const MIN_HOUR_TIME = new Date();
MIN_HOUR_TIME.setHours(MIN_HOUR_VALUE);
MIN_HOUR_TIME.setMinutes(0);

const IS_DEBUG = false;

// create filter function, that filters away an original event of
// editing event
function mkFilterEditingEvent(editingEvent) {
  if (editingEvent === undefined || editingEvent === null) {
    return () => true;
  }
  return (event) => {
    if (editingEvent === undefined || editingEvent === null) {
      return true;
    }
    return event.id !== editingEvent.id && !event.isTemporary;
  };
}

function copyEventToEdit(event) {
  return calendarEvent.copyForEdit({
    ...event,
    isTemporary: true,
    // TUTO_RULE_RESET_CAL_DATE_ON_CANCEL
    originalDate: event.getStartDate(),
  });
}

function updateTimeslotWithStart(timeslot, start) {
  updateTimeslot({
    timeslot,
    start,
  });
}

// type DropEventTooltipProps = {
//   onClose: Function,
//   onUpdateAll: Function,
//   parent: string,
//   position: string,
// };

function DropEventTooltip(props) {
  const { onClose, position, parent, onUpdateAll } = props;
  const closeBtn = <CloseIconButton onClick={onClose} className="CloseTooltip" title="Zavřít" />;

  return (
    <ToolTip active arrow="center" position={position} parent={parent}>
      <Closeable closeOnEsc closeOnOutsideClick isOpened onClose={onClose}>
        <div className="DropEventTooltip">
          {closeBtn}
          <div
            style={{
              margin: '6px auto 8px auto',
              textAlign: 'center',
              maxWidth: 150,
            }}
          >
            Událost byla změněna pro tento týden.
          </div>
          <PrimaryBtn onClick={onUpdateAll}>Změnit pro všechny následující týdny</PrimaryBtn>
        </div>
      </Closeable>
    </ToolTip>
  );
}

// type DeleteEventDialogProps = {
//   onClose: Function,
//   onDeleteAll: Function,
// };

function DeleteEventDialog(props) {
  const actions = [
    <FlatButton label="Zavřít" primary keyboardFocused onClick={props.onClose} />,
    <FlatButton label="Smazat" primary onClick={props.onDeleteAll} />,
  ];

  return (
    <div>
      <Dialog
        title="Událost byla smazána pro tento týden"
        actions={actions}
        modal={false}
        open
        onRequestClose={props.onClose}
      >
        Chtěli byste událost smazat pro všechny následující týdny?
      </Dialog>
    </div>
  );
}

const SELECTING_EVENTS = 'SELECTING_EVENTS';
// TUTO_REFACTOR: this is not necessary anymore with the latest react-big-calendar
const MOVING_EVENTS = 'MOVING_EVENTS';
const VIEW_ONLY = 'VIEW_ONLY';

// Expected behaviour:

// TUTO_RULE_ONLY_ONE_TOOLTIP_ON_CALENDAR: on creating new event,
// editing event is closed, vice versa.

// TUTO_RULE_TOOLTIP_DISALLOW_SIDEPANE: when toltip is active, user should not be
// able to open lessons side pane.

// TUTO_RULE_CREATE_EVENT_TIME_IS_SELECTED_BY_DRAGGING: when user want to change
// starting and ending time of new event, it must be done by dragging. So on selection
// of new slots, the state of creating event is kept, only tooltip is destroy and recreated.

// TUTO_RULE_CLICKING_ON_CALENDAR_CLOSES_EDIT_TOOLTIP: when user clicks outside of any
// event, it closes edit tooltip. It doesn't close create event tooltip.

// TUTO_RULE_DND_IS_ACTIVATED_ON_MOUSE_OVER_EVENT: when user has mouse pointing over an event,
// the drag and drop functionality is activated, so they can immediately move it.

// TUTO_RULE_DND_IS_ENABLED_FOR_WHOLE_EVENT_UI: user can drag any part of the event
// box to move it.

// TUTO_RULE_IGNORE_DAY_CHANGE:
// Don't change date if its in the same week. We have only a week view, so
// it makes no sense.
// We switch to day view only when a specific event is selected.

// TutoCalendar component is dump wrapper over react-big-calendar.
// State:

// TUTO_REFACTOR: rename also the file to TutoCalendar.jsx
class TutoCalendar extends React.Component {
  // messages: {|
  //   previous: string,
  //   next: string,
  //   today: string,
  // |};
  // default_duration: number;
  // defaultPrice: number;
  // onSelectSlot: AnyBoundMethod;
  // eventStyleGetter: AnyBoundMethod;
  // changeModeToSelecting: AnyBoundMethod;
  // changeModeToMoving: AnyBoundMethod;
  // onUpdateDone: AnyBoundMethod;
  // onCreateDone: AnyBoundMethod;
  // onDeleteEvent: AnyBoundMethod;
  // onSelectEvent: AnyBoundMethod;
  // onCreateCancel: AnyBoundMethod;
  // onUpdateCancel: AnyBoundMethod;
  // onEditEvent: AnyBoundMethod;
  // onReservate: AnyBoundMethod;
  // onEventDataChange: AnyBoundMethod;
  // removeClient: AnyBoundMethod;
  // mapCalEventToBigRBCEvent: AnyBoundMethod;
  // onUnselectEvent: AnyBoundMethod;
  // onEventMove: AnyBoundMethod;
  // onNavigate: AnyBoundMethod;
  // getAllClientsForEvent: AnyBoundMethod;
  // getEditingEventClients: AnyBoundMethod;
  // addClientWithEmail: AnyBoundMethod;
  // addClientWithData: AnyBoundMethod;
  // removeClientData: AnyBoundMethod;
  // eventComponent: *;
  // state: TutoCalendarState;
  // props: TutoCalendarProps;

  constructor(props) {
    super(props);

    this.eventStyleGetter = this.eventStyleGetter.bind(this);

    // react-big-calendar handlers
    this.onSelectSlot = this.onSelectSlot.bind(this);

    // internal handlers
    this.changeModeToSelecting = this.changeModeToSelecting.bind(this);
    this.changeModeToMoving = this.changeModeToMoving.bind(this);
    this.mapCalEventToBigRBCEvent = this.mapCalEventToBigRBCEvent.bind(this);

    this.onUpdateDone = this.onUpdateDone.bind(this);
    this.onCreateDone = this.onCreateDone.bind(this);
    this.onCreateCancel = this.onCreateCancel.bind(this);
    this.onUpdateCancel = this.onUpdateCancel.bind(this);
    this.onDeleteEvent = this.onDeleteEvent.bind(this);

    this.onNavigate = this.onNavigate.bind(this);
    this.onEventMove = this.onEventMove.bind(this);
    this.onEditEvent = this.onEditEvent.bind(this);
    this.onEventDataChange = this.onEventDataChange.bind(this);
    this.onUnselectEvent = this.onUnselectEvent.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.onReservate = this.onReservate.bind(this);

    this.getAllClientsForEvent = this.getAllClientsForEvent.bind(this);
    this.getEditingEventClients = this.getEditingEventClients.bind(this);
    this.addClientWithEmail = this.addClientWithEmail.bind(this);
    this.addClientWithData = this.addClientWithData.bind(this);
    this.removeClientData = this.removeClientData.bind(this);

    // clients
    this.addClientById = this.addClientById.bind(this);
    this.removeClient = this.removeClient.bind(this);

    // create EventComponent with bound TutoCalendar methods
    // this enables TUTO_RULE_DND_IS_ACTIVATED_ON_MOUSE_OVER_EVENT
    this.eventComponent = createEventComponent(
      {
        changeModeToMoving: this.changeModeToMoving,
        changeModeToSelecting: this.changeModeToSelecting,
        getEditingEventClients: this.getEditingEventClients,
        onEditEvent: this.onEditEvent,
        openLessonPane: this.onSelectEvent,
        openReservation: this.props.openReservation,
        onReservate: this.onReservate,
      },
      {
        isEmployee: this.props.isEmployee,
        isClient: this.props.isClient,
        isPublic: this.props.isPublic,
        permissions: this.props.permissions,
      }
    );

    this.messages = {
      // allDay: 'allDay',
      // month: 'month',
      // week: 'week',
      // day: 'day',
      // agenda: 'agenda',
      previous: 'předchozí',
      next: 'další',
      today: 'dnes',
    };

    const { selectedEvent } = this.props;
    this.state = {
      viewMode: selectedEvent ? SMALL_VIEW_MODE : BIG_VIEW_MODE,
      // boolean
      isEventTooltipActive: false,
      reservationActive: false,
      reservationDone: false,
      eventIdToReserve: undefined,
      // TutoID|undefined (id of editing event)
      // CalendarEvent|undefined

      // if tooltip is active when creating/editing a event,
      // it holds a CalendarEvent model
      editingEvent: null,
      selectedEvent: null,

      reservation: null,

      // SELECTING_EVENTS | MOVING_EVENTS | VIEW_ONLY
      calendarMode: this.props.viewOnlyMode ? VIEW_ONLY : SELECTING_EVENTS,

      // timeslot that has been dropped has new id when request is resolved
      dndNewId: undefined,
      // ... we have to keep the old id so we can change all weeks
      dndOldId: undefined,
      dndOldDate: undefined,
      // to delete event for all weeks
      deletedEventId: undefined,

      isPaymentModalOpened: false,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    this.scrollCalendar();

    if (this.props.isEmployee) {
      this.getBranchDefaults();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // This handles case when user clicks on calendar link in the header while
    // having open lesson history.
    const wontHaveEvent = !nextProps.selectedEvent && this.props.selectedEvent;
    if (wontHaveEvent) {
      // NOTE: This code should be in sync with `this.onUnselectEvent`
      const viewMode = BIG_VIEW_MODE;
      this.setState({ viewMode });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const changedMode = prevState.viewMode !== this.state.viewMode;
    if (changedMode) {
      const changedToSmall = this.state.viewMode === SMALL_VIEW_MODE;
      if (changedToSmall) {
        // Scroll to selected event
        this.scrollCalendar();
      }
    }

    // Now we can scroll to the first event
    // See TUTO_RULE_DISPLAY_EMPTY_CALENDAR_WHEN_FETCHING_DATA
    if (!prevProps.calDataLoaded && !!this.props.calDataLoaded) {
      this.scrollCalendar();
    }
  }

  getBranchDefaults() {
    request(`/api_customer/get-branch-info/${this.props.branchId}`).then((response) => {
      const { default_duration, defaultPrice } = response.data;

      this.default_duration = default_duration;
      this.defaultPrice = defaultPrice;
    });
  }

  onEventDataChange(changeset) {
    const { editingEvent } = this.state;
    if (editingEvent === null) {
      this.handleEditingEventIsNull();
      return;
    }

    // TUTO_RULE_EVENT_HAS_ONE_TIMESLOT
    const timeslot = editingEvent.getOneTimeslot();

    // TUTO_RULE_UPDATING_START_KEEPS_DURATION
    if (changeset.start) {
      this.props.changeCurrentDate(changeset.start);
      updateStart({ timeslot, start: changeset.start });
      this.forceUpdate();
      return;
    }

    // TUTO_RULE_UPDATING_END_ADDS_DURATION
    if (changeset.end) {
      updateTimeslot({
        timeslot,
        start: timeslot.getStartDate(),
        end: changeset.end,
      });
      this.forceUpdate();
      return;
    }

    if ('forReservation' in changeset) {
      updateTimeslot({ timeslot, forReservation: changeset.forReservation });
      this.forceUpdate();
      return;
    }

    if (changeset.activeTo) {
      timeslot.activeTo = changeset.activeTo;
      this.forceUpdate();
      return;
    }

    if ('onetime' in changeset) {
      const isOnetime = changeset.onetime;
      timeslot.onetime = isOnetime;
      if (!isOnetime) {
        // For recurring set default activeTo date
        timeslot.activeTo = addYears(timeslot.getStartDate(), 1);
      } else {
        timeslot.activeTo = undefined;
      }
      this.forceUpdate();
      return;
    }

    if (changeset.trainingId) {
      const duration = this.props.trainingsMap[changeset.trainingId].duration * 60 * 1000;
      updateTimeslot({
        timeslot,
        end: new Date(timeslot.getStartDate().getTime() + duration),
      });
    }

    const updated = calendarEvent.updateShallow(editingEvent, changeset);
    this.setState({ editingEvent: updated });
  }

  onCreateDone(options) {
    const forReservation = options && options.forReservation;

    const { editingEvent } = this.state;

    if (editingEvent === null) {
      this.handleEditingEventIsNull();
      return;
    }

    const payload = editingEvent.toCreateJSON();
    this.stopCreatingEvent().then(() => {
      this.props.createEvent({
        payload,
        forReservation,
        okMsg: 'Událost vytvořena.',
        errMsg: 'Vytvoření události selhalo.',
      });
    });
  }

  onUpdateDone() {
    const { editingEvent } = this.state;
    if (editingEvent === null) {
      this.handleEditingEventIsNull();
      return;
    }

    const payload = editingEvent.toUpdateJSON();

    const id = editingEvent.id;

    const { studentEmails, clientIds, trainingId } = payload;
    const updatedPromise = this.props.updateEvent({
      id,
      timeslot: editingEvent.getOneTimeslot().toUpdateJSON(),
      studentEmails,
      clientIds,
      trainingId,
      okMsg: 'Událost byla aktualizována.',
      errMsg: 'Aktualizace události selhala.',
    });
    updatedPromise.then(() => {
      this.stopEditingEvent();
    });
  }

  onUpdateCancel() {
    // TUTO_RULE_RESET_CAL_DATE_ON_CANCEL:
    const { editingEvent } = this.state;
    const { changeCurrentDate } = this.props;
    if (editingEvent) {
      changeCurrentDate(editingEvent.originalDate);
    }

    this.stopEditingEvent();
  }

  onCreateCancel() {
    this.stopCreatingEvent();
  }

  onSelectEvent(event) {
    if (!this.props.permissions.allowFormsAndExports) {
      return;
    }

    if (IS_DEBUG) {
      console.log('onSelectEvent: ', event); // eslint-disable-line
    }

    // TUTO_RULE_TOOLTIP_DISALLOW_SIDEPANE
    if (this.isEventEditingOrCreating()) {
      return;
    }

    this.props.selectEvent(event.id);
    this.setState({ viewMode: SMALL_VIEW_MODE });
  }

  // Is fired when a date selection is made.
  // User is dragging mouse between time slots of the calendar.
  onSelectSlot(rbcEvent) {
    if (!this.props.permissions.allowCalendarWrite) {
      return;
    }

    const startDate = rbcEvent.start;
    let endDate = rbcEvent.end;

    if (rbcEvent.slots.length <= 2) {
      if (!this.canCreateOnClick()) {
        return;
      }

      const end_default_duration = new Date(rbcEvent.start);
      // TUTO_PROTOTYPE: if branch has default duration, set it instead of this.
      end_default_duration.setMinutes(this.default_duration || 45);
      endDate = end_default_duration;

      // We think User clicks on slot for whole hour, not specific minutes
      startDate.setMinutes(0);
    }

    if (IS_DEBUG) {
      console.log('onSelectSlot rbcEvent: ', rbcEvent); // eslint-disable-line
    }

    // if the event is already creating, just update the times
    if (this.isCreatingEvent()) {
      const { editingEvent } = this.state;

      if (editingEvent === null) {
        this.handleEditingEventIsNull();
        return;
      }

      // TUTO_RULE_EVENT_HAS_ONE_TIMESLOT
      const timeslot = editingEvent.getOneTimeslot();
      updateTimeslotWithStart(timeslot, rbcEvent.start);

      // NOTE: the intent is to remove the tooltip and then update tooltip content
      // and show it.
      // Otherwise the CSS class is on multiple places and the tooltip is jumping
      // around the page.
      this.setState({ isEventTooltipActive: false }, () => {
        this.setState({ editingEvent, isEventTooltipActive: true });
      });
      return;
    }

    // calendar is not creating, so make one
    const calEvent = calendarEvent.createNewFromStartAndEnd(startDate, endDate, {
      trainingId: null,
    });
    this.startCreatingEvent(calEvent);
  }

  onUnselectEvent() {
    // TUTO_PROTOTYPE: empty event unselects now, change it
    const viewMode = BIG_VIEW_MODE;
    this.props.unselectEvent();
    this.setState({ viewMode });
  }

  onEventMove({ event: rbcEvent, start }) {
    if (!this.props.permissions.allowCalendarWrite) {
      return;
    }

    // this *should* close other tooltips when user move event while some open
    // we're setting state after a request is resolved, so it should
    this.closeAllTooltips();

    const appEvent = this.props.findEventByID(rbcEvent.id);
    if (!appEvent) {
      return null;
    }

    // TUTO_RULE_EVENT_HAS_ONE_TIMESLOT
    const timeslot = appEvent.getOneTimeslot();
    updateTimeslotWithStart(timeslot, start);

    const oldDate = timeslot.getStartDate();
    if (timeslot.onetime) {
      this.props.updateTimeslotAllWeeks(rbcEvent.id, timeslot.toUpdateJSON());
    } else {
      const updatePromise = this.props.updateTimeslotOneWeek({
        fromDate: oldDate,
        id: rbcEvent.id,
        timeslot: timeslot.toUpdateJSON(),
      });
      updatePromise.then((movedEvent) => {
        this.setState({
          dndOldDate: oldDate,
          dndOldId: rbcEvent.id,
          dndNewId: movedEvent.id,
        });
      });
    }

    this.changeModeToSelecting();
  }

  onNavigate(actualCalendarDate) {
    return (date) => {
      // TUTO_RULE_IGNORE_DAY_CHANGE
      if (actualCalendarDate && isSameWeek(actualCalendarDate, date)) {
        return;
      }

      this.props.changeCurrentDate(date);
    };
  }

  onEditEvent(rbcEvent) {
    if (!this.props.permissions.allowCalendarWrite) {
      return;
    }

    // Don't open tooltip when lessons pane is open.
    if (this.isLessonPaneOpen()) {
      return;
    }

    const event = this.props.findEventByID(rbcEvent.id);
    if (!event) {
      return;
    }

    const editingEvent = copyEventToEdit(event);
    this.startEditingEvent(editingEvent);
  }

  onDeleteEvent(eventId) {
    const event = this.getEvents().filter((e) => e.id === eventId)[0];
    const promise = this.props.cancelEvent({ eventId });
    promise.then(() => {
      this.stopEditingEvent();
      if (!event.onetime) {
        // asks user if he wants to delete all events
        this.setState({ deletedEventId: eventId });
      }
    });
  }

  onReservate(rbcEvent) {
    // Scroll to 1 hour before event start so user sees full ReservationForm
    const timeToScroll = stringifyTime(new Date(rbcEvent.start.valueOf() - 60 * 60 * 1000));
    this.scrollToTime(timeToScroll);
    this.setState({ reservationActive: true, eventIdToReserve: rbcEvent.id });
  }

  // Returns active day classname e.g. CurrentDay6
  // The first day in the week has class CurrentDay1, last one CurrentDay7
  // If there is no activ day in this week it return an empty string
  getCurrentDayClass() {
    const { currentDate, momentLocaleData } = this.props;

    if (!isInCurrentWeek(currentDate) || this.isDayView()) {
      // no class name
      return '';
    }

    const curDayNum = currentDate.getDay();
    const localeFirstDay = momentLocaleData.firstDayOfWeek();
    const startsWithSunday = localeFirstDay === SUN_DAYN;

    let dayNum;

    if (
      (localeFirstDay === MON_DAYN && curDayNum === SUN_DAYN) ||
      (startsWithSunday && curDayNum === SAT_DAYN)
    ) {
      dayNum = 7;
    } else if (startsWithSunday && curDayNum === SUN_DAYN) {
      dayNum = 1;
    } else if (startsWithSunday) {
      dayNum = curDayNum + 1;
    } else {
      dayNum = curDayNum;
    }

    return `CurrentDay${dayNum}`;
  }

  getSelectedEvent() {
    return this.props.selectedEvent;
  }

  getEvents = () => {
    const { editingEvent } = this.state;
    const { calDataLoaded } = this.props;

    // TUTO_RULE_DISPLAY_EMPTY_CALENDAR_WHEN_FETCHING_DATA
    if (!calDataLoaded) {
      return [];
    }

    const stateEvents = [editingEvent].filter(filters.notNull);

    let events;
    if (editingEvent) {
      events = [...this.props.calEvents.filter(mkFilterEditingEvent(editingEvent)), ...stateEvents];
    } else {
      events = [...this.props.calEvents, ...stateEvents];
    }
    events = events.map(this.mapCalEventToBigRBCEvent).filter(filters.notNull);

    const { trainingsMap } = this.props;
    const eventsWithPrices = events.map((e) => {
      if (typeof trainingsMap[e.trainingId] !== 'undefined') {
        return {
          ...e,
          price: trainingsMap[e.trainingId].priceWithVat,
        };
      } else {
        return e;
      }
    });

    // if (this.props.isClient && this.props.location.pathname === '/calendar') {
    //   return eventsWithPrices.filter(e => e.clientReserved);
    // }
    return eventsWithPrices;
  };

  getAllClientsForEvent(event) {
    const clientsList = event.getVisibleClients();

    const clients = [
      ...clientsList,
      ...event.getAllClientEmails().map(clientIdentity.createWithEmail),
    ];

    if (event.clientData) clients.push(event.clientData);

    return clients;
  }

  getEditingEventClients() {
    const { editingEvent } = this.state;
    if (editingEvent === null) {
      this.handleEditingEventIsNull();
      return [];
    }

    return this.getAllClientsForEvent(editingEvent);
  }

  // for saturday and sunday returns 'left' position, otherwise 'right'
  getPositionForTimeslot(timeslot) {
    const { momentLocaleData } = this.props;
    const localeFirstDay = momentLocaleData.firstDayOfWeek();
    const startsWithSunday = localeFirstDay === SUN_DAYN;

    const dayNum = timeslot.getDay();
    if (startsWithSunday) {
      if (dayNum === FRI_DAYN || dayNum === SAT_DAYN) {
        return 'left';
      }
    } else if (dayNum === SAT_DAYN || dayNum === SUN_DAYN) {
      return 'left';
    }

    return 'right';
  }

  findEventStartingFirst() {
    const { calEvents } = this.props;
    if (!calEvents.length) {
      return null;
    }

    const findEarliest = (earliest, current) => {
      const start = current.getStartDate();
      const startEarliest = earliest.getStartDate();

      const earliestTime = [startEarliest.getHours(), startEarliest.getMinutes()];
      const startTime = [start.getHours(), start.getMinutes()];

      if (startTime[0] < earliestTime[0]) {
        return current;
      } else if (startTime[0] === earliestTime[0] && startTime[1] < earliestTime[1]) {
        return current;
      }
      return earliest;
    };

    return calEvents.reduce(findEarliest, calEvents[0]);
  }

  scrollCalendar() {
    // Selected event has most priority
    const eventToScroll = this.getSelectedEvent();
    if (eventToScroll) {
      const hasScrolled = this.scrollToEvent(eventToScroll);
      if (hasScrolled) {
        return;
      }
    }

    // Scroll to given hour (precedes specific hours)
    const time = this.props.currentTime;
    if (time) {
      const hasScrolled = this.scrollToTime(time);
      if (hasScrolled) {
        return;
      }
    }

    // Scroll to first hour
    const startingEvent = this.findEventStartingFirst();
    if (startingEvent) {
      const hasScrolled = this.scrollToEvent(startingEvent);
      if (hasScrolled) {
        return;
      }
    }

    this.scrollToCurTime();
  }

  scrollToTime(time) {
    const timeStrTuple = time.split(':');
    const hourValue = Number(timeStrTuple[0]) - MIN_HOUR_VALUE;
    const rbctgEl = document.querySelector(`.rbc-timeslot-group:nth-child(${hourValue + 1})`);
    if (rbctgEl) {
      rbctgEl.scrollIntoView();
      return true;
    }
    return false;
  }

  scrollToCurTime() {
    const timeIndicatorEl = document.getElementsByClassName('rbc-current-time-indicator')[0];

    if (timeIndicatorEl) {
      timeIndicatorEl.scrollIntoView();
      return true;
    }

    return false;
  }

  scrollToEvent(event) {
    const eventEl = document.getElementById(`tutoevent-${event.id}`);
    if (eventEl) {
      eventEl.scrollIntoView();
      return true;
    }

    return false;
  }

  canCreateOnClick() {
    // Create event on click is available only if there is no opened tooltip.
    return (
      !this.isEventEditing() &&
      !this.isEventCreating() &&
      !this.isDNDTooltipOpened() &&
      !this.isDayView()
    );
  }

  closeAllTooltips() {
    if (this.isDNDTooltipOpened()) {
      this.closeDNDTooltip();
    }
    if (this.isEventEditing()) {
      this.stopEditingEvent();
    }
    if (this.isEventCreating()) {
      this.stopCreatingEvent();
    }
    if (this.isReserving()) {
      this.stopReservation();
    }
  }

  // (): Promise<any>
  changeModeToSelecting() {
    if (this.props.viewOnlyMode) {
      return Promise.resolve();
    }

    if (this.state.calendarMode !== SELECTING_EVENTS) {
      return new Promise((resolve) => this.setState({ calendarMode: SELECTING_EVENTS }, resolve));
    }
    return Promise.resolve();
  }

  // (): Promise<any>
  changeModeToMoving() {
    if (this.props.viewOnlyMode) {
      return Promise.resolve();
    }
    if (this.state.calendarMode !== MOVING_EVENTS) {
      return new Promise((resolve) => this.setState({ calendarMode: MOVING_EVENTS }, resolve));
    }
    return Promise.resolve();
  }

  handleEditingEventIsNull() {
    console.error('editingEvent is null'); // eslint-disable-line
  }

  startEditingEvent(editingEvent) {
    // TUTO_RULE_ONLY_ONE_TOOLTIP_ON_CALENDAR
    const stoppedPromise = this.stopCreatingEvent();
    stoppedPromise.then(() => {
      this.setState({ editingEvent, isEventTooltipActive: true });
    });
  }

  startCreatingEvent(editingEvent) {
    // TUTO_RULE_ONLY_ONE_TOOLTIP_ON_CALENDAR
    this.stopEditingEvent();
    this.setState({
      editingEvent,
      isEventTooltipActive: true,
    });
  }

  stopReservation() {
    this.setState({
      reservationActive: false,
      eventIdToReserve: undefined,
      reservationDone: false,
    });
  }

  stopCreatingEvent() {
    return new Promise((resolve) => {
      this.setState(
        {
          isEventTooltipActive: false,
          editingEvent: null,
        },
        resolve
      );
    });
  }

  stopEditingEvent() {
    this.setState({
      isEventTooltipActive: false,
      editingEvent: null,
    });
  }

  closeDNDTooltip() {
    if (this.isDNDTooltipOpened()) {
      this.setState({ dndOldId: undefined, dndNewId: undefined });
    }
  }

  closePaymentModal() {
    this.setState({
      isPaymentModalOpened: false,
    });
  }

  addClientById(client) {
    const { editingEvent } = this.state;
    if (editingEvent === null) {
      this.handleEditingEventIsNull();
      return;
    }
    const updatedEvent = calendarEvent.addClientById(editingEvent, client);

    this.setState({
      editingEvent: updatedEvent,
    });
  }

  addClientWithEmail(email) {
    const client = clientIdentity.createWithEmail(email);

    const { editingEvent } = this.state;
    // should be an invariant
    if (editingEvent === null) {
      return null;
    }

    const clientFound = editingEvent.isClientIn(client);
    if (!clientFound) {
      const updatedEvent = calendarEvent.addClientWithEmail(editingEvent, email);
      this.setState({
        editingEvent: updatedEvent,
      });
      return client;
    }
    return null;
  }

  addClientWithData(data) {
    const clientData = clientIdentity.createWithData(data);

    const { editingEvent } = this.state;
    // should be an invariant
    if (editingEvent === null) {
      return null;
    }

    const clientFound = editingEvent.hasClientData();
    if (!clientFound) {
      const updatedEvent = calendarEvent.addClientWithData(editingEvent, clientData);
      this.setState({ editingEvent: updatedEvent });
      return clientData;
    } else {
      const updatedEvent = calendarEvent.adjustClientData(editingEvent, clientData);
      this.setState({ editingEvent: updatedEvent });
      return clientData;
    }
  }

  removeClientData() {
    const { editingEvent } = this.state;
    // should be an invariant
    if (editingEvent === null) {
      return null;
    }

    const updatedEvent = calendarEvent.removeClientData(editingEvent);
    this.setState({ editingEvent: updatedEvent });
    return updatedEvent;
  }

  removeClient(client) {
    const { editingEvent } = this.state;
    if (editingEvent === null) {
      this.handleEditingEventIsNull();
      return;
    }
    const updatedEvent = calendarEvent.removeClient(editingEvent, client);
    this.setState({
      editingEvent: updatedEvent,
    });
  }

  isReserving() {
    return this.state.reservationActive;
  }

  isCreatingEvent() {
    const { editingEvent } = this.state;
    if (!editingEvent) {
      return false;
    }
    return editingEvent.isCreating;
  }

  isDayView() {
    const { viewMode } = this.state;
    return viewMode === SMALL_VIEW_MODE;
  }

  isDNDTooltipOpened() {
    return this.state.dndNewId !== undefined;
  }

  isSelectable() {
    return this.state.calendarMode === SELECTING_EVENTS;
  }

  isMoveable() {
    return this.state.calendarMode !== VIEW_ONLY;
  }

  isLessonPaneOpen() {
    const { viewMode } = this.state;
    return viewMode === SMALL_VIEW_MODE;
  }

  isEventEditing() {
    const { editingEvent } = this.state;
    return editingEvent !== null && !editingEvent.isCreating;
  }

  isEventCreating() {
    const { editingEvent } = this.state;
    return editingEvent !== null && editingEvent.isCreating;
  }

  // returns true if event is either being edited or created
  isEventEditingOrCreating() {
    const { editingEvent } = this.state;
    return editingEvent !== null;
  }

  // true if event is in creating mode
  isEventBeingCreated(event) {
    return (
      this.isEventEditingOrCreating() &&
      event.isCreating &&
      this.state.editingEvent !== null &&
      this.state.editingEvent.id === event.id
    );
  }

  // true if event is in editing mode
  isEventBeingEdited(event) {
    return (
      this.isEventEditingOrCreating() &&
      !event.isCreating &&
      this.state.editingEvent !== null &&
      this.state.editingEvent.id === event.id
    );
  }

  eventStyleGetter(event, start, end, isSelected) {
    const { isEmployee } = this.props;

    const borderWidth = '0 0 3px 0';
    let backgroundColor = theme.neutralColor;
    let borderColor = 'white';
    let color = '#454545';
    let width;

    const selectedEvent = this.getSelectedEvent();
    const isOpened = selectedEvent && String(selectedEvent.id) === String(event.id);

    // props to change styling
    const { isClient, isPublic } = this.props;

    // Set label color
    if (isEmployee || !isInPast(event.start) || event.clientReserved) {
      if (event.labelColor && event.isOpenToReservate) {
        backgroundColor = this.props.selectLightenColor(event.labelColor);
        borderColor = event.labelColor;
      } else if (event.labelColor) {
        backgroundColor = event.labelColor;
      } else if (event.isOpenToReservate) {
        // backgroundColor = '#e4e4e4';
        backgroundColor = '#d0e6f9';
        borderColor = '#78b8ee';
      }
    }

    // When the event is opened on side pane
    if (isOpened) {
      color = '#454545';
      width = '100%';
      borderColor = theme.activeGreenColor;
    }

    // Should we set neutral color?
    if (!event.isOpenToReservate) {
      if ((isClient && !event.clientIsIn && !event.clientReserved) || isPublic) {
        backgroundColor = theme.neutralColor;
      }
    }

    const style = {
      backgroundColor,
      color,
      width,
      borderColor,
      borderWidth:
        isClient || isPublic ? (!event.isOpenToReservate ? borderWidth : 0) : borderWidth,
      borderRadius: '0px',
      borderStyle: 'solid',
      display: 'block',
    };

    // Add classes for some states so we can use a tooltip
    let className;
    if (event.isEditing) {
      className = 'ActiveEditingEvent';
    } else if (event.isCreating) {
      className = 'ActiveCreatingEvent';
    }

    return {
      style,
      className,
    };
  }

  mapCalEventToBigRBCEvent(calEvent) {
    const isEditing = this.isEventBeingEdited(calEvent);
    const isCreating = this.isEventBeingCreated(calEvent);
    const isViewOnly = this.props.viewOnlyMode;
    const isDayView = this.isDayView();

    // TUTO_RULE_EVENT_HAS_ONE_TIMESLOT
    const timeslot = calEvent.getOneTimeslot();
    const { reservation } = calEvent;

    const { isClient, loggedUserId } = this.props;

    // See TUTO_IMPL_CHECK_STUDENT_IS_IN if you find it cumberstone
    const clientIsIn =
      isClient && !!calEvent.students.map((_) => _.id).find(whysfn.findId(loggedUserId));
    // See TUTO_IMPL_STUDENT_RESERVED_EVENT if you find it cumberstone
    const clientReserved =
      (reservation && isClient && !!reservation.reservedBy.find(whysfn.findId(loggedUserId))) ||
      false;
    const training = this.props.findTrainingById(calEvent.trainingId);

    const labelName = (training && training.name) || '';
    const labelColor = (training && training.color) || '';

    const students = this.getAllClientsForEvent(calEvent);
    const hasClients = !!students.length;

    const { forReservation } = timeslot;

    return {
      id: calEvent.id,
      trainingId: calEvent.trainingId,

      labelName,
      labelColor,

      // event state
      isEditing,
      isCreating,

      // calendar state
      isViewOnly,
      isDayView,

      onetime: timeslot.onetime,

      // start is default startAccessor
      start: timeslot.getStartDate(),
      // end is default endAccessor
      end: timeslot.getEndDate(),

      students,
      clientIsIn,
      clientReserved,
      forReservation,
      isOpenToReservate: forReservation && calEvent.isOpenToReservate(),
      isReserved: forReservation && calEvent.isReserved(),
      isDone: (!forReservation && hasClients) || (forReservation && calEvent.isPaid()),
      reservation,
    };
  }

  renderMoveConfirmTooltip() {
    const { dndNewId } = this.state;
    if (!dndNewId) {
      return null;
    }

    const parentSelector = `#tutoevent-${dndNewId}`;
    const event = this.props.findEventByID(dndNewId);
    if (!event) {
      if (IS_DEBUG) {
        console.warn('event not found'); // eslint-disable-line
      }
      return null;
    }

    const onTooltipClose = () => {
      this.closeDNDTooltip();
    };

    const timeslot = event.getOneTimeslot();

    // closeOnOutsideClick doesnt work properly
    // (probably because the tooltip component is rendered outside of body)
    return (
      <DropEventTooltip
        onUpdateAll={() => {
          const payload = timeslot.toUpdateJSON();
          const { dndOldDate, dndOldId } = this.state;
          if (dndOldId && dndOldDate) {
            // TUTO_PROTOTYPE: fromDate is correct?
            this.props.updateTimeslotOneWeek({
              id: dndOldId,
              onetimeId: dndNewId,
              updateAllWeeks: true,
              timeslot: payload,
              fromDate: dndOldDate,
            });
          }
          onTooltipClose();
        }}
        onClose={onTooltipClose}
        position={this.getPositionForTimeslot(timeslot)}
        parent={parentSelector}
      />
    );
  }

  renderDeleteDialog() {
    const { deletedEventId } = this.state;

    const closeDialog = () => this.setState({ deletedEventId: undefined });
    const onDeleteAll = () => {
      const promise = this.props.deleteRecurringEvent(deletedEventId);
      promise.then(closeDialog);
    };

    return <DeleteEventDialog onClose={closeDialog} onDeleteAll={onDeleteAll} />;
  }

  renderReservationTooltip() {
    const { eventIdToReserve } = this.state;
    if (!eventIdToReserve) {
      return null;
    }

    const eventToReserve = this.props.findEventByID(eventIdToReserve);
    if (!eventToReserve) {
      return null;
    }
    const timeslot = eventToReserve.getOneTimeslot();
    const parentSelector = `#tutoevent-${eventIdToReserve}`;

    const onClose = () => {
      this.stopReservation();
    };

    const onDone = (reservation) => {
      this.setState({ reservation }, () => {
        this.props.refetchCalEvents().then(() => {
          if (reservation.price === 0) {
            this.stopReservation();
          } else {
            this.setState({ reservationDone: true, isPaymentModalOpened: true });
          }
        });
      });
    };

    const event = this.props.calEvents.find((e) => e.id === eventIdToReserve);

    return (
      <ToolTip
        active
        position={this.getPositionForTimeslot(timeslot)}
        arrow="center"
        parent={parentSelector}
      >
        <Closeable closeOnEsc closeOnOutsideClick isOpened onClose={onClose}>
          <ReservationFormContainer
            isPublic={this.props.isPublic}
            branchId={this.props.branchId}
            permissions={this.props.permissions}
            publicUrl={this.props.publicUrl}
            start={timeslot.getStartDate()}
            end={timeslot.getEndDate()}
            eventId={eventIdToReserve}
            onReservationEnd={onClose}
            onFailed={this.props.refetchCalEvents}
            onDone={onDone}
            isDone={this.state.reservationDone}
            trainings={this.props.trainingsMap}
            trainingId={event ? event.trainingId : null}
            reservation={this.state.reservation}
          />
        </Closeable>
      </ToolTip>
    );
  }

  renderEventTooltip() {
    const { onEventDataChange, onUpdateCancel, onCreateCancel } = this;

    const isCreating = this.isCreatingEvent();

    const { editingEvent } = this.state;
    // should never happen
    if (editingEvent === null) {
      this.handleEditingEventIsNull();
      return null;
    }

    const eventStudentsState = this.getEditingEventClients();

    // TUTO_RULE_EVENT_HAS_ONE_TIMESLOT
    const timeslot = editingEvent.getOneTimeslot();
    const isReserved =
      timeslot.forReservation && editingEvent.getReservationStatus() === 'reserved';

    const eventData = {
      eventId: editingEvent.id,
      trainingId: editingEvent.trainingId,
      timeslotId: timeslot.id,
      onetime: timeslot.onetime,
      // reservationStatus: timeslot.forReservation && editingEvent.getReservationStatus(),
      isPaid: (timeslot.forReservation && editingEvent.getReservationStatus() === 'paid') || false,
      isOpen: (timeslot.forReservation && editingEvent.getReservationStatus() === 'open') || false,
      isReserved: isReserved || false,
      reservationId: isReserved && editingEvent.reservation ? editingEvent.reservation.id : null,
      start: timeslot.getStartDate(),
      end: timeslot.getEndDate(),
      activeTo: timeslot.activeTo || addYears(new Date(), 1),
      students: eventStudentsState,
      clientData: editingEvent.clientData,
    };

    // todo...
    let parentSelector = '.ActiveEditingEvent';
    if (isCreating) {
      parentSelector = '.ActiveCreatingEvent';
    }

    const onCreateReservation = () => {
      this.props.refetchCalEvents();
      this.closeAllTooltips();
    };

    const {
      branchId,
      publicUrl,
      trainingsMap,
      // clientIds,
    } = this.props;
    const onCancel = isCreating ? onCreateCancel : onUpdateCancel;

    const eventForm = (
      <EventForm
        branchId={branchId}
        permissions={this.props.permissions}
        publicUrl={publicUrl}
        eventData={eventData}
        onTrainingChange={onEventDataChange}
        onSubmit={isCreating ? this.onCreateDone : this.onUpdateDone}
        onCreateReservation={onCreateReservation}
        onCancel={onCancel}
        onDeleteEvent={this.onDeleteEvent}
        removeClient={this.removeClient}
        addClientWithEmail={this.addClientWithEmail}
        addClientWithData={this.addClientWithData}
        removeClientData={this.removeClientData}
        addClientById={this.addClientById}
        trainings={trainingsMap}
        isEditing={!isCreating}
      />
    );

    return (
      <ToolTip
        active
        position={this.getPositionForTimeslot(timeslot)}
        arrow="center"
        parent={parentSelector}
      >
        <Closeable closeOnEsc isOpened onClose={onCancel}>
          {eventForm}
        </Closeable>
      </ToolTip>
    );
  }

  renderLessons() {
    const selectedEvent = this.getSelectedEvent();
    if (!selectedEvent) {
      this.handleEditingEventIsNull();
      return null;
    }

    const { branchId } = this.props;
    return (
      <div className="CalendarLessons">
        <div className="CalendarNevitLessons">
          <AppForms
            branchId={branchId}
            event={selectedEvent}
            onClose={this.onUnselectEvent}
            clientId={(selectedEvent.students[0] && selectedEvent.students[0].id) || null}
          />
        </div>
      </div>
    );
  }

  processPayment = async () => {
    const { reservation } = this.state;

    if (reservation) {
      const { id, price } = reservation;

      let response = await request('/payments/make', {
        method: 'POST',
        data: {
          order_id: id,
          // Price cannot be 0
          total: price || 1,
          description: 'Rezervace kurzu',
        },
      });

      const paymentId = response.data.payment_id;
      window.location.replace(`/payments/handle?payment_id=${paymentId}`);
    }
  };

  renderPaymentModalContent() {
    const { reservation } = this.state;

    if (!reservation) {
      this.stopReservation();
      this.closePaymentModal();
      return null;
    }

    const { calEvents, trainingsMap } = this.props;
    const reservationId = reservation.id.toString();

    const reservationEvent = calEvents.filter((c) => {
      if (c.reservation) {
        return c.reservation.id === reservationId;
      } else {
        return false;
      }
    })[0];

    if (!reservationEvent) {
      this.stopReservation();
      this.closePaymentModal();
      return null;
    }

    const canPayLater = trainingsMap[reservationEvent.trainingId].canPayLater;

    return (
      <div>
        <FlexBlock hcenter>
          <h4>Děkujeme za rezervaci, vyberte způsob platby</h4>
        </FlexBlock>
        <PageSpace paddingBase={0.2} />
        <FlexBlock hcenter spaceBetween={canPayLater}>
          <RaisedButton
            icon={<CreditCardIcon />}
            primary
            label="Zaplatit kartou online"
            onClick={this.processPayment}
          />

          <PaddedBlock inline left>
            {canPayLater && (
              <RaisedButton
                icon={<PayLaterIcon />}
                primary
                label="Zaplatit na místě"
                onClick={() => {
                  this.stopReservation();
                  this.closePaymentModal();
                  this.props.history.push('/payment-success?online=false');
                }}
              />
            )}
          </PaddedBlock>
        </FlexBlock>
      </div>
    );
  }

  render() {
    const { viewMode } = this.state;

    const isToolbarVisible = !(viewMode === SMALL_VIEW_MODE);
    const isDayView = this.isDayView();

    const selectedEvent = this.getSelectedEvent();
    const actualCalendarDate = isDayView
      ? selectedEvent && selectedEvent.getStartDate()
      : this.props.currentDate;

    // TUTO_REFACTOR: moveable is not necessary anymore
    // moveable={this.isMoveable()}
    const bigCalendarRendered = (
      <DndBigCalendar
        className="DndBigCalendar"
        toolbar={isToolbarVisible}
        events={this.getEvents()}
        step={15}
        timeslots={4}
        min={MIN_HOUR_TIME}
        defaultView="week"
        view={isDayView ? 'day' : 'week'}
        selectable={this.isSelectable()}
        onSelectSlot={this.onSelectSlot}
        onView={() => {}}
        onNavigate={this.onNavigate(actualCalendarDate)}
        date={actualCalendarDate}
        onEventDrop={this.onEventMove}
        messages={this.messages}
        eventPropGetter={this.eventStyleGetter}
        views={['day', 'week']}
        components={{
          toolbar: CalendarToolbar,
          event: this.eventComponent,
        }}
      />
    );

    const isSplitView = isDayView;

    const activeDayClass = this.getCurrentDayClass();
    const classes = cx(
      `TutoCalendar ${(this.props.isPublic && 'TutoCalendar--small-padding') || ''} ${
        (this.props.isReallyPublic && 'TutoCalendar--no-padding') || ''
      }`,
      activeDayClass,
      {
        TutoCalendarSplitView: isSplitView,
      }
    );

    let calendarWrapped = bigCalendarRendered;

    // Add overlay when an event is selected.
    // or this.props.selectedEvent
    if (isDayView) {
      calendarWrapped = (
        <FlexBlock fullWidth>
          <ListenOnClick onDoubleClick={this.onUnselectEvent}>{bigCalendarRendered}</ListenOnClick>
          {selectedEvent ? this.renderLessons() : null}
        </FlexBlock>
      );
    }

    return (
      <div className={classes}>
        {calendarWrapped}
        {this.state.isEventTooltipActive ? this.renderEventTooltip() : null}
        {this.state.reservationActive ? this.renderReservationTooltip() : null}
        {this.state.dndNewId ? this.renderMoveConfirmTooltip() : null}
        {this.state.deletedEventId ? this.renderDeleteDialog() : null}
        <Modal
          isOpen={this.state.isPaymentModalOpened}
          // We don't want the user to be able to close the payment modal
          onRequestClose={() => {}}
          style={modalStyles}
          contentLabel="Platba rezervace"
        >
          {this.state.reservation &&
            this.state.isPaymentModalOpened &&
            this.renderPaymentModalContent()}
        </Modal>
      </div>
    );
  }
}

// dnd_functionality
export default DragDropContext(HTML5Backend)(TutoCalendar);
