import React, { Component } from 'react';

import ListenOnClick from './ListenOnClick';

const ENTER_KEY = 13;
const SPACE_KEY = 32;

// https://github.com/tj/react-click-outside
// https://github.com/tajo/react-portal

class ClickWrap extends Component {
  constructor(props) {
    super(props);
    this.onAnyClick = this.onAnyClick.bind(this);
  }

  componentDidMount() {
    const { capture } = this.props;
    document.addEventListener('click', this.onAnyClick, capture);
  }

  componentWillUnmount() {
    const { capture } = this.props;
    document.removeEventListener('click', this.onAnyClick, capture);
  }

  onAnyClick(e) {
    const { outside } = this.props;
    const containsTargetEl = this.containerEl.contains(e.target);
    if (outside && !containsTargetEl) {
      this.props.onClick(e);
    }
    if (!outside && containsTargetEl) {
      this.props.onClick(e);
    }
  }

  render() {
    const restProps = Object.assign({}, this.props);
    delete restProps.onClick;
    delete restProps.outside;
    delete restProps.capture;

    return (
      <span
        ref={(ref) => {
          this.containerEl = ref;
        }}
        {...restProps}
      />
    );
  }
}

ClickWrap.defaultProps = {
  capture: false,
};

export function OutsideClick(props) {
  return <ClickWrap {...props} outside />;
}

// We don't need to use non-react events for inside click.
// This is just a simple pattern/semantic component.
export function InsideClick(props) {
  const passProps = Object.assign({}, props);
  delete passProps.cursorPointer;

  const { cursorPointer } = props;
  const style = cursorPointer && { cursor: 'pointer' };
  return <span {...passProps} style={style} />;
}

InsideClick.defaultProps = {
  cursorPointer: true,
};

export function StopClick(props) {
  const onClick = (e) => {
    e.stopPropagation();
  };

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return <span onClick={onClick} {...props} />;
}

export function MakeClickable(props) {
  const { children, onClick, cursorPointer } = props;

  const style = {
    ...props.style,
    cursor: cursorPointer && 'pointer',
  };

  const onKeyPress = (e) => {
    const keyCode = e.which;
    if (keyCode === SPACE_KEY || keyCode === ENTER_KEY) {
      // Prevent the default action to stop scrolling when space is pressed
      e.preventDefault();
      onClick();
    }
  };

  const mergeProps = {
    onClick,
    style,
    role: 'button',
    tabIndex: 0,
    onKeyPress,
  };

  return React.cloneElement(children, mergeProps);
}

MakeClickable.defaultProps = {
  cursorPointer: true,
};

export { ListenOnClick };
