import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Block from 'whys-react/ui/Block';

import {
  PageContainer,
  EmployeeCalendarContainer,
  ClientCalendarContainer,
  PublicCalendarContainer,
} from '@components';

import { UserContext, BranchContext } from '@utils';

const CalendarPage = ({ isPublic }) => {
  const {
    state: { user },
  } = useContext(UserContext);
  const {
    state: { branchId },
  } = useContext(BranchContext);
  const params = useParams();

  if (!branchId && !isPublic) return null;

  if (!isPublic && (user.type === 'superuser' || user.type === 'employee')) {
    return <EmployeeCalendarContainer branchId={branchId} />;
  }

  if (!isPublic && user.type === 'client') {
    return <ClientCalendarContainer branchId={branchId} />;
  }

  if (isPublic && !params.publicUrl) {
    // Something is wrong
    return null;
  }

  // Public
  return <PublicCalendarContainer publicUrl={params.publicUrl} />;
};

export default ({ isPublic }) => {
  const {
    state: { loggedIn, user },
  } = useContext(UserContext);

  const isReallyPublic = !loggedIn;

  let text = 'Takto vypadá váš veřejný kalendář.';
  if (user && user.type === 'employee') {
    text = 'Takto vypadá veřejný kalendář vaší pobočky.';
  }

  return (
    <PageContainer isPublic={isPublic}>
      {
        // Employee is viewing public page
        isPublic && !isReallyPublic && (
          <div style={{ position: 'absolute', width: '100%', zIndex: 10 }}>
            <Block backgroundColor="#f2f4fb" width="100%" textAlign="center" padding=".2rem">
              <span>{text}</span> <Link to="/calendar">Upravit kalendář v aplikaci</Link>
            </Block>
          </div>
        )
      }
      <CalendarPage isPublic={isPublic} />
    </PageContainer>
  );
};
