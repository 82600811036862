import { createContext } from 'react';

export const AttachmentsContext = createContext();

export const initialAttachmentsState = {
  hasFetched: false,
  attachments: [],
  targetPage: 1,
  isFiltering: false,
  filteredAttachments: [],
  filteredPage: 1,
};

export const attachmentsReducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        hasFetched: true,
        attachments: action.attachments,
        targetPage: action.targetPage,
      };
    case 'RESET':
      return initialAttachmentsState;
    case 'ADD':
      return {
        ...state,
        attachments: [...state.attachments, ...action.attachments],
      };
    case 'DELETE':
      return {
        ...state,
        attachments: state.attachments.filter((_) => _.id !== action.id),
      };
    case 'FILTER':
      return {
        ...state,
        isFiltering: true,
        filteredAttachments: action.attachments,
        filteredPage: action.targetPage,
      };
    case 'STOP_FILTER':
      return {
        ...state,
        isFiltering: false,
        filteredAttachments: [],
        filteredPage: 1,
      };
    default:
      return state;
  }
};

export const setAttachments = ({ attachments, targetPage }) => ({
  type: 'SET',
  attachments,
  targetPage,
});

export const resetAttachments = () => ({
  type: 'RESET',
});

export const addAttachments = (attachments) => ({
  type: 'ADD',
  attachments,
});

export const deleteAttachment = (id) => ({
  type: 'DELETE',
  id,
});

export const filterAttachments = ({ attachments, targetPage }) => ({
  type: 'FILTER',
  attachments,
  targetPage,
});

export const stopAttachmentsFilter = () => ({
  type: 'STOP_FILTER',
});
