import React, { Component } from 'react';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import GridList from 'material-ui/GridList/GridList';
import GridTile from 'material-ui/GridList/GridTile';
import Snackbar from 'material-ui/Snackbar';

import { hasNoTrueValue } from '@oldUtils/object';
import { FlexBlock, PageSpace } from '@oldUI';

import { senaptecValidators } from './validators';

import { defaultSenaptecFormData } from './parseAppFormsData';

import styles from './SenaptecForm.module.css';

const getNumericValue = (val) => {
  return parseInt(val) || 0;
};

const isValidValue = (val) => {
  return !isNaN(parseInt(val));
};

const errorStyle = {
  position: 'absolute',
  bottom: '-10px',
  zIndex: 2,
};

class SenaptecForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.data,

      errors: {
        visualClarityRight: false,
        visualClarityLeft: false,
        visualClarityBoth: false,
        contractSensitivity: false,
        depthPerceptionRight: false,
        depthPerceptionLeft: false,
        depthPerceptionPrimary: false,
        nearFarQuickness: false,
        perceptionSpan: false,
        multiobjectTracking: false,
        reactionTime: false,
      },

      submitError: false,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const hasError = this.validate();

    if (!hasError) {
      this.props.onSenaptecFormSubmit(this.state, this.props.index);
    } else {
      this.setState({ submitError: true });
    }
  };

  onChangeFieldValue = (field, value) => {
    value = getNumericValue(value);
    if (!value || isValidValue(value)) {
      this.setState({
        [field]: value,
        errors: { ...this.state.errors, [field]: false },
      });
    }
  };

  validateTarget = (field) => {
    const value = this.state[field];
    const isValid = senaptecValidators[field](value);

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: !isValid,
      },
    }));
  };

  validate = () => {
    const errors = { ...this.state.errors };
    let hasError = false;

    Object.keys(this.state).forEach((key) => {
      if (key !== 'errors' && key !== 'submitError' && key !== 'actualDate' && key !== 'lessonId') {
        const value = parseFloat(this.state[key]);
        const isValid = senaptecValidators[key](value);
        if (!isValid) {
          errors[key] = true;
          hasError = true;
        }
      }
    });

    this.setState({ errors });
    return hasError;
  };

  renderFields() {
    return (
      <GridList cols={12} cellHeight="auto">
        {/* <GridTile cols={12}>
          <h4>Stuff</h4>
        </GridTile> */}
        <GridTile cols={6} style={{ paddingBottom: 20 }}>
          <TextField
            className={styles.LeftInput}
            floatingLabelText="Ostrost vidění pravé"
            value={this.state.visualClarityRight}
            onChange={(e) => this.onChangeFieldValue('visualClarityRight', e.target.value)}
            onBlur={() => this.validateTarget('visualClarityRight')}
            errorText={this.state.errors.visualClarityRight ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="visualClarityRight"
            fullWidth
          />
          <TextField
            className={styles.LeftInput}
            floatingLabelText="Ostrost vidění bino"
            value={this.state.visualClarityBoth}
            onChange={(e) => this.onChangeFieldValue('visualClarityBoth', e.target.value)}
            onBlur={() => this.validateTarget('visualClarityBoth')}
            errorText={this.state.errors.visualClarityBoth ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="visualClarityBoth"
            fullWidth
          />
          <TextField
            className={styles.LeftInput}
            floatingLabelText="Hloubka ostrosti pravé"
            value={this.state.depthPerceptionRight}
            onChange={(e) => this.onChangeFieldValue('depthPerceptionRight', e.target.value)}
            onBlur={() => this.validateTarget('depthPerceptionRight')}
            errorText={this.state.errors.depthPerceptionRight ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="depthPerceptionRight"
            fullWidth
          />
          <TextField
            className={styles.LeftInput}
            floatingLabelText="Hloubka ostrosti bino"
            value={this.state.depthPerceptionPrimary}
            onChange={(e) => this.onChangeFieldValue('depthPerceptionPrimary', e.target.value)}
            onBlur={() => this.validateTarget('depthPerceptionPrimary')}
            errorText={this.state.errors.depthPerceptionPrimary ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="depthPerceptionPrimary"
            fullWidth
          />
          <TextField
            className={styles.LeftInput}
            floatingLabelText="Vizualizace / krátkodobá paměť"
            value={this.state.perceptionSpan}
            onChange={(e) => this.onChangeFieldValue('perceptionSpan', e.target.value)}
            onBlur={() => this.validateTarget('perceptionSpan')}
            errorText={this.state.errors.perceptionSpan ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="perceptionSpan"
            fullWidth
          />
          <TextField
            className={styles.LeftInput}
            floatingLabelText="Reakční čas"
            value={this.state.reactionTime}
            onChange={(e) => this.onChangeFieldValue('reactionTime', e.target.value)}
            onBlur={() => this.validateTarget('reactionTime')}
            errorText={this.state.errors.reactionTime ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="reactionTime"
            fullWidth
          />
        </GridTile>
        <GridTile cols={6} style={{ paddingBottom: 20 }}>
          <TextField
            className={styles.RightInput}
            floatingLabelText="Ostrost vidění levé"
            value={this.state.visualClarityLeft}
            onChange={(e) => this.onChangeFieldValue('visualClarityLeft', e.target.value)}
            onBlur={() => this.validateTarget('visualClarityLeft')}
            errorText={this.state.errors.visualClarityLeft ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="visualClarityLeft"
            fullWidth
          />
          <TextField
            className={styles.RightInput}
            floatingLabelText="Kontrastní citlivost"
            value={this.state.contractSensitivity}
            onChange={(e) => this.onChangeFieldValue('contractSensitivity', e.target.value)}
            onBlur={() => this.validateTarget('contractSensitivity')}
            errorText={this.state.errors.contractSensitivity ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="contractSensitivity"
            fullWidth
          />
          <TextField
            className={styles.RightInput}
            floatingLabelText="Hloubka ostrosti levé"
            value={this.state.depthPerceptionLeft}
            onChange={(e) => this.onChangeFieldValue('depthPerceptionLeft', e.target.value)}
            onBlur={() => this.validateTarget('depthPerceptionLeft')}
            errorText={this.state.errors.depthPerceptionLeft ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="depthPerceptionLeft"
            fullWidth
          />
          <TextField
            className={styles.RightInput}
            floatingLabelText="Přechod dálka / blízko"
            value={this.state.nearFarQuickness}
            onChange={(e) => this.onChangeFieldValue('nearFarQuickness', e.target.value)}
            onBlur={() => this.validateTarget('nearFarQuickness')}
            errorText={this.state.errors.nearFarQuickness ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="nearFarQuickness"
            fullWidth
          />
          <TextField
            className={styles.RightInput}
            floatingLabelText="Různě se pohybující předměty"
            value={this.state.multiobjectTracking}
            onChange={(e) => this.onChangeFieldValue('multiobjectTracking', e.target.value)}
            onBlur={() => this.validateTarget('multiobjectTracking')}
            errorText={this.state.errors.multiobjectTracking ? 'Platný rozsah 0 - 100' : ''}
            errorStyle={errorStyle}
            name="multiobjectTracking"
            fullWidth
          />
        </GridTile>
        {/* <GridTile cols={6}>
          <div style={{ margin: '14px 0 18px 0', width: '60%' }}>
            <SelectField
              label="Senzorická *"
              value={this.state.dominance.sensory}
              onChange={(event) => {
                const { value } = event.target;
                this.onChange('dominance', 'sensory', value);
              }}
              name="sensoric"
              error={this.state.errors.dominance.sensory}
              values={['Pravé', 'Levé', 'Smíšené']}
              incrementIndex
            />
          </div>
        </GridTile> */}
      </GridList>
    );
  }

  renderSubmitButton() {
    return (
      <FlexBlock hcenter marginTop="10px">
        <RaisedButton type="submit" label="Uložit" primary />
      </FlexBlock>
    );
  }

  render() {
    return (
      <>
        <form onSubmit={this.onSubmit}>
          <GridList cols={12} cellHeight="auto">
            {this.renderFields()}
            <GridTile cols={12}>{this.renderSubmitButton()}</GridTile>
            <PageSpace />
          </GridList>
        </form>
        <Snackbar
          open={this.state.submitError}
          message="Někde je zadaná špatná hodnota, zkontrolujte prosím hodnoty ve formuláři"
          autoHideDuration={5000}
          onRequestClose={() => this.setState({ submitError: false })}
          bodyStyle={{ backgroundColor: '#d40000' }}
        />
      </>
    );
  }
}

export default SenaptecForm;
