import * as React from 'react';

import * as tutoUI from '@oldUI';
import { DangerBtn } from '@oldUI/buttons';

import { InvisibleFormDivider } from '@oldComponents/eventFormUIs';

// type ReservationStatusInfoProps = {|
//   status: ReservationStatusType,
//   onCancel: Function,
//   onDelete: Function,
//   renderTrainings: Function,
//   renderClients: Function,
//   isTrainingRequired: Function,
//   isClientRequired: Function,
//   onSubmit: Function,
// |};

function ReservationStatusInfo(props) {
  const { status } = props;

  if (status === 'reserved') {
    return (
      <div>
        <div>Tento trénink je rezervovaný.</div>
        <tutoUI.VSpace tiny />

        {props.permissions.allowCalendarWrite && (
          <tutoUI.FlexBlock vcenter hcenter fullWidth>
            <DangerBtn onClick={props.onCancel} fullWidth>
              Zrušit rezervaci
            </DangerBtn>
          </tutoUI.FlexBlock>
        )}
      </div>
    );
  }

  if (status === 'open') {
    return (
      <div>
        <div>Tento trénink je pro rezervaci.</div>
        <tutoUI.VSpace />
        {props.renderTrainings()}
        <InvisibleFormDivider />
        {props.renderClients()}
        <InvisibleFormDivider />

        <div className="SubmitBtns">
          {props.permissions.allowCalendarWrite && (
            <DangerBtn onClick={props.onDelete} fullWidth style={{ marginRight: '10px' }}>
              Zrušit trénink
            </DangerBtn>
          )}
          <button
            disabled={props.isTrainingRequired || props.isClientRequired}
            className="BtnDefault BtnSmall"
            onClick={props.onSubmit}
          >
            Obsadit
          </button>
        </div>
      </div>
    );
  }

  return null;
}

export default ReservationStatusInfo;
