import React, { useContext } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import { PageContainer, TrainingsManagement } from '@components';

import { UserContext, BranchContext } from '@utils';

const trainingsQuery = gql`
  query trainingsQuery($branchId: String) {
    branchTrainings(branchId: $branchId, filterDeleted: true) {
      id
      name
      color
      description
      duration
      priceWithoutVat
      priceWithVat
      canPayLater
      isPublic
    }
  }
`;

const changeTrainingMutation = gql`
  mutation changeTraining(
    $branchId: String
    $name: String
    $color: HexColor
    $description: String
    $duration: Int
    $priceWithoutVat: Float
    $priceWithVat: Float
    $id: ApplicationID!
    $canPayLater: Boolean
    $isPublic: Boolean
  ) {
    changeTraining(
      branchId: $branchId
      training: {
        id: $id
        name: $name
        description: $description
        duration: $duration
        priceWithoutVat: $priceWithoutVat
        priceWithVat: $priceWithVat
        color: $color
        canPayLater: $canPayLater
        isPublic: $isPublic
      }
    ) {
      ok
      training {
        id
        name
        description
        color
        duration
        priceWithoutVat
        priceWithVat
        canPayLater
        isPublic
      }
    }
  }
`;

const createTrainingMutation = gql`
  mutation createTraining($branchId: String, $createInput: CreateTrainingInput!) {
    createTraining(branchId: $branchId, training: $createInput) {
      ok
      training {
        id
        name
        color
        description
        duration
        priceWithoutVat
        priceWithVat
        canPayLater
        isPublic
      }
    }
  }
`;

const deleteTrainingMutation = gql`
  mutation deleteTraining($branchId: String, $id: ApplicationID!) {
    deleteTraining(branchId: $branchId, id: $id) {
      ok
    }
  }
`;

const Trainings = ({ branchId }) => {
  if (!branchId) return null;

  const {
    state: { user },
  } = useContext(UserContext);

  const { data, loading } = useQuery(trainingsQuery, {
    variables: { branchId },
  });

  const [callCreateTrainingMutation] = useMutation(createTrainingMutation);
  const [callChangeTrainingMutation] = useMutation(changeTrainingMutation);
  const [callDeleleTrainingMutation] = useMutation(deleteTrainingMutation);

  const trainings = loading ? [] : data.branchTrainings;

  return (
    <TrainingsManagement
      branchId={branchId}
      trainings={trainings}
      changeTraining={(training, changes) => {
        const variables = {
          branchId,
          id: training.id,
          name: changes.name || training.name,
          color: changes.color || training.color,
          description: changes.description,
          duration: changes.duration || training.duration,
          priceWithoutVat: changes.priceWithoutVat
            ? changes.priceWithoutVat
            : training.priceWithoutVat,
          priceWithVat: changes.priceWithVat ? changes.priceWithVat : training.priceWithVat,
          canPayLater:
            changes.canPayLater !== undefined ? changes.canPayLater : training.canPayLater,
          isPublic: changes.isPublic !== undefined ? changes.isPublic : training.isPublic,
        };

        return callChangeTrainingMutation({
          variables: variables,
        });
      }}
      createTraining={(trainingData) => {
        return callCreateTrainingMutation({
          variables: {
            branchId,
            createInput: trainingData,
          },
          update: (cache, result) => {
            const { training } = result.data.createTraining;
            cache.modify({
              fields: {
                branchTrainings(existing = []) {
                  cache.writeFragment({
                    data: training,
                    fragment: gql`
                      fragment NewTraining on Training {
                        id
                        name
                        color
                        description
                        duration
                        priceWithoutVat
                        priceWithVat
                        canPayLater
                        isPublic
                      }
                    `,
                  });
                },
              },
            });
          },
        });
      }}
      deleteTraining={(training) => {
        const { id } = training;
        return callDeleleTrainingMutation({
          variables: {
            branchId,
            id,
          },
          update: (cache) => {
            cache.modify({
              fields: {
                branchTrainings(existing, { readField }) {
                  return existing.filter((_) => id !== readField('id', _));
                },
              },
            });
          },
        });
      }}
    />
  );
};

export default () => {
  const {
    state: { branchId },
  } = useContext(BranchContext);

  return (
    <PageContainer>
      <Trainings branchId={branchId} />
    </PageContainer>
  );
};
