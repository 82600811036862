import React from 'react';

export function withActiveItem(activeTitle = 'activateItem') {
  const onFunc = `${activeTitle}On`;
  const offFunc = `${activeTitle}Off`;
  const toggleFunc = `${activeTitle}Toggle`;
  const itemName = `${activeTitle}ItemActive`;
  const activeItemExist = `${activeTitle}Exist`;

  return (WrappedComponent) => {
    class ActiveItemWrap extends React.Component {
      constructor(props) {
        super(props);
        this.state = { activeItem: undefined };

        this.onActivateItem = this.onActivateItem.bind(this);
        this.onDeactivateItem = this.onDeactivateItem.bind(this);
        this.onToggleItem = this.onToggleItem.bind(this);
      }

      onActivateItem(activeItem) {
        this.setState({ activeItem });
      }

      onToggleItem(activeItem) {
        if (this.state.activeItem === activeItem) {
          this.setState({ activeItem: undefined });
        } else {
          this.setState({ activeItem });
        }
      }

      onDeactivateItem() {
        if (this.state.activeItem !== undefined) {
          this.setState({ activeItem: undefined });
        }
      }

      render() {
        const { activeItem } = this.state;
        const propsFromState = {
          [itemName]: activeItem,
          [activeItemExist]: activeItem !== undefined,
        };

        const { onActivateItem, onDeactivateItem, onToggleItem } = this;
        const callbacks = {
          [onFunc]: onActivateItem,
          [offFunc]: onDeactivateItem,
          [toggleFunc]: onToggleItem,
        };

        return <WrappedComponent {...this.props} {...propsFromState} {...callbacks} />;
      }
    }

    return ActiveItemWrap;
  };
}

// tip: spread makeSwitchableDefaultProps flow type object to your component's props
export function makeSwitchable(switchState = 'switch') {
  const onFunc = `${switchState}On`;
  const offFunc = `${switchState}Off`;
  const toggleFunc = `${switchState}Toggle`;
  const isActiveTitle = `${switchState}Active`;

  return (WrappedComponent) => {
    class SwitchStateWrap extends React.Component {
      constructor(props) {
        super(props);
        this.state = { isActive: false };

        this.switchOn = this.switchOn.bind(this);
        this.switchOff = this.switchOff.bind(this);
        this.switchToggle = this.switchToggle.bind(this);
      }

      switchOn() {
        this.setState({ isActive: true });
      }

      switchOff() {
        this.setState({ isActive: false });
      }

      switchToggle() {
        this.setState((state) => ({ isActive: !state.isActive }));
      }

      render() {
        const { isActive } = this.state;
        const { switchOn, switchOff, switchToggle } = this;

        const passProps = {
          [onFunc]: switchOn,
          [offFunc]: switchOff,
          [toggleFunc]: switchToggle,
          [isActiveTitle]: isActive,
        };

        return <WrappedComponent {...this.props} {...passProps} />;
      }
    }

    return SwitchStateWrap;
  };
}

export function compose(...funcs) {
  if (funcs.length === 0) {
    return (arg) => arg;
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  const last = funcs[funcs.length - 1];
  const rest = funcs.slice(0, -1);
  return (...args) => rest.reduceRight((composed, f) => f(composed), last(...args));
}
